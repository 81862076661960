import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

export const SRO_FORM_COMPONENT_CLASS = 'sro-form-component'

export default class SROFormComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      hasError: false,
    })

    this.setHasError = this.setHasError.bind(this)
  }

  getClasses() {
    return [
      SRO_FORM_COMPONENT_CLASS,
      this.props.className,
      ...this.getExtraClasses(),
      { 'has-error': this.state.hasError },
    ]
  }

  setHasError(hasError) {
    this.setState({ hasError })
  }

  getExtraClasses() {
    return []
  }

  validateBySchema(data) {
    try {
      this.getSchema().validate(data)
      this.setBadFields([])
    } catch (err) {
      this.setBadFields(err.badFields || [])

      throw err
    }
  }

  setBadFields(refNames) {
    _.forIn(this.references, (ref, refName) => {
      const hasError = _.includes(refNames, refName)
      const element = this.references[refName].current

      if (element.setHasError && hasError) {
        element.setHasError(hasError)
      }
    })
  }
}

SROFormComponent.propTypes = {
  className: PropTypes.string,
}
