import React from 'react'

import _ from 'lodash'

import { messages } from '../../i18n/messages'
import GeoPicker, { isEmpty as isGeoEmpty } from '../widgets/SROFormComponents/GeoPicker'
import { WorkHoursPicker } from '../widgets/WorkHoursPicker'
import SROInput from '../widgets/SROFormComponents/SROInput'
import GreenCheckbox from '../widgets/GreenCheckbox'
import FormValidator from '../../utils/formValidator'
import SROFormComponent from '../widgets/SROFormComponents/SROFormComponents'
import Step2Shadow from './Step2Shadow'

const hasValue = (value) => value === 0 || !_.isEmpty(value)

class AgentLine extends SROFormComponent {
  constructor(props) {
    super(props)

    this.references = {
      sAddress: React.createRef(),
      eAddress: React.createRef(),
      workHours: React.createRef(),
      email: React.createRef(),
      isActive: React.createRef(),
      visitTimeMinutes: React.createRef(),
      dailyVisits: React.createRef(),
      maxDailyPhoneCalls: React.createRef(),
    }
  }

  getSchema() {
    return new FormValidator({
      'data.email': {
        title: `${messages.EMAIL} of agent ${this.props.externalId}`,
        validEmail: true,
        refName: 'email',
      },
    })
  }

  serialize() {
    const data = {
      id: this.props.id,
      externalAgentId: this.props.externalId,
      data: {
        isActive: this.references.isActive.current.getValue(),
        attributes: {
          ...this.props.attributes,
        },
      },
    }

    hasValue(this.references.email.current.getValue()) &&
      (data.data.email = this.references.email.current.getValue())
    !isGeoEmpty(this.references.sAddress.current.getValue()) &&
      (data.data.attributes.startLocation = this.references.sAddress.current.getValue())
    !isGeoEmpty(this.references.eAddress.current.getValue()) &&
      (data.data.attributes.endLocation = this.references.eAddress.current.getValue())
    hasValue(this.references.workHours.current.getValue()) &&
      (data.data.attributes.workingHours = this.references.workHours.current.getValue())
    hasValue(this.references.visitTimeMinutes.current.getValue()) &&
      (data.data.attributes.visitTimeMinutes = this.references.visitTimeMinutes.current.getValue())
    hasValue(this.references.dailyVisits.current.getValue()) &&
      (data.data.attributes.numDailyVisits = this.references.dailyVisits.current.getValue())
    hasValue(this.references.maxDailyPhoneCalls.current.getValue()) &&
      (data.data.attributes.maxDailyPhoneCalls =
        this.references.maxDailyPhoneCalls.current.getValue())

    this.validateBySchema(data)

    return data
  }

  render() {
    const orderStyle = {
      position: 'relative',
      zIndex: 99 - this.props.index,
    }

    return (
      <tr key={this.props.id}>
        <td>{this.props.externalId}</td>
        <td>{this.props.name}</td>
        <td style={orderStyle}>
          <GeoPicker
            className="s-address"
            defaultValue={this.props.attributes.startLocation}
            ref={this.references.sAddress}
            placeholder={messages.S_ADDRESS}
          />
        </td>
        <td style={orderStyle}>
          <GeoPicker
            className="e-address"
            defaultValue={this.props.attributes.endLocation}
            ref={this.references.eAddress}
            placeholder={messages.E_ADDRESS}
          />
        </td>
        <td>
          <WorkHoursPicker
            defaultValue={this.props.attributes.workingHours}
            ref={this.references.workHours}
          />
        </td>
        <td>
          <SROInput
            numeric={true}
            defaultValue={this.props.attributes.visitTimeMinutes}
            className="visit-time-minutes"
            ref={this.references.visitTimeMinutes}
          />
        </td>
        <td>
          <SROInput
            numeric={true}
            defaultValue={this.props.attributes.numDailyVisits}
            className="daily-visits"
            ref={this.references.dailyVisits}
          />
        </td>
        <td>
          <SROInput
            numeric={true}
            defaultValue={this.props.attributes.maxDailyPhoneCalls}
            className="max-daily-phone-calls"
            ref={this.references.maxDailyPhoneCalls}
          />
        </td>
        <td className="email-col">
          <SROInput defaultValue={this.props.email} ref={this.references.email} />
        </td>
        <td>
          <GreenCheckbox defaultChecked={!!this.props.isActive} ref={this.references.isActive} />
        </td>
      </tr>
    )
  }
}

class Step2AgentSettingsView extends SROFormComponent {
  static NEXT_BUTTON_TITLE = messages.NEXT

  constructor(props) {
    super(props)

    this.agentRequestId = this.props.fetchAgentData()
    this.agentLineRefs = []
    this.next = this.next.bind(this)
  }

  getFetchedAgentData() {
    return this.props.requestResults[this.agentRequestId]
  }

  renderTable() {
    const agentResponse = this.props.requestResults[this.agentRequestId]
    if (!agentResponse) {
      return null
    }
    const agentList = agentResponse.data
    const agentLines = _.map(agentList, (agentData, index) => {
      const ref = React.createRef()
      this.agentLineRefs.push(ref)
      return <AgentLine {...agentData} key={agentData.id} ref={ref} index={index} />
    })

    return (
      <table>
        <thead>
          <tr>
            <th>{messages.AGENT_CODE}</th>
            <th>{messages.AGENT_NAME}</th>
            <th>{messages.S_ADDRESS}</th>
            <th>{messages.E_ADDRESS}</th>
            <th>{messages.WORK_HOURS}</th>
            <th>{messages.VISIT_TIME}</th>
            <th>{messages.sro.daily_visits}</th>
            <th>{messages.sro.max_daily_phone_calls}</th>
            <th>{messages.EMAIL}</th>
            <th>{messages.ACTIVE}</th>
          </tr>
        </thead>
        <tbody>{agentLines}</tbody>
      </table>
    )
  }

  serialize() {
    return _.map(this.agentLineRefs, (agentLine) => agentLine.current.serialize())
  }

  next() {
    this.props.save({ agents: this.serialize() })
  }

  getExtraButtons() {
    return []
  }

  render() {
    if (!this.props.requestResults[this.agentRequestId]) {
      return <Step2Shadow />
    }
    return (
      <div className="agent-settings">
        <div className="section-title">{messages.AGENT_SETTINGS}</div>
        <div className="empty-values-comment">{messages.EMPTY_VALUES_SET_TO_DEFAULT}</div>
        {this.renderTable()}
      </div>
    )
  }
}

export default Step2AgentSettingsView
