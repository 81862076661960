import React, { useState } from 'react'
import { useTranslate } from 'react-admin'

import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

import WorkHoursModalView from '../../modals/WorkHoursModalView'
import { workHoursDataToDisplay } from '../../modals/WorkHoursModalView/workHourLogic'
import Icon from '../../icons'

const WorkHoursFormPicker = ({ label, className, helperText, source }) => {
  const translate = useTranslate()

  const {
    input: { onChange, value },
    meta: { touched, error },
  } = useField(source)

  const workHours = JSON.parse(value || '{}')

  const [showModal, setShowModal] = useState(false)

  const onSave = (value) => {
    setShowModal(false)
    onChange(JSON.stringify(value))
  }

  return (
    <div className="work-hours-form-picker">
      <div className="hidden-text-field">
        <TextField
          name={source}
          onChange={onChange}
          margin="normal"
          onFocus={() => setShowModal(true)}
          onBlur={() => {}}
          error={!!(touched && error)}
          helperText={(touched && error) || helperText}
          className={className}
        />
      </div>
      {showModal && (
        <WorkHoursModalView onSave={onSave} onCancel={() => setShowModal(false)} days={workHours} />
      )}
      <div className="upper-title">{translate(label)}</div>
      <div className="work-hours-input-frame" onClick={() => setShowModal(true)}>
        {workHoursDataToDisplay(workHours)}
        <div className="right-button">
          <Icon.clock />
        </div>
      </div>
    </div>
  )
}

WorkHoursFormPicker.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.string,
  source: PropTypes.string,
}

export default WorkHoursFormPicker
