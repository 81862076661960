import _ from 'lodash'

export class Route {
  static GOOGLE_MAP_LINK_PREFIX = 'https://www.google.com/maps/dir'

  constructor() {
    this.stops = []
  }

  addStop({ latitude, longitude, startTime, endTime, metersToNextStop, isEdge }) {
    this.stops.push({ latitude, longitude, startTime, endTime, metersToNextStop, isEdge })
  }

  getGoogleMapsLink() {
    return `${this.constructor.GOOGLE_MAP_LINK_PREFIX}/${this.formatGoogleMapsCoords()}`
  }

  getTotalMeters() {
    return _(this.stops)
      .map((stop) => stop.metersToNextStop)
      .sum()
  }

  formatGoogleMapsCoords() {
    return _(this.stops)
      .map((stop) => `${stop.latitude},${stop.longitude}`)
      .join('/')
  }

  getHour(dateTime) {
    let pieces

    try {
      pieces = dateTime.split(' ')
    } catch {
      return ''
    }

    if (pieces.length !== 2) {
      return ''
    }

    return pieces[1]
  }

  getTimeRange() {
    const sortedStops = _(this.stops)
      .filter((stop) => !stop.isEdge)
      .sortBy((stop) => stop.endTime && stop.endTime.moment)
      .value()

    if (sortedStops.length === 0) {
      return {}
    }

    return {
      startTime: sortedStops[0].startTime,
      endTime: sortedStops[sortedStops.length - 1].endTime,
    }
  }
}

export class CoordRange {
  constructor({ latitudeMin, latitudeMax, longitudeMin, longitudeMax }) {
    this.latitudeMin = latitudeMin
    this.latitudeMax = latitudeMax
    this.longitudeMin = longitudeMin
    this.longitudeMax = longitudeMax
  }

  min(valueA, valueB) {
    if (!valueA) {
      return valueB
    }

    if (!valueB) {
      return valueA
    }

    return valueA < valueB ? valueA : valueB
  }

  max(valueA, valueB) {
    if (!valueA) {
      return valueB
    }

    if (!valueB) {
      return valueA
    }

    return valueA > valueB ? valueA : valueB
  }

  getCommon(other) {
    return new CoordRange({
      longitudeMin: this.min(this.longitudeMin, other.longitudeMin),
      longitudeMax: this.max(this.longitudeMax, other.longitudeMax),
      latitudeMin: this.min(this.latitudeMin, other.latitudeMin),
      latitudeMax: this.max(this.latitudeMax, other.latitudeMax),
    })
  }

  isContaining(other) {
    return (
      other.latitudeMin >= this.latitudeMin &&
      other.latitudeMax <= this.latitudeMax &&
      other.longitudeMin >= this.longitudeMin &&
      other.longitudeMax <= this.longitudeMax
    )
  }

  getCenter() {
    return new CoordPoint({
      longitude: _.mean([this.longitudeMin, this.longitudeMax]),
      latitude: _.mean([this.latitudeMin, this.latitudeMax]),
    })
  }

  getWidth() {
    return this.longitudeMax - this.longitudeMin
  }

  getHeight() {
    return this.latitudeMax - this.latitudeMin
  }

  setLongitude(longitude) {
    this.longitude = longitude
    this.longitudeMax = longitude
    this.longitudeMin = longitude
  }

  setLatitude(latitude) {
    this.latitude = latitude
    this.latitudeMax = latitude
    this.latitudeMin = latitude
  }

  multiply(factors) {
    const longitudeRange = this.longitudeMax - this.longitudeMin
    const latitudeRange = this.latitudeMax - this.latitudeMin
    return new CoordRange({
      longitudeMax: this.longitudeMin + longitudeRange * factors.longitudeMax,
      longitudeMin: this.longitudeMax - longitudeRange * factors.longitudeMin,
      latitudeMax: this.latitudeMin + latitudeRange * factors.latitudeMax,
      latitudeMin: this.latitudeMax - latitudeRange * factors.latitudeMin,
    })
  }

  equals(other) {
    return (
      other.latitudeMin === this.latitudeMin &&
      other.latitudeMax === this.latitudeMax &&
      other.longitudeMin === this.longitudeMin &&
      other.longitudeMax === this.longitudeMax
    )
  }
}

export class CoordPoint extends CoordRange {
  constructor({ latitude, longitude }) {
    super({
      latitudeMin: latitude,
      latitudeMax: latitude,
      longitudeMin: longitude,
      longitudeMax: longitude,
    })

    this.longitude = longitude
    this.latitude = latitude
  }
}

export function getPointRange(points) {
  return _(points)
    .values()
    .flatten()
    .map((point) => point.coordPoint)
    .reduce((state, coordPoint) => state.getCommon(coordPoint), new CoordRange({}))
}
