import React from 'react'
import { useInput, useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import { SROMoment } from '../../../../utils/timeUtils'

import './sro-time-input.scss'

const SROTimeInput = (props) => {
  const translate = useTranslate()
  const { label, ...restProps } = props
  const {
    input: { name, onChange, value, ...restInput },
    meta: { touched, error },
    isRequired,
  } = useInput({ ...restProps })

  let parsedValue = value
  if (value === '') {
    parsedValue = null
  } else if (typeof value === 'string') {
    parsedValue = new SROMoment().fromTime(value).moment
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <TimePicker
        className="sro-time-input"
        {...restProps}
        name={name}
        variant="inline"
        inputProps={restInput}
        label={translate(label)}
        error={!!(touched && error)}
        helperText={touched && error}
        required={isRequired}
        ampm={false}
        value={parsedValue}
        onChange={onChange}
      />
    </MuiPickersUtilsProvider>
  )
}

SROTimeInput.propTypes = {
  label: PropTypes.string,
  parse: PropTypes.func,
}

export default SROTimeInput
