import React from 'react'

import _ from 'lodash'
import classnames from 'classnames'

import { renderPointGroup } from './googleMapsPointGroupRenderers'
import { CoordRange } from '../../../utils/geoUtils'
import SROMapView from '../sroMapView'
import {
  coordPointToGoogleMapsCoordPair,
  GOOGLE_MAPS_ZOOM_MIN,
  GOOGLE_MAPS_ZOOM_MAX,
  isZoomValid,
  coordRangeToGoogleMapsLatLngBounds,
} from '../../../utils/integrations/googleMapsUtils'
import {
  ROUTE_TYPE_TO_LINE_COLOR,
  CLIENT_ZOOM_LATITUDE_RANGE,
  CLIENT_ZOOM_LONGITUDE_RANGE,
} from '../sroMapDefs'
import Icon from '../../icons'
import LayerSelector from '../LayerSelector'

import '../sro-map.scss'

export default class SROGoogleMapsMapView extends SROMapView {
  constructor(props) {
    super(props)

    this.filter = () => this.props.nearbyOnly
    this.pointMarkers = []

    this.changeZoom = this.changeZoom.bind(this)
    this.setZoom = this.setZoom.bind(this)
    this.centralize = this.centralize.bind(this)
    this.getMapCoordRange = this.getMapCoordRange.bind(this)
    this.centralizeActivity = this.centralizeActivity.bind(this)
    this.onLayerFilterChange = this.onLayerFilterChange.bind(this)
  }

  getMaxZoom() {
    return GOOGLE_MAPS_ZOOM_MAX
  }

  renderRoute(map, route) {
    new window.google.maps.Polyline({
      map,
      path: _.map(route.coords, coordPointToGoogleMapsCoordPair),
      geodesic: true,
      strokeColor: ROUTE_TYPE_TO_LINE_COLOR[route.type],
      strokeOpacity: 1.0,
      strokeWeight: 2,
    })
  }

  renderPoints() {
    for (const pointType in this.props.points) {
      const pointGroup = this.props.points[pointType]
      this.pointMarkers = _.concat(this.pointMarkers, renderPointGroup(null, pointType, pointGroup))
    }

    this.setPointVisibility()
  }

  renderMap() {
    this.mapElement = document.getElementById(this.mapDOMId)
    this.map = new window.google.maps.Map(this.mapElement, {
      center: coordPointToGoogleMapsCoordPair(this.model.getRequiredCenter()),
      zoom: 10,
      fullscreenControl: false,
    })

    if (!this.model.hasPoints()) {
      this.mapController.setZoom(GOOGLE_MAPS_ZOOM_MIN)
      return
    }

    this.renderPoints()
    this.renderRoutes(this.map)
    this.adjustZoom(this.model.getPointRange())
  }

  componentDidMount() {
    this.renderMap()
  }

  componentWillUnmount() {
    this.mapElement.remove()
    delete this.map
  }

  renderContainer() {
    return (
      <div
        className={classnames('sro-map', this.props.className)}
        id={this.mapDOMId}
        key="container"
        style={this.props.style}
      />
    )
  }

  changeZoom(delta) {
    try {
      this.setZoom(this.zoom + delta)
    } catch {
      return
    }
  }

  setZoom(zoom) {
    if (!isZoomValid(zoom)) {
      throw new Error('Bad zoom')
    }

    this.map.setZoom(zoom)
  }

  getMapCoordRange() {
    const bounds = this.map.getBounds()
    return new CoordRange({
      latitudeMax: _.get(bounds, '_ne.lat'),
      longitudeMax: _.get(bounds, '_ne.lng'),
      latitudeMin: _.get(bounds, '_sw.lat'),
      longitudeMin: _.get(bounds, '_sw.lng'),
    })
  }

  setCenter(center) {
    this.map.setCenter(coordPointToGoogleMapsCoordPair(center))
  }

  centralizeActivity(activity) {
    const coords = activity.coords

    this.adjustZoom(
      new CoordRange({
        latitudeMin: coords.latitudeMin - CLIENT_ZOOM_LATITUDE_RANGE,
        latitudeMax: coords.latitudeMax + CLIENT_ZOOM_LATITUDE_RANGE,
        longitudeMin: coords.longitudeMin - CLIENT_ZOOM_LONGITUDE_RANGE,
        longitudeMax: coords.longitudeMax + CLIENT_ZOOM_LONGITUDE_RANGE,
      }),
    )
  }

  async adjustZoom(pointRange) {
    this.map.fitBounds(coordRangeToGoogleMapsLatLngBounds(this.model.getRequiredRange(pointRange)))
  }

  centralize(isInitial = true) {
    this.map.flyTo({
      center: this.getMapModel().getRequiredCenter(isInitial),
      zoom: this.zoom,
      speed: 1,
    })
  }

  onChangeZoomClick(delta) {
    const requestedZoom = this.model.zoom + delta
    if (requestedZoom > GOOGLE_MAPS_ZOOM_MAX || requestedZoom < GOOGLE_MAPS_ZOOM_MIN) {
      return
    }

    this.mapController.changeZoom(delta)
  }

  renderLayerSelector() {
    if (this.props.nearbyOnly) {
      return null
    }

    return (
      <LayerSelector
        onLayerFilterChange={this.onLayerFilterChange}
        layerFilter={this.layerFilter}
        requestParams={this.props.requestParams}
      />
    )
  }

  renderButtons() {
    return (
      <div className="map-buttons" key="buttons">
        <div className="map-button centralize" onClick={() => this.mapController.centralize(false)}>
          <Icon.scope />
        </div>
        {this.renderLayerSelector()}
      </div>
    )
  }

  setPointVisibility() {
    this.pointMarkers.forEach((pointMarker) => {
      pointMarker.marker.setMap(this.filter(pointMarker.point) ? this.map : null)
    })
  }

  onLayerFilterChange(filter) {
    this.filter = filter
    this.setPointVisibility()
  }

  render() {
    return (
      <div className="sro-map sro-google-maps-map-view">
        {this.renderContainer()}
        {this.renderButtons()}
      </div>
    )
  }
}
