import _ from 'lodash'
import { combineReducers } from 'redux'
import { CRUD_GET_LIST_SUCCESS } from 'ra-core'
import { ClientScreenResource } from '../providers/resources/comboResources'

function extraData(state = null, action) {
  if (
    action.type === CRUD_GET_LIST_SUCCESS &&
    _.get(action, 'meta.resource') === ClientScreenResource.RESOURCE_NAME
  ) {
    return action.payload
  }

  return state
}

export default combineReducers({
  extraData,
})
