import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import {
  List,
  Datagrid,
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  NumberField,
  DateField,
  SingleFieldList,
  ArrayField,
  ChipField,
  useTranslate,
} from 'react-admin'

export const PlanFailureList = (props) => {
  const translate = useTranslate()

  return (
    <div className="main-page list-view">
      <h1 className="page-title float-left">{translate('plan_failures')}</h1>
      <List {...props} className="sro-table">
        <Datagrid rowClick="show">
          <TextField source="id" label="ID" />
          <ReferenceField source="accountId" reference="accounts" label="ACCOUNT">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="agentId" reference="agents" label="AGENT">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" label="occurrence_date" />
        </Datagrid>
      </List>
    </div>
  )
}

const ExactExcludeDateField = ({ record, ...rest }) =>
  record && ['exactDate', 'excludeDate'].includes(record.type) ? (
    <DateField source="date" record={record} {...rest} />
  ) : null

ExactExcludeDateField.propTypes = {
  record: PropTypes.object,
}

const VisitDuringTimeField = ({ record, label, ...rest }) =>
  record && record.type === 'visitDuringTime' ? (
    <DateField record={record} label={label} {...rest} />
  ) : null

VisitDuringTimeField.propTypes = {
  record: PropTypes.object,
  label: PropTypes.string,
}

const LocationConstraintsTable = ({ record, source, label, ...rest }) => {
  return record && record[source] && record[source].length > 0 ? (
    <ArrayField record={record} source={source} label={label} {...rest}>
      <Datagrid>
        <TextField source="type" label="type" sortable={false} />
        <VisitDuringTimeField source="fromTime" label="START_TIME" sortable={false} />
        <VisitDuringTimeField source="toTime" label="END_TIME" sortable={false} />
        <ExactExcludeDateField sortable={false} />
      </Datagrid>
    </ArrayField>
  ) : null
}

LocationConstraintsTable.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  label: PropTypes.string,
}

const dayNumberToString = {
  1: 'sunday',
  2: 'monday',
  3: 'tuesday',
  4: 'wednesday',
  5: 'thursday',
  6: 'friday',
  7: 'saturday',
}

const WorkingHoursTable = ({ record, source, label, ...rest }) => {
  if (record && _.get(record, source) && Object.keys(_.get(record, source)).length > 0) {
    const workingHours = Object.keys(_.get(record, source)).map((dayNumber) => ({
      weekday: dayNumberToString[dayNumber],
      workingHours: _.get(record, source)[dayNumber].map((wh) => {
        const formattedFrom = `${_.padStart(wh.from[0], 2, '0')}:${_.padStart(wh.from[1], 2, '0')}`
        const formattedTo = `${_.padStart(wh.to[0], 2, '0')}:${_.padStart(wh.to[1], 2, '0')}`
        return {
          value: `${formattedFrom} - ${formattedTo}`,
        }
      }),
    }))

    record.newWorkingHours = workingHours
    return (
      <ArrayField record={record} source="newWorkingHours" label={label} {...rest}>
        <Datagrid>
          <TextField source="weekday" label="weekday" sortable={false} />
          <ArrayField source="workingHours" label="hours" sortable={false}>
            <SingleFieldList linkType={false}>
              <ChipField source="value" />
            </SingleFieldList>
          </ArrayField>
        </Datagrid>
      </ArrayField>
    )
  } else {
    return null
  }
}

WorkingHoursTable.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  label: PropTypes.string,
}

export const PlanFailureShow = (props) => {
  const translate = useTranslate()

  return (
    <div className="main-page">
      <h1 className="page-title">{translate('plan_failures')}</h1>
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="ID" />
          <ReferenceField source="accountId" reference="accounts" label="ACCOUNT">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="agentId" reference="agents" label="AGENT">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="accountId" reference="accounts" label="Account working hours">
            <WorkingHoursTable source="attributes.agent.workingHours" />
          </ReferenceField>
          <ReferenceField source="agentId" reference="agents" label="Agent specific working hours">
            <TextField source="attributes.workingHours" />
          </ReferenceField>
          <DateField source="createdAt" label="occurrence_date" />
          <ArrayField source="dates">
            <SingleFieldList linkType={false}>
              <ChipField source="value" />
            </SingleFieldList>
          </ArrayField>
          <TextField source="startLocation.address" label="S_ADDRESS" />
          <TextField source="endLocation.address" label="E_ADDRESS" />
          <ArrayField source="locations" label="CLIENTS">
            <Datagrid>
              <TextField source="externalId" label="CLIENT_CODE" sortable={false} />
              <TextField source="name" label="CLIENT_NAME" sortable={false} />
              <WorkingHoursTable source="openingHours" label="opening_hours" sortable={false} />
              <LocationConstraintsTable source="constraints" label="constraints" sortable={false} />
            </Datagrid>
          </ArrayField>
          <NumberField source="clientVisitTimeMinutes" />
          <NumberField source="searchTimeLimitSec" />
          <TextField source="error" label="error" />
        </SimpleShowLayout>
      </Show>
    </div>
  )
}
