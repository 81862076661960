import { connect } from 'react-redux'
import ClientView from '../components/ClientView'

const mapStateToProps = (state) => ({
  extraData: state.customReducers.clientList.extraData,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ClientView)
