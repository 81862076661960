import React from 'react'

import { messages } from '../../../i18n/messages'
import SRODropdown from '../../widgets/SROFormComponents/SRODropdown'
import SROInput from '../../widgets/SROFormComponents/SROInput'
import { accountIdEntry } from '../../../providers/localStorageProvider'
import Icons from '../../icons'
import FormValidator from '../../../utils/formValidator'
import SROFormComponent from '../../widgets/SROFormComponents/SROFormComponents'

export default class ConditionLine extends SROFormComponent {
  constructor(props) {
    super(props)

    this.state = { ...this.state, feature: this.props.feature, op: this.props.op }

    this.references = {
      feature: React.createRef(),
      op: React.createRef(),
      value: React.createRef(),
    }

    this.accountId = accountIdEntry.get()

    this.onFeatureChange = this.onFeatureChange.bind(this)
    this.onOpChange = this.onOpChange.bind(this)
  }

  getSchema() {
    return new FormValidator({
      feature: {
        title: messages.CHOOSE_FEATURE,
        required: true,
        refName: 'feature',
      },
      op: {
        title: messages.OP,
        required: true,
        refName: 'op',
      },
      value: {
        title: messages.VALUE,
        required: true,
        refName: 'value',
      },
    })
  }

  serialize() {
    const data = {
      feature: this.state.feature,
      op: this.state.op,
      value: this.references.value.current.getValue(),
    }

    this.validateBySchema(data)

    return data
  }

  onFeatureChange(newFeature) {
    this.setState({
      feature: newFeature,
    })
  }

  onOpChange(newOp) {
    this.setState({
      op: newOp,
    })
  }

  render() {
    return (
      <div className="condition-line">
        <SRODropdown
          ref={this.references.feature}
          title={messages.CHOOSE_FEATURE}
          options={this.props.features}
          value={this.state.feature}
          onChange={this.onFeatureChange}
        />
        <SRODropdown
          ref={this.references.op}
          title={messages.OP}
          options={this.props.ops}
          value={this.state.op}
          onChange={this.onOpChange}
        />
        <SROInput
          className="value-field"
          ref={this.references.value}
          title={messages.VALUE}
          defaultValue={this.props.value}
        />
        <Icons.xInCircle onClick={this.props.onRemove} />
      </div>
    )
  }
}
