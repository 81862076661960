import React from 'react'
import PropTypes from 'prop-types'

import { Pie } from './pieUtil'

const OUTER_RADIUS = 38
const INNER_RADIUS = 29
const THICKNESS = 6
const MARGIN = 3
const SIDE_SIZE = (OUTER_RADIUS + MARGIN) * 2

class NestedPie extends React.Component {
  constructor(props) {
    super(props)

    this.innerPerc = this.sanitizeValue(props.innerPerc)
    this.outerPerc = this.sanitizeValue(props.outerPerc)
  }

  renderPie(options) {
    const coverEllipseRadius = options.radius - THICKNESS
    const pie = new Pie(options.radius, options.center, THICKNESS)
    const beginningCircle = pie.getCoordsByPerc(0)
    const endCircle = pie.getCoordsByPerc(options.perc)

    return (
      <g>
        <ellipse
          cx={options.center}
          cy={options.center}
          rx={options.radius}
          ry={options.radius}
          fill={options.fill}
        />
        <ellipse
          cx={options.center}
          cy={options.center}
          rx={coverEllipseRadius}
          ry={coverEllipseRadius}
          fill={this.props.backgroundColor}
        />
        <polygon points={pie.makeSlicePoints(options.perc)} fill={options.emptyColor} />
        <ellipse
          cx={beginningCircle.x}
          cy={beginningCircle.y}
          rx={THICKNESS / 2}
          ry={THICKNESS / 2}
          fill={options.fill}
        />
        <ellipse
          cx={endCircle.x}
          cy={endCircle.y}
          rx={THICKNESS / 2}
          ry={THICKNESS / 2}
          fill={options.fill}
        />
        <ellipse
          cx={options.center}
          cy={options.center}
          rx={options.radius - THICKNESS}
          ry={options.radius - THICKNESS}
          fill={this.props.backgroundColor}
        />
      </g>
    )
  }

  renderSVG() {
    return (
      <svg height={SIDE_SIZE} width={SIDE_SIZE}>
        {this.renderPie({
          center: OUTER_RADIUS + MARGIN,
          radius: OUTER_RADIUS,
          fill: this.props.outerColor,
          emptyColor: this.props.outerEmptyColor,
          perc: this.outerPerc,
        })}
        {this.renderPie({
          center: OUTER_RADIUS + MARGIN,
          radius: INNER_RADIUS,
          fill: this.props.innerColor,
          emptyColor: this.props.innerEmptyColor,
          perc: this.innerPerc,
        })}
      </svg>
    )
  }

  sanitizeValue(value) {
    if (!window.isFinite(value)) {
      return 0
    }

    return Math.round(value)
  }

  renderTooltip() {
    return (
      <div className="pie-tooltip">
        <ul>
          <li style={{ color: this.props.innerColor }}>{`${this.props.innerLabel} ${Math.round(
            this.innerPerc,
          )}%`}</li>
          <li style={{ color: this.props.outerColor }}>{`${this.props.outerLabel} ${Math.round(
            this.outerPerc,
          )}%`}</li>
        </ul>
        <div className="triangle"> &nbsp; </div>
      </div>
    )
  }

  render() {
    return (
      <div
        className="nested-pie"
        style={{ width: `${OUTER_RADIUS * 2}px`, height: `${OUTER_RADIUS * 2}px` }}
      >
        {this.renderTooltip()}
        {this.renderSVG()}
      </div>
    )
  }
}

NestedPie.propTypes = {
  innerPerc: PropTypes.number,
  innerColor: PropTypes.string,
  innerLabel: PropTypes.string,
  innerEmptyColor: PropTypes.string,
  outerPerc: PropTypes.number,
  outerColor: PropTypes.string,
  outerLabel: PropTypes.string,
  outerEmptyColor: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default NestedPie
