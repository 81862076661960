import { connect } from 'react-redux'
import Step1AccountSettingsView from '../../components/SettingsView/Step1AccountSettingsView'
import { messages } from '../../i18n/messages'
import { SaveStep1Action, FetchAccountAction } from '../../actions/settings'

function mapStateToProps(state) {
  return {
    accountData: state.customReducers.settings.accountData,
    messages,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    save: (accountData) => dispatch(SaveStep1Action.make(accountData)),
    fetchAccountData: (accountId) => dispatch(FetchAccountAction.make(accountId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  Step1AccountSettingsView,
)
