import React from 'react'
import { useTranslate } from 'react-admin'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './sro-tooltip.scss'

function getBallonPosition(position, width) {
  let top = position === 'top' ? '-45px' : 'calc(100% + 45px)'
  let left = `calc(50% - ${width / 2}px)`
  if (position === 'left') {
    top = 'calc(50% - 15px)'
    left = 'calc(-100% - 60px)'
  } else if (position === 'right') {
    top = 'calc(50% - 15px)'
    left = 'calc(100% + 20px)'
  }
  return {
    top,
    left,
  }
}

function getTrianglePosition(position) {
  let bottom = position === 'top' ? '-5px' : 'calc(100% - 5px)'
  let left = 'calc(50% - 4px)'
  if (position === 'left') {
    bottom = 'calc(50% - 5px)'
    left = 'calc(100% - 5px)'
  } else if (position === 'right') {
    bottom = 'calc(50% - 5px)'
    left = '-5px'
  }
  return {
    bottom,
    left,
  }
}

const TooltipBalloon = ({ text, position }) => {
  const width = 30 + text.length * 4
  return (
    <div
      className="sro-lightweight-tooltip"
      style={{ width, ...getBallonPosition(position, width) }}
    >
      {text}
      <div className="triangle" style={{ ...getTrianglePosition(position) }}></div>
    </div>
  )
}

TooltipBalloon.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  position: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
}

const SROTooltip = ({ text, position = 'top', className, displayBlock, ...props }) => {
  const translate = useTranslate()
  const displayBlockClass = displayBlock ? 'display-block' : ''
  return (
    <div
      {...props}
      className={classnames(className, displayBlockClass, 'sro-lightweight-tooltip-anchor')}
    >
      {text && <TooltipBalloon text={translate(text, { _: text })} position={position} />}
      {props.children}
    </div>
  )
}

SROTooltip.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  position: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  className: PropTypes.string,
  displayBlock: PropTypes.bool,
}

export default SROTooltip
