import React from 'react'

import _ from 'lodash'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import PropTypes from 'prop-types'

import SROButton from '../../SROFormComponents/SROButton'

import './sro-line-chart.scss'
import { getDataSlice } from '../../../../utils/chartUtils'

import '../sro-charts.scss'

export default class SROLineChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      scrollValue: 0,
    })

    this.isScrollNecessary = this.isScrollNecessary.bind(this)
    this.scroll = this.scroll.bind(this)
    this.getDataLength = this.getDataLength.bind(this)
    this.translateItemKeys = this.translateItemKeys.bind(this)
  }

  render() {
    return (
      <div className="sro-line-chart">
        {this.renderScrollButtons()}
        {this.renderLineChart()}
      </div>
    )
  }

  isScrollNecessary() {
    return this.getDataLength() > this.props.maxHorizontalItems
  }

  getDataLength() {
    return _.get(this.props, 'data.length', 0)
  }

  scroll(isForward) {
    const delta = isForward ? this.props.itemsPerScroll : -this.props.itemsPerScroll
    const newScrollValue = this.state.scrollValue + delta

    if (newScrollValue >= this.getDataLength() || newScrollValue < 0) {
      return
    }

    this.setState({
      scrollValue: newScrollValue,
    })
  }

  renderScrollButtons() {
    if (!this.isScrollNecessary()) {
      return null
    }

    return (
      <div className="scroll-buttons">
        <SROButton className="scroll-button scroll-backward" onClick={() => this.scroll(false)}>
          {' '}
          &lt;&lt;{' '}
        </SROButton>
        <SROButton className="scroll-button scroll-forward" onClick={() => this.scroll(true)}>
          {' '}
          &gt;&gt;{' '}
        </SROButton>
      </div>
    )
  }

  translateItemKeys(item) {
    const originalKeys = _.keys(this.props.metrics)
    const keys = _.map(originalKeys, (key) => this.props.metrics[key].label)
    const values = _.map(originalKeys, (key) => item[key])

    return {
      ..._.zipObject(keys, values),
      name: item.name,
    }
  }

  renderLineChart() {
    const CustomTooltip = (props) => {
      return (
        <div className="line-tooltip">
          {this.props.generateTooltip(props)}
          <div className="line-tooltip-background"></div>
        </div>
      )
    }

    return (
      <LineChart
        width={this.props.width}
        height={this.props.height}
        data={getDataSlice(
          this.props.data,
          this.state.scrollValue,
          this.props.maxHorizontalItems,
          this.translateItemKeys,
        )}
        margin={{
          top: 5,
          right: 20,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid horizontal={false} stroke="#e7ebef" />
        <XAxis
          dataKey="name"
          stroke="#485465"
          tick={{ fontFamily: 'Ubuntu', fontSize: 11 }}
          axisLine={false}
        />
        <YAxis stroke="#485465" tick={{ fontFamily: 'Ubuntu', fontSize: 11 }} axisLine={false} />
        <Legend
          align="right"
          verticalAlign="top"
          wrapperStyle={{
            paddingBottom: '10px',
          }}
        />
        {_.map(this.props.metrics, (config, key) => (
          <Line
            type="monotone"
            dataKey={this.props.metrics[key].label}
            stroke={config.color}
            strokeWidth="2px"
            dot={false}
            key={key}
          />
        ))}
        <Tooltip content={<CustomTooltip />} />
      </LineChart>
    )
  }
}

SROLineChart.defaultProps = {
  width: 500,
  height: 300,
  maxHorizontalItems: 20,
  itemsPerScroll: 1,
}

SROLineChart.propTypes = {
  config: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  cx: PropTypes.number,
  cy: PropTypes.number,
  maxHorizontalItems: PropTypes.number,
  itemsPerScroll: PropTypes.number,
  metrics: PropTypes.object,
  data: PropTypes.array,
  generateTooltip: PropTypes.func,
}
