import React from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import inflection from 'inflection'
import compose from 'recompose/compose'
import { withStyles, createStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { getResources, translate } from 'ra-core'
import DefaultIcon from '@material-ui/icons/ViewList'
import SROTooltip from './widgets/SROTooltip'
import { locationTrackingUrlEntry } from '../providers/localStorageProvider'

import { DashboardMenuItem, Responsive } from 'react-admin'
import { MENU_SIDEBAR_CLASSNAME } from '../utils/reactAdminUtils'
import ProfileMenu from './Sidebar/ProfileMenu'
import SidebarIcons from './Sidebar/SidebarIcons'
import MenuItemLink from './widgets/MenuItemLink'

const styles = createStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
})

const translatedResourceName = (resource, translate) =>
  translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _:
      resource.options && resource.options.label
        ? translate(resource.options.label, {
            smart_count: 2,
            _: resource.options.label,
          })
        : inflection.humanize(inflection.pluralize(resource.name)),
  })

class Menu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      isOpen: true,
    }

    this.toggleOpen = this.toggleOpen.bind(this)
  }

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen }, this.props.toggleMenu)
  }

  render() {
    const {
      classes,
      className,
      dense,
      hasDashboard,
      onMenuClick,
      resources,
      translate,
      logout,
      ...rest
    } = this.props

    const cleanRest = _.omit(rest, ['toggleMenu'])
    const locationTrackingUrl = locationTrackingUrlEntry.get()

    return (
      <div className={classnames(classes.main, className, MENU_SIDEBAR_CLASSNAME)} {...cleanRest}>
        <div className="fill-sidebar">&nbsp;</div>
        <ProfileMenu />
        {hasDashboard && <DashboardMenuItem onClick={onMenuClick} />}
        {resources
          .filter((r) => r.hasList)
          .filter((r) => r.options.show !== false)
          .map((resource) => {
            const title = translatedResourceName(resource, translate)
            return (
              <SROTooltip
                position="right"
                text={this.state.isOpen ? '' : title}
                key={resource.name}
              >
                <MenuItemLink
                  to={`/${resource.name}`}
                  primaryText={title}
                  leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                  onClick={onMenuClick}
                  dense={dense}
                />
              </SROTooltip>
            )
          })}
        {locationTrackingUrl !== 'undefined' && (
          <MenuItemLink
            to={locationTrackingUrl}
            primaryText={translate('sro.location_tracking')}
            leftIcon={<SidebarIcons.LocationTrackingIcon />}
            isExternalLink={true}
          />
        )}
        <Responsive xsmall={logout} medium={null} />

        <div className="collapse-wrapper" onClick={this.toggleOpen}>
          <SidebarIcons.CollapseIcon />
        </div>
      </div>
    )
  }
}

Menu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
  onMenuClick: PropTypes.func,
  open: PropTypes.bool,
  pathname: PropTypes.string,
  resources: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
}

Menu.defaultProps = {
  onMenuClick: () => null,
}

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  resources: getResources(state),
  pathname: state.router.location.pathname, // used to force redraw on navigation
})

const enhance = compose(
  translate,
  connect(
    mapStateToProps,
    {}, // Avoid connect passing dispatch in props,
    null,
    {
      areStatePropsEqual: (prev, next) =>
        prev.resources.every(
          (value, index) => value === next.resources[index], // shallow compare resources
        ) &&
        prev.pathname === next.pathname &&
        prev.open === next.open,
    },
  ),
  withStyles(styles),
)

export default enhance(Menu)
