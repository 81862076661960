import React from 'react'
import _ from 'lodash'

import './styles.scss'

class Circle extends React.Component {
  render() {
    return (
      <div className="circle">
        <div className="outer"></div>
        <div className="inner"></div>
      </div>
    )
  }
}

class Gauge extends React.Component {
  render() {
    return (
      <div className="gauge box">
        <div className="gauge-title"></div>
        <Circle />
      </div>
    )
  }
}

class Pie extends React.Component {
  render() {
    return (
      <div className="pie">
        <div className="legend">
          {_.map(_.range(0, 5), (index) => (
            <div key={`legend-${index}`} className="legend-line" />
          ))}
        </div>
        <Circle />
      </div>
    )
  }
}

class ColChart extends React.Component {
  render() {
    const BAR_OFFSET_PX = 100
    const BAR_DIFF_PX = 67

    const BARS = [84, 135, 120, 168, 198, 120, 84]

    return (
      <div className="col-chart box">
        <div className="horizontal"></div>
        {_.map(BARS, (height, index) => {
          return (
            <div
              key={`vertical-${index}`}
              className="vertical"
              style={{ height: `${height}px`, left: `${BAR_OFFSET_PX + BAR_DIFF_PX * index}px` }}
            ></div>
          )
        })}
      </div>
    )
  }
}

export default class DashboardView extends React.Component {
  render() {
    return (
      <div className="dashboard-shadow">
        <div className="line">
          <Gauge />
          <Gauge />
          <Gauge />
        </div>
        <div className="line">
          <div className="pie-single box">
            <Pie />
          </div>
          <div className="pie-single box">
            <Pie />
          </div>
          <div className="pie-single box">
            <Pie />
          </div>
        </div>
        <div className="line">
          <ColChart />
          <ColChart />
        </div>
      </div>
    )
  }
}
