import _ from 'lodash'

import { messages, fill } from '../../i18n/messages'
import {
  CLIENT_POINT,
  PHONE_POINT,
  VISITED_CLIENT_POINT,
  ROUTE_END_POINT,
  LOCATION_POINT,
  ROUTE_START_POINT,
  ROUTE_START_AND_END_POINT,
  NEARBY_POINT,
  VISIT_TYPE_PHONE,
  UNPLANNED_PHONE_POINT,
  UNPLANNED_ON_SITE_POINT,
  VISIT_TYPE_CLIENT,
} from '../../components/SROMapView/sroMapDefs'
import { formatDate, formatTime, formatDateTime } from '../../utils/timeUtils'

export default class AgentLocationTransformer {
  getLastLocationPoints(lastLocation, agent) {
    if (!lastLocation.coordPoint || !lastLocation.coordPoint.latitude) {
      return {}
    }

    return {
      [LOCATION_POINT]: [
        {
          coordPoint: lastLocation.coordPoint,
          desc: fill(messages.AGENT_LOCATION, { agentName: agent.name }),
          datetime: formatDateTime(lastLocation.date),
        },
      ],
    }
  }

  translateToEdge(activity) {
    return Object.assign({}, activity, {
      coords: _.map(activity.coords, (value) => value + 0.001),
      isEdge: true,
      activity: null,
      client: undefined,
      clientId: null,
    })
  }

  getActivityPoints(activities, agentFirstLocation) {
    if (activities.length === 0) {
      return []
    }

    const preActivities = []
    const postActivities = []

    const openActivities = _.filter(
      activities,
      (activity) => !activity.isClosed && !activity.isCanceled,
    )

    if (openActivities[0] && !openActivities[0].isEdge) {
      preActivities.push(this.translateToEdge(openActivities[0]))
    }

    const lastOpen = openActivities[openActivities.length - 1]

    if (lastOpen && !lastOpen.isEdge) {
      postActivities.push(this.translateToEdge(lastOpen))
    }

    const allActivities = _.concat(preActivities, activities, postActivities)

    const filteredPoints = _(allActivities)
      .filter((activity) => activity.coords)
      .map((activity, index) => ({
        index,
        name: activity.clientName,
        visitType: activity.visitType,
        coordPoint: activity.coords,
        desc: activity.clientName,
        isEdge: activity.isEdge,
        isClosed: activity.isClosed,
        plannedVisit: activity.isClosed ? '' : formatTime(activity.plannedArrivalTime),
        arrivalTime: activity.isClosed ? formatTime(activity.arrivalTime) : '',
        rateName: activity.rateName,
        isPlanned: activity.isPlanned,
      }))
      .filter((activity) => !activity.isEdge || activity.index === 0)

    const pointCount = filteredPoints.value().length

    const points = filteredPoints
      .groupBy((activity) => {
        if (activity.index === 0) {
          return ROUTE_START_POINT
        }

        if (activity.index === pointCount - 1) {
          return ROUTE_END_POINT
        }

        if (activity.visitType === VISIT_TYPE_PHONE) {
          return activity.isPlanned ? PHONE_POINT : UNPLANNED_PHONE_POINT
        }

        if (activity.visitType === VISIT_TYPE_CLIENT) {
          return activity.isPlanned ? VISITED_CLIENT_POINT : UNPLANNED_ON_SITE_POINT
        }

        if (activity.isClosed) {
          return VISITED_CLIENT_POINT
        }

        return CLIENT_POINT
      })
      .mapValues((pointGroup) => _.map(pointGroup, (point) => _.omit(point, ['index'])))
      .value()

    if (agentFirstLocation) {
      points[ROUTE_START_POINT][0] = {
        coordPoint: agentFirstLocation.coordPoint,
        datetime: formatTime(agentFirstLocation.startTime),
        routeStarted: true,
      }
    } else {
      const activity = points[ROUTE_START_POINT][0]
      points[ROUTE_START_POINT][0] = {
        coordPoint: activity.coordPoint,
        datetime: activity.plannedVisit,
        routeStarted: false,
      }
    }

    // Make sure you see both start and end in case they are in the same position
    const startCoords = _.get(points[ROUTE_START_POINT], '[0].coordPoint')
    const endCoords = _.get(points[ROUTE_END_POINT], '[0].coordPoint')

    if (startCoords && endCoords && startCoords.equals(endCoords)) {
      points[ROUTE_START_AND_END_POINT] = points[ROUTE_START_POINT]
      delete points[ROUTE_START_POINT]
      delete points[ROUTE_END_POINT]
    }

    return points
  }

  getNearbyClientPoints(nearbyClients, clientIdsInRoute) {
    return {
      [NEARBY_POINT]: _(nearbyClients)
        .filter((nearbyClient) => clientIdsInRoute.indexOf(nearbyClient.clientId) === -1)
        .map((nearbyClient) => ({
          ...nearbyClient,
          desc: nearbyClient.name,
          lastVisit: formatDate(nearbyClient.lastVisit),
          plannedVisit: formatDate(nearbyClient.plannedVisit),
          nextVisitDate: formatDate(nearbyClient.nextVisitDate),
        }))
        .value(),
    }
  }

  getPoints({
    agent,
    activities,
    nearbyClients,
    agentLastLocation,
    nearbyOnly,
    agentFirstLocation,
  }) {
    const routeClientIds = _.map(activities, (activity) => activity.clientId)
    const nearbyClientPoints = this.getNearbyClientPoints(nearbyClients, routeClientIds)

    const points = {
      ...this.getActivityPoints(activities, agentFirstLocation),
      ...this.getLastLocationPoints(agentLastLocation, agent),
      ...nearbyClientPoints,
    }

    if (nearbyOnly) {
      return _.pick(points, [NEARBY_POINT, LOCATION_POINT])
    }

    return points
  }
}
