import React, { useState } from 'react'
import ClickAwareBox from 'click-aware-box'
import PropTypes from 'prop-types'

import { useTranslate } from 'react-admin'

import './sro-secret-input.scss'
import '../sro-form-style.scss'

const SROSecretInput = (props) => {
  const { record, source, label } = props
  const translate = useTranslate()
  const [showModal, setShowModal] = useState(false)

  function renderModal() {
    if (!showModal) {
      return null
    }

    return (
      <div className="secret-modal-wrapper">
        <div className="secret-modal">
          <div className="secret">{record[source]}</div>
          <div className="close-button" onClick={() => setShowModal(false)}>
            x
          </div>
        </div>
      </div>
    )
  }

  return (
    <ClickAwareBox
      className="sro-secret-input sro-form-component"
      onClickOut={() => setShowModal(false)}
    >
      {label && <div className="upper-title">{translate(label)}</div>}
      <div className="reveal-button" onClick={() => setShowModal(true)}>
        {translate('SHOW')}
      </div>
      {renderModal()}
    </ClickAwareBox>
  )
}

SROSecretInput.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
}

export default SROSecretInput
