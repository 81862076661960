import React, { useState } from 'react'
import { useLogin, Notification } from 'react-admin'

import { messages, fill } from '../../i18n/messages'
import SROInput from '../widgets/SROFormComponents/SROInput'
import SROButton from '../widgets/SROFormComponents/SROButton'
import SRONotification, { notifyError, notifyInfo } from '../widgets/SRONotification'
import { useGoToResource } from '../../utils/reactAdminUtils'
import Requester from '../../providers/request'
import { PasswordResetEmailResource } from '../../providers/resources/requestResources'
import { isAccountValidEntry } from '../../providers/localStorageProvider'
import { SettingsResource } from '../../providers/resources/requestResources'

import './login.scss'
import amodatLogo from './amodat-logo.png'
import addSpot from './loginSideImages/add-spot.svg'
import addSpotAction from './loginSideImages/add-spot-action.svg'
import pie from './loginSideImages/pie.svg'
import signalBars from './loginSideImages/signal-bars.svg'
import success from './loginSideImages/success.svg'
import path from './loginSideImages/path.svg'
import Icon from '../icons'

export default function LoginPage() {
  const [isLoading, setIsLoading] = useState(false)
  const login = useLogin()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const goToResource = useGoToResource()

  async function submit(e) {
    e.preventDefault()

    setIsLoading(true)

    try {
      await login({ username, password })
      const isAccountValid = isAccountValidEntry.get()
      if (isAccountValid === false) {
        goToResource(SettingsResource)
      }
    } catch (err) {
      notifyError(messages.LOGIN_FAILED)
      setIsLoading(false)
    }
  }

  async function resetPassword() {
    if (!username) {
      notifyError(messages.PLEASE_FILL_USERNAME)
      return
    }

    try {
      await new Requester().post(PasswordResetEmailResource, { data: { userIdentifier: username } })
    } catch (err) {
      notifyError(fill(messages.REQUEST_ERROR, { errorMessage: err.message }))
      return
    }

    notifyInfo(messages.PASSWORD_EMAIL_SENT)
  }

  return (
    <div className="login-page">
      {SRONotification.getElement()}
      <Notification />
      <div className="login-background">
        <div className="login-title">{messages.SYSTEM_WELCOME}</div>
        <div className="login-subtitle">{messages.SRO_TITLE}</div>

        <img src={addSpot} className="add-spot" alt="add-spot" />
        <img src={addSpotAction} className="add-spot-action" alt="add-spot-action" />
        <img src={pie} className="pie" alt="pie" />
        <img src={signalBars} className="signal-bars" alt="signal-bars" />
        <img src={success} className="success" alt="success" />
        <img src={path} className="path" alt="path" />
      </div>
      <div className="login-flow-form-wrapper">
        <form className="login-flow-form">
          <img src={amodatLogo} className="amodat-logo" alt="amodat-logo" />
          <SROInput
            defaultValue={username}
            onChange={(value) => setUsername(value)}
            placeholder={messages.USERNAME}
          />
          <SROInput
            type="password"
            defaultValue={password}
            onChange={(value) => setPassword(value)}
            placeholder={messages.PASSWORD}
          />
          <SROButton className="reset-password-button" onClick={resetPassword}>
            {messages.RESET_PASSWORD}
          </SROButton>
          {isLoading ? (
            <Icon.spinner />
          ) : (
            <SROButton type="submit" onClick={submit}>
              {messages.LOGIN}
            </SROButton>
          )}
        </form>
      </div>
    </div>
  )
}
