import React, { useState, useRef, useEffect, useCallback } from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useNotify } from 'react-admin'

import { messages } from '../../i18n/messages'

import './trip-map.scss'
import SROGoogleMapsMapView from '../SROMapView/SROGoogleMapsMapView'
import { ROUTE_TYPE_VISITED, ROUTE_TYPE_UNVISITED } from '../SROMapView/sroMapDefs'
import { accountIdEntry } from '../../providers/localStorageProvider'
import icon from '../icons'
import { TripMapResource } from '../../providers/resources/requestResources'
import { MOBILE_WIDTH_PX } from '../../Config'
import { sleep } from '../../utils/asyncUtils'
import { SROMoment } from '../../utils/timeUtils'
import TripMapShadow from './TripMapShadow'
import { isGoogleMapsLoaded } from '../../utils/integrations/googleMapsUtils'
import { getRandomString } from '../../utils/stringUtils'
import { useTripMapData } from '../../hooks/tripMapHooks'

const MOBILE_TABLE_HEIGHT_PERCENT = 70
const TABLE_WIDTH_PX = 500

export function generateDayTripLink({ agentId, date }) {
  return `/${TripMapResource.RESOURCE_NAME}?agentId=${agentId}&date=${date}`
}

const TRIP_TABLE_SECTION_CLASS_NAME = 'trip-table-section'
const SELECTORS_TO_HIDE_ON_EMBEDDED = ["div[class*='MuiDrawerdocked-']", '.headroom-wrapper']

const MODE_ROUTE = 'route'
const MODE_NEARBY = 'nearby'

function isMobile() {
  return window.innerWidth <= MOBILE_WIDTH_PX
}

function getTopContainer() {
  return document.getElementsByClassName('trip-map-page')[0]
}

function getDesiredContentSize(isPopup) {
  const decrease = isPopup ? 40 : 0
  return {
    height: window.innerHeight - decrease,
    width: window.innerWidth - decrease,
  }
}

function getTableWidth(tableOpen) {
  if (isMobile() || !tableOpen) {
    return 0
  }

  return TABLE_WIDTH_PX
}

function getTableHeight() {
  if (!isMobile()) {
    return 0
  }

  return `${MOBILE_TABLE_HEIGHT_PERCENT}%`
}

const TripMapView = (props) => {
  const { isPopup, onClose } = props
  const { search } = useLocation()
  const notify = useNotify()

  function getInjectedParam(param) {
    return props[param] || new URLSearchParams(search).get(param)
  }

  const agentId = getInjectedParam('agentId')
  const dateStr = getInjectedParam('date')
  const date = dateStr ? SROMoment.fromString(dateStr) : SROMoment.now()
  const accountId = parseInt(getInjectedParam('accountId') || accountIdEntry.get())
  const externalAgentId = getInjectedParam('externalAgentId')
  const nearbyOnly = getInjectedParam('mode', MODE_ROUTE) === MODE_NEARBY
  const embedded = getInjectedParam('embedded', false) === 'true'

  function getRequestParams() {
    return _.pickBy({ accountId, agentId, date, externalAgentId, nearbyOnly }, _.identity)
  }

  const { data = {}, error } = useTripMapData(getRequestParams())
  const { agentLastLocation, activities, routes, points } = data

  if (error) {
    notify('REQUEST_ERROR', 'error', { errorMessage: error.message })
  }

  const [tableOpen, setTableOpen] = useState(!embedded && !isMobile() && !nearbyOnly)
  const [showHoveringMessage, setShowHoveringMessage] = useState(true)
  const [readyToRenderMap, setReadyToRenderMap] = useState(false)
  const mapComponentRef = useRef()

  function getTableSection() {
    return document.getElementsByClassName(TRIP_TABLE_SECTION_CLASS_NAME)[0]
  }

  const fillScreen = useCallback(() => {
    const topContainer = getTopContainer()
    if (!topContainer) {
      return
    }

    const dimensions = getDesiredContentSize(isPopup)
    topContainer.style.height = `${dimensions.height}px`
    topContainer.style.width = `${dimensions.width}`
  }, [isPopup])

  useEffect(() => {
    window.addEventListener('resize', fillScreen)

    async function awaitTableSection() {
      while (!getTableSection()) {
        await sleep(100)
      }
    }

    awaitTableSection().then(() => {
      setReadyToRenderMap(true)
    })

    return function cleanup() {
      window.removeEventListener('resize', fillScreen)
    }
  }, [fillScreen])

  function centralizeActivity(activity) {
    const mapComponent = _.get(mapComponentRef, 'current')
    if (!mapComponent) {
      return
    }

    mapComponent.centralizeActivity(activity)

    if (isMobile()) {
      setTableOpen(false)
    }
  }

  function renderTableLine(activity) {
    return (
      <tr key={activity.id}>
        <td>
          <div className="centralize-client-button" onClick={() => centralizeActivity(activity)}>
            <icon.scope />
          </div>
        </td>
        <td className={classnames('line-tooltip')}>{activity.clientName}</td>
        <td>{activity.clientCity}</td>
        <td className="insignificant">
          {activity.plannedArrivalTime ? activity.plannedArrivalTime.formatTime() : '-'}
        </td>
        <td className="insignificant">
          {activity.arrivalTime ? activity.arrivalTime.formatTime() : '-'}
        </td>
        <td>{`${activity.visitTimeMinutes} min`}</td>
      </tr>
    )
  }

  function renderTable() {
    const renderedActivities = _(activities)
      .filter((activity) => !!activity.clientId)
      .map(renderTableLine)
      .value()

    return [
      <div
        className={classnames(TRIP_TABLE_SECTION_CLASS_NAME, { closed: !tableOpen })}
        key="trip-table"
      >
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{messages.CLIENT}</th>
                <th>{messages.CITY_NAME}</th>
                <th>{messages.V_TIME_PLANNED}</th>
                <th>{messages.V_TIME_ACTUAL}</th>
                <th>{messages.V_DURATION}</th>
              </tr>
            </thead>
            <tbody>{renderedActivities}</tbody>
          </table>
        </div>
        <div className="table-toggle-wrapper">
          <div className="table-toggle" key="table-toggle" onClick={() => setTableOpen(!tableOpen)}>
            <svg className="toggle-icon" width="15" height="30" viewBox="0 0 30 20">
              <path stroke="black" strokeWidth={4} d="M20,0l-15,15l15,15" fill="none" />
            </svg>
          </div>
        </div>
      </div>,
    ]
  }

  function renderMap() {
    if (!readyToRenderMap) {
      return
    }

    const mapRoutes = _.map(routes, (route) => ({
      coords: route.coords,
      type: route.isClosed ? ROUTE_TYPE_VISITED : ROUTE_TYPE_UNVISITED,
    }))

    return [
      tableOpen ? <div className="screen-darkener" key="screen-darkener" /> : null,
      <SROGoogleMapsMapView
        ref={mapComponentRef}
        requestParams={getRequestParams()}
        className="trip-map"
        key={`trip-map-${getRandomString()}`}
        points={points}
        routes={mapRoutes}
        mapMaxWidthPercent={100}
        mapMaxHeightPercent={100}
        nearbyOnly={nearbyOnly}
        style={{
          left: `${getTableWidth(tableOpen)}px`,
          height: `${100 - getTableHeight()}%`,
          width: `${getDesiredContentSize().width - getTableWidth(tableOpen)}px`,
        }}
      />,
    ]
  }

  function renderHoveringMessage() {
    if (agentLastLocation.coordPoint || !showHoveringMessage) {
      return null
    }

    return (
      <div className="hovering-message" key="hovering-message">
        <icon.x className="close-hovering-message" onClick={() => setShowHoveringMessage(false)} />
        <div className="hovering-message-content">{messages.AGENT_LOCATION_CANNOT_BE_DETECTED}</div>
      </div>
    )
  }

  function renderContent() {
    if (!activities || !isGoogleMapsLoaded()) {
      return <TripMapShadow />
    }

    fillScreen()

    return [renderTable(), renderMap(), renderHoveringMessage()]
  }

  function renderCloseButton() {
    if (!onClose) {
      return null
    }

    return <icon.x className="close-map" onClick={onClose} />
  }

  function setEmbeddedMode() {
    if (!embedded) {
      return
    }

    SELECTORS_TO_HIDE_ON_EMBEDDED.forEach((selector) => {
      _.each(document.querySelectorAll(selector), (item) => {
        item.style.display = 'none'
      })
    })
  }

  setEmbeddedMode()

  return (
    <div className="trip-map-page" key="map">
      {renderCloseButton()}
      {renderContent()}
    </div>
  )
}

TripMapView.propTypes = {
  onClose: PropTypes.func,
  searchParams: PropTypes.string,
  isPopup: PropTypes.bool,
}

export default TripMapView
