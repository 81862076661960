import React from 'react'

import { v1 as uuidv1 } from 'uuid'
import _ from 'lodash'
import classnames from 'classnames'

import { accountIdEntry } from '../../providers/localStorageProvider'
import WorkHoursModalView from '../modals/WorkHoursModalView'
import { workHoursDataToDisplay } from '../modals/WorkHoursModalView/workHourLogic'
import { messages } from '../../i18n/messages'
import GeoPicker from '../widgets/SROFormComponents/GeoPicker'
import SROInput from '../widgets/SROFormComponents/SROInput'
import SROButton from '../widgets/SROFormComponents/SROButton'
import FormValidator from '../../utils/formValidator'
import { flatToDeep, compareFlatDeep } from '../../utils/objectUtils'
import SROFormComponent from '../widgets/SROFormComponents/SROFormComponents'
import SROTooltip from '../widgets/SROTooltip'
import Step1Shadow from './Step1Shadow'

const SCHEMA = new FormValidator({
  name: {
    title: messages.NAME,
    required: true,
    maxLength: 64,
    refName: 'accountName',
  },
  'attributes.location': {
    title: messages.account_location,
    required: true,
    customRequired: (value) => !!value.address,
    refName: 'accountLocation',
  },
  'attributes.startPlanningNumDaysFromToday': {
    title: messages.FREEZE_PERIOD,
    required: true,
    gt: 0,
    refName: 'startPlanningNumDaysFromToday',
  },
  'attributes.nearbyClientsRadiusMeters': {
    title: messages.NEARBY_CLIENTS_RADIUS_METERS,
    required: true,
    gt: 0,
    refName: 'nearbyClientsRadiusMeters',
  },
  'attributes.visitTypeClientMaximumDistanceMeters': {
    title: messages.VISIT_MAX_DISTANCE_METERS,
    required: true,
    gt: 0,
    refName: 'visitTypeClientMaximumDistanceMeters',
  },
  'attributes.locationTrackingScreenUrl': {
    title: messages.sro.location_tracking_screen_url,
    refName: 'locationTrackingScreenUrl',
  },
  'attributes.agent.visitTimeMinutes': {
    title: messages.VISIT_TIME,
    required: true,
    gt: 0,
    refName: 'visitTimeMinutes',
  },
  'attributes.agent.startLocation': {
    title: messages.S_ADDRESS,
    required: true,
    customRequired: (value) => !!value.address,
    refName: 'startLocation',
  },
  'attributes.agent.endLocation': {
    title: messages.E_ADDRESS,
    required: true,
    customRequired: (value) => !!value.address,
    refName: 'endLocation',
  },
  'attributes.agent.workingHours': {
    customValidation: (data) => {
      if (_.keys(_.get(data, 'attributes.agent.workingHours')).length === 0) {
        throw new Error(messages.ERROR_WORKING_HOURS_MUST_CONTAIN_MIN_1)
      }
    },
  },
  'attributes.agent.numDailyVisits': {
    title: messages.sro.daily_visits,
    required: true,
    gt: 0,
    refName: 'numDailyVisits',
  },
  'attributes.agent.maxDailyPhoneCalls': {
    title: messages.sro.max_daily_phone_calls,
    required: true,
    gt: 0,
    refName: 'maxDailyPhoneCalls',
  },
  'attributes.agentKPIs.companyTarget': {
    title: messages.COMPANY_TARGET,
    required: true,
    gt: 0,
    lte: 100,
    refName: 'companyTarget',
  },
  'attributes.agentKPIs.sales': {
    title: messages.SALES,
    required: true,
    gte: 0,
    lte: 100,
    refName: 'sales',
  },
  'attributes.agentKPIs.collection': {
    title: messages.COLLECTION,
    required: true,
    gte: 0,
    lte: 100,
    refName: 'collection',
  },
  'attributes.agentKPIs.visits': {
    title: messages.VISITS,
    required: true,
    gte: 0,
    lte: 100,
    refName: 'visits',
  },
  'attributes.agentKPIs.successfulVisits': {
    title: messages.SUCCESSFUL_VISITS,
    required: true,
    gte: 0,
    lte: 100,
    refName: 'successfulVisits',
  },
  totalAgentScorePercentages: {
    customValidation: (data) => {
      const { attributes = {} } = data
      const { agentKPIs = {} } = attributes
      const { sales, collection, visits, successfulVisits = {} } = agentKPIs
      if (sales + collection + visits + successfulVisits !== 100) {
        throw new Error(messages.TOTAL_AGENT_SCORE_MUST_BE_100_PERC)
      }
    },
  },
})

class Step1AccountSettingsView extends SROFormComponent {
  static NEXT_BUTTON_TITLE = messages.NEXT

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      workHours: null,
      showWorkHoursModal: false,
      agentScorePercentages: 0,
      ...this.getFormPropsToUpdateState(this.props),
    }

    this.accountId = accountIdEntry.get()
    this.props.fetchAccountData(this.accountId)

    this.onHoursChange = this.onHoursChange.bind(this)
    this.showWorkHoursModal = this.showWorkHoursModal.bind(this)
    this.next = this.next.bind(this)
  }

  calculateAgentScore(params) {
    const {
      accountAgentKPIsSales,
      accountAgentKPIsCollection,
      accountAgentKPIsVisits,
      accountAgentKPIsSuccessfulVisits,
    } = params
    return (
      parseInt(accountAgentKPIsSales || 0) +
      parseInt(accountAgentKPIsCollection || 0) +
      parseInt(accountAgentKPIsVisits || 0) +
      parseInt(accountAgentKPIsSuccessfulVisits || 0)
    )
  }

  getFormPropsToUpdateState(props) {
    const { accountData } = props
    if (!accountData) {
      return {}
    }

    const { attributes = {} } = accountData
    const { agent = {}, agentKPIs = {} } = attributes
    const state = {
      accountName: props.accountData.name,
      accountLocation: attributes.location,
      accountStartPlanningDaysFromToday: attributes.startPlanningNumDaysFromToday,
      accountNearbyClientsRadiusMeters: attributes.nearbyClientsRadiusMeters,
      accountVisitTypeClientMaximumDistanceMeters: attributes.visitTypeClientMaximumDistanceMeters,
      accountLocationTrackingScreenUrl: attributes.locationTrackingScreenUrl,

      accountAgentStartLocation: agent.startLocation,
      accountAgentEndLocation: agent.endLocation,
      accountAgentVisitTimeMinutes: agent.visitTimeMinutes,
      accountAgentNumDailyVisits: agent.numDailyVisits,
      accountAgentMaxDailyPhoneCalls: agent.maxDailyPhoneCalls,

      accountAgentKPIsCompanyTarget: agentKPIs.companyTarget,
      accountAgentKPIsSales: agentKPIs.sales,
      accountAgentKPIsCollection: agentKPIs.collection,
      accountAgentKPIsVisits: agentKPIs.visits,
      accountAgentKPIsSuccessfulVisits: agentKPIs.successfulVisits,
    }

    state.totalAgentScorePercentages = this.calculateAgentScore(state)

    return state
  }

  onFieldNameChange(fieldName) {
    const self = this
    return (value) => {
      const newState = { [fieldName]: value }

      if (
        [
          'accountAgentKPIsSales',
          'accountAgentKPIsCollection',
          'accountAgentKPIsVisits',
          'accountAgentKPIsSuccessfulVisits',
        ].includes(fieldName)
      ) {
        newState.totalAgentScorePercentages = this.calculateAgentScore({
          ...this.state,
          ...newState,
        })
      }

      self.setState(newState)
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    // force rerender the form when the accountdata changes.
    // Natively it doesn't do so, because I set the defaultValue and not the value.
    const { accountData } = props
    const { attributes = {} } = accountData
    this.setState({
      key: uuidv1(),
      workHours: _.get(attributes, 'agent.workingHours', {}),
      ...this.getFormPropsToUpdateState(props),
    })
  }

  renderAccountForm() {
    if (!this.props.accountData) {
      return undefined
    }

    return (
      <div className="account-section section-box">
        <div className="section-title">{this.props.messages.ACCOUNT_DETAILS}</div>
        <div className="sro-custom-form">
          <SROInput
            className="account-name"
            title={this.props.messages.ACCOUNT_NAME}
            defaultValue={this.state.accountName}
            onChange={this.onFieldNameChange('accountName')}
          />
          <GeoPicker
            title={this.props.messages.ADDRESS}
            defaultValue={this.state.accountLocation}
            onChange={this.onFieldNameChange('accountLocation')}
          />
          <SROInput
            numeric={true}
            className="start-planning"
            title={this.props.messages.FREEZE_PERIOD}
            defaultValue={this.state.accountStartPlanningDaysFromToday}
            onChange={this.onFieldNameChange('accountStartPlanningDaysFromToday')}
          />
          <SROInput
            numeric={true}
            className="nearby-clients-radius"
            title={messages.NEARBY_CLIENTS_RADIUS_METERS}
            defaultValue={this.state.accountNearbyClientsRadiusMeters}
            onChange={this.onFieldNameChange('accountNearbyClientsRadiusMeters')}
          />
          <SROInput
            numeric={true}
            className="max-dist-input"
            title={messages.VISIT_MAX_DISTANCE_METERS}
            defaultValue={this.state.accountVisitTypeClientMaximumDistanceMeters}
            onChange={this.onFieldNameChange('accountVisitTypeClientMaximumDistanceMeters')}
          />
          <SROInput
            className="location-tracking-screen-url"
            title={messages.sro.location_tracking_screen_url}
            defaultValue={this.state.accountLocationTrackingScreenUrl}
            onChange={this.onFieldNameChange('accountLocationTrackingScreenUrl')}
          />
        </div>
      </div>
    )
  }

  showWorkHoursModal() {
    this.setState({
      showWorkHoursModal: true,
    })
  }

  renderAgentDefaultsForm() {
    if (!this.props.accountData) {
      return null
    }

    return (
      <div className="agent-defaults-section section-box">
        <div className="section-title">{this.props.messages.AGENT_DEFAULT_DETAILS}</div>

        <div className="sro-custom-form">
          <GeoPicker
            title={messages.S_ADDRESS}
            defaultValue={this.state.accountAgentStartLocation}
            onChange={this.onFieldNameChange('accountAgentStartLocation')}
          />
          <GeoPicker
            className="e-address"
            title={messages.E_ADDRESS}
            defaultValue={this.state.accountAgentEndLocation}
            onChange={this.onFieldNameChange('accountAgentEndLocation')}
          />
          <SROInput
            numeric={true}
            className="visit-time"
            title={messages.VISIT_TIME}
            defaultValue={this.state.accountAgentVisitTimeMinutes}
            onChange={this.onFieldNameChange('accountAgentVisitTimeMinutes')}
          />
          <SROInput
            numeric={true}
            className="daily-visits"
            title={messages.sro.daily_visits}
            defaultValue={this.state.accountAgentNumDailyVisits}
            onChange={this.onFieldNameChange('accountAgentNumDailyVisits')}
          />
          <SROInput
            numeric={true}
            className="max-daily-phone-calls"
            title={messages.sro.max_daily_phone_calls}
            defaultValue={this.state.accountAgentMaxDailyPhoneCalls}
            onChange={this.onFieldNameChange('accountAgentMaxDailyPhoneCalls')}
          />
        </div>
        <div className="work-hours-section">
          <SROButton className="add-work-hours sro-button-white" onClick={this.showWorkHoursModal}>
            {this.props.messages.ADD_WORK_HOURS}
          </SROButton>
          <SROTooltip text={workHoursDataToDisplay(this.state.workHours)}>
            <div className={classnames('work-hours-display-wrapper')}>
              <div className={classnames('work-hours-display')}>
                {workHoursDataToDisplay(this.state.workHours)}
              </div>
            </div>
          </SROTooltip>
        </div>
      </div>
    )
  }

  renderAgentScoreForm() {
    if (!this.props.accountData) {
      return null
    }

    return (
      <div className="agent-score-section section-box">
        <div className="section-title">{this.props.messages.AGENT_SCORE_DEFINITION}</div>

        <div className="sro-custom-form">
          <SROInput
            numeric={true}
            title={`${messages.COMPANY_TARGET} (%)`}
            defaultValue={this.state.accountAgentKPIsCompanyTarget}
            onChange={this.onFieldNameChange('accountAgentKPIsCompanyTarget')}
          />
          <h3>{messages.WEIGHT_PER_PARAMETER_IN_PERCENT}</h3>
          <SROInput
            className="agent-kpi-input"
            numeric={true}
            title={messages.SALES}
            defaultValue={this.state.accountAgentKPIsSales}
            onChange={this.onFieldNameChange('accountAgentKPIsSales')}
          />
          <SROInput
            className="agent-kpi-input"
            numeric={true}
            title={messages.COLLECTION}
            defaultValue={this.state.accountAgentKPIsCollection}
            onChange={this.onFieldNameChange('accountAgentKPIsCollection')}
          />
          <SROInput
            className="agent-kpi-input"
            numeric={true}
            title={messages.VISITS}
            defaultValue={this.state.accountAgentKPIsVisits}
            onChange={this.onFieldNameChange('accountAgentKPIsVisits')}
          />
          <SROInput
            className="agent-kpi-input"
            numeric={true}
            title={messages.SUCCESSFUL_VISITS}
            defaultValue={this.state.accountAgentKPIsSuccessfulVisits}
            onChange={this.onFieldNameChange('accountAgentKPIsSuccessfulVisits')}
          />
          <div className="agent-score-total-display">
            <span className="bold">{messages.TOTAL}</span>: {this.state.totalAgentScorePercentages}
          </div>
        </div>
      </div>
    )
  }

  getExtraButtons() {
    return []
  }

  onHoursChange(data) {
    this.setState({
      workHours: data,
      showWorkHoursModal: false,
    })
  }

  renderModals() {
    if (!this.state.showWorkHoursModal) {
      return undefined
    }

    return (
      <WorkHoursModalView
        onSave={this.onHoursChange}
        days={this.state.workHours}
        onCancel={() => this.setState({ showWorkHoursModal: false })}
      />
    )
  }

  render() {
    if (!this.props.accountData) {
      return <Step1Shadow />
    }

    return (
      <div className="settings-account-page">
        {this.renderModals()}
        {this.renderAccountForm()}
        {this.renderAgentDefaultsForm()}
        {this.renderAgentScoreForm()}
      </div>
    )
  }

  hasAnythingChanged() {
    return !compareFlatDeep(this.serializeFlat(), this.props)
  }

  serialize() {
    return flatToDeep(this.serializeFlat())
  }

  serializeFlat() {
    return {
      'accountData.id': this.accountId,
      'accountData.name': this.state.accountName,
      'accountData.attributes.location': this.state.accountLocation,
      'accountData.attributes.startPlanningNumDaysFromToday':
        this.state.accountStartPlanningDaysFromToday,
      'accountData.attributes.nearbyClientsRadiusMeters': parseInt(
        this.state.accountNearbyClientsRadiusMeters,
      ),
      'accountData.attributes.visitTypeClientMaximumDistanceMeters': parseInt(
        this.state.accountVisitTypeClientMaximumDistanceMeters,
      ),
      'accountData.attributes.locationTrackingScreenUrl':
        this.state.accountLocationTrackingScreenUrl,
      'accountData.attributes.agent.startLocation': this.state.accountAgentStartLocation,
      'accountData.attributes.agent.endLocation': this.state.accountAgentEndLocation,
      'accountData.attributes.agent.visitTimeMinutes': parseInt(
        this.state.accountAgentVisitTimeMinutes,
      ),
      'accountData.attributes.agent.workingHours': this.state.workHours,
      'accountData.attributes.agent.numDailyVisits': parseInt(
        this.state.accountAgentNumDailyVisits,
      ),
      'accountData.attributes.agent.maxDailyPhoneCalls': parseInt(
        this.state.accountAgentMaxDailyPhoneCalls,
      ),
      'accountData.attributes.agentKPIs.companyTarget': parseInt(
        this.state.accountAgentKPIsCompanyTarget,
      ),
      'accountData.attributes.agentKPIs.sales': parseInt(this.state.accountAgentKPIsSales),
      'accountData.attributes.agentKPIs.collection': parseInt(
        this.state.accountAgentKPIsCollection,
      ),
      'accountData.attributes.agentKPIs.visits': parseInt(this.state.accountAgentKPIsVisits),
      'accountData.attributes.agentKPIs.successfulVisits': parseInt(
        this.state.accountAgentKPIsSuccessfulVisits,
      ),
    }
  }

  next() {
    if (!this.hasAnythingChanged()) {
      this.props.save(null)
      return
    }

    const serialized = this.serialize()

    SCHEMA.validate(serialized.accountData)

    this.props.save(serialized)
  }
}

export default Step1AccountSettingsView
