import { combineReducers } from 'redux'
import { DataReceivedAction } from '../actions/requestAction'

function requestResults(state = {}, action) {
  if (action.type !== DataReceivedAction.NAME) {
    return state
  }

  return Object.assign({}, state, {
    [action.requestId]: action.data,
  })
}

export default combineReducers({
  requestResults,
})
