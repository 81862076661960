import React from 'react'
import _ from 'lodash'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import PropTypes from 'prop-types'

import SROButton from '../../SROFormComponents/SROButton'
import { getDataSlice } from '../../../../utils/chartUtils'

import './sro-stack-bar.scss'
import '../sro-charts.scss'

export default class SROStackedBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      scrollValue: 0,
    }

    this.isScrollNecessary = this.isScrollNecessary.bind(this)
    this.getDataLength = this.getDataLength.bind(this)
    this.scroll = this.scroll.bind(this)
  }

  getDataLength() {
    return _.get(this.props, 'data.length', 0)
  }

  renderScrollButtons() {
    if (!this.isScrollNecessary()) {
      return null
    }

    return (
      <div className="scroll-buttons">
        <SROButton className="scroll-button scroll-backward" onClick={() => this.scroll(false)}>
          {' '}
          &lt;&lt;{' '}
        </SROButton>
        <SROButton className="scroll-button scroll-forward" onClick={() => this.scroll(true)}>
          {' '}
          &gt;&gt;{' '}
        </SROButton>
      </div>
    )
  }

  scroll(isForward) {
    const delta = isForward ? this.props.itemsPerScroll : -this.props.itemsPerScroll
    const newScrollValue = this.state.scrollValue + delta

    if (newScrollValue >= this.getDataLength() || newScrollValue < 0) {
      return
    }

    this.setState({
      scrollValue: newScrollValue,
    })
  }

  render() {
    const data = getDataSlice(
      this.props.data,
      this.state.scrollValue,
      this.props.maxHorizontalItems,
      (item) => item,
    )
    const BarTooltip = this.props.tooltip

    return (
      <div className="sro-stack-bar">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid horizontal={false} stroke="#e7ebef" />
          <XAxis
            dataKey="agentName"
            stroke="#485465"
            interval={0}
            tick={{ fontFamily: 'Ubuntu', fontSize: 11 }}
            axisLine={false}
          />
          <YAxis stroke="#485465" tick={{ fontFamily: 'Ubuntu', fontSize: 11 }} axisLine={false} />
          <Tooltip content={<BarTooltip metrics={this.props.metrics} />} />
          <Legend
            align="right"
            verticalAlign="top"
            wrapperStyle={{
              paddingBottom: '10px',
            }}
          />
          {_.map(this.props.metrics, (metric) => (
            <Bar
              name={metric.label}
              key={metric.name}
              dataKey={metric.name}
              barSize={20}
              stackId="a"
              fill={metric.color}
            />
          ))}
        </BarChart>
        {this.renderScrollButtons()}
      </div>
    )
  }

  isScrollNecessary() {
    return this.getDataLength() > this.props.maxHorizontalItems
  }
}

SROStackedBar.propTypes = {
  itemsPerScroll: PropTypes.number,
  maxHorizontalItems: PropTypes.number,
  data: PropTypes.array,
  tooltip: PropTypes.func,
  metrics: PropTypes.array,
}

SROStackedBar.defaultProps = {
  maxHorizontalItems: 6,
  itemsPerScroll: 3,
}
