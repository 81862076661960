import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import icon from '../../icons'
import SROTooltip from '../SROTooltip'

import './question-mark-with-tooltip.scss'

class QuestionMarkWithTooltip extends React.Component {
  render() {
    return (
      <SROTooltip text={this.props.tooltipText}>
        <div className={classnames('question-mark-with-tooltip')}>
          <icon.infoI />
        </div>
      </SROTooltip>
    )
  }
}

QuestionMarkWithTooltip.propTypes = {
  tooltipText: PropTypes.string,
}

export default QuestionMarkWithTooltip
