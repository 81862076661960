import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Button } from '@material-ui/core'
import {
  required,
  minLength,
  maxLength,
  email,
  SimpleShowLayout,
  Toolbar,
  SaveButton,
  Create,
  Edit,
  Show,
  SimpleForm,
  List,
  Datagrid,
  TextField,
  FunctionField,
  ReferenceField,
  SelectInput,
  TextInput,
  useTranslate,
  FormDataConsumer,
  DateField,
  BooleanInput,
} from 'react-admin'
import PropTypes from 'prop-types'

import { SROMoment } from '../../utils/timeUtils'
import SRODateField from '../widgets/SROFormComponents/SRODateField'
import EditPencilIcon from '../widgets/EditPencilIcon'
import ActivityCheckbox from '../widgets/ActivityCheckbox'
import { userIdEntry, localeEntry } from '../../providers/localStorageProvider'
import Requester from '../../providers/request'
import { UserFullResource } from '../../providers/resources/comboResources'
import { goToResource } from '../../utils/reactAdminUtils'
import SROListActions from '../widgets/SROFormComponents/SROListActions'
import {
  NON_ADMIN_ROLES,
  ROLE_AGENT_TEAM_MANAGER,
  ROLE_ACCOUNT_MANAGER,
  ROLE_AGENT,
} from '../../providers/roles'
import { AccountResource, AgentTeamResource } from '../../providers/resources/requestResources'
import SROTooltip from '../widgets/SROTooltip'

import './user.scss'
import '../sro-table.scss'

export function UserList(props) {
  const translate = useTranslate()

  function renderIsActiveCheckbox(record) {
    const disabled = `${record.id}` === `${userIdEntry.get()}`

    return (
      <div>
        <ActivityCheckbox
          itemId={record.id}
          displayName={record.username}
          updateIsActive={async ({ itemId, isActive }) =>
            await new Requester().update(UserFullResource, { id: itemId, data: { isActive } })
          }
          defaultIsActive={record.isActive || false}
          successMessageTemplate={translate('USER_UPDATE_SUCCESS')}
          failureMessageTemplate={translate('USER_UPDATE_FAIL')}
          disabledMessage={translate('CANNOT_CHANGE_OWN_STATUS')}
          disabled={disabled}
        />
      </div>
    )
  }

  return (
    <div className="user-page main-page list-view">
      <h1 className="page-title float-left">{translate('USERS')}</h1>
      <List
        {...props}
        bulkActionButtons={false}
        actions={<SROListActions createLabel="ADD_USER" />}
        className="sro-table"
      >
        <Datagrid>
          <ReferenceField
            source="accountId"
            reference={AccountResource.RESOURCE_NAME}
            label="ACCOUNT"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="id" label="USER_ID" />
          <TextField source="username" label="USERNAME" />
          <TextField source="email" label="EMAIL" />
          <SRODateField source="lastLoginAt" label="LAST_LOGIN" />
          <TextField source="roleList" label="ROLES" />
          <ReferenceField
            source="agentTeamId"
            reference={AgentTeamResource.RESOURCE_NAME}
            label="AGENT_TEAM"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <FunctionField source="isActive" label="ACTIVE" render={renderIsActiveCheckbox} />
          <SRODateField source="createdAt" label="CREATED" />
          <SRODateField source="updatedAt" label="UPDATED" />
          <FunctionField
            source="id"
            label=""
            sortable={false}
            render={(props) => <EditPencilIcon resource={UserFullResource} id={props.id} />}
          />
        </Datagrid>
      </List>
    </div>
  )
}

UserList.propTypes = {
  id: PropTypes.number,
  record: PropTypes.object,
}

function EditSideTable(props) {
  const { record = {} } = props

  const translate = useTranslate()

  function formatDate(serverDate) {
    const sroDate = SROMoment.fromServerString(serverDate)

    if (!sroDate) {
      return null
    }

    return sroDate.formatDateShort()
  }

  return (
    <table className="sro-side-table">
      <tbody>
        <tr>
          <th>{translate('USER_ID')}</th>
          <td>{record.id}</td>
        </tr>
        <tr>
          <th>{translate('USERNAME')}</th>
          <td>{record.username}</td>
        </tr>
        <tr>
          <th>{translate('ACCOUNT_NAME')}</th>
          <td>{record.accountName}</td>
        </tr>
        <tr>
          <th>{translate('LAST_LOGIN')}</th>
          <td>{formatDate(record.lastLoginAt)}</td>
        </tr>
        <tr>
          <th>{translate('CREATED')}</th>
          <td>{formatDate(record.createdAt)}</td>
        </tr>
        <tr>
          <th>{translate('UPDATED')}</th>
          <td>{formatDate(record.updatedAt)}</td>
        </tr>
        <tr>
          <th>{translate('ACTIVE')}</th>
          <td>
            <BooleanInput
              label=""
              source="isActive"
              disabled={`${record.id}` === `${userIdEntry.get()}`}
            />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

EditSideTable.propTypes = {
  record: PropTypes.object,
}

function EditFormWrapper(props) {
  const { record, allAgentTeams } = props

  const translate = useTranslate()

  const LOCALES = [
    { id: 'he', name: translate('HEBREW') },
    { id: 'en', name: translate('ENGLISH') },
  ]

  const disabled = `${record.id}` === `${userIdEntry.get()}`

  return (
    <table className="edit-table">
      <tbody>
        <tr>
          <td>
            <TextInput source="email" label="EMAIL" record={record} fullWidth />
            <SROTooltip text={disabled ? 'CANNOT_CHANGE_OWN_ROLE' : ''} displayBlock>
              <SelectInput
                source="roleId"
                choices={NON_ADMIN_ROLES}
                label="ROLE"
                disabled={disabled}
                record={record}
                fullWidth
              />
            </SROTooltip>
            <FormDataConsumer>
              {({ formData }) =>
                formData.roleId === ROLE_AGENT_TEAM_MANAGER && (
                  <SelectInput
                    key="agentTeamId"
                    source="agentTeamId"
                    choices={allAgentTeams}
                    label="AGENT_TEAM"
                    record={record}
                    fullWidth
                  />
                )
              }
            </FormDataConsumer>
            <SROTooltip text="LOGOUT_LOGIN_TO_APPLY_CHANGES" displayBlock>
              <SelectInput
                source="languageCode"
                choices={LOCALES}
                label="LANGUAGE"
                record={record}
                fullWidth
              />
            </SROTooltip>
          </td>
          <td>
            <EditSideTable record={record} />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

EditFormWrapper.propTypes = {
  record: PropTypes.object,
  onReadinessChanged: PropTypes.func,
  allAgentTeams: PropTypes.array,
}

function UserForm({ title, children }) {
  const translate = useTranslate()

  return (
    <div className="user-form-wrapper">
      <h1 className="page-title float-left">{translate(title)}</h1>
      <div className="back-from-edit-wrapper">
        <Button className="link-style-button" onClick={() => goToResource(UserFullResource)}>
          {translate('BACK_TO_USERS')}
        </Button>
      </div>
      {children}
    </div>
  )
}

UserForm.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

function getToolbar(isReady) {
  return function UserFormToolbar(props) {
    return (
      <Toolbar {...props}>
        <SaveButton className="sro-button" disabled={!isReady} />
      </Toolbar>
    )
  }
}

export function UserEditView(props) {
  const { fetchAllFormOptions, allAgentTeams } = props
  const [isReady, setIsReady] = useState(true)

  useEffect(() => {
    fetchAllFormOptions()
  }, [fetchAllFormOptions])

  const restProps = _.omit(props, [
    'allAccounts',
    'allAgentTeams',
    'allAgents',
    'fetchAllFormOptions',
  ])
  const UserFormToolbar = getToolbar(isReady)

  return (
    <UserForm title="EDIT_USER">
      <Edit undoable={false} {...restProps} className="sro-form user-form">
        <SimpleForm variant="standard" toolbar={<UserFormToolbar />}>
          <EditFormWrapper
            allAgentTeams={allAgentTeams}
            onReadinessChanged={(isReady) => setIsReady(isReady)}
          />
        </SimpleForm>
      </Edit>
    </UserForm>
  )
}

UserEditView.propTypes = {
  fetchAllFormOptions: PropTypes.func,
  allAgentTeams: PropTypes.array,
}

export function UserCreateView(props) {
  const { fetchAllFormOptions, allAccounts, allAgents, allAgentTeams } = props
  const [roleId, setRoleId] = useState(null)

  useEffect(() => {
    fetchAllFormOptions()
  }, [fetchAllFormOptions])

  function getExtraInputs() {
    const inputs = [
      <SelectInput
        source="roleId"
        choices={NON_ADMIN_ROLES}
        label="ROLE"
        key="roleId"
        onChange={(element) => setRoleId(element.target.value)}
        validate={[required()]}
      />,
    ]

    if (roleId === ROLE_ACCOUNT_MANAGER) {
      inputs.push(
        <SelectInput
          source="accountId"
          choices={allAccounts || []}
          label="ACCOUNT"
          key="accountId"
          validate={[required()]}
        />,
      )
    }

    if (roleId === ROLE_AGENT_TEAM_MANAGER) {
      inputs.push(
        <SelectInput
          source="agentTeamId"
          choices={allAgentTeams || []}
          label="AGENT_TEAM"
          key="agentTeamId"
          validate={[required()]}
        />,
      )
    }

    if (roleId === ROLE_AGENT) {
      inputs.push(
        <SelectInput
          source="agentId"
          choices={allAgents || []}
          label="AGENT"
          key="agentId"
          validate={[required()]}
        />,
      )
    }

    return inputs
  }

  const UserFormToolbar = getToolbar(true)

  const restProps = _.omit(props, [
    'allAccounts',
    'allAgentTeams',
    'allAgents',
    'fetchAllFormOptions',
  ])

  return (
    <UserForm title="CREATE_USER">
      <Create {...restProps} className="sro-form user-form user-create">
        <SimpleForm variant="standard" toolbar={<UserFormToolbar />}>
          <TextInput
            source="username"
            label="USERNAME"
            validate={[required(), minLength(3), maxLength(15)]}
          />
          <TextInput source="email" label="EMAIL" validate={[required(), email()]} />
          <TextInput source="rawPassword" label="PASSWORD" validate={[required(), minLength(7)]} />
          {getExtraInputs()}
        </SimpleForm>
      </Create>
    </UserForm>
  )
}

UserCreateView.propTypes = {
  fetchAllFormOptions: PropTypes.func,
  allAccounts: PropTypes.array,
  allAgents: PropTypes.array,
  allAgentTeams: PropTypes.array,
}

export function UserShow(props) {
  const translate = useTranslate()

  return (
    <div className="main-page">
      <h1 className="page-title">{translate('USER_DETAILS')}</h1>
      <Show {...props} id={userIdEntry.get()}>
        <SimpleShowLayout>
          <TextField source="email" label="EMAIL" />
          <TextField source="roleList" label="ROLES" />
          <ReferenceField
            source="agentTeamId"
            reference={AgentTeamResource.RESOURCE_NAME}
            label="AGENT_TEAM"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="id" label="USER_ID" />
          <TextField source="username" label="USERNAME" />
          <TextField source="account.name" label="ACCOUNT" />
          <DateField source="lastLoginAt" label="LAST_LOGIN" locales={localeEntry.get()} />
          <DateField source="createdAt" label="CREATED" locales={localeEntry.get()} />
          <DateField source="updatedAt" label="UPDATED" locales={localeEntry.get()} />
        </SimpleShowLayout>
      </Show>
    </div>
  )
}
