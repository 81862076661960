import { connect } from 'react-redux'

import Step3ClientSettingsView from '../../components/SettingsView/Step3ClientSettingsView'
import { messages } from '../../i18n/messages'
import { SaveStep3Action, FetchAccountAction, CloseSubModalAction } from '../../actions/settings'
import { ClientSummarySimulationRequestAction } from '../../actions/clientSummarySimulator'

function mapStateToProps(state) {
  return {
    accountData: state.customReducers.settings.accountData,
    messages,
    clientSummarySimulationData: state.customReducers.clientSummarySimulation.data,
    preSimulationError: state.customReducers.clientSummarySimulation.error,
    step3status: state.customReducers.settings.step3status,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    save: (data) => dispatch(SaveStep3Action.make(data)),
    fetchAccountData: (accountId) =>
      dispatch(FetchAccountAction.make(accountId, { enriched: true })),
    onSubModalClose: () => dispatch(CloseSubModalAction.make()),
    fetchClientSummarySimulation: (accountId, data) =>
      dispatch(ClientSummarySimulationRequestAction.make(accountId, data)),
    ...ownProps,
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  Step3ClientSettingsView,
)
