import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import WorkHoursModalView from '../../modals/WorkHoursModalView'
import { workHoursDataToDisplay } from '../../modals/WorkHoursModalView/workHourLogic'
import SROTooltip from '../SROTooltip'

import './work-hour-picker.scss'

export class WorkHoursPicker extends React.Component {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      showModal: false,
      workHours: this.props.defaultValue,
    })

    this.textInputRef = React.createRef()

    this.showModal = this.showModal.bind(this)
    this.onSave = this.onSave.bind(this)
  }

  showModal() {
    this.setState({ showModal: true })
  }

  onSave(newValue) {
    this.setState({
      workHours: newValue,
      showModal: false,
    })
  }

  renderModal() {
    if (!this.state.showModal) {
      return null
    }

    return (
      <WorkHoursModalView
        onSave={this.onSave}
        onCancel={() => this.setState({ showModal: false })}
        days={this.state.workHours}
      />
    )
  }

  renderDisplay() {
    return (
      <div className="work-hours-display" onClick={this.showModal}>
        {workHoursDataToDisplay(this.state.workHours)}
      </div>
    )
  }

  getValue() {
    return this.state.workHours
  }

  render() {
    return (
      <div className="work-hours-picker">
        {this.renderModal()}
        <SROTooltip text={workHoursDataToDisplay(this.state.workHours)}>
          <div className={classnames('work-hours-display-wrapper')}>{this.renderDisplay()}</div>
        </SROTooltip>
      </div>
    )
  }
}

WorkHoursPicker.propTypes = {
  defaultValue: PropTypes.object,
}
