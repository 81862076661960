import React from 'react'
import { translate } from 'react-admin'
import PropTypes from 'prop-types'

import SROTooltip from '../SROTooltip'
import icons from '../../icons'
import classnames from 'classnames'
import { generateDayTripLink } from '../../TripMapView'

import './route-link.scss'

class RouteLink extends React.Component {
  renderRouteIcon() {
    if (!this.props.showLocationIcon) {
      return undefined
    }

    return <icons.framelessMapIcon />
  }

  render() {
    const mapLink = generateDayTripLink({
      agentId: this.props.agentId,
      date: this.props.date,
    })

    return (
      <SROTooltip text={this.props.translate('DATE_ROUTE')}>
        <div className={classnames('route-link', 'small-route-link')}>
          <a href={mapLink} target="_" className="bottom-line">
            <icons.kiteRight className="from" />{' '}
            {this.props.dateStartTime ? this.props.dateStartTime.formatTime() : undefined}
            {this.renderRouteIcon()}
            <div className="map-space">&nbsp;</div>
            <icons.flag className="to" />{' '}
            {this.props.dateEndTime ? this.props.dateEndTime.formatTime() : undefined}
          </a>
        </div>
      </SROTooltip>
    )
  }
}

RouteLink.propTypes = {
  agentId: PropTypes.number,
  date: PropTypes.string,
  dateStartTime: PropTypes.object,
  dateEndTime: PropTypes.object,
  translate: PropTypes.func,
  showLocationIcon: PropTypes.bool,
}

export default translate(RouteLink)
