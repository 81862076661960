import React from 'react'

import _ from 'lodash'
import classnames from 'classnames'
import ClickAwareBox from 'click-aware-box'

import { ToggleButton } from '../Buttons'

import './sro-form-style.scss'
import SROFormComponent from './SROFormComponents'

class SRODropdown extends SROFormComponent {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      isOpen: false,
    })

    this.onChange = this.onChange.bind(this)
    this.close = this.close.bind(this)
    this.toggle = this.toggle.bind(this)
  }

  getTitleMapping() {
    return _.zipObject(
      _.map(this.props.options, (option) => option.id),
      _.map(this.props.options, (option) => option.name),
    )
  }

  onChange(value) {
    this.props.onChange(value)
    this.close()
  }

  close() {
    this.setState({
      isOpen: false,
    })
  }

  renderOptions() {
    if (!this.state.isOpen) {
      return undefined
    }

    return (
      <div className="options">
        {_.map(this.props.options, (item, index) => (
          <div
            className={classnames('option', { last: index === this.props.options.length - 1 })}
            onClick={() => this.onChange(item.id)}
            key={`option_${item.id}`}
          >
            {item.name}
          </div>
        ))}
      </div>
    )
  }

  toggle() {
    if (this.props.disabled) {
      return
    }

    this.setState({ isOpen: !this.state.isOpen })
  }

  getExtraClasses() {
    return ['sro-dropdown', { open: this.state.isOpen, 'has-title': !!this.props.title }]
  }

  render() {
    const hasChosen = !!this.props.value
    const displaySelected = hasChosen
      ? this.getTitleMapping()[this.props.value]
      : this.props.placeholder
    return (
      <ClickAwareBox className={classnames(this.getClasses())} onClickOut={this.close}>
        <div className="all-content">
          {this.props.title && <div className="upper-title">{this.props.title}</div>}
          <div className="toggle-wrapper" onClick={this.toggle}>
            <ToggleButton value={true} width={10} height={10} />
          </div>
          <div
            className={classnames('selected-item', { 'no-selected': !hasChosen })}
            onClick={this.toggle}
          >
            {displaySelected}
          </div>
          {this.renderOptions()}
        </div>
      </ClickAwareBox>
    )
  }
}

SRODropdown.defaultProps = {
  onChange: () => {},
}

export default SRODropdown
