import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from 'recharts'
import SROButton from '../../SROFormComponents/SROButton'
import { getDataSlice } from '../../../../utils/chartUtils'

import './sro-bar.scss'
import '../sro-charts.scss'

export default class SROBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      scrollValue: 0,
    }

    this.scroll = this.scroll.bind(this)
  }

  getDataLength() {
    return this.props.data.length || 0
  }

  isScrollNecessary() {
    return this.getDataLength() > this.props.maxHorizontalItems
  }

  scroll(isForward) {
    const delta = isForward ? this.props.itemsPerScroll : -this.props.itemsPerScroll
    const newScrollValue = this.state.scrollValue + delta

    if (newScrollValue >= this.getDataLength() || newScrollValue < 0) {
      return
    }

    this.setState({
      scrollValue: newScrollValue,
    })
  }

  renderScrollButtons() {
    if (!this.isScrollNecessary()) {
      return null
    }

    return (
      <div className="scroll-buttons">
        <SROButton className="scroll-button scroll-backward" onClick={() => this.scroll(false)}>
          {' '}
          &lt;&lt;{' '}
        </SROButton>
        <SROButton className="scroll-button scroll-forward" onClick={() => this.scroll(true)}>
          {' '}
          &gt;&gt;{' '}
        </SROButton>
      </div>
    )
  }

  render() {
    const data = getDataSlice(
      this.props.data,
      this.state.scrollValue,
      this.props.maxHorizontalItems,
      (item) => item,
    )
    const BarTooltip = this.props.tooltip

    return (
      <div className="sro-bar">
        <BarChart
          width={this.props.width}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid horizontal={false} stroke="#e7ebef" />
          <XAxis
            dataKey={this.props.xAxisDataKey}
            stroke="#485465"
            interval={0}
            tick={{ fontFamily: 'Ubuntu', fontSize: 11 }}
            axisLine={false}
          />
          <YAxis
            stroke="#485465"
            tick={{ fontFamily: 'Ubuntu', fontSize: 11 }}
            axisLine={false}
            domain={[0, this.props.maxYAxis || 'auto']}
          />
          {BarTooltip && (
            <Tooltip cursor={false} content={<BarTooltip metrics={this.props.metrics} />} />
          )}
          <Legend
            align="right"
            verticalAlign="top"
            wrapperStyle={{
              paddingBottom: '10px',
            }}
          />
          {_.map(this.props.horizontalLines, (props, i) => {
            return <ReferenceLine key={i} {...props} />
          })}
          {_.map(this.props.metrics, (metric) => (
            <Bar
              name={metric.label}
              key={metric.name}
              dataKey={metric.name}
              barSize={10}
              fill={metric.color}
            />
          ))}
        </BarChart>
        {this.renderScrollButtons()}
      </div>
    )
  }
}

SROBar.propTypes = {
  data: PropTypes.array,
  maxHorizontalItems: PropTypes.number,
  itemsPerScroll: PropTypes.number,
  tooltip: PropTypes.func,
  width: PropTypes.number,
  xAxisDataKey: PropTypes.string,
  maxYAxis: PropTypes.number,
  horizontalLines: PropTypes.array,
  metrics: PropTypes.array,
}

SROBar.defaultProps = {
  maxHorizontalItems: 10,
  itemsPerScroll: 3,
}
