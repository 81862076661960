import { useMutation } from 'react-admin'

import { ActivityResource } from '../providers/resources/requestResources'

export function useReportActivityDoPrintBack() {
  const [mutate, { loading, data }] = useMutation()

  const dispatch = ({ externalAgentId, activityId, doPrintBack }) => {
    return mutate({
      type: 'CREATE',
      resource: ActivityResource.RESOURCE_NAME,
      payload: { data: { externalAgentId, activityId, doPrintBack } },
    })
  }

  return { dispatch, data, loading }
}
