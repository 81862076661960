import React from 'react'

import _ from 'lodash'
import { messages } from '../../i18n/messages'
import { CoordRange } from '../../utils/geoUtils'
import { accountModeEntry } from '../../providers/localStorageProvider'

export const ROUTE_TYPE_VISITED = 'route-type-visited'
export const ROUTE_TYPE_UNVISITED = 'route-type-unvisited'

export const ROUTE_TYPE_TO_LINE_COLOR = {
  [ROUTE_TYPE_VISITED]: '#ff4b4e',
  [ROUTE_TYPE_UNVISITED]: '#5788CB',
}

export const CLIENT_POINT = 'client'
export const UNPLANNED_ON_SITE_POINT = 'unplanned-on-site'
export const UNPLANNED_PHONE_POINT = 'unplanned-phone'
export const VISITED_CLIENT_POINT = 'client-visited'
export const ROUTE_START_POINT = 'route-start'
export const ROUTE_END_POINT = 'route-end'
export const ROUTE_START_AND_END_POINT = 'route-start-and-end'
export const LOCATION_POINT = 'location'
export const NEARBY_POINT = 'nearby-client'
export const PHONE_POINT = 'phone-point'

export const VISIT_TYPE_PHONE = 'phone'
export const VISIT_TYPE_CLIENT = 'client'

export const MAPBOX_ZOOM_MAX = 22
export const MAPBOX_ZOOM_MIN = 0

export const isZoomValid = (zoom) => zoom >= MAPBOX_ZOOM_MIN && zoom <= MAPBOX_ZOOM_MAX

const MAP_ICON_FOLDER = '/map-icons'

const VISIT_TYPE_TO_MESSAGE = {
  [VISIT_TYPE_CLIENT]: messages.ON_SITE,
  [VISIT_TYPE_PHONE]: messages.PHONE,
}

const defaultIconWidth = 30
const defaultIconHeight = 30

export const ICON_BY_POINT_TYPE = _.mapValues(
  {
    [PHONE_POINT]: { filename: 'phoneMarker.png' },
    [CLIENT_POINT]: { filename: 'clientMarker.png' },
    [UNPLANNED_ON_SITE_POINT]: { filename: 'unplannedOnSite.png' },
    [UNPLANNED_PHONE_POINT]: { filename: 'unplannedPhone.png' },
    [VISITED_CLIENT_POINT]: { filename: 'visitedClientMarker.png' },
    [ROUTE_START_POINT]: { filename: 'startCircle.svg', anchorX: 5, anchorY: 5 },
    [ROUTE_END_POINT]: { filename: 'routeEndMarker.png' },
    [ROUTE_START_AND_END_POINT]: { filename: 'routeStartAndEndMarker.png' },
    [LOCATION_POINT]: {
      filename: 'locationMarker.png',
      width: defaultIconWidth * 2,
      height: defaultIconHeight * 2,
    },
    [NEARBY_POINT]: {
      path: 'M24 12c5.239 0 9.5 4.119 9.5 9.181 0 5.697-8.577 15.33-8.663 15.427A1.122 1.122 0 0 1 24 37c-.313 0-.61-.14-.836-.392-.087-.096-8.664-9.73-8.664-15.427C14.5 16.12 18.762 12 24 12zm-.18 36.067c2.144 0 3.883-1.72 3.883-3.843s-1.739-3.843-3.883-3.843c-2.145 0-3.884 1.72-3.884 3.843s1.739 3.843 3.884 3.843z',
      fillColor: '#f5a523',
      fillOpacity: 0.9,
      strokeWeight: 0,
      scale: 0.8,
      anchorX: 25,
      anchorY: 45,
    },
  },
  (icon) => {
    const resultIcon = {
      scaledSize: {
        height: icon.height || defaultIconWidth,
        width: icon.width || defaultIconWidth,
      },
      anchorX: icon.anchorX,
      anchorY: icon.anchorY,
      ...icon,
    }

    if (icon.filename) {
      resultIcon.url = `${MAP_ICON_FOLDER}/${icon.filename}`
    }

    return resultIcon
  },
)

function getBaseTooltipColumns(pointData) {
  let tooltipColumns = [
    { title: () => messages.CLIENT_NAME, value: (pointData) => pointData.name },
    { title: () => messages.RATING, value: (pointData) => pointData.rateName },
  ]

  const accountMode = accountModeEntry.get()
  if (accountMode !== 'SRO_LIGHT') {
    tooltipColumns = [
      ...tooltipColumns,
      {
        title: () => messages.V_TIME_PLANNED,
        value: (pointData) => pointData.plannedVisit || 'N/A',
      },
      {
        title: () => messages.ACTUAL_VISIT_TIME,
        value: (pointData) => pointData.arrivalTime || 'N/A',
      },
    ]
  }

  if (!pointData.nearbyLabels || pointData.nearbyLabels.length === 0) {
    tooltipColumns.push({
      title: () => messages.NEXT_VISIT_DATE,
      value: (pointData) => pointData.nextVisitDate,
    })
  } else {
    const nearbyLabelColumns = pointData.nearbyLabels.map((nearbyLabel) => {
      return { title: () => nearbyLabel.label, value: () => nearbyLabel.value }
    })
    tooltipColumns = [...tooltipColumns, ...nearbyLabelColumns]
  }

  return tooltipColumns
}

export function getMarkerIcon(groupType, item) {
  const icon = { ...ICON_BY_POINT_TYPE[groupType] }
  if (icon.fillColor && item.nearbyMarkerColor) {
    icon.fillColor = item.nearbyMarkerColor
  }

  return icon
}

function makeTooltipTitle(title) {
  return <div className="tooltip-title">{title}</div>
}

function getTripEndPopup(pointData) {
  const TRIP_END_COL_SCHEMA = _.concat(
    { title: () => makeTooltipTitle(messages.TRIP_END), value: () => ' ' },
    {
      title: () => messages.VISIT_TYPE,
      value: (pointData) => VISIT_TYPE_TO_MESSAGE[pointData.visitType],
    },
    getBaseTooltipColumns(pointData),
  )
  return generatePopup(pointData, TRIP_END_COL_SCHEMA)
}

function getTripStartPopup(pointData) {
  const TRIP_START_COL_SCHEMA = [
    { title: () => makeTooltipTitle(messages.TRIP_START), value: () => ' ' },
    {
      title: (pointData) =>
        pointData.routeStarted ? messages.START_TIME : messages.PLANNED_START_TIME,
      value: (pointData) => pointData.datetime,
    },
  ]
  return generatePopup(pointData, TRIP_START_COL_SCHEMA)
}

export function getClientPopup(pointData) {
  return generatePopup(pointData, getBaseTooltipColumns(pointData))
}

function getLocationPopup(pointData) {
  const LOCATION_COL_SCHEMA = [
    { title: (pointData) => makeTooltipTitle(pointData.desc), value: () => ' ' },
    {
      title: () => messages.LAST_LOCATION,
      value: (pointData) => pointData.datetime,
    },
  ]
  return generatePopup(pointData, LOCATION_COL_SCHEMA)
}

function generatePopup(pointData, colSchema) {
  const cols = _.filter(
    colSchema,
    (col) => !_.isNull(col.value(pointData)) && !_.isUndefined(col.value(pointData)),
  )

  return (
    <table className="client-tooltip">
      <tbody>
        {_.map(cols, (col) => (
          <tr key={col.title(pointData)}>
            <th className="title">{col.title(pointData)}</th>
            <td className="value">{col.value(pointData)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export const TOOLTIP_BY_ICON_TYPE = {
  [NEARBY_POINT]: getClientPopup,
  [CLIENT_POINT]: getClientPopup,
  [UNPLANNED_ON_SITE_POINT]: getClientPopup,
  [UNPLANNED_PHONE_POINT]: getClientPopup,
  [PHONE_POINT]: getClientPopup,
  [VISITED_CLIENT_POINT]: getClientPopup,
  [ROUTE_END_POINT]: getTripEndPopup,
  [ROUTE_START_AND_END_POINT]: getTripEndPopup,
  [ROUTE_START_POINT]: getTripStartPopup,
  [LOCATION_POINT]: getLocationPopup,
}

const TEL_AVIV_RANGE = new CoordRange({
  longitudeMin: 34.747629,
  longitudeMax: 34.798572,
  latitudeMin: 32.0511345,
  latitudeMax: 32.0981045,
})

export const DEFAULT_RANGE = TEL_AVIV_RANGE

export const CLIENT_ZOOM_LATITUDE_RANGE = 0.008
export const CLIENT_ZOOM_LONGITUDE_RANGE = 0.008
