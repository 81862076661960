import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { LOGIN_SCREEN_URL } from '../providers/authProvider'
import { tokenEntry } from '../providers/localStorageProvider'

export const TOP_RULER_HEIGHT_PX = 48

export const MENU_SIDEBAR_CLASSNAME = 'menu-sidebar'

export function goLogin() {
  if (window.location.href.includes(LOGIN_SCREEN_URL)) {
    return
  }

  tokenEntry.remove()
  window.location.href = '/'
}

export function useGoToResource() {
  const history = useHistory()

  const goToResource = useCallback(
    (resource, id = undefined) => {
      let path = `/${resource.RESOURCE_NAME}`
      if (id) {
        path = `${path}/${id}`
      }

      return history.push(path)
    },
    [history],
  )

  return goToResource
}

export function goToResource(resource) {
  window.location.href = `/${resource.RESOURCE_NAME}`
}

export function getSidebarMenuWidth() {
  const sideBar = document.getElementsByClassName(MENU_SIDEBAR_CLASSNAME)[0]
  return sideBar ? sideBar.clientWidth : 0
}

export function getUrlEntity() {
  const results = window.location.href.match(/\/(\w+)\/(\d+)(\/(\d+))?/)

  if (!results) {
    return {}
  }

  return {
    resourceName: results[1],
    resourceId: results[2],
    tabId: results[4],
  }
}
