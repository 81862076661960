import moment from 'moment'

export const DAY_MILLISECONDS = 24 * 60 * 60 * 1000

const DATE_FORMAT = 'YYYY-MM-DD'
const DATE_FORMAT_SHORT = 'DD.MM.YY'
const DATE_TIME_FORMAT = 'DD.MM.YY HH:mm'
const TIME_FORMAT = 'HH:mm'
const EURO_FORMAT = 'DD/MM/YYYY'
const SHORT_MONTH_YEAR_FORMAT = "MMM 'YY"

export const GLOBAL_LOCALE_CODE = 'en-ie'

export class SROMoment {
  constructor(givenMoment) {
    this.moment = moment(givenMoment)
  }

  static fromServerString(rawDateTimeFromServer) {
    if (!rawDateTimeFromServer) {
      return undefined
    }

    return new SROMoment(moment.utc(rawDateTimeFromServer))
  }

  static fromString(dateString) {
    if (!dateString) {
      return undefined
    }

    return new SROMoment(moment(dateString))
  }

  static parseShortMonthYear(dateString) {
    if (!dateString) {
      return undefined
    }

    return new SROMoment(moment(dateString, SHORT_MONTH_YEAR_FORMAT))
  }

  static now() {
    return new SROMoment(moment())
  }

  gte(other) {
    if (!other) {
      return true
    }

    return this.moment.isAfter(other.moment) || this.moment.isSame(other.moment)
  }

  gt(other) {
    if (!other) {
      return true
    }

    return this.moment.isAfter(other.moment)
  }

  eq(other) {
    if (!other) {
      return false
    }

    return this.moment.isSame(other.moment)
  }

  lt(other) {
    if (!other) {
      return false
    }

    return other.gte(this)
  }

  lte(other) {
    if (!other) {
      return false
    }

    return other.gte(this)
  }

  formatISO() {
    return `${this.moment.format(DATE_FORMAT)}T00:00:00.000Z`
  }

  fromDate(date) {
    // Not adding UTC here, since it's local
    return new SROMoment(moment(date))
  }

  fromTime(timeString) {
    // Not adding UTC here, since it's local
    return new SROMoment(moment(timeString, TIME_FORMAT))
  }

  formatEuro() {
    return this.moment.format(EURO_FORMAT)
  }

  formatDate() {
    return this.moment.format(DATE_FORMAT)
  }

  formatDateShort() {
    return this.moment.format(DATE_FORMAT_SHORT)
  }

  formatTime() {
    return this.moment.format(TIME_FORMAT)
  }

  formatDateTime() {
    return this.moment.format(DATE_TIME_FORMAT)
  }

  formatShortMonthYear() {
    return this.moment.format(SHORT_MONTH_YEAR_FORMAT)
  }

  toJSDate() {
    return this.moment.toDate()
  }

  add(count, units) {
    return new SROMoment(moment(this.moment).add(count, units))
  }

  diff(other) {
    return this.moment.diff(other)
  }

  isToday() {
    return this.formatDate() === SROMoment.now().formatDate()
  }

  startOf(unit) {
    return new SROMoment(moment(this.moment).startOf(unit))
  }

  endOf(unit) {
    return new SROMoment(moment(this.moment).endOf(unit))
  }
}

export function formatDate(sroMoment) {
  if (!sroMoment) {
    return ''
  }

  return sroMoment.formatDateShort()
}

export function formatTime(sroMoment) {
  if (!sroMoment) {
    return ''
  }

  return sroMoment.formatTime()
}

export function formatDateTime(sroMoment) {
  if (!sroMoment) {
    return ''
  }

  return sroMoment.formatDateTime()
}

export const getMonthNameByNum = (monthNum) => moment.monthsShort()[monthNum - 1]

export const MONTH_CODE_LIST = [
  'MONTH_JAN',
  'MONTH_FEB',
  'MONTH_MAR',
  'MONTH_APR',
  'MONTH_MAY',
  'MONTH_JUN',
  'MONTH_JUL',
  'MONTH_AUG',
  'MONTH_SEP',
  'MONTH_OCT',
  'MONTH_NOV',
  'MONTH_DEC',
]
