import React from 'react'
import _ from 'lodash'

import './analytics-shadow.scss'

class ColChart1 extends React.Component {
  render() {
    const BAR_OFFSET_PX = 100
    const BAR_DIFF_PX = 67

    const BARS = [120, 120, 120, 84, 135, 120, 120, 168, 198, 120, 84, 142, 120, 120, 120, 120]

    return (
      <div className="col-chart-1 box">
        <div className="horizontal"></div>
        {_.map(BARS, (height, index) => {
          return (
            <div
              key={`vertical-${index}`}
              className="vertical"
              style={{ height: `${height}px`, left: `${BAR_OFFSET_PX + BAR_DIFF_PX * index}px` }}
            ></div>
          )
        })}
      </div>
    )
  }
}

class ColChart2 extends React.Component {
  render() {
    const BAR_OFFSET_PX = 100
    const BAR_DIFF_PX = 67

    const BARS = [84, 135, 120, 168, 198, 120, 84]

    return (
      <div className="col-chart-2 box">
        <div className="horizontal"></div>
        {_.map(BARS, (height, index) => {
          return (
            <div
              key={`vertical-${index}`}
              className="vertical"
              style={{ height: `${height}px`, left: `${BAR_OFFSET_PX + BAR_DIFF_PX * index}px` }}
            ></div>
          )
        })}
      </div>
    )
  }
}

export default class DashboardView extends React.Component {
  render() {
    return (
      <div className="analytics-shadow">
        <div className="line">
          <ColChart1 />
        </div>
        <div className="line">
          <ColChart2 />
          <ColChart2 />
        </div>
      </div>
    )
  }
}
