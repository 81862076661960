import React from 'react'
import PropTypes from 'prop-types'

import './percentage-input.scss'

export default class PercentageInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      value: this.props.defaultValue,
    })

    this.onChange = this.onChange.bind(this)
    this.getValue = this.getValue.bind(this)
    this.getAbsoluteValue = this.getAbsoluteValue.bind(this)
  }

  getValue() {
    return parseInt(this.state.value)
  }

  getAbsoluteValue() {
    return parseInt((this.state.value * this.props.total) / 100)
  }

  onChange(event) {
    const value = event.target.value.replace(/[^\d]/g, '').replace(/^0*/g, '') || 0
    this.setState({ value }, this.props.onChange)
  }

  getName() {
    return this.props.name
  }

  render() {
    const absoluteValue = Math.round(this.props.total * (this.state.value / 100)) || 0
    return (
      <div className="percentage-input">
        <div className="percent">
          <input value={this.state.value} onChange={this.onChange} />
          <div className="percent-symbol">%</div>
        </div>
        <div className="absolute-view">{absoluteValue}</div>
      </div>
    )
  }
}

PercentageInput.propTypes = {
  total: PropTypes.number,
  defaultValue: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
}

PercentageInput.defaultProps = {
  defaultValue: 0,
  onChange: () => {},
}
