import React from 'react'
import PropTypes from 'prop-types'

import { getRandomString } from '../../utils/stringUtils'
import { getPointRange } from '../../utils/geoUtils'
import MapController from './mapController'
import { DEFAULT_RANGE } from './sroMapDefs'

import './sro-map.scss'

export default class SROMapView extends React.Component {
  constructor(props) {
    super(props)

    this.mapDOMId = `map-${getRandomString()}`
    this.configController()
  }

  renderRoutes(map) {
    this.props.routes.forEach((route) => this.renderRoute(map, route))
  }

  getController() {
    const mapCoordsRange = this.map ? this.getMapCoordRange() : getPointRange(this.props.points)

    return new MapController({
      mapCoordsRange: mapCoordsRange.getWidth() ? mapCoordsRange : DEFAULT_RANGE,
      zoom: this.getMaxZoom(),
      points: this.props.points,
      mapMaxWidthPercent: this.props.mapMaxWidthPercent,
      mapMaxHeightPercent: this.props.mapMaxHeightPercent,
    })
  }

  configController() {
    this.mapController = this.getController()
    this.model = this.mapController.getModel()

    this.setCenter = this.setCenter.bind(this)
    this.setZoom = this.setZoom.bind(this)

    this.makeSubscriptions()
  }

  UNSAFE_componentWillReceiveProps() {
    this.configController()
  }

  makeSubscriptions() {
    this.model.onZoomChange(this.setZoom)
    this.model.onCenterChange(this.setCenter)
  }

  getBounds() {
    throw new Error('Not implemented')
  }

  getVisibleWindowRange() {
    const range = this.getMapCoordRange()

    return range.multiply({
      latitudeMax: 1,
      latitudeMin: this.props.mapMaxHeightPercent / 100,
      longitudeMax: 1,
      longitudeMin: this.props.mapMaxWidthPercent / 100,
    })
  }
}

SROMapView.propTypes = {
  mapMaxHeightPercent: PropTypes.number,
  mapMaxWidthPercent: PropTypes.number,
  routes: PropTypes.array,
  points: PropTypes.object,
}
