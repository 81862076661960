import { put, takeEvery } from 'redux-saga/effects'
import {
  AccountListRequestedAction,
  AccountListReceivedAction,
} from '../actions/accountListActions'
import Requester from '../providers/request'
import { AccountResource } from '../providers/resources/requestResources'

function* fetch() {
  const data = yield new Requester().getEverything(AccountResource)
  yield put(AccountListReceivedAction.make(data.data))
}

export default function* tripMapSaga() {
  yield takeEvery(AccountListRequestedAction.NAME, fetch)
}
