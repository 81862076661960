import { connect } from 'react-redux'

import DailyActivityView from '../components/DailyActivityView'
import { makeChangeFilter } from '../actions/dailyActivity'

function mapStateToProps(state) {
  return {
    filter: state.customReducers.dailyActivity.filter,
    data: state.customReducers.dailyActivity.data,
    status: state.customReducers.dailyActivity.status,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeFilter: (newFilter) => dispatch(makeChangeFilter(newFilter)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DailyActivityView)
