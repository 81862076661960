import { useQuery, useTranslate } from 'react-admin'
import _ from 'lodash'
import moment from 'moment'

import { SROMoment } from '../utils/timeUtils'
import { toPrecision } from '../utils/numberUtils'

import { DEFAULT_QUERY } from '../providers/queryTools'
import {
  RawReportActivitiesResource,
  SalesSummaryResource,
  VisitsByRatingResource,
  AnnualActivityResource,
  AgentResource,
} from '../providers/resources/requestResources'

function getAnnualParams(params) {
  const to = moment().endOf('month')
  const formattedTo = new SROMoment(to).formatDate()
  const from = moment().startOf('month').subtract(1, 'year').add(1, 'month')
  const formattedFrom = new SROMoment(from).formatDate()

  return {
    from: formattedFrom,
    to: formattedTo,
    agentId: AgentResource.getQueryAgentId(params.agentId),
  }
}

function countSalesSummaryByRating(salesSummaryRaw) {
  return _(salesSummaryRaw.records)
    .map((value) => ({
      name: value.rateName,
      value: value.count,
    }))
    .sortBy('name')
    .value()
}

function countVisitsByRating(visitsByRatingRaw) {
  return _.map(visitsByRatingRaw.records, (value) => ({
    name: value.rateName,
    value: value.onSiteActivities,
  }))
}

function getActualVisitTimesByAgent(reportActivityByAgentRaw) {
  return _(reportActivityByAgentRaw.records)
    .keyBy('agentId')
    .map((item) => {
      const onSiteVisitTimeHours = toPrecision(item.onSiteVisitTimeMinutes / 60, 1)
      const totalWorkingHours = toPrecision(item.totalWorkingMinutes / 60, 1)

      return {
        agentId: item.agentId,
        agentName: item.agentName,
        actualVisitTime: onSiteVisitTimeHours,
        routeTime: totalWorkingHours - onSiteVisitTimeHours,
        total: totalWorkingHours,
      }
    })
    .map((item) => ({
      ...item,
      percents: {
        actualVisitTime: Math.round((100 * item.actualVisitTime) / item.total) || 0,
        routeTime: Math.round((100 * item.routeTime) / item.total) || 0,
      },
    }))
    .value()
}

function getTotalRouteTime(reportActivityRaw, translate) {
  const onSiteVisitTimeMinutes = _.get(reportActivityRaw, 'records[0].onSiteVisitTimeMinutes')
  const byPhoneVisitTimeMinutes = _.get(reportActivityRaw, 'records[0].byPhoneVisitTimeMinutes')
  const totalWorkingMinutes = _.get(reportActivityRaw, 'records[0].totalWorkingMinutes')
  const onSiteVisitTimeHours = toPrecision(onSiteVisitTimeMinutes / 60, 1)
  const byPhoneVisitTimeHours = toPrecision(byPhoneVisitTimeMinutes / 60, 1)
  const totalWorkingHours = toPrecision(totalWorkingMinutes / 60, 1)

  return [
    { name: translate('ON_SITE_VISIT_TIME'), value: onSiteVisitTimeHours },
    { name: translate('BY_PHONE_VISIT_TIME'), value: byPhoneVisitTimeHours },
    {
      name: translate('OTHER'),
      value: toPrecision(totalWorkingHours - (onSiteVisitTimeHours + byPhoneVisitTimeHours)),
    },
  ]
}

function getActualVisitsByCategories(reportActivityRaw, translate) {
  return [
    {
      name: translate('ON_SITE_PLANNED'),
      value: _.get(reportActivityRaw, 'records[0].plannedOnSiteActivities'),
    },
    {
      name: translate('BY_PHONE'),
      value: _.get(reportActivityRaw, 'records[0].byPhoneActivities'),
    },
    {
      name: translate('CANCELED'),
      value: _.get(reportActivityRaw, 'records[0].canceledNonRescheduledActivities'),
    },
    {
      name: translate('ON_SITE_UNPLANNED'),
      value: _.get(reportActivityRaw, 'records[0].unplannedOnSiteActivities'),
    },
    { name: translate('LEADS'), value: _.get(reportActivityRaw, 'records[0].leadActivities') },
  ]
}

function calculateTotalSuccessVisits(plannedActivitiesRaw) {
  const activities = _.get(plannedActivitiesRaw, 'records[0].successfulOnSiteActivities')
  const planned = _.get(plannedActivitiesRaw, 'records[0].plannedActivities')
  return {
    absActual: activities,
    absPlanned: planned,
    percent: Math.round((100 * activities) / planned || 0),
  }
}

function calculateTotalVisits(plannedActivitiesRaw) {
  const activities = _.get(plannedActivitiesRaw, 'records[0].onSiteActivities')
  const planned = _.get(plannedActivitiesRaw, 'records[0].plannedActivities')
  return {
    percent: Math.round((100 * activities) / planned || 0),
    absActual: activities,
    absPlanned: planned,
  }
}

export function useDashboardData(params) {
  const translate = useTranslate()
  const payload = {
    ...params,
    from: params.from.formatDate(),
    to: params.to.formatDate(),
  }

  const { data: reportActivity, loading: reportActivityLoading } = useQuery({
    type: 'GET_RAW',
    resource: RawReportActivitiesResource.RESOURCE_NAME,
    payload,
  })
  const { data: reportActivityByAgent, loading: reportActivityByAgentLoading } = useQuery({
    type: 'GET_RAW',
    resource: RawReportActivitiesResource.RESOURCE_NAME,
    payload: { ...payload, dimensions: ['agentId'] },
  })
  const { data: salesSummary, loading: salesSummaryLoading } = useQuery({
    type: 'GET_RAW',
    resource: SalesSummaryResource.RESOURCE_NAME,
    payload,
  })
  const { data: visitsByRating, loading: visitsByRatingLoading } = useQuery({
    type: 'GET_RAW',
    resource: VisitsByRatingResource.RESOURCE_NAME,
    payload,
  })
  const { data: reportActivityAnnual, loading: reportActivityAnnualLoading } = useQuery({
    type: 'GET_RAW',
    resource: AnnualActivityResource.RESOURCE_NAME,
    payload: getAnnualParams(params),
  })
  const agentPayload = _.cloneDeep(DEFAULT_QUERY)
  agentPayload.filter.isActive = true
  const { data: agents, loading: agentsLoading } = useQuery({
    type: 'GET_LIST',
    resource: AgentResource.RESOURCE_NAME,
    payload: agentPayload,
  })

  const loading =
    reportActivityLoading ||
    reportActivityByAgentLoading ||
    salesSummaryLoading ||
    visitsByRatingLoading ||
    reportActivityAnnualLoading ||
    agentsLoading

  if (loading) {
    return { data: undefined, loading }
  }

  return {
    data: {
      agents,
      totalVisits: calculateTotalVisits(reportActivity),
      customersByRating: countSalesSummaryByRating(salesSummary),
      totalSuccessVisits: calculateTotalSuccessVisits(reportActivity),
      visitsByRating: countVisitsByRating(visitsByRating),
      actualVisitsByCategories: getActualVisitsByCategories(reportActivity, translate),
      totalRouteTime: getTotalRouteTime(reportActivity, translate),
      reportActivityAnnual: reportActivityAnnual,
      actualVisitTimesPerAgent: getActualVisitTimesByAgent(reportActivityByAgent),
    },
    loading,
  }
}
