import _ from 'lodash'

export function flatToDeep(flat) {
  const result = {}

  _.forOwn(flat, (value, key) => {
    _.set(result, key, value)
  })

  return result
}

export function compareFlatDeep(flat, deep) {
  return _.reduce(
    flat,
    (result, value, key) => {
      const deepValue = _.get(deep, key)

      if (!result) {
        return false
      }

      if (_.isEqual(deepValue, value)) {
        return true
      }

      if (Number.isFinite(deepValue)) {
        return `${deepValue}` === `${value}`
      }

      return false
    },
    true,
  )
}
