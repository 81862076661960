import { messages } from '../../i18n/messages'
import { VISIT_TYPE_PHONE, VISIT_TYPE_CLIENT, NEARBY_POINT } from './sroMapDefs'

const isNearbyAllowed = (pageFilter) => pageFilter.date.isToday()

export const MAP_LAYERS = [
  {
    name: 'layer-phone',
    title: messages.VISIT_TYPE_PHONE,
    default: () => true,
    enabled: {
      check: () => true,
    },
    filter: { visitType: VISIT_TYPE_PHONE },
  },
  {
    name: 'layer-new-activities',
    title: messages.NEW_ACTIVITIES,
    default: () => true,
    enabled: {
      check: () => true,
    },
    filter: { visitType: VISIT_TYPE_CLIENT, isPlanned: false },
  },
  {
    name: 'layer-nearby-clients',
    title: messages.NEARBY_CLIENTS,
    default: () => false,
    enabled: {
      check: isNearbyAllowed,
      denialMessage: messages.NEARBY_ONLY_TODAY,
    },
    filter: { pointType: NEARBY_POINT },
  },
]
