import React from 'react'
import PropTypes from 'prop-types'

import SRODropdown from '../SRODropdown'
import { getResourceClassByName } from '../../../../providers/resources/resources'

import './table-inline-dropdown.scss'

export default class TableInlineDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      value: this.props.record[this.props.source],
    })

    this.onChange = this.onChange.bind(this)
    this.save = this.save.bind(this)
  }

  save() {
    this.props.save({
      resource: getResourceClassByName(this.props.resource),
      data: {
        id: this.props.record.id,
        data: { [this.props.source]: this.state.value },
      },
    })
  }

  onChange(value) {
    this.setState(
      {
        value: value,
      },
      this.save,
    )
  }

  render() {
    return (
      <SRODropdown
        options={this.props.options}
        value={this.state.value}
        onChange={this.onChange}
        className="table-inline-dropdown"
      />
    )
  }
}

TableInlineDropdown.propTypes = {
  options: PropTypes.array,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  save: PropTypes.func,
}
