import { put, takeEvery } from 'redux-saga/effects'
import Requester from '../providers/request'
import { messages } from '../i18n/messages'
import {
  ClientSummarySimulationRequestAction,
  ClientSummarySimulationResponseAction,
  ClientSummarySimulationError,
} from '../actions/clientSummarySimulator'
import { SimulationFullResource } from '../providers/resources/comboResources'
import { BAD_REQUEST } from '../providers/queryTools'

function* fetch(action) {
  const requester = new Requester()

  try {
    const data = yield requester.post(SimulationFullResource, {
      accountId: action.accountId,
      data: action.data,
    })
    yield put(ClientSummarySimulationResponseAction.make(data))
  } catch (err) {
    if (err.type !== BAD_REQUEST) {
      yield ClientSummarySimulationError.make(messages.UNKNOWN_ERROR)
    }

    yield put(ClientSummarySimulationError.make(err.action))
  }
}

export default function* () {
  yield takeEvery(ClientSummarySimulationRequestAction.NAME, fetch)
}
