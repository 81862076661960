import CreateActivitySaga from './createActivitySaga'
import DailyActivitySaga from './dailyActivitySaga'
import SettingSaga from './settingSaga'
import RequestSaga from './requestSaga'
import ClientSimulatorSaga from './clientSimulatorSaga'
import AccountListSaga from './accountListSaga'
import AgentTeamListSaga from './agentTeamListSaga'
import AgentListSaga from './agentListSaga'
import AnalyticsSaga from './analyticsSaga'
import ClientListSaga from './clientListSaga'

export default [
  CreateActivitySaga,
  DailyActivitySaga,
  SettingSaga,
  RequestSaga,
  ClientSimulatorSaga,
  AccountListSaga,
  AgentTeamListSaga,
  AgentListSaga,
  AnalyticsSaga,
  ClientListSaga,
]
