import React from 'react'
import { translate } from 'react-admin'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import SROTooltip from '../SROTooltip'

import './buttons.scss'

const SVGButtonWrapper = (props) => {
  return (
    <SROTooltip text={props.title}>
      <div className={classnames('sro-button')}>
        <svg
          className={classnames('button-svg', props.className)}
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          viewBox="0 0 60 60"
          title={props.title}
          onClick={props.onClick || (() => {})}
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(0 0)">
              <rect width="60" height="60" rx="5" className="background" />
            </g>
            {props.content}
          </g>
        </svg>
      </div>
    </SROTooltip>
  )
}

SVGButtonWrapper.propTypes = {
  fill: PropTypes.string,
  hoverFill: PropTypes.string,
  content: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

class SVGButton extends React.Component {
  render() {
    return (
      <SVGButtonWrapper
        className={this.constructor.CLASS_NAME}
        onClick={this.props.onClick}
        title={this.props.title}
        content={this.getButtonContent()}
        fill={this.fill}
        hoverFill={this.hoverFill}
      />
    )
  }
}

SVGButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
}

export class AddButton extends SVGButton {
  static CLASS_NAME = 'add-button'

  getButtonContent() {
    return (
      <g transform="translate(0 0)">
        <g stroke="#FFF" strokeLinecap="round" strokeWidth="2">
          <path d="M31 19.5v21M20.5 30h21" />
        </g>
      </g>
    )
  }
}

export class ExportButton extends SVGButton {
  static CLASS_NAME = 'export-button'

  getButtonContent() {
    return (
      <g transform="translate(0 0)">
        <path
          fill="#FFF"
          d="M32.607 38.91h4.46v-3.174h-4.46v-.952h4.46v-3.173h-4.46v-.952h4.46v-3.173h-4.46v-.952h4.46v-3.173h-4.46v-.952h4.46v-3.173h-4.46v-1.656h12.302v23.64h-12.302v-2.31zM31.84 14v30.732L14 41.474v-24.347L31.84 14zm6.15 5.236v3.173h5.074v-3.173h-5.075zm0 4.125v3.173h5.074v-3.173h-5.075zm0 4.125v3.173h5.074v-3.173h-5.075zm0 4.125v3.173h5.074v-3.173h-5.075zm0 4.125v3.173h5.074v-3.173h-5.075z"
        />
        <text fill="#D1F0AE" fontFamily="Ubuntu" fontSize="12.061" transform="translate(14 14)">
          <tspan x="4.346" y="16.283">
            X
          </tspan>
        </text>
      </g>
    )
  }
}

export class PrintButton extends SVGButton {
  static CLASS_NAME = 'print-button'

  getButtonContent() {
    return (
      <g transform="translate(0 0)">
        <path
          fill="#FFF"
          d="M37.375 25h-15.75a3.37 3.37 0 0 0-3.375 3.375v6.75h4.5v4.5h13.5v-4.5h4.5v-6.75A3.37 3.37 0 0 0 37.375 25zM34 37.375h-9V31.75h9v5.625zm3.375-7.875a1.128 1.128 0 0 1-1.125-1.125c0-.619.506-1.125 1.125-1.125s1.125.506 1.125 1.125-.506 1.125-1.125 1.125zM36.25 19.375h-13.5v4.5h13.5v-4.5z"
        />
        <path d="M16 16h27v27H16z" />
      </g>
    )
  }
}

export class ReloadButton extends SVGButton {
  static CLASS_NAME = 'reload-button'

  getButtonContent() {
    return (
      <g stroke="#FFF" strokeLinecap="round">
        <path
          strokeWidth="2"
          d="M35.506 35.525a9.333 9.333 0 1 1-6.673-15.859A9.333 9.333 0 0 1 38.167 29"
        />
        <path fill="#FFF" strokeLinejoin="round" d="M34.667 27.833h7l-3.5 4.667z" />
      </g>
    )
  }
}

class ToggleButtonClass extends React.Component {
  render() {
    const pathByIsOpen = {
      true: 'M0,5l10,10l10,-10',
      false: 'M5,0l10,10l-10,10',
    }

    return (
      <SROTooltip text={this.props.title} displayBlock={this.props.displayBlock}>
        <div
          className={classnames('toggle-button')}
          onClick={(...args) => !this.props.disabled && this.props.onClick(...args)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 20 20"
            className={classnames('pointer', this.props.className)}
            title={this.props.title}
            alt={this.props.title}
          >
            <g fill="none" fillRule="evenodd">
              <g stroke="#979797" strokeWidth="2">
                <path d={pathByIsOpen[!!this.props.value]} />
              </g>
            </g>
          </svg>
        </div>
      </SROTooltip>
    )
  }
}

ToggleButtonClass.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  displayBlock: PropTypes.bool,
  onClick: PropTypes.func,
}

ToggleButtonClass.defaultProps = {
  width: 20,
  height: 20,
  onClick: () => {},
}

export const ToggleButton = translate(ToggleButtonClass)
