import reactAdminSystemHebrewMessages from './reactAdminSystemHebrewMessages'

const reactAdminMandatoryKeys = {
  Unauthorized: 'לא מאושר',
}

const resources = {
  agentBreak: {
    name: 'זמן הפסקה |||| זמני הפסקה',
  },
  agentTeam: {
    name: 'צוות סוכנים |||| צוותי סוכנים',
  },
}

export default {
  ...reactAdminSystemHebrewMessages,
  ...reactAdminMandatoryKeys,
  resources,
  'Bad Request': 'בקשה נכשלה',
  ID: 'מזהה',
  ACTIVITY_CREATED: 'פעילות חדשה נוצרה בהצלחה',
  ACTIVITY_WILL_APPEAR_LATER: 'הפעילות תופיע ביום הפעילות הבא לאחר חישוב המסלול מחדש',
  ACTIVITY_CREATION_FAILED: 'יצירת פעילות חדשה נכשלה: %{error}',
  AGENT: 'סוכן',
  CREATE_ACTIVITY: 'צור פעילות',
  RATING: 'דירוג',
  CLIENT_NAME: 'שם לקוח',
  ADDRESS: 'כתובת',
  CITY_NAME: 'עיר',
  DAYS_VISIT: 'ימי ביקור',
  VISIT_PURPOSE: 'מטרת ביקור',
  VISIT_REMARK: 'הערת ביקור',
  REQUEST_NEW_SCHEDULE: 'שבץ ביקורים מחדש',
  EXPORT_ACTIVITY: 'ייצא פעילות',
  PRINT_ACTIVITY: 'הדפס פעילות',
  ACTIVITY_CALENDAR: 'יומן פעילות',
  NO_ITEMS_TO_SHOW: 'לא נמצאו תוצאות',
  CREATE_NEW_ACTIVITY_FOR_AGENT_X: 'ייצר פעילות חדשה עבור %{agentName}',
  CANCEL: 'בטל',
  SCHEDULED_AT: 'מתוכנן ל %{time}',
  ACTIVITY_RESCHEDULE_FAILED: 'שיבוץ מחדש נכשל',
  ACTIVITY_RESCHEDULED: 'שיבוץ מחדש הושלם',
  ALL_WEEK: 'כל השבוע',
  TOTAL_PLANNED_VISITS: 'סה"כ ביקורים מתוכננים',
  TOTAL_UNPLANNED_VISITS: 'סה"כ ביקורים לא מתוכננים',
  LOADING: 'טוען',
  PLANNED_VISIT: 'ביקור מתוכנן',
  PLANNED_VISITS: 'ביקורים מתוכננים',
  UNPLANNED_VISITS: 'ביקורים לא-מתוכננים',
  SELECT_DATE_RANGE: 'בחר טווח תאריכים',
  DATE_ROUTE: 'מסלול יומי מתוכנן, לחץ להצגת מפה',
  DAY_TRAVEL_DISTANCE: 'אורך מסלול יומי',
  SHOW_DETAILS: 'הראה פרטים',
  APPLY: 'החל',
  DAILY_ACTIVITY: 'פעילות יומית',
  ORDER_TOTAL: 'סה"כ הזמנה',
  S_TIME: 'זמן התחלה',
  E_TIME: 'זמן סיום',
  LAST_VISIT: 'ביקור אחרון',
  LAST_ORDER_T: 'סה"כ הזמנה קודמת',
  CANCELLATION: 'ביטול',
  CANCELED: 'מבוטלות',
  NEW: 'חדשות',
  NEW_ACTIVITIES: 'פעילויות חדשות',
  CLOSED: 'סגורות',
  ACTIVITIES: 'פעילויות',
  TOTAL_ORDERS: 'סה"כ הזמנות',
  DAILY: 'יומי',
  MONTHLY: 'חודשי',
  SALES_GOALS: 'יעדי מכירות',
  NOT_SELLING_REASON: 'סיבת אי-מכירה: %{value}',
  CANCELLATION_REASON: 'סיבת ביטול: %{value}',
  RESCHEDULE_REASON: 'סיבת שיבוץ מחדש: %{value}',
  RESCHEDULE_TIME: 'זמן שיבוץ מחדש: %{value}',
  NO_INFO_TO_SHOW: 'אין נתונים להצגה',
  TOTAL_KM: 'סה"כ ק"מ',
  SETTINGS: 'הגדרות',
  SYSTEM_WELCOME: 'ברוכים הבאים לאמודט SRO',
  NEXT: 'הבא',
  ACCOUNT: 'חשבון',
  ACCOUNT_DETAILS: 'הגדרות חשבון',
  AGENT_DEFAULT_DETAILS: 'סוכן - נתוני ברירת מחדל',
  ADD_WORK_HOURS: 'הגדר שעות עבודה',
  ACCOUNT_SETTINGS: 'הגדרות חשבון',
  AGENT_CODE: 'קוד סוכן',
  AGENT_NAME: 'שם סוכן',
  S_ADDRESS: 'כתובת התחלה',
  E_ADDRESS: 'כתובת סיום',
  WORK_HOURS: 'שעות עבודה',
  VISIT_TIME: 'זמן ביקור',
  ACTUAL_VISIT_TIME: 'זמן ביקור בפועל',
  EMAIL: 'אימייל',
  STATUS: 'סטאטוס',
  ACTIVE: 'פעיל',
  NAME: 'שם',
  VISIT_FREQUENCY: 'תדירות ביקורים',
  FEATURE: 'נתון',
  OP: 'פעולה',
  VALUE: 'ערך',
  MONDAY: 'שני',
  TUESDAY: 'שלישי',
  WEDNESDAY: 'רביעי',
  THURSDAY: 'חמישי',
  FRIDAY: 'שישי',
  SATURDAY: 'שבת',
  SUNDAY: 'ראשון',
  SAVE: 'שמור',
  ADD_DAY: 'הוסף יום',
  RATING_NAME: 'שם דירוג',
  ADD_CONDITION: '+ הוסף תנאי',
  REMOVE_RATING: 'הסר דירוג',
  ADD_RATING: 'הוסף דירוג',
  AGENT_SETTINGS: 'הגדרות סוכן',
  MOVE_ITEM_UP: 'הזז למעלה',
  MOVE_ITEM_DOWN: 'הזז למטה',
  AT_LEAST_ONE_RATING_REQUIRED: 'שגיאה: דרוש לפחות דירוג אחד',
  CHOOSE_FEATURE: 'בחר נתון',
  PREVIEW: 'הצגה',
  TOTAL_VISITS: 'סה"כ ביקורים',
  AGENT_EXPECTED_VISITS: 'כמות ביקורים מוערכת',
  AGENT_ACTUAL_TOTAL_VISITS: 'ביקורים בפועל',
  ACCOUNT_NAME: 'שם לקוח',
  SAVING_FAILED: 'שמירה נכשלה',
  FINISH_SETUP: 'סיים התקנה',
  BACK: 'חזרה',
  FROM_MUST_BE_LT_TO: 'זמן התחלה חייב להיות לפני זמן סיום',
  MUST_FILL_START_AND_END: 'זמן התחלה וזמן סיום הם חובה',
  KEY_IS_MANDATORY: "שדה '%{title}' הוא חובה",
  ERROR_OVER_MAX_LENGTH: "שדה '%{title}' ארוך יותר מ %{max} תוים",
  ERROR_VALUE_MUST_BE_GT: "שדה '%{title}' חייב להיות גדול מ %{gt}",
  ERROR_VALUE_MUST_BE_GTE: "שדה '%{title}' חייב להיות גדול או שווה ל %{gte}",
  ERROR_VALUE_MUST_BE_LTE: "שדה '%{title}' חייב להיות קטן או שווה ל %{lte}",
  ERROR_WORKING_HOURS_MUST_CONTAIN_MIN_1: 'שעות פעילות חייבות להכיל לפחות יום אחד',
  ERROR_INVALID_EMAIL: "שדה '%{title}' אינו אימייל חוקי",
  TOTAL_MUST_BE_100_PERC: 'סה"כ-% לסוכן %{agent} לא שווה ל 100%',
  CLIENT: 'לקוח',
  V_TIME_PLANNED: 'זמן ביקור מתוכנן',
  V_TIME_ACTUAL: 'זמן ביקור בפועל',
  V_DURATION: 'אורך ביקור',
  AGENT_LOCATION: 'מיקום %{agentName}',
  AGENT_LOCATION_CANNOT_BE_DETECTED: 'מיקום סוכן לא נמצא',
  FREEZE_PERIOD: 'תקופת הקפאה',
  WAIT_PLANNING_SCHEDULE: 'מתכנן שיבוץ לכל הסוכנים, זה יקח כמה דקות...',
  CORRECT_ERRORS_BEFORE_SAVING: 'נמצאו שגיאות. אנא תקן אותן לפני השמירה',
  FINISHED_LOADING_REDIRECTING_MAIN: 'תכנון שיבוץ הושלם, מעביר אותך למסך הראשי...',
  UNKNOWN_ERROR: 'קרתה שגיאה לא מזוהה. אנא נסה שנית מאוחר יותר או פנה לתמיכה',
  CUSTOM_RATING: 'קיבוע דירוג',
  NEXT_VISIT: 'ביקור הבא',
  USED_SLASH_CREDIT: 'קרדיט / נוצל',
  MONTH_PURCHASE: 'מכירות חודשי',
  UNPLANNED_ACTIVITY: 'ביקור לא-מתוכנן',
  ALL_AGENTS: 'כל הסוכנים',
  EMPTY_VALUES_SET_TO_DEFAULT: '* שדות ריקים יקבלו את ערכם מהגדרות ברירת המחדל של החשבון',
  ERROR_EMAIL_ALREADY_TAKEN: 'סוכן %{externalAgentId}: אימייל תפוס על ידי סוכן אחר',
  NEARBY_CLIENTS_RADIUS_METERS: 'רדיוס לקוחות קרובים (מטרים)',
  VISIT_MAX_DISTANCE_METERS: 'מרחק מקסימלי לביקור (מטרים)',
  MONTHLY_VISITS: 'כמות ביקורים חודשי',
  AGENT_RATE_PREFERENCES: 'הגדרות דירוג סוכן',
  ERROR_AGENT_NOT_FOUND: 'סוכן לא נמצא',
  NEXT_VISIT_DATE: 'תאריך ביקור הבא',
  DATE: 'תאריך',
  CLIENT_CODE: 'קוד לקוח',
  TOTAL_SUCCESS_VISITS: 'כמות ביקורים מוצלחים',
  MONTHLY_VISITS_GRAPH: 'גרף ביקורים חודשי',
  TOTAL_DAILY_ORDERS: 'סה"כ הזמנות יומי',
  DAILY_VISITS_GRAPH: 'גרף ביקורים יומי',
  CLIENTS_RATING: 'דירוג לקוחות',
  CUSTOMERS_BY_RATING: 'לקוחות לפי דירוג',
  AGENTS_VISITS_BY_RATINGS: 'ביקורים לפי דירוג',
  VISITS_BY_CATEGORIES: 'ביקורים לפי קטגוריה',
  TOTAL_ROUTE_TIME: 'סה"כ זמן מסלול',
  ACTUAL_VISIT_TIMES_PER_AGENT: 'זמני ביקורים בפועל לסוכן',
  ANNUAL_TREND: 'טרנד שנתי - לפי תכנון',
  AGENT_UPDATE_SUCCESS: 'סוכן %{displayName} עודכן בהצלחה',
  AGENT_UPDATE_FAIL: 'עדכון סוכן %{displayName} נכשל',
  ACTIVITY_UPDATE_SUCCESS: 'פעילות %{displayName} עודכן בהצלחה',
  ACTIVITY_UPDATE_FAIL: 'עדכון פעילות %{displayName} נכשל',
  USER_UPDATE_SUCCESS: 'משתמש %{displayName} עודכן בהצלחה',
  USER_UPDATE_FAIL: 'עדכון משתמש %{displayName} נכשל',
  ACCOUNT_UPDATE_SUCCESS: 'חשבון %{displayName} עודכן בהצלחה',
  ACCOUNT_UPDATE_FAIL: 'עדכון חשבון %{displayName} נכשל',
  EDIT: 'ערוך',
  NUMBER_OF_CLIENTS: 'מסך לקוחות',
  EDIT_AGENT: 'עריכת סוכן',
  EDIT_ACCOUNT: 'עריכת חשבון',
  CLIENT_RATING: 'דירוג לקוח',
  SAVING_PLEASE_WAIT: 'שומר. אנא המתן...',
  ON_SITE_PLANNED: 'מתוכנן בבית-לקוח',
  ON_SITE_UNPLANNED: 'לא-מתוכנן בבית-לקוח',
  BY_PHONE: 'טלפוני',
  LEADS: 'לידים',
  ON_SITE_VISIT_TIME: 'זמן ביקור בבית-ללקוח',
  BY_PHONE_VISIT_TIME: 'זמן ביקור טלפוני',
  OTHER: 'אחר',
  CUSTOMERS_COUNT: 'מספר לקוחות',
  VISITS_COUNT: 'מספר ביקורים',
  TOTAL_HOURS: 'סה"כ שעות',
  BACK_TO_AGENTS: '◄ חזרה לסוכנים',
  ON_SITE: 'בבית-לקוח',
  SUCCESSFUL: 'מוצלח',
  ROUTE_TIME: 'זמן מסלול',
  ACTUAL_VISITS_TIME: 'זמן ביקורים בפועל',
  ACCOUNT_ID: 'קוד חשבון',
  AGENTS: 'סוכנים',
  SHOW: 'הצג',
  API_KEY: 'מפתח API',
  CREATED: 'נוצר',
  UPDATED: 'עודכן',
  BACK_TO_ACCOUNTS: '◄ חזור לחשבונות',
  BACK_TO_USERS: '◄ חזור למשתמשים',
  ADD_ACCOUNT: 'הוסף חשבון',
  USER_ID: 'קוד לקוח',
  USERNAME: 'שם משתמש',
  LAST_LOGIN: 'כניסה אחרונה',
  ROLES: 'תפקידים',
  ADD_USER: 'הוסף משתמש',
  PASSWORD: 'סיסמה',
  AGENT_TEAM: 'צוות סוכנים',
  USERS: 'משתמשים',
  ACCOUNTS: 'חשבונות',
  MY_USER: 'המשתמש שלי',
  CLIENTS: 'לקוחות',
  REQUEST_ERROR: 'שגיאה: %{errorMessage}',
  ROLE_ADMIN: 'אדמיניסטרטור',
  ROLE_ACCOUNT_MANAGER: 'מנהל חשבון',
  ROLE_AGENT_TEAM_MANAGER: 'מנהל צוות סוכנים',
  ROLE_AGENT: 'סוכן',
  ROLE: 'תפקיד',
  EDIT_USER: 'ערוך משתמש',
  CREATE_USER: 'צור משתמש',
  'ra.notification.created': 'פעולה הושלמה בהצלחה',
  PROGRESS: 'התקדמות: %{percent}%',
  START_TIME: 'זמן התחלה',
  END_TIME: 'זמן סיום',
  AVG_KM_PER_VISIT: 'ק"מ-ממוצע/ביקור',
  CREATE_ACCOUNT: 'צור חשבון',
  USER_DETAILS: 'פרטי משתמש',
  LOGIN: 'היכנס',
  RESET_PASSWORD: 'אפס סיסמה',
  LOGIN_FAILED: 'שם משתמש או סיסמה לא נכונים',
  CANNOT_CHANGE_OWN_STATUS: 'לא ניתן להפוך את עצמך ללא פעיל',
  CANNOT_CHANGE_OWN_ROLE: 'לא ניתן לשנות לעצמך את התפקיד',
  LOGOUT: 'התנתק',
  DASHBOARD: 'דאשבורד',
  PLEASE_FILL_USERNAME: 'אנא הכנס שם משתמש או אימייל',
  PASSWORD_EMAIL_SENT: 'שלחנו לך אימייל עם לינק לאיפוס הסיסמה',
  NEW_PASSWORD: 'סיסמה חדשה',
  RE_ENTER_NEW_PASSWORD: 'הכנס סיסמה מחדש',
  RESET: 'אפס',
  PASSWORDS_DO_NOT_MATCH: 'סיסמאות לא זהות',
  PLEASE_CHOOSE_A_NEW_PASSWORD: 'אנא בחר סיסמה חדשה',
  PASSWORD_RESET_WENT_WRONG: 'אירעה שגיאה. יתכן שקוד האיפוס כבר לא תקף, אנא נסה שנית',
  PASSWORD_RESET_SUCCESSFULLY: 'איפוס סיסמה הושלם בהצלחה',
  CONTINUE_TO_LOGIN: 'המשך לכניסה למערכת',
  SRO_TITLE: 'אופטימיזציית מסלולי מכירות',
  ON_SITE_ACTIVITIES: 'פעילויות בבית-לקוח',
  PHONE_ACTIVITIES: 'פעילויות טלפוניות',
  PLANNED: 'מתוכננים',
  ACTUAL: 'בפועל',
  LABEL_PERCENT_HOURS: '%{label}: %{percent}% (%{hours} שעות)',
  TRIP_END: 'סוף ביקור',
  TRIP_START: 'התחלת ביקור',
  VISIT_TYPE: 'סוג ביקור',
  PHONE: 'טלפון',
  NEARBY_CLIENTS: 'לקוחות קרובים',
  VISIT: 'ביקור',
  VISIT_TYPE_PHONE: 'טלפון',
  NEARBY_ONLY_TODAY: 'לא ניתן להציג לקוחות קרובים ליום אחר מהיום',
  LOADING_MAP: 'טוען מפה...',
  PLANNED_START_TIME: 'זמן התחלה מתוכנן',
  LAST_LOCATION: 'מיקום אחרון',
  WORK_SUMMARY: 'סיכום ביצוע עבודה',
  SALES_SUMMARY: 'סיכום יעדי מכירות',
  SUCCESS_SUMMARY: 'סיכום פעילויות מוצלחות',
  ANALYTICS: 'ניתוחים',
  LAST_ORDER: 'הזמנה אחרונה',
  HEBREW: 'עברית',
  ENGLISH: 'אנגלית',
  LANGUAGE: 'שפה',
  LOGOUT_LOGIN_TO_APPLY_CHANGES: 'יש לבצע התנתקות מהמשתמש ואז כניסה מחדש כדי להחיל את שינויים',
  SCORE_BY_CATEGORY: 'ציון לפי קטגוריה',
  SALES: 'מכירות',
  VISITS: 'ביקורים',
  COLLECTION: 'גביה',
  SUCCESSFUL_VISITS: 'ביקורים מוצלחים',
  AGENT_SCORE_DEFINITION: 'הגדרת ציוני סוכן',
  COMPANY_TARGET: 'מטרת חברה',
  WEIGHT_PER_PARAMETER_IN_PERCENT: 'משקל כל פרמטר באחוזים',
  TOTAL: 'סה"כ',
  TOTAL_AGENT_SCORE_MUST_BE_100_PERC: 'סה"כ ציוני סוכנים חייב להיות שווה ל-100%',
  AGENT_SCORE_CURRENT_MONTH: 'ציון סוכן - חודש נוכחי',
  AGENT_SCORE_CURRENT_YEAR: 'ציון סוכן - שנה נוכחית',
  AGENT_KPIS_NOT_CONFIGURED: 'ציוני סוכן לא מוגדרים, אנא כנס למסך ההגדרות על מנת לראות מסך זה',
  AGENT_SCORE: 'ציון סוכן',
  COMPANY_SCORE: 'ציון חברה',
  ORDER_DATE: 'תאריך הזמנה',
  REFERENCE_NUM: 'אסמכתא',
  SUPPLY_DATE: 'תאריך אספקה',
  LINE_NUM: 'מספר שורה',
  ITEM_CODE: 'קוד פריט',
  ITEM_NAME: 'שם פריט',
  QUANTITY: 'כמות',
  ITEMS_PER_PACKAGE: 'כמות באריזה',
  AMOUNT_OF_PACKAGES: 'כמות אריזות',
  PRICE: 'מחיר',
  DISCOUNT: 'הנחה',
  BONUS: 'בונוס',
  COMMENT: 'הערה',
  VAT: 'מע"מ',
  TOTAL_INCLUDING_VAT: 'סה"כ כולל מע"מ',
  PRINT_BACK: 'הדפסה חוזרת',
  PRINT_BACK_SENT: 'הדפסה חוזרת נשלחה',
  OBJECTIVE_THIS_YEAR: 'יעד שנה נוכחית',
  OBJECTIVE_LAST_YEAR: 'יעד שנה שעברה',
  MONTH: 'חודש',
  MONTH_JAN: 'ינואר',
  MONTH_FEB: 'פברואר',
  MONTH_MAR: 'מרץ',
  MONTH_APR: 'אפריל',
  MONTH_MAY: 'מאי',
  MONTH_JUN: 'יוני',
  MONTH_JUL: 'יולי',
  MONTH_AUG: 'אוגוסט',
  MONTH_SEP: 'ספטמבר',
  MONTH_OCT: 'אוקטובר',
  MONTH_NOV: 'נובמבר',
  MONTH_DEC: 'דצמבר',
  NO_INFO_TO_DISPLAY: 'אין מידע להצגה',
  empty: '',

  time_breaks: 'זמני הפסקה',
  add_time_break: 'הוסף זמן הפסקה',
  edit_time_break: 'ערוך זמן הפסקה',
  break_hour_type: 'סוג שעת הפסקה',
  break_start_time: 'זמן התחלת הפסקה',
  break_end_time: 'זמן סיום הפסקה',
  date_range: 'טווח תאריכים',
  agentbreak: 'זמן הפסקה',
  description: 'תיאור',
  start_date: 'תאריך התחלה',
  end_date: 'תאריך סיום',
  all_ratings: 'כל הדירוגים',
  account_location: 'מיקום חשבון',
  last_visit_type: 'סוג ביקור אחרון',
  yes: 'כן',
  no: 'לא',
  plan_failures: 'כשלונות תכנון',
  occurrence_date: 'זמן תקרית',
  constraints: 'אילוצים',
  weekday: 'יום בשבוע',
  opening_hours: 'שעות פתיחה',
  hours: 'שעות',
  dates: 'תאריך',
  type: 'סוג',
  error: 'שגיאה',
  'entity conflict, the entity already exists':
    'התגלה קונפליקט: פריט עם אותם הפרטים המזהים כבר קיים',
  disabled: 'לא פעיל',
  no_team: 'ללא צוות',
  agent_teams: 'צוותי סוכנים',
  add_agent_team: 'הוסף צוות סוכנים',
  edit_agent_team: 'ערוך צוות סוכנים',
  'cannot complete this action due to a relation constraint':
    'לא ניתן להשלים את הבקשה כתוצאה מאילוץ קיים על הרשומה',
  sro: {
    back_to_agent_teams: '◄ חזרה לצוותי סוכנים',
    back_to_time_breaks: '◄ חזרה לזמני הפסקה',
    remove_agents_from_team_before_deleting: 'יש להסיר את כל הסוכנים מצוות זה לפני מחיקתו',
    password_rules: 'הסיסמה צריכה להיות באורך 7 תווים לפחות',
    daily_visits: 'כמות ביקורים יומי',
    max_daily_phone_calls: 'כמות שיחות טלפון יומי',
    any: 'הכל',
    phone_visit: 'שיחת טלפון',
    phone_visits: 'שיחות טלפון',
    client_visit: 'ביקור בבית לקוח',
    client_visits: 'ביקורים בבית לקוח',
    mode: 'תוכנית',
    sales_dashboard: 'דאשבורד מכירות',
    daily_goal: 'יעד יומי',
    monthly_goal: 'יעד חודשי',
    yearly_goal: 'יעד שנתי',
    daily_agent_sales: 'מכירות סוכנים יומי',
    monthly_agent_sales: 'מכירות סוכנים חודשי',
    yearly_agent_sales: 'מכירות סוכנים שנתי',
    sales_goal: 'יעד מכירות',
    sales_actual: 'מכירות בפועל',
    current_year: 'שנה נוכחית',
    last_year: 'שנה קודמת',
    location_tracking: 'מעקב מיקום',
    location_tracking_screen_url: 'קישור למסך מעקב מיקום',
  },
}
