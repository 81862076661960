import React from 'react'
import { translate } from 'react-admin'
import PropTypes from 'prop-types'

import icons from '../../icons'
import { generateDayTripLink } from '../../TripMapView'
import { fill, messages } from '../../../i18n/messages'
import SROTooltip from '../SROTooltip'

import './route-link.scss'

class BigRouteLink extends React.Component {
  render() {
    const calculatedLocLeft = 42 + (160 / 100) * this.props.visitProgress
    const mapLink = generateDayTripLink({
      agentId: this.props.agentId,
      date: this.props.date,
    })
    return (
      <div className="route-link big-route-link">
        <span className="bottom-line">
          <icons.kiteRight className="from edge-icon" />
          <SROTooltip text={messages.START_TIME} className="date-from">
            {this.props.dateStartTime ? this.props.dateStartTime.formatTime() : undefined}
          </SROTooltip>
          <SROTooltip
            text={fill(messages.PROGRESS, { percent: Math.round(this.props.visitProgress) })}
            className="location-icon-wrapper"
            style={{ left: calculatedLocLeft }}
          >
            <icons.framelessMapIcon />
          </SROTooltip>
          <div className="map-space">&nbsp;</div>
          <icons.flag className="to edge-icon" />
          <SROTooltip text={messages.END_TIME} className="date-to">
            {this.props.dateEndTime ? this.props.dateEndTime.formatTime() : undefined}
          </SROTooltip>
        </span>
        <SROTooltip text={this.props.translate('DATE_ROUTE')} className="map-icon-wrapper">
          <a
            href={mapLink}
            onClick={(e) => {
              if (window.event.ctrlKey) {
                return
              }

              e.preventDefault()
              this.props.onClick({ agentId: this.props.agentId, date: this.props.date })
            }}
          >
            <icons.mapIcon className="map-icon" />
          </a>
        </SROTooltip>
      </div>
    )
  }
}

BigRouteLink.propTypes = {
  agentId: PropTypes.number,
  date: PropTypes.string,
  dateStartTime: PropTypes.object,
  dateEndTime: PropTypes.object,
  visitProgress: PropTypes.number,
  onClick: PropTypes.func,
  translate: PropTypes.func,
}

export default translate(BigRouteLink)
