import _ from 'lodash'
import { CoordRange } from '../geoUtils'

export const coordPointToGoogleMapsCoordPair = (coordPoint) => ({
  lng: coordPoint.longitude,
  lat: coordPoint.latitude,
})

export const coordRangeToGoogleMapsLatLngBounds = (coordRange) => {
  const northWest = {
    lng: coordRange.longitudeMin,
    lat: coordRange.latitudeMax,
  }

  const southEast = {
    lng: coordRange.longitudeMax,
    lat: coordRange.latitudeMin,
  }

  return new window.google.maps.LatLngBounds(northWest, southEast)
}

export const GOOGLE_MAPS_ZOOM_MAX = 20
export const GOOGLE_MAPS_ZOOM_MIN = 0

export function isZoomValid(zoom) {
  return zoom >= GOOGLE_MAPS_ZOOM_MIN && zoom <= GOOGLE_MAPS_ZOOM_MAX
}

export const googleMapBoundsToCoordRange = (bounds) =>
  new CoordRange({
    latitudeMin: bounds.getSouthWest().lat(),
    latitudeMax: bounds.getNorthEast().lat(),
    longitudeMin: bounds.getSouthWest().lng(),
    longitudeMax: bounds.getNorthEast().lng(),
  })

export const isGoogleMapsLoaded = () => !!_.get(window, 'google.maps')
