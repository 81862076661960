import React from 'react'
import _ from 'lodash'

import './step-2-shadow.scss'

class TableLine extends React.Component {
  render() {
    return (
      <div className="table-line">
        <div className="small-input shadow-input"></div>
        <div className="small-input shadow-input"></div>
        <div className="small-input shadow-input"></div>
        <div className="small-input shadow-input"></div>
        <div className="medium-input shadow-input"></div>
        <div className="medium-input shadow-input"></div>
        <div className="medium-input shadow-input"></div>
        <div className="medium-input shadow-input"></div>
        <div className="large-input shadow-input"></div>
      </div>
    )
  }
}

export default class Step2Shadow extends React.Component {
  render() {
    return (
      <div className="step-2-shadow">
        <div className="step-title"></div>
        <div className="table-header"></div>
        {_.map(_.range(0, 5), (index) => (
          <TableLine key={index} />
        ))}
      </div>
    )
  }
}
