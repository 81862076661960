import React from 'react'
import _ from 'lodash'

import './shadow-table.scss'

const rows = 5
const cols = 7

const ShadowTable = () => {
  return (
    <div className="shadow-wrapper">
      <table className="shadow-table">
        <thead>
          <tr>
            {_.map(Array(cols), (_val, index) => (
              <th key={'header_' + index}>
                <div>&nbsp;</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {_.map(Array(rows), (_val, rowIndex) => (
            <tr className={'row_' + rowIndex} key={'row_' + rowIndex}>
              {_.map(Array(cols), (_val, colIndex) => (
                <td key={'row_' + rowIndex + '_col_' + colIndex}>
                  <div>&nbsp;</div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ShadowTable
