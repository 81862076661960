import { put, takeEvery } from 'redux-saga/effects'
import {
  AnalyticsDataReceivedAction,
  AnalyticsDataRequestedAction,
} from '../actions/analyticsAction'
import Requester from '../providers/request'
import { AnalyticsResource } from '../providers/resources/comboResources'

function* callRequestData(action) {
  const { data } = yield new Requester().getRaw(AnalyticsResource, action.params)

  if (!data) {
    return
  }

  yield put(AnalyticsDataReceivedAction.make(data))
}

export default function* analyticsSaga() {
  yield takeEvery(AnalyticsDataRequestedAction.NAME, callRequestData)
}
