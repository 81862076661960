import _ from 'lodash'

import { ActivityCalendarResource } from '../../providers/resources/requestResources'
import { Route } from '../../utils/geoUtils'
import { SROMoment } from '../../utils/timeUtils'
import { toPrecision } from '../../utils/numberUtils'
import { messages } from '../../i18n/messages'

class CalendarTransformer {
  constructor() {
    this.agents = {}
  }

  loadData(data) {
    _.forEach(data, (travel) => {
      this.addTravel(travel)
    })
  }

  export() {
    const travels = _(this.agents)
      .values()
      .map((agentTravels) => agentTravels.export())
      .flatMap()
      .value()
    return travels
  }

  addTravel(travelData) {
    if (!travelData.agent || !travelData.agentId) {
      console.error('Dropping bad entry. Travel data :', travelData)
      return
    }

    const fixedData = {
      ...travelData,
      date: SROMoment.fromServerString(travelData.date),
      arrivalTime: SROMoment.fromServerString(travelData.arrivalTime),
      isPlanned: !!travelData.isPlanned,
    }
    const { agentId } = fixedData
    this.agents[agentId] = this.agents[agentId] || new AgentTravels(agentId)
    this.agents[agentId].addTravel(fixedData)
  }
}

class AgentTravels {
  constructor(agentId) {
    this.agentId = agentId
    this.visits = 0
    this.totalKM = 0
    this.dates = {}
    this.counts = {
      true: {
        true: 0,
        false: 0,
      },
      false: {
        true: 0,
        false: 0,
      },
    }
  }

  getKey() {
    return `agent_${this.agentId}`
  }

  /**
   * @param {object} travelData
   * @returns {AgentDateTravel}
   */
  getOrCreateAgentDate(travelData) {
    const dateStr = travelData.formatDate()
    this.dates[dateStr] = this.dates[dateStr] || new AgentDateTravel(this, travelData)
    return this.dates[dateStr]
  }

  addTravel(travelData) {
    const agentDateTravel = this.getOrCreateAgentDate(travelData.date)

    this.agentId = travelData.agentId
    this.externalAgentId = travelData.agent.externalId
    this.agentName = travelData.agent.name
    this.visits++
    this.totalKM += travelData.metersToNextStop / 1000

    agentDateTravel.addTravel(travelData)

    if (travelData.activityId) {
      this.counts[travelData.isPlanned][travelData.isPhone]++
    }
  }

  exportSubs() {
    return _(this.dates)
      .keys()
      .sort()
      .map((key) => this.dates[key].export())
      .flatMap()
      .value()
  }

  export() {
    return {
      id: this.getKey(),
      agentId: this.agentId,
      agentName: this.agentName,
      externalAgentId: this.externalAgentId,
      dependencies: [],
      record_type: ActivityCalendarResource.CALENDAR_RECORD_TYPES.RECORD_TYPE_AGENT_GROUP,
      counts: this.counts,
      avgKMPerVisit: toPrecision(this.totalKM / this.visits, 1),
      subs: this.exportSubs(),
    }
  }
}

class AgentDateTravel {
  constructor(agent, travelDate) {
    this.agent = agent
    this.travelDate = travelDate
    this.travels = []
    this.totalDayMeters = 0
    this.counts = {
      true: {
        true: 0,
        false: 0,
      },
      false: {
        true: 0,
        false: 0,
      },
    }
    this.route = new Route()
  }

  getKey() {
    return `${this.agent.getKey()}_date_${this.travelDate.formatDate()}`
  }

  getDependencyKeys() {
    return [this.agent.getKey()]
  }

  addRouteStop(travel) {
    this.route.addStop({ latitude: travel.latitude, longitude: travel.longitude })
  }

  addTravel(travelData) {
    this.date = travelData.date
    this.travels.push(new Travel(travelData, this.agent, this))
    this.addRouteStop(travelData)
    this.totalDayMeters += travelData.metersToNextStop || 0

    if (travelData.activityId) {
      this.counts[travelData.isPlanned][travelData.isPhone]++
    }
  }

  export() {
    const arrivalTimes = _(this.travels)
      .filter((travel) => travel.data.activityId)
      .map((travel) => travel.data.arrivalTime)
    const dateStartTime = arrivalTimes.minBy('moment')
    const dateEndTime = arrivalTimes.maxBy('moment')

    return {
      id: this.getKey(),
      date: this.date,
      counts: this.counts,
      dependencies: this.getDependencyKeys(),
      totalDayKM: Math.round(this.totalDayMeters / 1000),
      record_type: ActivityCalendarResource.CALENDAR_RECORD_TYPES.RECORD_TYPE_AGENT_DATE_GROUP,
      mapLink: this.route.getGoogleMapsLink(),
      subs: this.exportSubs(),
      dateStartTime,
      dateEndTime,
    }
  }

  exportSubs() {
    return _.map(this.travels, (travel) => travel.export())
  }
}

class Travel {
  constructor(data, agent, travelDate) {
    this.data = { ...data }
    this.agent = agent
    this.travelDate = travelDate

    this.translateVisitDays = this.translateVisitDays.bind(this)
  }

  getDependencyKeys() {
    return [this.agent.getKey(), this.travelDate.getKey()]
  }

  translateVisitDays() {
    if (!this.data.client) {
      return ''
    }

    if (
      !Array.isArray(this.data.client.openingHours) ||
      this.data.client.openingHours.length === 0
    ) {
      return messages.ALL_WEEK
    }

    return _(this.data.client.openingHours)
      .map(({ start, end }) => `${start}-${end}`)
      .join(', ')
  }

  export() {
    return {
      ...this.data,
      clientName: this.data.client ? this.data.client.name : '',
      address: this.data.client ? this.data.client.address : this.data.address,
      dependencies: this.getDependencyKeys(),
      visitDays: this.translateVisitDays(),
      record_type: ActivityCalendarResource.CALENDAR_RECORD_TYPES.RECORD_TYPE_TRAVEL,
    }
  }
}

export default CalendarTransformer
