export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'development'
export const API_URL_BASE =
  process.env.REACT_APP_API_URL_BASE || 'http://localhost:8090/api/v0/sales'
export const MAPBOX_API_KEY =
  'pk.eyJ1IjoiYW1vZGF0LWxvY2F0aW9uIiwiYSI6ImNrMTk4dWw0ODAwMGEzZ3BhaDQzNDRqd2EifQ.-re5oJu3PZxwqFRNGkOIFA'
export const MOBILE_WIDTH_PX = 700
export const SENTRY_DSN =
  process.env.REACT_APP_SENTRY_DSN || 'https://85a96df132024cd9a91e8dae23ac6d12@sentry.io/1805374'
export const SENTRY_ENABLED = process.env.REACT_APP_SENTRY_ENABLED === '1'
export const VERSION = process.env.REACT_APP_VERSION || 'undefined'
export const HEAP_ID = process.env.REACT_APP_HEAP_ID
