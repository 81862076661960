import React from 'react'
import ReactDOM from 'react-dom'
import ReactHeap from 'reactjs-heap'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import { SENTRY_ENABLED, SENTRY_DSN, ENVIRONMENT, VERSION, HEAP_ID } from './Config'
import App from './App'

if (SENTRY_ENABLED) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    release: `sro-client@${VERSION}`,
    integrations: [
      new Integrations.CaptureConsole({
        levels: ['error', 'warn'],
      }),
    ],
    normalizeDepth: 10,
    attachStacktrace: true,
  })
}

if (HEAP_ID) {
  ReactHeap.initialize(HEAP_ID)
}

ReactDOM.render(<App />, document.getElementById('root'))
