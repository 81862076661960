import _ from 'lodash'

import { CoordPoint, getPointRange } from '../../utils/geoUtils'
import { DEFAULT_RANGE, LOCATION_POINT } from './sroMapDefs'

const ZOOM_CHANGE_EVENT = 'zoomChange'
const CENTER_CHANGE_EVENT = 'centerChange'

export default class MapModel {
  constructor({ points, mapMaxWidthPercent, mapMaxHeightPercent, zoom, mapCoordsRange }) {
    this.subscriptions = {}
    this.points = points
    this.mapMaxWidthPercent = mapMaxWidthPercent
    this.mapMaxHeightPercent = mapMaxHeightPercent
    this.zoom = zoom
    this.mapCoordsRange = mapCoordsRange
  }

  updateBounds(bounds) {
    this.bounds = bounds
  }

  areThereAnyPoints() {
    return _(this.points).values().flatten().value().length > 0
  }

  getPointRange() {
    if (!this.areThereAnyPoints()) {
      return DEFAULT_RANGE
    }

    return getPointRange(this.points)
  }

  hasPoints() {
    return (
      _(this.points)
        .map((category) => category.length)
        .sum() > 0
    )
  }

  getCurrentAgentLocation() {
    return _.get(this.points, [LOCATION_POINT, 0, 'coordPoint'])
  }

  getRequiredCenter(isInitial = true) {
    const currentAgentLocation = this.getCurrentAgentLocation()

    if (!isInitial && currentAgentLocation) {
      return currentAgentLocation
    }

    function calculateCentersOffset(totalLength, windowPercent) {
      const linesPerPercent = totalLength / 100
      const linePercentDiff = (100 - windowPercent) / 2
      return linePercentDiff * linesPerPercent
    }

    const point = this.getPointRange().getCenter()

    const center = new CoordPoint({
      longitude:
        point.longitude -
        calculateCentersOffset(this.mapCoordsRange.getWidth(), this.mapMaxWidthPercent),
      latitude:
        point.latitude +
        calculateCentersOffset(this.mapCoordsRange.getHeight(), this.mapMaxHeightPercent),
    })

    return center
  }

  arePointsDisplayed(visibleWindowRange) {
    const pointsRange = this.getRequiredRange(this.getPointRange())
    return visibleWindowRange.isContaining(pointsRange)
  }

  getRequiredRange(pointRange) {
    return pointRange.multiply({
      longitudeMax: 1,
      longitudeMin: 100 / this.mapMaxWidthPercent,
      latitudeMax: 1,
      latitudeMin: 100 / this.mapMaxHeightPercent,
    })
  }

  subscribeEvent(eventName, callback) {
    if (!this.subscriptions[eventName]) {
      this.subscriptions[eventName] = []
    }

    this.subscriptions[eventName].push(callback)
  }

  onZoomChange(callback) {
    this.subscribeEvent(ZOOM_CHANGE_EVENT, callback)
  }

  onCenterChange(callback) {
    this.subscribeEvent(CENTER_CHANGE_EVENT, callback)
  }

  changeZoom(delta) {
    this.setZoom((this.zoom += delta))
  }

  setZoom(zoom) {
    this.zoom = zoom
    this.invokeCallbacks(ZOOM_CHANGE_EVENT, [zoom])
  }

  centralize(isInitial) {
    this.center = this.getRequiredCenter(isInitial)
    this.invokeCallbacks(CENTER_CHANGE_EVENT, [this.center])
  }

  invokeCallbacks(eventName, params) {
    const callbacks = _.get(this.subscriptions, eventName, [])

    callbacks.forEach((callback) => callback(...params))
  }
}
