import React, { forwardRef, cloneElement, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

const NavLinkRef = forwardRef((props, ref) => {
  const { isExternalLink, ...restPropsNavLink } = props
  // eslint-disable-next-line
  const { activeClassName, ...restPropsExternalLink } = restPropsNavLink

  if (isExternalLink) {
    return <a href={props.to} target="_blank" rel="noreferrer" {...restPropsExternalLink} />
  }

  return <NavLink innerRef={ref} {...restPropsNavLink} />
})

NavLinkRef.displayName = 'NavLinkRef'

NavLinkRef.propTypes = {
  activeClassName: PropTypes.string,
  to: PropTypes.string,
  isExternalLink: PropTypes.bool,
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      color: theme.palette.text.secondary,
    },
    active: {
      color: theme.palette.text.primary,
    },
    icon: { minWidth: theme.spacing(5) },
  }),
  { name: 'RaMenuItemLink' },
)

const MenuItemLink = forwardRef((props, ref) => {
  const { className, primaryText, leftIcon, onClick, sidebarIsOpen, ...rest } = props
  const classes = useStyles(props)

  const handleMenuTap = useCallback(
    (e) => {
      onClick && onClick(e)
    },
    [onClick],
  )

  const renderMenuItem = () => {
    return (
      <MenuItem
        className={classnames(classes.root, className)}
        activeClassName={classes.active}
        component={NavLinkRef}
        ref={ref}
        {...rest}
        onClick={handleMenuTap}
      >
        {leftIcon && (
          <ListItemIcon className={classes.icon}>
            {cloneElement(leftIcon, {
              titleAccess: primaryText,
            })}
          </ListItemIcon>
        )}
        {primaryText}
      </MenuItem>
    )
  }

  if (sidebarIsOpen) {
    return renderMenuItem()
  }

  return (
    <Tooltip title={primaryText} placement="right">
      {renderMenuItem()}
    </Tooltip>
  )
})

MenuItemLink.displayName = 'MenuItemLink'

MenuItemLink.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  leftIcon: PropTypes.element,
  onClick: PropTypes.func,
  primaryText: PropTypes.node,
  staticContext: PropTypes.object,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  sidebarIsOpen: PropTypes.bool,
  isExternalLink: PropTypes.bool,
}

MenuItemLink.defaultProps = {
  isExternalLink: false,
}

export default MenuItemLink
