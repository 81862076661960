import _ from 'lodash'
import moment from 'moment'

import { SROMoment, getMonthNameByNum } from '../../utils/timeUtils'
import { MAPBOX_API_KEY } from '../../Config'
import DailyActivityTransformer from '../transformers/dailyActivityTransformer'
import CalendarTransformer from '../transformers/calendarTransformer'
import BaseResource from './baseResource'
import { CoordPoint } from '../../utils/geoUtils'
import { messages } from '../../i18n/messages'

class RequestResource extends BaseResource {}

export class ActivityCalendarResource extends RequestResource {
  static RESOURCE_NAME = 'activity-calendar'

  static CALENDAR_RECORD_TYPES = {
    RECORD_TYPE_AGENT_GROUP: 'AGENT_GROUP',
    RECORD_TYPE_AGENT_DATE_GROUP: 'AGENT_DATE_GROUP',
    RECORD_TYPE_TRAVEL: 'TRAVEL',
  }

  getResourcePath() {
    return 'plan/agentSchedule'
  }

  transformGetRawResponse(data) {
    const transformer = new CalendarTransformer()
    transformer.loadData(data.data.records)
    return transformer.export()
  }
}

export class DailyActivityResource extends RequestResource {
  static RESOURCE_NAME = 'daily-activity'

  getResourcePath() {
    return 'plan/agentActivity'
  }

  transformGetListResponse(data) {
    const transformer = new DailyActivityTransformer()
    transformer.loadData(data.data.records)
    return transformer.export()
  }

  getListRequest(params) {
    const query = {
      from: params.filter.date,
      to: params.filter.date,
      agentId:
        params.filter.agentId === AgentResource.ALL_AGENTS_ID ? undefined : params.filter.agentId,
    }

    return {
      url: this.buildUrl(query),
    }
  }
}

export class MapDetailsResource extends RequestResource {
  static RESOURCE_NAME = 'map-details'
  getResourcePath() {
    return 'plan/map/details'
  }

  getActivityCoords(activity) {
    const longitude = _.get(activity, 'longitude') || _.get(activity, 'client.longitude')
    const latitude = _.get(activity, 'latitude') || _.get(activity, 'client.latitude')

    if (!longitude || !latitude) {
      return null
    }

    return new CoordPoint({ longitude, latitude })
  }

  calculateDuration(activity) {
    const startTime = SROMoment.fromServerString(activity.startTime)
    const endTime = SROMoment.fromServerString(activity.endTime)

    if (!activity.isClosed || !startTime || !endTime) {
      return activity.visitTimeMinutes
    }

    return Math.ceil(moment.duration(endTime.diff(startTime.moment)).asMinutes())
  }

  getRoutes(routes, agentLocations) {
    const closedRoutes = _(routes)
      .filter((route) => !route.isClosed)
      .map((route) => ({
        isClosed: false,
        coords: route.coords.map((coord) => new CoordPoint(coord)),
      }))
      .value()

    const openRoutes = {
      isClosed: true,
      coords: _.map(agentLocations, (location) => new CoordPoint(location)),
    }

    return _.concat(closedRoutes, openRoutes)
  }

  transformGetRawResponse(result) {
    const { data } = result

    const { agent, agentLastLocation, nearbyClients, activities, routes, agentLocations } = data
    const agentLastLocationDate = _.get(agentLastLocation, 'datetime')
    let agentFirstLocation = null
    if (agentLocations[0]) {
      agentFirstLocation = {
        startTime: SROMoment.fromServerString(agentLocations[0].datetime),
        coordPoint: new CoordPoint(agentLocations[0]),
      }
    }

    return {
      agent,
      agentLastLocation: {
        coordPoint: new CoordPoint({
          longitude: _.get(agentLastLocation, 'longitude'),
          latitude: _.get(agentLastLocation, 'latitude'),
        }),
        date: agentLastLocationDate ? SROMoment.fromServerString(agentLastLocationDate) : undefined,
      },
      nearbyClients: _.map(nearbyClients, (client) => ({
        name: client.name,
        clientId: client.id,
        rateName: _.get(client, 'rate.name'),
        coordPoint: new CoordPoint({
          longitude: client.longitude,
          latitude: client.latitude,
        }),
        lastVisit: SROMoment.fromServerString(client.lastVisitDate),
        plannedVisit: SROMoment.fromServerString(client.plannedVisitDate),
        nextVisitDate: SROMoment.fromServerString(client.nextVisitDate),
        nearbyLabels: client.attributes.nearbyLabels,
        nearbyMarkerColor: client.attributes.nearbyMarkerColor,
      })),
      activities: _.map(activities, (activity) => ({
        id: activity.id,
        clientId: activity.clientId,
        clientName: _.get(activity, 'client.name'),
        clientCity: _.get(activity, 'client.cityName'),
        plannedArrivalTime: SROMoment.fromServerString(activity.arrivalTime),
        arrivalTime: SROMoment.fromServerString(activity.startTime),
        visitTimeMinutes: this.calculateDuration(activity),
        coords: this.getActivityCoords(activity),
        isClosed: activity.isClosed,
        isCanceled: activity.isCanceled,
        isEdge: !activity.activityId,
        visitType: activity.visitType,
        clientRateId: _.get(activity, 'client.rateId'),
        clientLastVisit: SROMoment.fromServerString(_.get(activity, 'client.lastVisitDate')),
        rateName: activity.rateName,
        isPlanned: activity.isPlanned,
        isPhone: activity.isPhone,
      })),
      routes: this.getRoutes(routes, agentLocations),
      agentFirstLocation,
    }
  }
}

export class ActivityResource extends RequestResource {
  static RESOURCE_NAME = 'activity'

  getResourcePath() {
    return 'plan/activity'
  }
}

export class AgentResource extends RequestResource {
  static RESOURCE_NAME = 'agent'

  static DEFAULT_START_PLANNING_NUM_DAY_FROM_TODAY = 7
  static ALL_AGENTS_ID = -1

  STRINGIFIED_FIELDS = [
    'attributes.workingHours',
    'attributes.startLocation',
    'attributes.endLocation',
  ]

  STRINGIFIED_SUFFIX = 'Stringified'

  static getQueryAgentId(agentId) {
    if (agentId === AgentResource.ALL_AGENTS_ID) {
      return undefined
    }

    return agentId
  }

  parseStringified(origParams) {
    const params = _.cloneDeep(origParams)

    _.forEach(this.STRINGIFIED_FIELDS, (field) => {
      const stringifiedField = `${field}${this.STRINGIFIED_SUFFIX}`
      const stringifiedValue = _.get(params.data, stringifiedField)

      _.set(params.data, stringifiedField, undefined)

      if (!stringifiedValue) {
        return
      }

      _.set(params.data, field, stringifiedValue ? JSON.parse(stringifiedValue) : undefined)
    })

    return params
  }

  stringify(origParams) {
    const params = _.cloneDeep(origParams)
    _.forEach(this.STRINGIFIED_FIELDS, (field) => {
      const stringifiedField = `${field}${this.STRINGIFIED_SUFFIX}`

      const value = _.get(params.data, field)
      _.set(params.data, stringifiedField, JSON.stringify(value))
    })

    return params
  }

  getUpdateRequest(params) {
    return super.getUpdateRequest(this.parseStringified(params))
  }

  transformGetOneResponse(data) {
    return this.stringify(data).data
  }
}

export class AgentStatResource extends RequestResource {
  static RESOURCE_NAME = 'agent-stats'

  getResourcePath() {
    return 'agent/stats'
  }
}

export class AccountStatResource extends RequestResource {
  static RESOURCE_NAME = 'account-stats'

  getResourcePath() {
    return 'account/stats'
  }
}

export class AgentPlusAllResource extends RequestResource {
  static RESOURCE_NAME = 'agent-plus-all'

  getResourcePath() {
    return 'agent'
  }

  transformGetListResponse(data) {
    return _.concat([{ id: 0, name: messages.ALL_AGENTS }], data.data.records)
  }
}

export class ClientResource extends RequestResource {
  static RESOURCE_NAME = 'clients-resource'

  getResourcePath() {
    return 'client'
  }

  transformGetListResponse(data) {
    return data.data.records.map((client) => {
      client.usedSlashCredit = `${client.usedCredit} / ${client.credit}`
      return client
    })
  }
}

export class ClientExternalIDResource extends ClientResource {
  static RESOURCE_NAME = 'client-externalId'

  getListRequest(params) {
    const query = {
      _q_externalId: params.search || undefined,
      _attributes: ['externalId'],
    }

    return {
      url: this.buildUrl(query),
    }
  }

  transformGetListResponse(data) {
    const records = _.get(data, 'data.records')
    return _.map(records, (item) => ({ id: item.externalId, name: item.externalId }))
  }
}

export class ClientByAgentResource extends RequestResource {
  static RESOURCE_NAME = 'client-by-agent'

  getResourcePath() {
    return 'client'
  }

  getListRequest(params) {
    const query = {
      agentId: 0,
      ...params.filter,
    }

    return {
      url: this.buildUrl(query),
    }
  }

  transformGetListResponse(data) {
    return _.map(data.data.records, (item) => ({
      id: item.id,
      name: `${item.name} (${item.externalId})`,
      externalId: item.externalId,
    }))
  }
}

export class UserResource extends RequestResource {
  static RESOURCE_NAME = 'user'

  getEnrichedRecord(item) {
    return {
      ...item,
      roleList: _(item.roles)
        .map((role) => role.name)
        .join(', '),
    }
  }

  transformGetListResponse(data) {
    return _.map(data.data.records, this.getEnrichedRecord)
  }

  transformGetOneResponse(data) {
    return this.getEnrichedRecord(data.data)
  }
}

export class AccountResource extends RequestResource {
  static RESOURCE_NAME = 'account'
}

export class AccountRatingsResource extends RequestResource {
  static RESOURCE_NAME = 'account-ratings'

  getResourcePath() {
    return 'account'
  }

  transformItem(item) {
    return _.keyBy(item.clientRating, 'id')
  }

  transformGetOneResponse(responseData) {
    return this.transformItem(responseData.data)
  }

  transformGetListResponse(responseData) {
    const records = _.get(responseData, 'data.records')

    return _(records).keyBy('id').mapValues(this.transformItem).value()
  }
}

export class AccountOptionsResource extends RequestResource {
  static RESOURCE_NAME = 'account-options'

  getResourcePath(params) {
    return `account/${params.id}/clientRating/formulaOptions`
  }
}

export class ReportActivityResource extends RequestResource {
  static RESOURCE_NAME = 'report-activity'

  getResourcePath() {
    return 'report/activity'
  }

  getListRequest(params) {
    const query = {
      dimensions: params.dimensions,
      from: params.from,
      to: params.to,
    }

    return {
      url: this.buildUrl(query),
    }
  }

  transformGetListResponse(data) {
    return _.map(data.data.records, (record) => ({
      accountId: record.accountId,
      agentId: record.agentId,
      agentName: record.agentName,
      workSummary: (100 * record.onSiteActivities) / record.plannedActivities || 0,
      salesSummary: (100 * record.orderActual) / record.salesTarget || 0,
      successSummary: (100 * record.successfulOnSiteActivities) / record.plannedActivities || 0,
      visitProgress:
        (100 * (record.closedActivities + record.canceledActivities)) / record.activities || 0,
      canceledActivities: record.canceledActivities,
      newActivities: record.newActivities,
      salesActual: record.salesActual,
      salesTarget: record.salesTarget,
      collectionActual: record.collectionActual,
      collectionTarget: record.collectionTarget,
      plannedOnSiteActivities: record.plannedOnSiteActivities,
      successfulOnSiteActivities: record.successfulOnSiteActivities,
      plannedActivities: record.plannedActivities,
    }))
  }
}

export class SalesSummary extends RequestResource {
  static RESOURCE_NAME = 'sales-summary'
}

export class SettingsResource extends RequestResource {
  static RESOURCE_NAME = 'settings'
}

export class ClientRatingSimulatorResource extends RequestResource {
  static RESOURCE_NAME = 'client-rating-simulator'

  getResourcePath(params) {
    return `account/${params.accountId}/clientRating/simulate`
  }
}

export class AccountAgentsReplanResource extends RequestResource {
  static RESOURCE_NAME = 'account-agents-replan'

  getResourcePath(params) {
    return `plan/agentSchedule/account/${params.accountId}`
  }
}

export class AccountValidationResource extends RequestResource {
  static RESOURCE_NAME = 'account-validation'

  getResourcePath(params) {
    return `account/${params.accountId}/validate`
  }
}

export class MapBoxDrivingDirectionsResource extends RequestResource {
  static RESOURCE_NAME = 'mapbox-driving-directions'

  getRawRequest(query) {
    const encodedWaypoints = _(query.waypoints)
      .map((waypoint) => `${waypoint[0]},${waypoint[1]}`)
      .join(';')
    return {
      url: `https://api.mapbox.com/directions/v5/mapbox/driving/${encodedWaypoints}.json?access_token=${MAPBOX_API_KEY}&geometries=geojson`,
    }
  }

  transformGetRawResponse(data) {
    // This overrides the default data.data behavior
    return data
  }
}

export class AgentTeamResource extends RequestResource {
  static RESOURCE_NAME = 'agentTeam'

  transformDeleteError(err) {
    if (err.info && err.info.code === 'ForeignKeyConstraintError') {
      err.message = 'sro.remove_agents_from_team_before_deleting'
    }

    return err
  }
}

export class TripMapResource extends RequestResource {
  static RESOURCE_NAME = 'trip-map'
  static SHOW_SIDEBAR = false
}

export class RawReportActivitiesResource extends RequestResource {
  static RESOURCE_NAME = 'raw-activities'

  getResourcePath() {
    return 'report/activity'
  }

  getRawRequest(params) {
    const agentId = AgentResource.getQueryAgentId(params.agentId)
    const queryParams = {
      ...params,
      agentId,
    }
    if (agentId) {
      queryParams.dimensions = ['agentId']
    }

    return { url: this.buildUrl(queryParams) }
  }
}

export class SalesSummaryResource extends RequestResource {
  static RESOURCE_NAME = 'sales-summary'

  getResourcePath() {
    return 'report/salesSummary'
  }

  getRawRequest(params) {
    const queryParams = {
      dimensions: ['rateId'],
      agentId: AgentResource.getQueryAgentId(params.agentId),
      to: params.to,
    }
    return { url: this.buildUrl(queryParams) }
  }
}

export class VisitsByRatingResource extends RequestResource {
  static RESOURCE_NAME = 'visits-by-rating'

  getResourcePath() {
    return 'report/activity'
  }

  getRawRequest(params) {
    const queryParams = {
      dimensions: ['rateName'],
      from: params.from,
      to: params.to,
      agentId: AgentResource.getQueryAgentId(params.agentId),
    }
    return { url: this.buildUrl(queryParams) }
  }
}

export class AnnualActivityResource extends RequestResource {
  static RESOURCE_NAME = 'annual-activity'

  getResourcePath() {
    return 'report/activity'
  }

  getRawRequest(params) {
    const queryParams = {
      dimensions: ['year', 'month'],
      from: params.from,
      to: params.to,
      agentId: AgentResource.getQueryAgentId(params.agentId),
    }

    return { url: this.buildUrl(queryParams) }
  }

  transformGetRawResponse(response) {
    const records = _.get(response, 'data.records', [])

    const graphData = _(records)
      .map((record) => ({
        name: getMonthNameByNum(record.month),
        plannedActivities: record.plannedActivities,
        plannedOnSiteActivities: record.plannedOnSiteActivities,
        successfulOnSiteActivities: record.successfulOnSiteActivities,
        salesActual: record.salesActual,
        salesTarget: record.salesTarget,
        percent: {
          plannedActivities:
            Math.round((100 * record.plannedActivities) / record.plannedActivities) || 0,
          plannedOnSiteActivities:
            Math.round((100 * record.plannedOnSiteActivities) / record.plannedActivities) || 0,
          successfulOnSiteActivities:
            Math.round((100 * record.successfulOnSiteActivities) / record.plannedActivities) || 0,
          salesActual: Math.round((100 * record.salesActual) / record.salesTarget),
          salesTarget: Math.round((100 * record.salesTarget) / record.salesTarget),
        },
      }))
      .value()

    const percentValues = _(graphData)
      .keyBy('name')
      .mapValues((item) => item.percent)
      .value()

    return {
      graphData,
      percentValues,
    }
  }
}

export class PasswordResetEmailResource extends RequestResource {
  static RESOURCE_NAME = 'password-reset-email'

  getResourcePath() {
    return 'user/password/forgot'
  }
}

export class VerifyEmailPasswordResetResource extends RequestResource {
  static RESOURCE_NAME = 'verify'
}

export class ResetPasswordResource extends RequestResource {
  static RESOURCE_NAME = 'reset-password'

  getResourcePath() {
    return 'user/password'
  }
}

export class ReportAgentScoreResource extends RequestResource {
  static RESOURCE_NAME = 'report-agent-score'

  getResourcePath() {
    return 'report/agent/score'
  }

  transformGetRawResponse(response) {
    return response.data
  }
}

export class AgentBreakResource extends RequestResource {
  static RESOURCE_NAME = 'agent-breaks'

  getResourcePath() {
    return 'plan/agentBreak'
  }
}

export class ClientRatingResource extends RequestResource {
  static RESOURCE_NAME = 'client-ratings'

  getResourcePath() {
    return 'account/clientRating'
  }
}

export class PlanFailureResource extends RequestResource {
  static RESOURCE_NAME = 'plan-failures'

  getResourcePath() {
    return 'plan/failure'
  }

  transformRecord(record) {
    const constraintsByIdx = _.groupBy(record.constraints, 'locationIdx')
    return {
      ...record,
      locations: record.locations.map((location, i) => {
        location.constraints = constraintsByIdx[i] || []
        return location
      }),
      clientIds: record.locations.map((location) => location.id),
      dates: record.dates.map((date) => ({ value: date })),
    }
  }

  transformGetOneResponse(data) {
    return this.transformRecord(data.data)
  }

  transformGetListResponse(data) {
    return data.data.records.map(this.transformRecord)
  }
}

export const REQUEST_RESOURCE_CLASSES = [
  ActivityCalendarResource,
  DailyActivityResource,
  ActivityResource,
  AgentResource,
  AgentPlusAllResource,
  ClientResource,
  ClientByAgentResource,
  UserResource,
  AccountResource,
  AgentStatResource,
  AccountStatResource,
  AccountOptionsResource,
  SalesSummary,
  ReportActivityResource,
  SettingsResource,
  ClientRatingSimulatorResource,
  AccountAgentsReplanResource,
  AccountValidationResource,
  MapBoxDrivingDirectionsResource,
  TripMapResource,
  AccountRatingsResource,
  ClientExternalIDResource,
  RawReportActivitiesResource,
  SalesSummaryResource,
  VisitsByRatingResource,
  AnnualActivityResource,
  MapDetailsResource,
  AgentTeamResource,
  PasswordResetEmailResource,
  VerifyEmailPasswordResetResource,
  ResetPasswordResource,
  ReportAgentScoreResource,
  AgentBreakResource,
  ClientRatingResource,
  PlanFailureResource,
]
