import React from 'react'

import PropTypes from 'prop-types'
import _ from 'lodash'
import { CreateButton } from 'react-admin'

import Icon from '../../../icons'

import './sro-list-actions.scss'

const SROListActions = (props) => (
  <CreateButton
    className="sro-add-item-button"
    label={props.createLabel}
    icon={<Icon.circledPlus />}
    basePath={_.get(props, 'basePath')}
  />
)

SROListActions.propTypes = {
  createLabel: PropTypes.string,
}

export default SROListActions
