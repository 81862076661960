import React from 'react'

function makeComponent(content) {
  const generateComponent = () => content
  return generateComponent
}

export default {
  AccountsIcon: makeComponent(
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="19" viewBox="0 0 23 19">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M21 17.6H1.4V9.915h5.756c.611 1.76 2.095 2.837 4.044 2.837 1.95 0 3.455-1.077 4.066-2.837H21V17.6zM3.586 1.475H18.6l2.037 7.04h-6.526c-.032 1.68-1.223 2.837-2.91 2.837-1.689 0-2.858-1.157-2.89-2.837H1.622l1.964-7.04zm18.802 8.322a.696.696 0 0 0-.01-.304L19.808.588a.7.7 0 0 0-.675-.513H3.047a.7.7 0 0 0-.677.52L.02 9.045a.686.686 0 0 0 .048.462c-.04.13-.068.265-.068.408V17.6A1.4 1.4 0 0 0 1.4 19H21a1.4 1.4 0 0 0 1.4-1.4V9.915c0-.04-.009-.078-.012-.118z"
      />
    </svg>,
  ),
  ActivityCalendarIcon: makeComponent(
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M19.646 20H2.353C1.034 20 0 18.776 0 17.213V4.577c0-1.562.593-2.674 1.913-2.674h5.74V.95c0-.513.312-.95.832-.95.522 0 .943.416.943.929l.137.974h2.87l.137-.974c0-.513.421-.929.942-.929.521 0 .943.416.943.929l-.11.974h5.74c1.32 0 1.913 1.112 1.913 2.674v12.636C22 18.776 20.966 20 19.646 20zm-.107-16h-5.11v.375c0 .51-.536.731-1.048.731-.514 0-.807-.222-.807-.731V4H9.479v.375c0 .51-.295.731-.807.731-.513 0-1.05-.222-1.05-.731V4l-5.542.16c-.162 0-.027.19-.027.763L2.08 7h17.893L20 4.923C20 4.35 19.701 4 19.539 4zm.409 13.162L19.92 9H2.08l-.028 8.162c0 .545-.134.838.028.838h17.84c.162 0 .028-.293.028-.838zm-11.93-2.168H5.014v-3.017c0-.361-.106-.977.258-.977h3.07c.364 0 .658.292.658.654v3.05c0 .36-.619.29-.982.29zm-2.857.135c-.363 0 0-.29 0 0z"
      />
    </svg>,
  ),
  AgentsIcon: makeComponent(
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20">
      <g fill="#FFF" fillRule="evenodd">
        <path d="M10.36 12.513c2.755 0 5-2.76 5-6.154 0-3.395-2.245-6.154-5-6.154s-5 2.76-5 6.154c0 3.395 2.25 6.154 5 6.154zm0-10.77c1.935 0 3.5 2.052 3.5 4.616 0 2.564-1.565 4.615-3.5 4.615s-3.5-2.05-3.5-4.615c0-2.564 1.565-4.615 3.5-4.615z" />
        <path d="M14.935 12a.759.759 0 0 0-.825.692.768.768 0 0 0 .675.846c2.326.28 4.128 2.22 4.29 4.616H1.645c.177-2.39 1.971-4.32 4.29-4.616a.768.768 0 0 0 .675-.846.759.759 0 0 0-.825-.692C5.73 12 .11 12.656.11 18.918c0 .425.336.77.75.77h19a.76.76 0 0 0 .75-.77c0-6.262-5.62-6.918-5.675-6.918z" />
      </g>
    </svg>,
  ),
  AnalyticsIcon: makeComponent(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M13 18.002a.998.998 0 0 1-.94-.658L8.986 8.889l-2.048 5.465a1.001 1.001 0 0 1-1.794.163l-2.187-3.645-1.124 1.685a1.001 1.001 0 0 1-1.664-1.11l2-3a.966.966 0 0 1 .856-.445.997.997 0 0 1 .833.485l1.935 3.224 2.27-6.06A1 1 0 0 1 9 5h.005a1 1 0 0 1 .935.659l2.946 8.102 3.152-11.035c.12-.421.502-.715.94-.725.43-.026.833.268.97.684l2 6a1 1 0 1 1-1.896.632l-.98-2.933-3.11 10.89a1 1 0 0 1-.927.726H13"
      />
    </svg>,
  ),
  ClientsIcon: makeComponent(
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M16.384 3.903L16.289 4a4.145 4.145 0 0 0-.91-1.416A4.381 4.381 0 0 0 14 1.625l.072-.072c.056-.057.573-.553 1.216-.553.37 0 .734.17 1.08.505.412.398.625.791.632 1.167.007.385-.2.8-.616 1.231M2.258 13c.345.09 1.062.355 1.794 1.094.565.568.829 1.194.948 1.575-.869.279-2.78.938-4 1.331.36-1.187.967-3.03 1.258-4M12.41 3c.286.07.965.296 1.663 1A3.47 3.47 0 0 1 15 5.705C12.773 7.923 7.647 13.001 5.63 15a4.613 4.613 0 0 0-.97-1.45A4.995 4.995 0 0 0 3 12.423L12.41 3M16.933.884C16.321.297 15.647 0 14.926 0c-1.126 0-1.947.728-2.172.95-.316.314-11.106 11.117-11.106 11.117a.577.577 0 0 0-.148.256c-.244.9-1.463 4.893-1.475 4.933a.578.578 0 0 0 .144.578.568.568 0 0 0 .575.139c.042-.014 4.16-1.346 4.838-1.55a.568.568 0 0 0 .237-.14C6.247 15.86 16.31 5.9 16.979 5.21c.69-.713 1.033-1.455 1.02-2.205-.013-.742-.371-1.455-1.066-2.121"
      />
    </svg>,
  ),
  DailyActivityIcon: makeComponent(
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M22.198 19H.802C.36 19 0 19.448 0 20s.36 1 .802 1h21.396c.443 0 .802-.448.802-1s-.36-1-.802-1z"
        />
        <path
          fill="#FFFEFE"
          d="M10.5 18h2c.828 0 1.5-.711 1.5-1.588v-5.824C14 9.711 13.328 9 12.5 9h-2C9.672 9 9 9.711 9 10.588v5.824c0 .421.158.825.44 1.123.28.298.662.465 1.06.465zm0-7.412h2v5.824h-2v-5.824z"
        />
        <path
          fill="#FFF"
          d="M17.5 18h2c.828 0 1.5-.728 1.5-1.625v-9.75C21 5.728 20.328 5 19.5 5h-2c-.828 0-1.5.728-1.5 1.625v9.75c0 .897.672 1.625 1.5 1.625zm0-11.375h2v9.75h-2v-9.75zM4.5 18h2c.828 0 1.5-.733 1.5-1.636V1.636C8 .733 7.328 0 6.5 0h-2C3.672 0 3 .733 3 1.636v14.728C3 17.267 3.672 18 4.5 18zm0-16.364h2v14.728h-2V1.636z"
        />
      </g>
    </svg>,
  ),
  UsersIcon: makeComponent(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22">
      <g fill="#FFF" fillRule="evenodd">
        <path d="M18.204 19.476a.509.509 0 0 1-.5-.402.502.502 0 0 1 .398-.592c2.31-.465 3.477-1.674 4.248-2.557-.542-.86-1.763-2.257-3.483-2.84-1.983-.672-2.409-.928-2.409-2.123 0-.223.15-.419.366-.482.324-.094.728-.096 1.195-.099.697-.004 1.563-.009 1.846-.38.24-.315.14-.54-.361-1.296a13.4 13.4 0 0 1-.376-.59c-.965-1.638-1.038-2.363-1.122-3.202l-.016-.165c-.21-2.01-1.3-3.1-3.24-3.24-1.694.122-2.744.977-3.12 2.54a.51.51 0 0 1-.615.372.501.501 0 0 1-.378-.604c.48-1.994 1.929-3.17 4.08-3.313a.777.777 0 0 1 .068 0c2.46.163 3.958 1.634 4.22 4.142l.017.17c.078.781.135 1.347.99 2.795.113.195.233.375.347.546.477.72 1.018 1.536.322 2.447-.587.77-1.734.777-2.656.783-.161 0-.325.001-.473.007.135.21.526.363 1.648.743 2.23.755 3.704 2.628 4.224 3.615a.497.497 0 0 1-.065.557l-.077.09c-.808.935-2.161 2.502-4.975 3.068a.52.52 0 0 1-.103.01" />
        <path d="M9.263 21.8c-5.117 0-8.71-.749-8.86-.78A.504.504 0 0 1 0 20.503c.197-4.067 2.771-5.329 4.69-5.925 1.432-.445 2.204-1.174 2.212-1.182a.516.516 0 0 1 .72.007.496.496 0 0 1-.004.708c-.037.037-.939.902-2.62 1.425-1.793.557-3.644 1.542-3.946 4.582 1.104.197 4.213.676 8.211.676s7.107-.48 8.21-.676c-.302-3.04-2.152-4.025-3.945-4.582-1.682-.523-2.583-1.388-2.62-1.425a.498.498 0 0 1-.004-.71.517.517 0 0 1 .72-.005c.021.02.792.74 2.212 1.182 1.918.596 4.493 1.858 4.69 5.925a.504.504 0 0 1-.403.515c-.15.032-3.744.78-8.86.78" />
        <path d="M5.443 10.19l.042.025c.194.118.362.22.424.436.199.68 1.544 3.216 3.354 3.216s3.155-2.536 3.354-3.217c.062-.214.23-.317.424-.435l.041-.025c.484-.876.386-1.234.343-1.325a.275.275 0 0 0-.15-.136.504.504 0 0 1-.418-.521c0-.01.052-1.022-.197-1.947-.024-.084-.649-2.154-3.397-2.154-2.775 0-3.392 2.134-3.398 2.156-.248.923-.197 1.934-.197 1.945a.504.504 0 0 1-.417.52c-.004.003-.106.034-.155.147-.04.093-.127.454.347 1.315m3.82 4.682c-2.454 0-3.94-2.872-4.28-3.781l-.036-.023c-.154-.094-.265-.161-.342-.295-.558-.972-.703-1.757-.43-2.333.123-.256.302-.421.468-.526.002-.426.04-1.178.236-1.91.275-1.005 1.545-2.902 4.384-2.902 2.84 0 4.109 1.897 4.383 2.9.198.733.235 1.486.237 1.912.166.105.345.27.467.526.273.576.13 1.361-.43 2.333-.076.134-.187.201-.341.295l-.036.023c-.342.91-1.826 3.78-4.28 3.78" />
      </g>
    </svg>,
  ),
  Login: makeComponent(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22">
      <g fill="#FFF" fillRule="evenodd">
        <path d="M18.204 19.476a.509.509 0 0 1-.5-.402.502.502 0 0 1 .398-.592c2.31-.465 3.477-1.674 4.248-2.557-.542-.86-1.763-2.257-3.483-2.84-1.983-.672-2.409-.928-2.409-2.123 0-.223.15-.419.366-.482.324-.094.728-.096 1.195-.099.697-.004 1.563-.009 1.846-.38.24-.315.14-.54-.361-1.296a13.4 13.4 0 0 1-.376-.59c-.965-1.638-1.038-2.363-1.122-3.202l-.016-.165c-.21-2.01-1.3-3.1-3.24-3.24-1.694.122-2.744.977-3.12 2.54a.51.51 0 0 1-.615.372.501.501 0 0 1-.378-.604c.48-1.994 1.929-3.17 4.08-3.313a.777.777 0 0 1 .068 0c2.46.163 3.958 1.634 4.22 4.142l.017.17c.078.781.135 1.347.99 2.795.113.195.233.375.347.546.477.72 1.018 1.536.322 2.447-.587.77-1.734.777-2.656.783-.161 0-.325.001-.473.007.135.21.526.363 1.648.743 2.23.755 3.704 2.628 4.224 3.615a.497.497 0 0 1-.065.557l-.077.09c-.808.935-2.161 2.502-4.975 3.068a.52.52 0 0 1-.103.01" />
        <path d="M9.263 21.8c-5.117 0-8.71-.749-8.86-.78A.504.504 0 0 1 0 20.503c.197-4.067 2.771-5.329 4.69-5.925 1.432-.445 2.204-1.174 2.212-1.182a.516.516 0 0 1 .72.007.496.496 0 0 1-.004.708c-.037.037-.939.902-2.62 1.425-1.793.557-3.644 1.542-3.946 4.582 1.104.197 4.213.676 8.211.676s7.107-.48 8.21-.676c-.302-3.04-2.152-4.025-3.945-4.582-1.682-.523-2.583-1.388-2.62-1.425a.498.498 0 0 1-.004-.71.517.517 0 0 1 .72-.005c.021.02.792.74 2.212 1.182 1.918.596 4.493 1.858 4.69 5.925a.504.504 0 0 1-.403.515c-.15.032-3.744.78-8.86.78" />
        <path d="M5.443 10.19l.042.025c.194.118.362.22.424.436.199.68 1.544 3.216 3.354 3.216s3.155-2.536 3.354-3.217c.062-.214.23-.317.424-.435l.041-.025c.484-.876.386-1.234.343-1.325a.275.275 0 0 0-.15-.136.504.504 0 0 1-.418-.521c0-.01.052-1.022-.197-1.947-.024-.084-.649-2.154-3.397-2.154-2.775 0-3.392 2.134-3.398 2.156-.248.923-.197 1.934-.197 1.945a.504.504 0 0 1-.417.52c-.004.003-.106.034-.155.147-.04.093-.127.454.347 1.315m3.82 4.682c-2.454 0-3.94-2.872-4.28-3.781l-.036-.023c-.154-.094-.265-.161-.342-.295-.558-.972-.703-1.757-.43-2.333.123-.256.302-.421.468-.526.002-.426.04-1.178.236-1.91.275-1.005 1.545-2.902 4.384-2.902 2.84 0 4.109 1.897 4.383 2.9.198.733.235 1.486.237 1.912.166.105.345.27.467.526.273.576.13 1.361-.43 2.333-.076.134-.187.201-.341.295l-.036.023c-.342.91-1.826 3.78-4.28 3.78" />
      </g>
    </svg>,
  ),
  DashboardIcon: makeComponent(
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="34 23 23 23">
      <g fill="#FFF" fillRule="evenodd">
        <path d="M55.912 39.016l-1.968-.967-1.5.808 1.641.802-8.99 4.844-8.886-4.838 1.735-.785-1.495-.815-2.063.95a.698.698 0 0 0-.021 1.24l10.393 5.66a.696.696 0 0 0 .667.001l10.502-5.66a.7.7 0 0 0-.015-1.24z" />
        <path d="M53.834 33.061l-1.499.81 1.75.888-8.99 4.844-8.886-4.838 1.867-.938-1.496-.815-2.194 1.103a.699.699 0 0 0-.021 1.24l10.393 5.66a.696.696 0 0 0 .667.001l10.502-5.66a.7.7 0 0 0-.015-1.24l-2.078-1.055z" />
        <path d="M55.912 28.354L45.52 23.076a.703.703 0 0 0-.632-.002l-10.5 5.278a.702.702 0 0 0-.022 1.24l10.393 5.66a.71.71 0 0 0 .667.001l10.502-5.659a.7.7 0 0 0-.015-1.24z" />
      </g>
    </svg>,
  ),
  CollapseIcon: makeComponent(
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 22 0)">
        <rect width="21" height="21" x=".5" y=".5" stroke="#FFFEFE" rx="5" />
        <path
          stroke="#FFF"
          strokeLinejoin="round"
          d="M11 7l-4 4 4-4zm-4 4c1.52 1.506 2.48 2.494 4 4l-4-4zM15 7l-4 4 4-4zm-4 4c1.52 1.506 2.48 2.494 4 4l-4-4z"
        />
      </g>
    </svg>,
  ),
  AgentBreakIcon: makeComponent(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M9 16h2V8H9v8zm3-14C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-4h2V8h-2v8z"
          transform="translate(-2 -2)"
        />
      </g>
    </svg>,
  ),
  LocationTrackingIcon: makeComponent(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
      <path
        fill="#FFF"
        d="M6 0c3.279.09 5.918 2.968 6 6.545a7.753 7.753 0 0 1-.797 3.022l-.046.088c-.034.065-.86 1.52-1.824 2.974C7.497 15.447 6.72 16 6 16c-.46 0-1.543 0-5.17-6.342l-.047-.09A7.757 7.757 0 0 1 0 6.544C.082 2.968 2.721.09 6 0zm0 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
        transform="translate(4 2)"
      />
    </svg>,
  ),
}
