import React from 'react'

import './step1-shadow.scss'

class FormField extends React.Component {
  render() {
    return (
      <div className={this.CLASS_NAME}>
        <div className="field-title"></div>
        <div className="field-input"></div>
      </div>
    )
  }
}

class BigFormField extends FormField {
  CLASS_NAME = 'big-form-field'
}

class SmallFormField extends FormField {
  CLASS_NAME = 'small-form-field'
}

export default class Step1Shadow extends React.Component {
  render() {
    return (
      <div className="step1-shadow">
        <div className="form-line">
          <div className="form-title"></div>
          <BigFormField />
        </div>
        <div className="horizontal-line"></div>
        <div className="form-line line-2">
          <div className="form-title"></div>
          <BigFormField />
          <BigFormField />
          <BigFormField />
        </div>
        <div className="horizontal-line lower-horizontal-line"></div>
        <div className="form-line">
          <div className="form-title"></div>
          <BigFormField />
        </div>
        <div className="form-line">
          <SmallFormField />
          <SmallFormField />
          <SmallFormField />
          <SmallFormField />
        </div>
      </div>
    )
  }
}
