import React from 'react'
import { translate } from 'react-admin'

export default translate(({ translate }) => (
  <tr className="agent-content-headers">
    <th>{translate('RATING')}</th>
    <th>{translate('CLIENT_NAME')}</th>
    <th>{translate('ADDRESS')}</th>
    <th>{translate('VISIT_FREQUENCY')}</th>
    <th>{translate('DAYS_VISIT')}</th>
    <th>{translate('VISIT_PURPOSE')}</th>
  </tr>
))
