import { connect } from 'react-redux'
import AgentSettingsView from '../../components/SettingsView/Step2AgentSettingsView'
import { messages } from '../../i18n/messages'
import { AgentResource } from '../../providers/resources/requestResources'
import { GET_LIST } from 'ra-core'
import { RequestAction } from '../../actions/requestAction'
import { getRandomString } from '../../utils/stringUtils'
import { DEFAULT_QUERY } from '../../providers/queryTools'
import { SaveStep2Action } from '../../actions/settings'

function mapStateToProps(state) {
  return {
    messages,
    requestResults: state.customReducers.request.requestResults,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAgentData: () => {
      const requestId = getRandomString()
      dispatch(
        RequestAction.make({
          resource: AgentResource,
          requestType: GET_LIST,
          params: {
            requestId,
            ...DEFAULT_QUERY,
          },
          requestId,
        }),
      )

      return requestId
    },
    save: (data) => dispatch(SaveStep2Action.make(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  AgentSettingsView,
)
