import React from 'react'

import { useTranslate } from 'react-admin'

import _ from 'lodash'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

import GeoPicker from './GeoPicker'

import './sro-form-style.scss'

const GeoFormPicker = ({ label, className, helperText, record, source }) => {
  const translate = useTranslate()

  const rawValue = _.get(record, source, '{}')
  const parsedValue = JSON.parse(rawValue)

  const {
    input: { onChange },
    meta: { touched, error },
  } = useField(source)

  return (
    <div className="geo-form-picker">
      <div className="hidden-text-field">
        <TextField
          name={source}
          onChange={onChange}
          margin="normal"
          onFocus={() => {}}
          onBlur={() => {}}
          error={!!(touched && error)}
          helperText={(touched && error) || helperText}
          className={className}
        />
      </div>
      <label className="field-title">{translate(label)}</label>
      <GeoPicker
        defaultValue={parsedValue}
        onChange={(value) => onChange(JSON.stringify(value))}
        key={JSON.stringify(rawValue)}
      />
    </div>
  )
}

GeoFormPicker.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
}

export default GeoFormPicker
