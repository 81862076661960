import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classnames from 'classnames'

import flag from './flag.svg'
import kiteRight from './kite-right.svg'
import location from './location.svg'
import time from './time.svg'
import user from './user.svg'
import mapIcon from './map-icon.svg'
import framelessMapIcon from './frameless-map-icon.svg'
import house from './house.svg'
import phone from './phone.svg'
import xInCircle from './x-in-circle.png'
import plusInCircle from './plus-in-circle.png'
import minusInCircle from './minus-in-circle.png'
import dragHandle from './drag-handle.png'
import geoInput from './geoinput.svg'
import loadingGif from './loadingGif.gif'
import successV from './successV.png'
import errorTriangle from './error-triangle.svg'
import bulb from './bulb.png'
import infoI from './infoI.png'
import editPencil from './edit-pencil.svg'
import clock from './clock.png'
import circledPlus from './circledPlus.svg'
import person from './person.png'
import collapse from './collapse.svg'
import scope from './scope.png'
import layers from './layers.svg'
import emptyList from './empty-list.svg'

import './icons.scss'

const makeImage = (icon, name, props, className) => (
  <img
    {...props}
    src={icon}
    alt={name}
    className={classnames('icon', name, className, { pointer: !!props.onClick })}
  />
)

makeImage.propTypes = {
  onClick: PropTypes.func,
}

const makeImages = (icons) =>
  _.mapValues(icons, (icon, name) => (props) => makeImage(icon, name, props, props.className))

const simpleIcons = makeImages({
  flag,
  kiteRight,
  location,
  time,
  user,
  mapIcon,
  framelessMapIcon,
  house,
  phone,
  xInCircle,
  plusInCircle,
  minusInCircle,
  dragHandle,
  geoInput,
  loadingGif,
  successV,
  errorTriangle,
  bulb,
  infoI,
  editPencil,
  clock,
  circledPlus,
  person,
  collapse,
  scope,
  layers,
  emptyList,
})

const Calendar = (props) => {
  const { className, onClick } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 23, 20"
      alt={'Calendar'}
      className={classnames('icon', 'calendar', className, {
        pointer: !!onClick,
      })}
    >
      <g fill="#4da1ff" fillRule="evenodd">
        <path
          d="M20.646 20H3.353c-1.319 0-2.353-1.224-2.353-2.787v-12.636c0-1.562.593-2.674 1.913-2.674h5.74
                    v-.953c0-.513.312-.95.832-.95.522 0 .943.416.943.929l.137.974h2.87
                    l.137-.974c0-.513.421-.929.942-.929.521 0 .943.416.943.929l-.11.974h5.74c1.32 0 1.913 1.112 1.913 2.674
                    v12.636c0 1.563-1.034 2.787-2.354 2.787zm-.107-16h-5.11
                    v.375c0 .51-.536.731-1.048.731-.514 0-.807-.222-.807-.731
                    V4h-3.095v.375c0 .51-.295.731-.807.731-.513 0-1.05-.222-1.05-.731V4l-5.542.16c-.162 0-.027.19-.027.763
                    L3.08 7h17.893l.027-2.077c0-.573-.299-.923-.461-.923zm.409 13.162L20.92 9
                    H3.08l-.028 8.162c0 .545-.134.838.028.838h17.84c.162 0 .028-.293.028-.838zm-11.93-2.168h-3.004
                    v-3.017c0-.361-.106-.977.258-.977h3.07c.364 0 .658.292.658.654v3.05c0 .36-.619.29-.982.29zm
                    -2.857.135c-.363 0 0-.29 0 0z"
        />
      </g>
    </svg>
  )
}

Calendar.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

class Spinner extends React.Component {
  render() {
    return (
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    )
  }
}

class Planned extends React.Component {
  render() {
    return (
      <div className={classnames('icon', 'icon-wrapper', this.props.className)}>
        <simpleIcons.user className="planned-user" />
        <simpleIcons.time className="planned-time" />
      </div>
    )
  }
}

Planned.propTypes = {
  className: PropTypes.string,
}

class Loading extends React.Component {
  render() {
    return (
      <div
        className={classnames('icon', 'icon-wrapper', 'loading-gif', this.props.className)}
      ></div>
    )
  }
}

Loading.propTypes = {
  className: PropTypes.string,
}

class X extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        {...this.props}
        className={classnames([this.props.className, 'icon', 'x'])}
      >
        <path stroke="#979797" d="M0,0L13,13M13,0L0,13" />
      </svg>
    )
  }
}

X.propTypes = {
  className: PropTypes.string,
}

const complexIcons = {
  spinner: Spinner,
  planned: Planned,
  loading: Loading,
  calendar: Calendar,
  x: X,
}

export default { ...simpleIcons, ...complexIcons }
