export default class Action {
  static NAME = undefined

  static getExtraData() {
    return {}
  }

  static make() {
    return {
      type: this.NAME,
      ...this.getExtraData(...arguments),
    }
  }
}
