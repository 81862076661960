import { connect } from 'react-redux'
import ActivityCalendarView from '../components/ActivityCalendarView'
import { createActivityFormOpen, requestAgentClientList } from '../actions/createActivityForm'
import { getRandomString } from '../utils/stringUtils'

function mapDispatchToProps(dispatch) {
  return {
    showCreateActivityForm: (agentName, externalAgentId, agentId) => {
      dispatch(createActivityFormOpen(agentName, externalAgentId, agentId, getRandomString()))
      dispatch(requestAgentClientList({ agentId, isActive: true }))
    },
  }
}

export default connect(null, mapDispatchToProps)(ActivityCalendarView)
