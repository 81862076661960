import { put, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'

import {
  AccountDataReceivedAction,
  FetchAccountAction,
  SaveStep1Action,
  Step1SaveFailure,
  Step1SaveSuccess,
  SaveStep2Action,
  Step2SaveFailure,
  Step2SaveSuccess,
  SaveStep3Action,
  Step3SaveFailure,
  Step3SaveSuccess,
} from '../actions/settings'
import {
  AccountResource,
  AgentResource,
  AccountOptionsResource,
  AccountAgentsReplanResource,
  AccountValidationResource,
} from '../providers/resources/requestResources'
import Requester from '../providers/request'
import { runObjectInParallel } from '../utils/asyncUtils'
import { fill, messages } from '../i18n/messages'
import { handleCatch } from '../components/widgets/SRONotification'

async function getAccountData(accountId, options = {}) {
  const accountDataRequest = new Requester().getOne(AccountResource, accountId)

  const enrichmentPromises = {}

  if (options.enriched) {
    enrichmentPromises.accountOptions = new Requester().getRaw(AccountOptionsResource, {
      id: accountId,
    })
  }

  const accountData = (await accountDataRequest).data
  const enrichmentData = await runObjectInParallel(enrichmentPromises)
  return Object.assign({}, accountData, enrichmentData)
}

function* fetchAccount(action) {
  try {
    const data = yield getAccountData(action.accountId, action.options)
    yield put(AccountDataReceivedAction.make(data))
  } catch (err) {
    handleCatch(err)
    return
  }
}

class StepSaver {
  constructor() {
    this.save = this.save.bind(this)
    this.saveStep = this.saveStep.bind(this)
  }

  async saveStep() {
    throw new Error('Not implemented')
  }

  *save(action) {
    try {
      yield this.saveStep(action)
      yield put(this.constructor.SUCCESS_ACTION.make())
    } catch (err) {
      const parts = [err.message, _.get(err, 'data.error.name'), _.get(err, 'data.error.message')]
      const message = _.join(parts, ' ')
      yield put(this.constructor.FAILURE_ACTION.make(message))
    }
  }
}

class Step1Saver extends StepSaver {
  static SAVE_ACTION = SaveStep1Action
  static SUCCESS_ACTION = Step1SaveSuccess
  static FAILURE_ACTION = Step1SaveFailure

  async saveStep(action) {
    if (action.data === null) {
      return []
    }
    const accountData = action.data.accountData

    const requester = new Requester()
    const data = {
      id: accountData.id,
      data: accountData,
    }
    const accountSaveRequest = requester.update(AccountResource, data)
    return [await accountSaveRequest]
  }
}

class Step2Saver extends StepSaver {
  static SAVE_ACTION = SaveStep2Action
  static SUCCESS_ACTION = Step2SaveSuccess
  static FAILURE_ACTION = Step2SaveFailure

  EMAIL_CONFLICT_MESSAGE = 'entity conflict, the entity already exists'

  async saveAgent(agentId, agentData, externalAgentId) {
    const requester = new Requester()
    const data = {
      id: agentId,
      data: agentData,
    }

    try {
      return await requester.update(AgentResource, data)
    } catch (err) {
      if (_.get(err, 'data.error.message') === this.EMAIL_CONFLICT_MESSAGE) {
        throw new Error(fill(messages.ERROR_EMAIL_ALREADY_TAKEN, { externalAgentId }))
      }

      throw err
    }
  }

  async saveStep(action) {
    const calls = _.map(action.data.agents, (agentData) =>
      this.saveAgent(agentData.id, agentData.data, agentData.externalAgentId),
    )
    return await Promise.all(calls)
  }
}

class Step3Saver extends StepSaver {
  static SAVE_ACTION = SaveStep3Action
  static SUCCESS_ACTION = Step3SaveSuccess
  static FAILURE_ACTION = Step3SaveFailure

  async isAccountAlreadyValid(accountId) {
    const requester = new Requester()
    const response = await requester.getOne(AccountResource, accountId)
    return response.data.isValid
  }

  async saveAccount(patchData) {
    const requester = new Requester()
    return await requester.patch(AccountResource, patchData)
  }

  async saveAgent({ agentId, rateVisits }) {
    const requester = new Requester()
    return requester.patch(AgentResource, { id: agentId, routePreferences: { rateVisits } })
  }

  async isPlanningInProgress(accountId) {
    const requester = new Requester()
    const response = await requester.getOne(AccountResource, accountId)
    return response.data.isDuringPlanning
  }

  async waitForAccountPlan(accountId) {
    while (await this.isPlanningInProgress(accountId)) {
      await new Promise((sleepDone) => setTimeout(sleepDone, 1000))
    }
  }

  async requestNewSchedule(accountId) {
    const requester = new Requester()
    await requester.post(AccountAgentsReplanResource, { accountId })
    await this.waitForAccountPlan(accountId)
  }

  async validateAccount(accountId) {
    const response = await new Requester().getRaw(AccountValidationResource, { accountId })

    if (response.data.errors.length > 0) {
      throw new Error(response.data.errors[0])
    }
  }

  async saveStep(action) {
    const { accountPatchData, modalData, onDone } = action.data
    const accountId = action.data.accountPatchData.id

    const isAlreadyValid = await this.isAccountAlreadyValid(accountId)

    const accountData = await this.saveAccount(accountPatchData)
    const nameToIdMapping = _(accountData.data.clientRating)
      .mapKeys((rating) => rating.name)
      .mapValues((rating) => rating.id)
      .value()

    const modalDataById = _.map(modalData, (agentData) => {
      const ratingById = _.mapKeys(agentData.rateVisits, (_, nameKey) => nameToIdMapping[nameKey])
      return { ...agentData, rateVisits: ratingById }
    })
    const agentUpdateRequests = _.map(modalDataById, this.saveAgent)
    await Promise.all(agentUpdateRequests)

    await this.validateAccount(accountId)

    if (!isAlreadyValid) {
      await this.requestNewSchedule(accountId)
    }

    if (onDone) {
      const doCalculateSchedule = !isAlreadyValid
      onDone({ doCalculateSchedule })
    }
  }
}

const STEP_SAVERS = [Step1Saver, Step2Saver, Step3Saver]

export default function* settingSaga() {
  yield takeEvery(FetchAccountAction.NAME, fetchAccount)

  for (const stepSaverIndex in STEP_SAVERS) {
    const stepSaverClass = STEP_SAVERS[stepSaverIndex]
    const stepSaver = new stepSaverClass()
    yield takeEvery(stepSaverClass.SAVE_ACTION.NAME, stepSaver.save)
  }
}
