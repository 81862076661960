import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classnames from 'classnames'

import SRODropdown from '../widgets/SROFormComponents/SRODropdown'
import { SROMoment } from '../../utils/timeUtils'
import { numberFormat } from '../../utils/stringUtils'
import { AgentResource } from '../../providers/resources/requestResources'
import { useSalesDashboardData } from '../../hooks/salesDashboardHooks'
import { isRTL } from '../../i18n/messages'
import SROBar from '../widgets/charts/SROBar'
import SROPie from '../widgets/charts/SROPie'
import SROGauge from '../widgets/charts/SROGauge'
import SROLineChart from '../widgets/charts/SROLineChart'
import LoadingShadow from './SalesDashboardShadow'

import './styles.scss'

function getMonthsList() {
  const months = []
  let curDate = SROMoment.now()
  for (let i = 0; i < 12; i++) {
    const formattedMonth = curDate.formatShortMonthYear()
    months.push({
      id: formattedMonth,
      name: formattedMonth,
      monthStart: curDate.startOf('month'),
      monthEnd: curDate.endOf('month'),
    })
    curDate = curDate.add(-1, 'month')
  }

  return months
}

export default function Dashboard() {
  const translate = useTranslate()

  const monthsList = getMonthsList()
  const monthById = _.keyBy(monthsList, 'id')
  const [filter, setFilter] = useState({
    agentId: AgentResource.ALL_AGENTS_ID,
    month: monthsList[0],
  })
  const { data } = useSalesDashboardData(filter)

  function getAgentList() {
    return [{ id: AgentResource.ALL_AGENTS_ID, name: translate('ALL_AGENTS') }, ...data.agents]
  }

  function renderFilter() {
    return (
      <div className="filter">
        <h1 className="page-title float-left">{translate('sro.sales_dashboard')}</h1>
        <SRODropdown
          options={monthsList}
          onChange={(formattedMonth) => {
            setFilter({ ...filter, month: monthById[formattedMonth] })
          }}
          value={filter.month.id}
        />
        <SRODropdown
          options={getAgentList()}
          onChange={(newAgentId) => {
            setFilter({ ...filter, agentId: newAgentId })
          }}
          value={filter.agentId}
          className="agent-selector"
        />
      </div>
    )
  }

  function renderGauge(title, { percent, absActual, absPlanned }) {
    return (
      <>
        <div className="chart-title">{translate(title)}</div>
        <SROGauge
          percent={percent}
          value={numberFormat(absActual)}
          bgColor="white"
          tooltipContent={
            <>
              <div className={classnames({ rtl: isRTL() })}>{`${translate(
                'sro.sales_goal',
              )}: ${numberFormat(absPlanned)}`}</div>
              <div className={classnames({ rtl: isRTL() })}>{`${translate(
                'sro.sales_actual',
              )}: ${numberFormat(absActual)}`}</div>
            </>
          }
        />
      </>
    )
  }

  function renderPie(title, data) {
    return (
      <>
        <div className="chart-title">{translate(title)}</div>
        <SROPie
          data={data}
          isRTL={isRTL()}
          generateTooltip={(item) => (
            <ul>
              <li className={classnames('tooltip-highlight', { rtl: isRTL() })}>
                {item.name} ({item.percent}%)
              </li>
            </ul>
          )}
        />
      </>
    )
  }

  function renderGraph(title, { percentValues, graphData }) {
    const metrics = {
      salesActual: { label: translate('sro.sales_actual'), color: '#72A2F9' },
      salesTarget: { label: translate('sro.sales_goal'), color: '#DB5352' },
    }
    const nameToKey = _(metrics)
      .mapValues((item) => item.label)
      .invert()
      .value()

    return (
      <>
        <div className="chart-title">{translate(title)}</div>
        <SROLineChart
          data={graphData}
          metrics={metrics}
          width={560}
          generateTooltip={(event) => {
            const items = _.get(event, 'payload', [])
            return (
              <div className="annual-line-tooltip">
                <div className="group-title">{event.label}</div>
                {_.map(items, (item) => (
                  <div className="tooltip-item" key={item.name}>
                    <div className="tooltip-item-dot" style={{ backgroundColor: item.color }}></div>
                    <div className={classnames('tooltip-item-text', { rtl: isRTL() })}>
                      {item.name}: {percentValues[item.payload.name][nameToKey[item.dataKey]]}% (
                      {numberFormat(item.value)})
                    </div>
                  </div>
                ))}
              </div>
            )
          }}
        />
      </>
    )
  }

  function renderBar(monthlyScoreByCategory) {
    return (
      <SROBar
        data={monthlyScoreByCategory}
        metrics={[
          { name: 'total.sales', label: translate('sro.sales_goal'), color: '#ff9d87' },
          { name: 'value.sales', label: translate('sro.sales_actual'), color: '#ffda84' },
        ]}
        tooltip={ScoreByCategoryTooltip}
        maxYAxis={100}
        xAxisDataKey="agentName"
        width={1270}
      />
    )
  }

  function renderGraphs() {
    return (
      <div className="dashboard-container">
        <div className="dashboard-row cells-3">
          <div className="dashboard-cell">{renderGauge('sro.yearly_goal', data.salesYear)}</div>
          <div className="dashboard-cell">{renderGauge('sro.monthly_goal', data.salesMonth)}</div>
          <div className="dashboard-cell">{renderGauge('sro.daily_goal', data.salesDay)}</div>
        </div>
        <div className="dashboard-row cells-3">
          <div className="dashboard-cell">
            {renderPie('sro.yearly_agent_sales', data.salesByAgentYear)}
          </div>
          <div className="dashboard-cell">
            {renderPie('sro.monthly_agent_sales', data.salesByAgentMonth)}
          </div>
          <div className="dashboard-cell">
            {renderPie('sro.daily_agent_sales', data.salesByAgentDay)}
          </div>
        </div>
        <div className="dashboard-row cells-2">
          <div className="dashboard-cell col-3">
            {renderGraph('sro.current_year', data.reportMonthlyCurrentYear)}
          </div>
          <div className="dashboard-cell col-3">
            {renderGraph('sro.last_year', data.reportMonthlyLastYear)}
          </div>
        </div>
        <div className="dashboard-row">
          <div className="dashboard-cell">
            {renderBar(data.analyticsReport.monthlyScoreByCategory)}
          </div>
        </div>
      </div>
    )
  }

  if (!data) {
    return <LoadingShadow />
  }

  return (
    <div className="dashboard">
      {renderFilter()}
      {renderGraphs()}
    </div>
  )
}

const ScoreByCategoryTooltip = ({ active, label, payload, metrics }) => {
  if (active) {
    const metricsByName = _.keyBy(metrics, 'name')
    return (
      <div className="actual-visit-tooltip">
        <div className="background"></div>
        <div className="list-title">{label}</div>
        <div className="item-list">
          {_.map(payload, (item) => {
            const value = numberFormat(_.get(item.payload, item.dataKey))
            return (
              <div key={item.dataKey} className="item-line">
                <div className="item-circle" style={{ backgroundColor: item.color }}></div>
                <div className={classnames('item-title', { rtl: isRTL() })}>{`${
                  metricsByName[item.dataKey].label
                }: ${value}`}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return null
}

ScoreByCategoryTooltip.propTypes = {
  active: PropTypes.bool,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
  payload: PropTypes.array,
}
