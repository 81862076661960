import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { padNumber } from '../../../utils/stringUtils'
import SRODropdown from '../SROFormComponents/SRODropdown'

import './time-dropdown.scss'

const HALF_HOURS_IN_DAY = 24 * 2

export class TimeDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.select = React.createRef()
  }

  makeOptions() {
    return _.map(_.range(HALF_HOURS_IN_DAY), (halfHourIndex) => {
      const hour = Math.floor(halfHourIndex / 2)
      const minute = halfHourIndex % 2 === 0 ? 0 : 30
      const value = `${padNumber(hour, 2)}:${padNumber(minute, 2)}`
      return { id: [hour, minute], name: value }
    })
  }

  render() {
    const { className, ...otherProps } = this.props
    return (
      <SRODropdown
        ref={this.select}
        options={this.makeOptions()}
        className={classnames('time-dropdown', className)}
        {...otherProps}
      />
    )
  }
}

TimeDropdown.propTypes = {
  className: PropTypes.string,
}
