import Action from './action'

export class AgentTeamListRequestedAction extends Action {
  static NAME = 'agent-team-list-requested-action'
}

export class AgentTeamListReceivedAction extends Action {
  static NAME = 'agent-team-list-received-action'

  static getExtraData(data) {
    return { data }
  }
}
