import React from 'react'
import _ from 'lodash'
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts'
import PropTypes from 'prop-types'

import './sro-pie.scss'

export default class SROPie extends React.Component {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      activeSlice: null,
    })

    this.onClick = this.onClick.bind(this)
    this.onSliceEnter = this.onSliceEnter.bind(this)
    this.onSliceLeave = this.onSliceLeave.bind(this)
  }

  onClick() {
    return [
      {
        target: 'labels',
        mutation: (props) => {
          return { text: props.text + '!' }
        },
      },
    ]
  }

  onSliceEnter(data, index) {
    this.setState({ activeSlice: index })
  }

  onSliceLeave() {
    this.setState({ activeSlice: null })
  }

  render() {
    const CustomTooltip = (params) => {
      return <div className="pie-tooltip">{_.get(params, 'payload[0].payload.tooltipContent')}</div>
    }

    const COLORS = [
      '#ffd166',
      '#0052ff',
      '#b8e986',
      '#bd10e0',
      '#ff9900',
      '#0b98ff',
      '#49c7cb',
      '#ff4b4e',
      '#ff7151',
      '#4dbcff',
    ]

    const sum = _(this.props.data)
      .map((item) => item.value)
      .sum()

    const data = _(this.props.data)
      .map((item, index) =>
        Object.assign({}, item, {
          color: COLORS[index % COLORS.length],
          percent: Math.round((100 * item.value) / sum),
        }),
      )
      .map((item) =>
        Object.assign({}, item, {
          tooltipContent: this.props.generateTooltip(item),
        }),
      )
      .value()

    const legendData = _(data)
      .map((item) => ({
        value: item.name,
        type: 'pie',
        id: item.name,
        color: item.color,
      }))
      .value()

    const radius = 110
    const innerRadius = radius - 40
    const extendedOuterRadius = radius + 10
    const legendSize = 150

    const emptyData = [
      {
        color: this.props.emptyColor,
        percent: 100,
        value: 1,
      },
    ]

    return (
      <div className="sro-pie">
        <PieChart
          width={extendedOuterRadius * 2 + legendSize}
          height={extendedOuterRadius * 2}
          style={{ direction: this.props.isRTL ? 'rtl' : 'ltr' }}
        >
          <Pie
            onMouseEnter={this.onSliceEnter}
            onMouseLeave={this.onSliceLeave}
            data={emptyData}
            innerRadius={innerRadius}
            outerRadius={radius}
            fill="#8884d8"
            paddingAngle={0}
            dataKey="value"
            isAnimationActive={false}
          >
            <Cell stroke={this.props.emptyColor} fill={this.props.emptyColor} />
          </Pie>

          <Pie
            onMouseEnter={this.onSliceEnter}
            onMouseLeave={this.onSliceLeave}
            data={data}
            innerRadius={innerRadius}
            outerRadius={radius}
            fill="#8884d8"
            paddingAngle={0}
            dataKey="value"
          >
            {_.map(data, (entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Pie
            onMouseEnter={this.onSliceEnter}
            onMouseLeave={this.onSliceLeave}
            data={data}
            innerRadius={innerRadius}
            outerRadius={extendedOuterRadius}
            fill="#8884d8"
            paddingAngle={0}
            dataKey="value"
          >
            {_.map(data, (entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={index === this.state.activeSlice ? entry.color : 'none'}
              />
            ))}
          </Pie>
          <Tooltip
            content={<CustomTooltip />}
            offset={0}
            allowEscapeViewBox={true}
            coordinate={{ x: 100, y: 100 }}
          />
          <Legend
            onMouseEnter={this.onSliceEnter}
            onMouseLeave={this.onSliceLeave}
            align={this.props.isRTL ? 'right' : 'left'}
            verticalAlign="top"
            layout="vertical"
            payload={legendData}
            wrapperStyle={{
              [this.props.isRTL ? 'right' : 'left']: '0px',
            }}
            iconSize={10}
          />
        </PieChart>
      </div>
    )
  }
}

SROPie.propTypes = {
  // TODO: this should be only array, change dashboard to not supply object
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  generateTooltip: PropTypes.func,
  emptyColor: PropTypes.string,
  isRTL: PropTypes.bool,
}

SROPie.defaultProps = {
  emptyColor: '#E5ECF6',
  isRTL: false,
}
