import React, { createElement } from 'react'

import ClickAwareBox from 'click-aware-box'

import { messages, fill } from '../../../i18n/messages'
import { goLogin } from '../../../utils/reactAdminUtils'

import './notification.scss'

const SEVERITY_INFO = 'info'
const SEVERITY_WARNING = 'warning'
const SEVERITY_ERROR = 'error'

export default class SRONotification extends React.Component {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      text: '',
      show: false,
      severity: SEVERITY_INFO,
    })

    this.hide = this.hide.bind(this)
  }

  hide() {
    if (this.timeShown && new Date().getTime() - this.timeShown < 100) {
      return
    }

    this.setState({
      show: false,
    })
  }

  render() {
    if (!this.state.show) {
      return null
    }

    return (
      <ClickAwareBox className="notification-top-wrapper" onClickOut={this.hide}>
        <div className="notification-middle-wrapper">
          <div className="notification-bottom-wrapper">{this.state.text}</div>
        </div>
      </ClickAwareBox>
    )
  }

  setNotification(text, args, severity) {
    this.timeShown = new Date().getTime()

    this.setState({
      text: fill(text, args),
      show: true,
      severity,
    })
  }

  static getElement() {
    if (!SRONotification.element) {
      SRONotification.elementRef = React.createRef()
      SRONotification.element = createElement(SRONotification, { ref: SRONotification.elementRef })
    }

    return SRONotification.element
  }

  static getInstance() {
    return SRONotification.elementRef ? SRONotification.elementRef.current : undefined
  }

  static notify(text, args, severity) {
    const instance = SRONotification.getInstance()

    if (!instance) {
      return
    }

    instance.setNotification(text, args, severity)
  }
}

export const notifyInfo = (text, args) => SRONotification.notify(text, args, SEVERITY_INFO)
export const notifyWarning = (text, args) => SRONotification.notify(text, args, SEVERITY_WARNING)
export const notifyError = (text, args) => SRONotification.notify(text, args, SEVERITY_ERROR)

export function handleCatch(error) {
  if (error && error.status === 401) {
    goLogin()
    return
  }

  notifyError(messages.UNKNOWN_ERROR)
}
