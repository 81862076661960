import _ from 'lodash'

import { roleIdEntry } from './localStorageProvider'
import { messages } from '../i18n/messages'

export const ROLE_ADMIN = 1
export const ROLE_ACCOUNT_MANAGER = 2
export const ROLE_AGENT_TEAM_MANAGER = 3
export const ROLE_AGENT = 4

export const hasAtLeastRole = (level) => {
  return roleIdEntry.get() <= level
}

export const notAdmin = () => {
  return roleIdEntry.get() !== ROLE_ADMIN
}

export const ALL_ROLES = [
  { id: ROLE_ADMIN, name: messages.ROLE_ADMIN },
  { id: ROLE_ACCOUNT_MANAGER, name: messages.ROLE_ACCOUNT_MANAGER },
  { id: ROLE_AGENT_TEAM_MANAGER, name: messages.ROLE_AGENT_TEAM_MANAGER },
  { id: ROLE_AGENT, name: messages.ROLE_AGENT },
]

export const NON_ADMIN_ROLES = _.filter(ALL_ROLES, (role) => role.id !== ROLE_ADMIN)
