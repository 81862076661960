import React, { useEffect, useState, useCallback } from 'react'
import { createPortal } from 'react-dom'
import DatePicker from 'react-datepicker'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { SROMoment } from '../../../utils/timeUtils'
import icons from '../../icons'
import { messages } from '../../../i18n/messages'

import 'react-datepicker/dist/react-datepicker.css'
import './date-range-input.scss'

const DateRangeInput = (props) => {
  const { className, from, to, isSingle, fullWidth, onChange: propsOnChange } = props

  const [previewRange, setPreviewRange] = useState({ from, to, isValid: true })
  const [isOpen, setIsOpen] = useState(false)
  const [popupCoords, setPopupCoords] = useState({ left: 0, top: 0 })

  const onApply = useCallback(() => {
    setIsOpen(false)
    propsOnChange(previewRange.from, previewRange.to)
  }, [previewRange, propsOnChange])

  function onChange(name, value) {
    const update = { ...previewRange, [name]: value }
    const newPreviewRange = { ...previewRange, ...update }
    update.isValid = isSingle || newPreviewRange.from.lte(newPreviewRange.to)

    setPreviewRange(update)
  }

  useEffect(() => {
    if (isSingle && isOpen) {
      onApply()
    }
  }, [isSingle, onApply])

  function renderDatePicker(name) {
    return (
      <div className="calendar-wrapper">
        <DatePicker
          inline
          className={'date-picker date-' + name}
          selected={previewRange[name].toJSDate()}
          onChange={(value) => onChange(name, SROMoment.prototype.fromDate(value))}
        />
      </div>
    )
  }

  function renderGoButton() {
    if (isSingle) {
      return undefined
    }

    return (
      <div className="date-picker-go-button">
        <Button className="sro-button" disabled={!previewRange.isValid} onClick={onApply}>
          {messages.APPLY}
        </Button>
      </div>
    )
  }

  function renderPickerModal() {
    if (!isOpen) {
      return undefined
    }

    const Portal = ({ children }) => {
      const mount = document.getElementsByTagName('body')[0]
      const el = document.createElement('div')

      useEffect(() => {
        const onClick = (e) => {
          if (!el.contains(e.target)) {
            setIsOpen(false)
          }
        }

        mount.appendChild(el)
        mount.addEventListener('click', onClick)
        return () => {
          mount.removeEventListener('click', onClick)
          mount.removeChild(el)
        }
      }, [el, mount])

      return createPortal(children, el)
    }

    return (
      <Portal>
        <div className="date-picker-wrapper">
          <div
            className={classnames('date-picker', { single: isSingle })}
            style={{ left: popupCoords.left, top: popupCoords.top }}
          >
            {renderDatePicker('from')}
            {isSingle ? undefined : renderDatePicker('to')}
            {renderGoButton()}
          </div>
        </div>
      </Portal>
    )
  }

  function togglePickerView(e) {
    const rect = e.target.getBoundingClientRect()
    setIsOpen(!isOpen)
    setPopupCoords({
      left: rect.x,
      top: rect.y + window.scrollY,
    })
  }

  return (
    <div className={classnames('date-picker-top', fullWidth && 'full-width', className)}>
      <div className="date-picker-ro" onClick={togglePickerView}>
        <div className="date-display">
          {isSingle
            ? from.formatDateShort()
            : `${from.formatDateShort()} - ${to.formatDateShort()}`}
        </div>
        <icons.calendar className="calendar-button" />
      </div>
      {renderPickerModal()}
    </div>
  )
}

DateRangeInput.propTypes = {
  className: PropTypes.string,
  from: PropTypes.object,
  to: PropTypes.object,
  isSingle: PropTypes.bool,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
}

DateRangeInput.defaultProps = {
  isSingle: true,
}

export default DateRangeInput
