import React from 'react'

import _ from 'lodash'

import SROFormComponent from '../widgets/SROFormComponents/SROFormComponents'
import ClientRatingFormView from '../AccountView/ClientRatingFormView'
import ClientRatingSummaryModal from './ClientRatingSummaryModal'
import { SUB_MODALS } from '../ClientRatingSummary/submodals'
import { STEP_3_DONE } from '../../actions/settings'
import { accountIdEntry } from '../../providers/localStorageProvider'
import { messages } from '../../i18n/messages'

const DELAY_BEFORE_REDIRECT_ON_DONE_MS = 2000

class Step3ClientSettingsView extends SROFormComponent {
  static NEXT_BUTTON_TITLE = messages.PREVIEW

  constructor(props) {
    super(props)

    this.state = { ...this.state, showSummaryModal: false }

    this.accountId = accountIdEntry.get()
    this.props.fetchAccountData(this.accountId)

    this.references = { clientRating: React.createRef() }

    this.closePreview = this.closePreview.bind(this)
    this.next = this.next.bind(this)
    this.getRatingElem = this.getRatingElem.bind(this)
    this.onSave = this.onSave.bind(this)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (_.get(newProps, 'step3status.status') === STEP_3_DONE.status) {
      setTimeout(() => (window.location.href = '/'), DELAY_BEFORE_REDIRECT_ON_DONE_MS)
    }

    if (newProps.clientSummarySimulationData) {
      this.setState({
        showSummaryModal: true,
      })
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  render() {
    if (!this.props.accountData) {
      return
    }

    return (
      <div className="client-settings">
        <ClientRatingFormView
          ref={this.references.clientRating}
          accountData={this.props.accountData}
          notifyError={this.props.notifyError}
          save={this.props.save}
          accountId={accountIdEntry.get()}
        />
        {this.renderSubModal()}
        {this.renderSummaryModal()}
      </div>
    )
  }

  closePreview() {
    this.setState({ showSummaryModal: false })
  }

  getRatingElem() {
    return this.references.clientRating.current
  }

  serialize() {
    return this.getRatingElem().serialize()
  }

  setBadFields(refNames) {
    this.getRatingElem().setBadFields(refNames)
  }

  next() {
    try {
      this.props.fetchClientSummarySimulation(this.accountId, this.serialize([]))
    } catch (err) {
      this.props.notifyError(err.message)
      return
    }
  }

  onSave(modalData, newUUIDs) {
    this.getRatingElem().save(modalData, newUUIDs)
  }

  renderSummaryModal() {
    if (!this.state.showSummaryModal) {
      return undefined
    }

    return (
      <ClientRatingSummaryModal
        agentSummary={this.props.clientSummarySimulationData}
        ratings={this.serialize()}
        onClose={this.closePreview}
        onSave={this.onSave}
        step3status={this.props.step3status}
      />
    )
  }

  renderSubModal() {
    const SubModal = SUB_MODALS[_.get(this.props, 'step3status.status')]
    const errorMessage = _.get(this.props, 'step3status.error', '')

    if (!SubModal || !this.props.step3status.showModal) {
      return null
    }

    return <SubModal error={errorMessage} onClose={this.props.onSubModalClose} />
  }
}

export default Step3ClientSettingsView
