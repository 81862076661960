import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import _ from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import DateRangeInput from '../widgets/DateRangeInput'
import BigRouteLink from '../widgets/RouteLink/BigRouteLink'
import { ToggleButton } from '../widgets/Buttons'
import PieGauge from '../widgets/Pie/PieGauge'
import NestedPie from '../widgets/Pie/NestedPie'
import { STATUS_DATA_RECEIVED } from '../../actions/dailyActivity'
import LoadingPlaceholder from './LoadingPlaceholder'
import SRODropdown from '../widgets/SROFormComponents/SRODropdown'
import { SROMoment } from '../../utils/timeUtils'
import NumberView from '../widgets/NumberView'
import QuestionMarkWithTooltip from '../widgets/QuestionMarkWithTooltip'
import SROTooltip from '../widgets/SROTooltip'
import { messages } from '../../i18n/messages'
import { AgentResource } from '../../providers/resources/requestResources'
import SROTabSet from '../widgets/SROTabSet'
import icons from '../icons'
import TripMapPopup from '../SROMapView/TripMapPopup'
import { accountModeEntry } from '../../providers/localStorageProvider'
import OrderDrilldownView from './OrderDrilldownView'

import './daily-activity-view.scss'

const COLS = 12

const DEFAULT_SALES_GOAL = {
  workSummary: 0,
  salesSummary: 0,
  successSummary: 0,
  visitProgress: 0,
}

const OrderCount = ({ lineData, tooltipText }) => {
  return (
    <SROTooltip text={lineData.totalSale === 0 ? tooltipText : ''}>
      <div>
        <NumberView value={lineData.totalSale} tooltipEnabled={false} />
      </div>
    </SROTooltip>
  )
}

OrderCount.propTypes = {
  lineData: PropTypes.object,
  tooltipText: PropTypes.string,
}

const DailyActivityView = ({ changeFilter, filter, data, status }) => {
  const search = window.location.search
  const embedded = new URLSearchParams(search).get('embedded') === 'true'
  const dateStr = new URLSearchParams(search).get('date')
  const date = dateStr ? SROMoment.fromString(dateStr) : SROMoment.now()
  const translate = useTranslate()

  const [showDatePicker, setShowDatePicker] = useState(false)
  const [mapAgentId, setMapAgentId] = useState(null)
  const [mapDate, setMapDate] = useState(null)
  const [mapShow, setMapShow] = useState(false)
  const [drilldownModalShown, setDrilldownModalShown] = useState({})
  const [drilldownModalData, setDrilldownModalData] = useState({})
  const [agentState, setAgentState] = useState({})

  useEffect(() => {
    changeFilter({
      agent: AgentResource.ALL_AGENTS_ID,
      date: date,
    })
  }, [changeFilter, date.formatISO()])

  function showDrilldownModal(data) {
    setDrilldownModalShown({ [data.clientId]: true })
    setDrilldownModalData({ ...data })
  }

  function hideDrilldownModal(id) {
    setDrilldownModalShown({ [id]: false })
  }

  function onToggleDateRangeSelectorView() {
    setShowDatePicker(!showDatePicker)
  }

  function onAgentFilterChange(newAgentId) {
    changeFilter({
      agent: newAgentId,
      date: filter.date,
    })
  }

  function onDateChange(newDate) {
    changeFilter({
      agent: filter.agent,
      date: newDate,
    })
  }

  function renderFilter() {
    return (
      <div className="daily-activity-header">
        <h1 className="page-title float-left">{translate('DAILY_ACTIVITY')}</h1>
        <DateRangeInput
          isSingle={true}
          isOpen={showDatePicker}
          onChange={onDateChange}
          onToggleSelectorView={onToggleDateRangeSelectorView}
          from={filter.date}
        />
        <SRODropdown
          options={data.agents}
          onChange={onAgentFilterChange}
          value={filter.agent}
          className="agent-selector"
        />
      </div>
    )
  }

  function generateTimeCol(time, planned, isClosed) {
    const tooltipText = isClosed
      ? translate('SCHEDULED_AT', {
          time: planned ? planned.formatTime() : undefined,
        })
      : ''

    return (
      <td className={classnames('time centered')}>
        <SROTooltip text={tooltipText}>
          <span>{time ? time.formatTime() : undefined}</span>
        </SROTooltip>
      </td>
    )
  }

  function getVisitTypeIcon(type) {
    const IconType = {
      client: icons.house,
      phone: icons.phone,
    }[type]

    if (!IconType) {
      return undefined
    }

    return <IconType className="visit-type-icon" />
  }

  function getUnplannedIcon(isPlanned) {
    if (isPlanned) {
      return null
    }
    return (
      <SROTooltip text={messages.UNPLANNED_ACTIVITY}>
        <div>
          <icons.bulb />
        </div>
      </SROTooltip>
    )
  }

  function getQuestionMarkIcon(lineData) {
    if (!lineData.isCanceled) {
      return undefined
    }

    const templates = [
      { title: 'NOT_SELLING_REASON', value: lineData.notSellingReason },
      { title: 'CANCELLATION_REASON', value: lineData.cancelReason },
      { title: 'RESCHEDULE_REASON', value: lineData.rescheduleReason },
      { title: 'RESCHEDULE_TIME', value: lineData.rescheduleTime },
    ]

    const tooltipText = _(templates)
      .map((template) =>
        template.value ? translate(template.title, { value: template.value }) : undefined,
      )
      .filter()
      .join('\n')

    return <QuestionMarkWithTooltip tooltipText={tooltipText || translate('NO_INFO_TO_SHOW')} />
  }

  function generateVisitInfoIcons(lineData) {
    return (
      <span>
        {getQuestionMarkIcon(lineData)}
        {getVisitTypeIcon(lineData.type)}
        {getUnplannedIcon(lineData.isPlanned)}
      </span>
    )
  }

  function renderAgentVisit(lineData, index) {
    if (!agentState[getAgentStateName(lineData.agentId)] && !embedded) {
      return undefined
    }

    const rating = data.ratingDictionary[lineData.rating]
    const tooltipText = lineData.notSellingReason
      ? translate('NOT_SELLING_REASON', { value: '' + lineData.notSellingReason })
      : ''

    return (
      <tr key={index} className={classnames('details', lineData.classification)}>
        <td className="info-icons leftmost centered artificial-shadow-source">
          <div className="artificial-shadow left-shadow"></div>
          <span>{generateVisitInfoIcons(lineData)}</span>
        </td>
        <td className={classnames('rating', 'centered', 'artificial-shadow-source', rating)}>
          {rating}
        </td>
        <td className="centered">{lineData.clientName}</td>
        <td className="centered">{lineData.cityName}</td>
        <td
          className={classnames('centered', 'order-count')}
          onClick={() => showDrilldownModal(lineData)}
        >
          <OrderCount lineData={lineData} tooltipText={tooltipText} />
        </td>
        {generateTimeCol(lineData.startTime, lineData.startTimePlanned, lineData.isClosed)}
        {generateTimeCol(lineData.endTime, lineData.endTimePlanned, lineData.isClosed)}
        <td className="centered">{lineData.lastVisit ? lineData.lastVisit.formatDate() : ''}</td>
        <td className="centered">
          <NumberView value={lineData.lastOrderTotal} />
        </td>
        <td className="centered">{lineData.agentRemark}</td>
        <td className="centered artificial-shadow-source">
          <span>{lineData.visitPurpose}</span>
          <div className="artificial-shadow"></div>
        </td>
      </tr>
    )
  }

  function renderAgentCards() {
    if (!data) {
      return undefined
    }

    return (
      <table className="daily-activity-agent">
        {_.map(data.activitiesByAgent, renderAgentCard)}
      </table>
    )
  }

  function renderGlobalSummary() {
    if (!data) {
      return undefined
    }

    const salesGoalGlobal = data.salesGoalGlobal || DEFAULT_SALES_GOAL

    return (
      <div className="global-summary">
        <div className="global-summary-box">
          <div className="global-summary-title">{translate('WORK_SUMMARY')}</div>
          <div className="global-summary-value">
            <PieGauge
              perc={salesGoalGlobal.workSummary}
              color1={'#fad961'}
              color2={'#f76b1c'}
              backgroundColor={'white'}
              emptyColor={'#dbecf8'}
            />
          </div>
        </div>
        <div className="global-summary-box central">
          <div className="global-summary-title">{translate('SALES_SUMMARY')}</div>
          <div className="global-summary-value">
            <PieGauge
              perc={salesGoalGlobal.salesSummary}
              color1={'#2355ae'}
              color2={'#51e0d2'}
              backgroundColor={'white'}
              emptyColor={'#dbecf8'}
            />
          </div>
        </div>
        <div className="global-summary-box">
          <div className="global-summary-title">{translate('SUCCESS_SUMMARY')}</div>
          <div className="global-summary-value">
            <PieGauge
              perc={salesGoalGlobal.successSummary}
              color1={'#3023ae'}
              color2={'#c86dd7'}
              backgroundColor={'white'}
              emptyColor={'#dbecf8'}
            />
          </div>
        </div>
      </div>
    )
  }

  function onOpenMap({ agentId, date }) {
    setMapAgentId(agentId)
    setMapDate(date)
    setMapShow(true)
  }

  function renderAgentSummary(agentData) {
    const isOpen = agentState[getAgentStateName(agentData.agentId)] && !embedded
    const agentSummary = data.salesGoalByAgent[agentData.agentId] || DEFAULT_SALES_GOAL

    return (
      <div className="agent-summary-box">
        <div className="artificial-shadow right-shadow"></div>
        <div className="artificial-shadow left-shadow"></div>
        <div className="artificial-shadow top-shadow"></div>
        <div className="agent-summary-column agent-profile">
          <icons.person className="agent-image" alt={data.agentName} height={30} />
          <div className="agent-name">{agentData.agentName}</div>
          <div className="agent-title">{agentData.agentTitle}</div>
        </div>
        <div className="agent-summary-column">
          <NestedPie
            outerColor="#4a90e2"
            outerEmptyColor="#edecec"
            innerColor="#feca34"
            innerEmptyColor="#edecec"
            backgroundColor="white"
            outerPerc={agentSummary.salesSummary}
            innerPerc={agentSummary.workSummary}
            innerLabel={translate('DAILY')}
            outerLabel={translate('SALES_GOALS')}
          />
        </div>
        <div className="agent-summary-column total-order-box">
          <div className="agent-total-orders-value">
            <NumberView value={agentData.totalOrders} />
          </div>
          <div className="agent-total-orders-title">{translate('TOTAL_ORDERS')}</div>
        </div>
        <div className="agent-summary-column pair-column">
          <div className="agent-summary-pair-line">
            <div className="agent-summary-key">{translate('ACTIVITIES')}</div>
            <div className="agent-summary-value">
              <NumberView value={agentData.activities} />
            </div>
          </div>
          <div className="agent-summary-pair-line">
            <div className="agent-summary-key">{translate('CLOSED')}</div>
            <div className="agent-summary-value">
              <NumberView value={agentData.activitiesDone} />
            </div>
          </div>
        </div>
        <div className="agent-summary-column pair-column">
          <div className="agent-summary-pair-line">
            <div className="agent-summary-key">{translate('NEW')}</div>
            <div className="agent-summary-value">
              <NumberView value={agentSummary.newActivities} />
            </div>
          </div>
          <div className="agent-summary-pair-line">
            <div className="agent-summary-key">{translate('CANCELED')}</div>
            <div className="agent-summary-value">
              <NumberView value={agentSummary.canceledActivities} />
            </div>
          </div>
        </div>
        <div className="agent-summary-column pair-column">
          <div className="agent-summary-pair-line crowded">
            <div className="agent-summary-key">{translate('TOTAL_KM')}</div>
            <div className="agent-summary-value">{agentData.totalKM}</div>
            <div className="agent-summary-key">{translate('AVG_KM_PER_VISIT')}</div>
            <div className="agent-summary-value">{agentData.avgKM}</div>
          </div>
          <div className="agent-summary-pair-line route-link">
            <BigRouteLink
              visitProgress={agentSummary.visitProgress}
              onClick={onOpenMap}
              dateStartTime={agentData.startTime}
              dateEndTime={agentData.endTime}
              agentId={agentData.agentId}
              date={filter.date.formatDate()}
            />
          </div>
        </div>
        <div className={classnames('agent-summary-pair-line', 'agent-toggle', { closed: !isOpen })}>
          <ToggleButton
            onClick={() => toggleAgent(agentData.agentId)}
            value={isOpen}
            title={translate('SHOW_DETAILS')}
          />
        </div>
      </div>
    )
  }

  function getAgentStateName(agentId) {
    return `show_agent_${agentId}`
  }

  function toggleAgent(agentId) {
    const agentStateName = getAgentStateName(agentId)
    setAgentState({ [agentStateName]: !agentState[agentStateName] })
  }

  function renderAgentVisitHeader(agentId) {
    if (!agentState[getAgentStateName(agentId)] && !embedded) {
      return undefined
    }

    return (
      <tr className="detail-header">
        <th className="leftmost artificial-shadow-source">
          <div className="artificial-shadow left-shadow"></div>
        </th>
        <th>{translate('RATING')}</th>
        <th>{translate('CLIENT_NAME')}</th>
        <th>{translate('CITY_NAME')}</th>
        <th>{translate('ORDER_TOTAL')}</th>
        <th>{translate('S_TIME')}</th>
        <th>{translate('E_TIME')}</th>
        <th>{translate('LAST_VISIT')}</th>
        <th>{translate('LAST_ORDER_T')}</th>
        <th>{translate('VISIT_REMARK')}</th>
        <th className="rightmost artificial-shadow-source">
          <div className="artificial-shadow right-shadow"></div>
          <span>{translate('VISIT_PURPOSE')}</span>
        </th>
      </tr>
    )
  }

  function renderAgentVisits(agentData) {
    if (!agentState[getAgentStateName(agentData.agentId)] && !embedded) {
      return undefined
    }

    const [phoneActivities, visitActivities] = _(agentData.agentVisits)
      .partition((lineData) => lineData.isPhone)
      .value()

    function ActivitiesTab(activities) {
      const tab = () => (
        <table className="daily-activity-agent">
          <tbody>
            {renderAgentVisitHeader(agentData.agentId)}
            {_.map(activities, renderAgentVisit)}
          </tbody>
        </table>
      )

      return tab
    }

    const accountMode = accountModeEntry.get()
    if (accountMode === 'SRO_LIGHT') {
      return ActivitiesTab(agentData.agentVisits)()
    } else {
      return (
        <SROTabSet
          tabs={[
            {
              label: `${messages.ON_SITE_ACTIVITIES} (${visitActivities.length})`,
              Content: ActivitiesTab(visitActivities),
            },
            {
              label: `${messages.PHONE_ACTIVITIES} (${phoneActivities.length})`,
              Content: ActivitiesTab(phoneActivities),
            },
          ]}
        />
      )
    }
  }

  function renderAgentCard(agentData, index) {
    return [
      <tbody key={`${index}_content`}>
        <tr>
          <th colSpan={COLS} className="agent-content artificial-shadow-source">
            {renderAgentSummary(agentData)}
            {renderAgentVisits(agentData)}
          </th>
        </tr>
      </tbody>,
      <tbody key={`${index}_spacing`}>
        <tr>
          <th colSpan={COLS} className="agent-spacing artificial-shadow-source">
            <div className="artificial-shadow top-shadow"></div>
            <div className="artificial-shadow bottom-shadow"></div>
          </th>
        </tr>
      </tbody>,
    ]
  }

  function renderData() {
    if (status !== STATUS_DATA_RECEIVED) {
      return <LoadingPlaceholder />
    }

    return (
      <div>
        {renderGlobalSummary()}
        {renderAgentCards()}
      </div>
    )
  }

  function getTripMapPopup() {
    if (!mapShow) {
      return null
    }

    return (
      <TripMapPopup
        key="trip-popup"
        agentId={mapAgentId}
        date={mapDate}
        onClose={() => {
          setMapShow(false)
        }}
      />
    )
  }

  function setEmbeddedMode() {
    if (!embedded || status !== STATUS_DATA_RECEIVED) {
      return
    }

    document.getElementById('sidebar').style.display = 'none'
    for (const item of document.getElementsByClassName('daily-activity-page')) {
      item.style.margin = '0 0 0 10px'
    }
    for (const item of document.querySelectorAll('*')) {
      item.style.boxShadow = 'none'
    }
  }

  setEmbeddedMode()

  return (
    <div className="daily-activity-page page">
      <OrderDrilldownView
        shown={drilldownModalShown}
        onHide={hideDrilldownModal}
        lineData={drilldownModalData}
      />
      {getTripMapPopup()}
      <div className="daily-activity-content">
        {renderFilter()}
        {renderData()}
      </div>
    </div>
  )
}

DailyActivityView.propTypes = {
  changeFilter: PropTypes.func,
  filter: PropTypes.object,
  data: PropTypes.object,
  status: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.any),
}

export default DailyActivityView
