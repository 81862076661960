import React from 'react'
import { useTranslate } from 'react-admin'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import _ from 'lodash'

import {
  AddButton,
  ToggleButton,
  ExportButton,
  PrintButton,
} from '../../components/widgets/Buttons'
import NumberView from '../widgets/NumberView'
import Icon from '../icons'
import { messages } from '../../i18n/messages'

const ExportAgent = (props) => {
  const { data } = props

  function getRating(record) {
    const travel = {
      client: {
        calculatedRateId: _.get(record, 'client.calculatedRateId'),
        accountId: record.accountId,
      },
    }
    return props.getRating(travel)
  }

  const COLUMNS = [
    {
      title: messages.AGENT_CODE,
      value: (record) => _.get(record, 'agent.externalId'),
    },
    {
      title: messages.DATE,
      value: (record) => record.date.formatEuro(),
    },
    {
      title: messages.RATING,
      value: (record) => getRating(record).name,
    },
    {
      title: messages.CLIENT_NAME,
      value: (record) => record.clientName,
    },
    {
      title: messages.ADDRESS,
      value: (record) => record.address,
    },
    {
      title: messages.VISIT_FREQUENCY,
      value: (record) => getRating(record).visitFrequencyDays,
    },
    {
      title: messages.DAYS_VISIT,
      value: (record) => record.visitDays,
    },
    {
      title: messages.VISIT_PURPOSE,
      value: (record) => record.visitPurpose || messages.PLANNED_VISIT,
    },
  ]

  function getVisitData(visit) {
    return _.map(COLUMNS, (col) => col.value(visit))
  }

  function getDayVisits(dayInfo) {
    return _.map(dayInfo.subs, (sub) => getVisitData({ dayInfo, ...sub }))
  }

  function getData() {
    const titles = _.map(COLUMNS, (col) => col.title)
    const values = _(data.subs).map(getDayVisits).flatten().value()

    return _.concat([titles], values)
  }

  return (
    <CSVLink data={getData()} filename={`agent-${data.externalAgentId}-schedule.csv`}>
      <ExportButton title={messages.EXPORT_ACTIVITY} />
    </CSVLink>
  )
}

ExportAgent.propTypes = {
  getRating: PropTypes.func,
  data: PropTypes.shape({
    subs: PropTypes.array,
    externalAgentId: PropTypes.string,
  }),
}

const AgentRow = (props) => {
  const {
    isOpen,
    data,
    showCreateActivityForm,
    onToggle,
    openLines,
    getRating,
    printLink,
    counts,
  } = props
  const translate = useTranslate()

  function print() {
    window.open(printLink, '', '')
  }

  return (
    <tr className={classnames('agent', { close: !isOpen })}>
      <td colSpan={6} className="agent">
        <div className="agent-line-stat-box agent-profile-box left-item">
          <Icon.person alt={data.agentName} height={30} />
          <div className="profile-name">{data.agentName}</div>
        </div>
        <div className="agent-line-stat-box by-planned left-item">
          <div className="count-by-planned planned">
            <NumberView value={counts.plannedCount} />
          </div>
          <div className="by-planned-label">{translate('TOTAL_PLANNED_VISITS')}</div>
        </div>
        <div className="agent-line-stat-box by-planned left-item">
          <div className="count-by-planned unplanned">
            <NumberView value={counts.unplannedCount} />
          </div>
          <div className="by-planned-label">{translate('TOTAL_UNPLANNED_VISITS')}</div>
        </div>
        <div className="agent-line-stat-box by-planned left-item">
          <div className="count-by-planned avg">
            <NumberView value={data.avgKMPerVisit} />
          </div>
          <div className="by-planned-label">{translate('AVG_KM_PER_VISIT')}</div>
        </div>

        <div className="agent-buttons">
          <div className="right-item show-details">
            <ToggleButton
              title={translate('SHOW_DETAILS')}
              className="agent-toggle"
              onClick={() => onToggle(data.id)}
              value={openLines[data.id]}
            />
          </div>
          <div className="right-item">
            <AddButton
              onClick={() =>
                showCreateActivityForm(data.agentName, data.externalAgentId, data.agentId)
              }
              title={translate('CREATE_ACTIVITY')}
            />
          </div>
          <div className="right-item">
            <ExportAgent data={data} getRating={getRating} />
          </div>
          <div className="right-item">
            <PrintButton title={messages.PRINT_ACTIVITY} onClick={print} />
          </div>
        </div>
      </td>
    </tr>
  )
}

AgentRow.propTypes = {
  data: PropTypes.object,
  getRating: PropTypes.func,
  showCreateActivityForm: PropTypes.func,
  isOpen: PropTypes.bool,
  printLink: PropTypes.string,
  onToggle: PropTypes.func,
  openLines: PropTypes.object,
  counts: PropTypes.shape({
    plannedCount: PropTypes.number,
    unplannedCount: PropTypes.number,
  }),
}

export default AgentRow
