import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import _ from 'lodash'
import PropTypes from 'prop-types'

import DateRangeInput from '../widgets/DateRangeInput'
import SRODropdown from '../widgets/SROFormComponents/SRODropdown'
import { toPrecision } from '../../utils/numberUtils'
import { SROMoment } from '../../utils/timeUtils'
import { AgentResource } from '../../providers/resources/requestResources'
import { useDashboardData } from '../../hooks/dashboardHooks'
import SROPie from '../widgets/charts/SROPie'
import SROGauge from '../widgets/charts/SROGauge'
import SROLineChart from '../widgets/charts/SROLineChart'
import SROStackedBar from '../widgets/charts/SROStackedBar'
import DashboardShadow from './DashboardShadow'

import './dashboard.scss'

export default function Dashboard() {
  const translate = useTranslate()
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [filter, setFilter] = useState({
    agentId: AgentResource.ALL_AGENTS_ID,
    from: SROMoment.now(),
    to: SROMoment.now(),
  })

  const { data } = useDashboardData(filter)

  function getAgentList() {
    return _.concat(
      [{ id: AgentResource.ALL_AGENTS_ID, name: translate('ALL_AGENTS') }],
      data.agents,
    )
  }

  function renderFilter() {
    return (
      <div className="filter">
        <h1 className="page-title float-left">{translate('DASHBOARD')}</h1>
        <DateRangeInput
          isSingle={false}
          isOpen={showDatePicker}
          onChange={(from, to) => {
            setFilter({
              agentId: filter.agentId,
              from,
              to,
            })
          }}
          onToggleSelectorView={() => setShowDatePicker(!showDatePicker)}
          from={filter.from}
          to={filter.to}
        />
        <SRODropdown
          options={getAgentList()}
          onChange={(newAgentId) => {
            setFilter({
              agentId: newAgentId,
              from: filter.from,
              to: filter.to,
            })
          }}
          value={filter.agentId}
          className="agent-selector"
        />
      </div>
    )
  }

  function renderAnnual(data) {
    const percentValues = data.reportActivityAnnual.percentValues
    const metrics = {
      plannedActivities: { label: translate('PLANNED_VISITS'), color: '#DB5352' },
      plannedOnSiteActivities: { label: translate('ON_SITE'), color: '#9DD144' },
      successfulOnSiteActivities: { label: translate('SUCCESSFUL'), color: '#72A2F9' },
    }
    const nameToKey = _(metrics)
      .mapValues((item) => item.label)
      .invert()
      .value()

    return (
      <div>
        <div className="chart-title">{translate('ANNUAL_TREND')}</div>
        <SROLineChart
          data={data.reportActivityAnnual.graphData}
          metrics={metrics}
          generateTooltip={(event) => {
            const items = _.get(event, 'payload', [])
            return (
              <div className="annual-line-tooltip">
                <div className="group-title">{event.label}</div>
                {_.map(items, (item) => (
                  <div className="tooltip-item" key={item.name}>
                    <div className="tooltip-item-dot" style={{ backgroundColor: item.color }}></div>
                    <div className="tooltip-item-text">
                      {item.name} - {percentValues[item.payload.name][nameToKey[item.dataKey]]}% (
                      {item.value})
                    </div>
                  </div>
                ))}
              </div>
            )
          }}
        />
      </div>
    )
  }

  function renderActualVisitTimesPerAgent(data) {
    return (
      <div>
        <div className="chart-title">{translate('ACTUAL_VISIT_TIMES_PER_AGENT')}</div>
        <SROStackedBar
          data={data.actualVisitTimesPerAgent}
          metrics={[
            { name: 'routeTime', label: translate('ROUTE_TIME'), color: '#74B9FA' },
            { name: 'actualVisitTime', label: translate('ACTUAL_VISIT_TIME'), color: '#9DD144' },
          ]}
          tooltip={ActualVisitTooltip}
        />
      </div>
    )
  }

  function renderFilterDependentGauges() {
    return (
      <div>
        <div className="dashboard-container">
          <div className="dashboard-row cells-3">
            <div className="dashboard-cell">
              <div className="chart-title">{translate('TOTAL_VISITS')}</div>
              <SROGauge
                percent={data.totalVisits.percent}
                bgColor="white"
                tooltipContent={
                  <div>
                    <div>{`${translate('PLANNED')} - ${data.totalVisits.absPlanned}`}</div>
                    <div>{`${translate('ACTUAL')} - ${data.totalVisits.absActual}`}</div>
                  </div>
                }
              />
            </div>
            <div className="dashboard-cell">
              <div className="chart-title">{translate('CUSTOMERS_BY_RATING')}</div>
              <SROPie
                data={data.customersByRating}
                generateTooltip={(item) => (
                  <ul>
                    <li className="tooltip-highlight">
                      {item.name} - {item.percent}%
                    </li>
                    <li>
                      {translate('CUSTOMERS_COUNT')} - {item.value}
                    </li>
                  </ul>
                )}
              />
            </div>
            <div className="dashboard-cell">
              <div className="chart-title">{translate('AGENTS_VISITS_BY_RATINGS')}</div>
              <SROPie
                data={data.visitsByRating}
                generateTooltip={(item) => (
                  <ul>
                    <li className="tooltip-highlight">
                      {item.name} - {item.percent}%
                    </li>
                    <li>
                      {translate('VISITS_COUNT')} - {item.value}
                    </li>
                  </ul>
                )}
              />
            </div>
          </div>
          <div className="dashboard-row cells-3">
            <div className="dashboard-cell">
              <div className="chart-title">{translate('TOTAL_SUCCESS_VISITS')}</div>
              <SROGauge
                percent={data.totalSuccessVisits.percent}
                bgColor="white"
                tooltipContent={
                  <div>
                    <div>{`${translate('PLANNED')} - ${data.totalSuccessVisits.absPlanned}`}</div>
                    <div>{`${translate('ACTUAL')} - ${data.totalSuccessVisits.absActual}`}</div>
                  </div>
                }
              />
            </div>
            <div className="dashboard-cell">
              <div className="chart-title">{translate('VISITS_BY_CATEGORIES')}</div>
              <SROPie
                data={data.actualVisitsByCategories}
                generateTooltip={(item) => (
                  <ul>
                    <li className="tooltip-highlight">
                      {item.name} - {item.percent}%
                    </li>
                    <li>
                      {translate('VISITS_COUNT')} - {item.value}
                    </li>
                  </ul>
                )}
              />
            </div>
            <div className="dashboard-cell">
              <div className="chart-title">{translate('TOTAL_ROUTE_TIME')}</div>
              <SROPie
                data={data.totalRouteTime}
                generateTooltip={(item) => (
                  <ul>
                    <li className="tooltip-highlight">
                      {item.name} - {item.percent}%
                    </li>
                    <li>
                      {translate('TOTAL_HOURS')} - {item.value}
                    </li>
                  </ul>
                )}
              />
            </div>
          </div>
          <div className="dashboard-row cells-2">
            <div className="dashboard-cell col-3">{renderAnnual(data)}</div>
            <div className="dashboard-cell col-3">{renderActualVisitTimesPerAgent(data)}</div>
          </div>
        </div>
      </div>
    )
  }

  if (!data) {
    return <DashboardShadow />
  }

  return (
    <div className="dashboard">
      {renderFilter()}
      {renderFilterDependentGauges()}
    </div>
  )
}

function ActualVisitTooltip({ metrics, label, payload }) {
  const translate = useTranslate()

  const labels = _(metrics)
    .keyBy('name')
    .mapValues((item) => item.label)
    .value()

  return (
    <div className="actual-visit-tooltip">
      <div className="background"></div>
      <div className="list-title">{label}</div>
      <div className="item-list">
        {_.map(payload, (item) => (
          <div key={item.dataKey} className="item-line">
            <div className="item-circle" style={{ backgroundColor: item.color }}></div>
            <div className="item-title">
              {translate('LABEL_PERCENT_HOURS', {
                label: labels[item.dataKey],
                percent: item.payload.percents[item.dataKey],
                hours: toPrecision(item.payload[item.dataKey], 1),
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

ActualVisitTooltip.propTypes = {
  metrics: PropTypes.array,
  label: PropTypes.string,
  payload: PropTypes.array,
}
