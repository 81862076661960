import { v4 as uuidv4 } from 'uuid'

export const getRandomString = () => {
  return Math.random().toString(36).substr(2, 10)
}

export const getUUID = () => {
  return uuidv4()
}

export const numberFormat = (number) => {
  if (window.isNaN(number) || typeof number === 'undefined' || number === null || number === 0) {
    return '0'
  }

  const sign = number / Math.abs(number)
  const rounded = Math.abs(Math.round(number))

  if (rounded >= 1000000) {
    return `${sign * (rounded / 1000000).toFixed(1)}M`
  }

  if (rounded >= 1000) {
    return `${sign * (rounded / 1000).toFixed(1)}K`
  }

  return '' + sign * rounded
}

export const percentFormat = (percentage) => {
  if (
    window.isNaN(percentage) ||
    typeof percentage === 'undefined' ||
    percentage === null ||
    percentage === 0
  ) {
    return '0%'
  }

  return `${Math.round(percentage * 100) / 100}%`
}

export const padNumber = (number, minDigits) => {
  const rawNumStr = '' + number

  const missingDigits = Math.max(0, minDigits - rawNumStr.length)
  const padding = '0'.repeat(missingDigits)
  return `${padding}${rawNumStr}`
}
