import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import checked from './checked.svg'
import unchecked from './unchecked.svg'
import checkedDisabled from './checked-disabled.svg'
import SROTooltip from '../SROTooltip'

import './green-checkbox.scss'

export default class GreenCheckbox extends React.Component {
  constructor(props) {
    super(props)

    this.isControlled = this.getIsControlled(this.props)

    this.state = Object.assign({}, this.state, {
      checked: this.isControlled ? this.props.checked : this.props.defaultChecked,
    })

    this.toggle = this.toggle.bind(this)
    this.getImageSrc = this.getImageSrc.bind(this)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.getIsControlled(newProps) !== this.isControlled) {
      throw new Error('Cannot change controlled mode')
    }

    if (this.isControlled && newProps.checked !== this.state.checked) {
      this.setState({ checked: newProps.checked })
    }
  }

  getIsControlled(props) {
    if (
      (props.checked && props.defaultChecked) ||
      (typeof props.checked === 'undefined' && typeof props.defaultChecked === 'undefined')
    ) {
      throw new Error(
        'Error: exactly one of the properties "checked" and "defaultChecked" must be provided',
      )
    }

    return typeof props.checked !== 'undefined'
  }

  toggle(e) {
    if (this.props.disabled) {
      return
    }

    const newChecked = !this.state.checked

    this.props.onToggle(e, newChecked)

    if (!this.isControlled) {
      this.setState({
        checked: newChecked,
      })
    }
  }

  getValue() {
    return this.state.checked
  }

  getImageSrc() {
    if (!this.state.checked) {
      return unchecked
    }

    if (this.props.disabled) {
      return checkedDisabled
    }

    return checked
  }

  render() {
    return (
      <SROTooltip
        text={this.props.disabled ? this.props.disabledMessage : ''}
        className={this.props.className}
      >
        <img
          alt="Checkbox"
          className={classnames('green-checkbox', this.props.className)}
          src={this.getImageSrc()}
          onClick={this.toggle}
        />
      </SROTooltip>
    )
  }
}

GreenCheckbox.propTypes = {
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  className: PropTypes.string,
  onToggle: PropTypes.func,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
}

GreenCheckbox.defaultProps = {
  onToggle: () => {},
}
