import Action from './action'

export class AccountListRequestedAction extends Action {
  static NAME = 'account-list-requested-action'
}

export class AccountListReceivedAction extends Action {
  static NAME = 'account-list-received-action'

  static getExtraData(data) {
    return { data }
  }
}
