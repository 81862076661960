import _ from 'lodash'
import jsxToString from 'jsx-to-string'

import { coordPointToGoogleMapsCoordPair } from '../../../utils/integrations/googleMapsUtils'
import { getMarkerIcon, TOOLTIP_BY_ICON_TYPE } from '../sroMapDefs'

function renderTooltip({ map, groupType, item, marker }) {
  const tooltipRenderer = TOOLTIP_BY_ICON_TYPE[groupType]

  const content = tooltipRenderer ? jsxToString(tooltipRenderer(item)) : ''

  var infoWindow = new window.google.maps.InfoWindow({ content })

  function makePopupVisibilitySetter(open) {
    const visibilityFunction = open ? 'open' : 'close'

    return function () {
      if (!tooltipRenderer) {
        return
      }

      infoWindow[visibilityFunction](map, marker)
    }
  }

  marker.addListener('click', makePopupVisibilitySetter(true))
  marker.addListener('mouseover', makePopupVisibilitySetter(true))
  marker.addListener('mouseout', makePopupVisibilitySetter(false))
}

function renderMarkers(map, groupType, item) {
  const icon = getMarkerIcon(groupType, item)

  const markerGroupOptions = {
    map,
    icon: {
      ...icon,
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(icon.anchorX || 0, icon.anchorY || 25),
    },
    position: coordPointToGoogleMapsCoordPair(item.coordPoint),
    type: groupType,
  }
  const marker = new window.google.maps.Marker(markerGroupOptions)
  renderTooltip({ map, groupType, item, marker })
  return { marker, point: { ...item, pointType: groupType } }
}

export function renderPointGroup(map, groupType, groupItems) {
  return _.map(groupItems, (item) => renderMarkers(map, groupType, item))
}
