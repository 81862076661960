import React, { useState, useRef, useEffect } from 'react'
import { useTranslate } from 'react-admin'
import classnames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'

import Step1AccountSettings from '../../containers/Settings/Step1AccountSettings'
import Step2AgentSettings from '../../containers/Settings/Step2AgentSettings'
import Step3ClientSettings from '../../containers/Settings/Step3ClientSettings'
import SROButton from '../widgets/SROFormComponents/SROButton'
import SROTooltip from '../widgets/SROTooltip'

import './settings.scss'

const STEPS = {
  1: Step1AccountSettings,
  2: Step2AgentSettings,
  3: Step3ClientSettings,
}

export default function SettingsView({ error, stepNum, goToStep, initStepNum }) {
  const translate = useTranslate()
  const [errorMessage, setErrorMessage] = useState('')
  const [clickNext, setClickNext] = useState(0)
  const currentStepRef = useRef()

  useEffect(() => {
    initStepNum()
  }, [initStepNum])

  function getCurrentStep() {
    return STEPS[stepNum]
  }

  function renderStep() {
    const Step = getCurrentStep()

    if (!Step) {
      return undefined
    }

    return <Step ref={currentStepRef} notifyError={setErrorMessage} />
  }

  function onBackClick() {
    if (stepNum <= 1) {
      return
    }

    goToStep(stepNum - 1)
  }

  useEffect(() => {
    const step = currentStepRef.current
    // check if this works

    if (clickNext === 0) {
      return
    }

    setErrorMessage('')
    try {
      step.next()
      step.setBadFields([])
    } catch (err) {
      step.setBadFields(err.badFields || [])
      setErrorMessage(err.message)
    }
  }, [clickNext])

  function renderProgressBar() {
    return (
      <div className="progress-bar">
        {_.map([1, 2, 3], (curStepNum) => (
          <div
            key={curStepNum}
            className={classnames('step-circle', `step-${curStepNum}`, {
              'current-step': curStepNum === stepNum,
            })}
          >
            {curStepNum}
          </div>
        ))}
        {_.map([1, 2], (delimiter) => (
          <div key={delimiter} className={`delimiter delimiter-${delimiter}`}></div>
        ))}
      </div>
    )
  }

  function showNavigationButtons() {
    const currentStep = getCurrentStep()
    if (!currentStep) {
      return null
    }

    const buttons = []

    buttons.push(
      <SROButton key="next" className="save-button" onClick={() => setClickNext(clickNext + 1)}>
        {currentStep.NEXT_BUTTON_TITLE}
      </SROButton>,
    )

    if (stepNum > 1) {
      buttons.push(
        <SROButton key="back" className="back-button sro-button-secondary" onClick={onBackClick}>
          {translate('BACK')}
        </SROButton>,
      )
    }

    buttons.push(
      <SROTooltip text={error.length > 80 ? error : null} key="error-line" className="error-line">
        <div className="text">{error}</div>
      </SROTooltip>,
    )

    return buttons
  }

  return (
    <div className="settings-page page">
      <div className="settings-header">
        <div className="header-title">{translate('SYSTEM_WELCOME')}</div>
        {renderProgressBar(stepNum)}
      </div>
      <div className="settings-step-content">{renderStep()}</div>
      <div className="settings-footer">
        <div className="error-message">{errorMessage}</div>
        {showNavigationButtons()}
      </div>
    </div>
  )
}

SettingsView.propTypes = {
  stepNum: PropTypes.number,
  error: PropTypes.string,
  goToStep: PropTypes.func,
  initStepNum: PropTypes.func,
}
