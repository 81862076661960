import { combineReducers } from 'redux'

import createActivityForm from './createActivityForm'
import dailyActivity from './dailyActivity'
import settings from './settings'
import request from './request'
import clientSummarySimulation from './clientSummarySimulation'
import clientList from './clientList'
import accountList from './accountList'
import agentTeamList from './agentTeamList'
import agentList from './agentList'
import analytics from './analytics.reducer'

export default combineReducers({
  createActivityForm,
  dailyActivity,
  settings,
  request,
  clientSummarySimulation,
  clientList,
  accountList,
  agentTeamList,
  agentList,
  analytics,
})
