import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import SROButton from '../../widgets/SROFormComponents/SROButton'
import { messages } from '../../../i18n/messages'
import { TimeDropdown } from '../../widgets/TimeDropdown'
import SRODropdown from '../../widgets/SROFormComponents/SRODropdown'
import icons from '../../icons'
import { DAY_OPTIONS, formatTime } from './workHourLogic'

import './work-hours-modal.scss'

class DayLine extends React.Component {
  constructor(props) {
    super(props)

    this.state = { ...this.state, fromTime: this.props.from, toTime: this.props.to }

    this.references = {
      fromTime: React.createRef(),
      toTime: React.createRef(),
    }

    this.setFrom = this.setFrom.bind(this)
    this.setTo = this.setTo.bind(this)
  }

  serialize() {
    const from = this.state.fromTime
    const to = this.state.toTime

    return {
      [this.props.id]: [{ from, to }],
    }
  }

  setTo(toTime) {
    this.setState({ toTime })
  }

  setFrom(fromTime) {
    this.setState({ fromTime })
  }

  render() {
    return (
      <div className="day-line">
        <icons.minusInCircle className="remove-day-button" onClick={this.props.onRemove} />
        <div className="day-name">{this.props.name}</div>
        <div className="error-message">{this.props.errorMessage}</div>
        <div className="time-dropdown-wrapper">
          <TimeDropdown
            ref={this.references.fromTime}
            value={this.state.fromTime}
            onChange={this.setFrom}
            className="from"
          />
          <TimeDropdown
            ref={this.references.toTime}
            value={this.state.toTime}
            onChange={this.setTo}
            className="to"
          />
        </div>
      </div>
    )
  }
}

DayLine.propTypes = {
  from: PropTypes.array,
  to: PropTypes.array,
  id: PropTypes.string,
  onRemove: PropTypes.func,
  name: PropTypes.string,
  errorMessage: PropTypes.string,
}

export default class WorkHoursModalView extends React.Component {
  constructor(props) {
    super(props)

    this.state = { ...this.state, days: this.props.days || {}, errorMessages: {} }

    this.dayRefs = this.makeDayRefs()

    this.onAddDay = this.onAddDay.bind(this)
    this.onRemoveDay = this.onRemoveDay.bind(this)
    this.onSave = this.onSave.bind(this)
    this.getUnsetDays = this.getUnsetDays.bind(this)
  }

  makeDayRefs() {
    return _(DAY_OPTIONS)
      .keyBy('id')
      .mapValues(() => React.createRef())
      .value()
  }

  serialize() {
    const lodashDays = _(this.dayRefs).map((ref) => (ref.current ? ref.current.serialize() : null))

    const errorArr = lodashDays
      .map((day) => {
        if (!day) {
          return undefined
        }
        const dayId = _.keys(day)[0]
        const dayConf = _.map(day, (value, dayId) => ({ value, dayId }))
        const dayRange = _.get(dayConf, '0.value.0')

        if (!dayRange) {
          return undefined
        }

        if (!dayRange.from || !dayRange.to) {
          return { [dayId]: messages.MUST_FILL_START_AND_END }
        }

        if (formatTime(dayRange.from) < formatTime(dayRange.to)) {
          return undefined
        }

        return { [dayId]: messages.FROM_MUST_BE_LT_TO }
      })
      .filter()
      .value()

    if (errorArr.length > 0) {
      const errors = Object.assign({}, ...errorArr)
      throw errors
    }

    return Object.assign({}, ...lodashDays.filter().value())
  }

  onSave() {
    try {
      this.props.onSave(this.serialize())
    } catch (errors) {
      this.setState({
        errorMessages: errors,
      })
    }
  }

  onAddDay(dayId) {
    const newDays = _.clone(this.state.days)
    newDays[dayId] = [{ from: null, to: null }]
    this.setState({
      days: newDays,
    })
  }

  onRemoveDay(dayNum) {
    const newDays = _.clone(this.state.days)
    delete newDays[dayNum]
    this.setState({ days: newDays })
  }

  getSetDaysData() {
    const daysData = _(DAY_OPTIONS)
      .map((day, dayIndex) => {
        const daySchedule = this.state.days[day.id]

        if (!daySchedule || daySchedule.length !== 1) {
          return undefined
        }

        const dayData = daySchedule[0]

        const dayConf = DAY_OPTIONS[dayIndex]
        return Object.assign({}, dayData, {
          ref: this.dayRefs[dayConf.id],
          onRemove: () => this.onRemoveDay(dayConf.id),
          id: dayConf.id,
          name: dayConf.name,
          key: dayIndex,
          errorMessage: this.state.errorMessages[dayConf.id],
        })
      })
      .filter()
      .values()
      .value()

    return daysData
  }

  getUnsetDays() {
    return _.filter(DAY_OPTIONS, (day) => !this.state.days[day.id])
  }

  render() {
    return (
      <div className="work-hours-wrapper">
        <div className="work-hours-modal">
          <div className="title">{messages.WORK_HOURS}</div>
          <div className="add-day-panel">
            <div className="add-day-title">{messages.ADD_DAY}</div>
            <SRODropdown
              onChange={this.onAddDay}
              options={this.getUnsetDays()}
              placeholder={messages.ADD_DAY}
            />
          </div>
          <div className="days-wrapper">
            <div className="days">
              {_.map(this.getSetDaysData(), (dayProps) => (
                <DayLine {...dayProps} />
              ))}
            </div>
          </div>
          <div className="buttons">
            <SROButton className="save-button" onClick={this.onSave}>
              {messages.SAVE}
            </SROButton>
            <SROButton className="cancel-button sro-button-secondary" onClick={this.props.onCancel}>
              {messages.CANCEL}
            </SROButton>
          </div>
        </div>
      </div>
    )
  }
}

WorkHoursModalView.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  days: PropTypes.object,
}

WorkHoursModalView.defaultProps = {
  days: {},
}
