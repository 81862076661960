import React from 'react'

import _ from 'lodash'
import classnames from 'classnames'
import { Button } from '@material-ui/core'

import './sro-form-style.scss'
import SROFormComponent from './SROFormComponents'

export default class SROButton extends SROFormComponent {
  getExtraClasses() {
    return ['sro-button']
  }

  render() {
    const otherProps = _.omit(this.props, 'className')
    return <Button className={classnames(this.getClasses())} {...otherProps} />
  }
}
