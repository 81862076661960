import { combineReducers } from 'redux'

import {
  CREATE_ACTIVITY_FORM_ACTION_TYPES,
  CREATE_ACTIVITY_FORM_CLOSE,
  CREATE_ACTIVITY_FORM_OPEN,
  CREATE_ACTIVITY_FORM_FAILURE,
  CREATE_ACTIVITY_FORM_LOADING,
  RECEIVE_AGENT_CLIENT_LIST,
} from '../actions/createActivityForm'

const DEFAULT_AGENT_DATA = {
  name: '',
  externalId: '',
}

function formState(state = CREATE_ACTIVITY_FORM_CLOSE, action) {
  // Same name. We filter the type before the combineReducers already
  if (CREATE_ACTIVITY_FORM_ACTION_TYPES.includes(action.type)) {
    return action.type
  }

  return state
}

function formError(state = '', action) {
  if (action.type === CREATE_ACTIVITY_FORM_FAILURE) {
    return action.error || ''
  }

  return state
}

function agent(state = DEFAULT_AGENT_DATA, action) {
  if (action.type === CREATE_ACTIVITY_FORM_OPEN) {
    return {
      name: action.agentName,
      externalId: action.externalAgentId,
      agentId: action.agentId,
      callId: action.callId,
    }
  }

  return state
}

function shown(state = false, action) {
  if (action.type === CREATE_ACTIVITY_FORM_OPEN) {
    return true
  }

  if (action.type === CREATE_ACTIVITY_FORM_CLOSE) {
    return false
  }

  return state
}

function loading(state = false, action) {
  if (action.type === CREATE_ACTIVITY_FORM_LOADING) {
    return action.loading
  }

  return state
}

function agentClientList(state = [], action) {
  if (action.type === RECEIVE_AGENT_CLIENT_LIST) {
    return action.data.data.records
  }

  return state
}

export default combineReducers({
  formState,
  formError,
  agent,
  shown,
  loading,
  agentClientList,
})
