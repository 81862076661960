export const ORDER_ASC = 'ASC'
export const ORDER_DESC = 'DESC'

export const BAD_REQUEST = 'bad-request'

export const DEFAULT_QUERY = {
  filter: {},
  pagination: {},
  sort: {
    field: 'id',
    order: ORDER_DESC,
  },
}

export const ALL_ITEMS_QUERY = Object.assign({}, DEFAULT_QUERY, {
  pagination: {
    perPage: 9999,
  },
})

export const makeFilter = (filter) => Object.assign({}, DEFAULT_QUERY, filter)
