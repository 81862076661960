import React from 'react'
import TripMap from '../../../components/TripMapView'

import './trip-map-popup.scss'

const TripMapPopup = (props) => {
  return (
    <div className="trip-map-popup-wrapper">
      <div className="trip-map-popup">
        <TripMap isPopup={true} {...props} />
      </div>
    </div>
  )
}

export default TripMapPopup
