import _ from 'lodash'

export default class LayerFilter {
  constructor({ layerConfig, requestParams }) {
    this.layers = this.initializeLayers({ layerConfig, requestParams })
    this.layerOrder = _.map(layerConfig, (layer) => layer.name)

    this.filter = this.filter.bind(this)
  }

  initializeLayers({ layerConfig, requestParams }) {
    return _(layerConfig)
      .keyBy('name')
      .mapValues((layer) => ({
        ...layer,
        activated: layer.default(requestParams),
        enabled: {
          isEnabled: layer.enabled.check(requestParams),
          denialMessage: layer.enabled.denialMessage,
        },
      }))
      .value()
  }

  match(layer, point) {
    const cleanPoint = _.pick(point, _.keys(layer.filter))

    return _.isEqual(layer.filter, cleanPoint)
  }

  getRelevantLayers(layers, point) {
    return _.filter(layers, (layer) => this.match(layer, point))
  }

  filter(point) {
    const relevantLayers = this.getRelevantLayers(this.layers, point)

    if (_.isEmpty(relevantLayers)) {
      return true
    }

    return _.some(relevantLayers, (layer) => this.match(layer, point) && layer.activated)
  }

  getLayers() {
    return _.map(this.layerOrder, (layerName) =>
      _.pick(this.layers[layerName], ['name', 'title', 'enabled', 'activated']),
    )
  }

  toggleLayer(name) {
    this.layers[name].activated = !this.layers[name].activated
  }
}
