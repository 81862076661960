import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { useTranslate } from 'ra-core'
import { CreateButton } from 'react-admin'
import inflection from 'inflection'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Icons from '../icons'

import './empty-list.scss'

const useStyles = makeStyles(
  (theme) => ({
    message: {
      textAlign: 'center',
      opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
      margin: '0 1em',
      color: theme.palette.type === 'light' ? 'inherit' : theme.palette.text.primary,
    },
    icon: {
      marginTop: '2em',
      marginBottom: '2em',
    },
    toolbar: {
      textAlign: 'center',
      marginTop: '2em',
    },
  }),
  { name: 'RaEmpty' },
)

const EmptyList = (props) => {
  const { resource, basePath } = props
  const classes = useStyles(props)
  const translate = useTranslate()

  const resourceName = inflection.humanize(
    translate(`resources.${resource}.name`, {
      smart_count: 0,
      _: inflection.pluralize(resource),
    }),
    true,
  )

  const emptyMessage = translate('ra.page.empty', { name: resourceName })
  const inviteMessage = translate('ra.page.invite')

  return (
    <div>
      <div className={classnames('empty-list', classes.message)}>
        <Icons.emptyList className={classes.icon} />
        <Typography variant="h4" paragraph>
          {translate(`resources.${resource}.empty`, {
            _: emptyMessage,
          })}
        </Typography>
        <Typography variant="body1">
          {translate(`resources.${resource}.invite`, {
            _: inviteMessage,
          })}
        </Typography>
      </div>
      <div className={classes.toolbar}>
        <CreateButton basePath={basePath} />
      </div>
    </div>
  )
}

EmptyList.propTypes = {
  resource: PropTypes.string,
  basePath: PropTypes.string,
}

export default EmptyList
