import _ from 'lodash'
import axios from 'axios'
import { stringify } from 'query-string'
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
} from 'react-admin'

import { API_URL_BASE } from '../../Config'
import { getAuthHeader } from '../authProvider'
import { DEFAULT_QUERY } from '../queryTools'
import { REPORT, GET_RAW } from '../dataProvider'

const QUERY_STRINGIFY_OPTIONS = { arrayFormat: 'bracket' }

export class RequestError extends Error {
  constructor(error) {
    const errorObject = _.get(error, 'data.error', {})
    const message = errorObject.message || error.statusText || 'Unknown Error'
    super(message)

    this.name = errorObject.name || 'UnknownError'
    this.status = error.status
    this.info = errorObject.info || {}

    Error.captureStackTrace && Error.captureStackTrace(this, this.constructor)
  }

  toJSON() {
    return {
      message: this.message,
      name: this.name,
      status: this.status,
      info: this.info,
    }
  }
}

export default class BaseResource {
  static SHOW_SIDEBAR = true

  async fetch(type, params) {
    const { url, options = {} } = this.buildRequest(type, params)

    if (!options.headers) {
      options.headers = { accept: 'application/json' }
    }

    const authHeader = getAuthHeader()
    if (authHeader) {
      options.headers['authorization'] = authHeader
    }

    try {
      const response = await axios({
        ...options,
        url,
      })
      return await this.transformResponse(type, response, params)
    } catch (error) {
      throw this.transformError(type, error.response || error)
    }
  }

  buildReportQuery() {
    throw new Error('Reports are not implemented for this resource')
  }

  getResourcePath() {
    return this.constructor.RESOURCE_NAME
  }

  getResponseMetaData(response) {
    return {
      total: parseInt(response.headers['x-total-count']),
    }
  }

  getContainerClassName() {
    return `resource-${this.constructor.RESOURCE_NAME}`
  }

  async transformResponse(type, response, params) {
    const translationMethod = {
      [GET_LIST]: this.transformGetListResponse,
      [GET_ONE]: this.transformGetOneResponse,
      [GET_MANY]: this.transformGetManyResponse,
      [GET_MANY_REFERENCE]: this.transformGetManyReferenceResponse,
      [UPDATE]: this.transformUpdateResponse,
      [CREATE]: this.transformCreateResponse,
      [DELETE]: this.transformDeleteResponse,
      [REPORT]: this.transformGetReportResponse,
      [GET_RAW]: this.transformGetRawResponse,
    }[type]

    if (!translationMethod) {
      throw new Error(`Unsupported fetch action type ${type}`)
    }

    return {
      data: await translationMethod.call(this, response.data, params),
      ...this.getResponseMetaData(response),
    }
  }

  transformCreateResponse(data) {
    return data.data
  }

  transformGetReportResponse(data) {
    return data.data.records
  }

  transformGetManyResponse(data) {
    return data.data.records
  }

  transformGetListResponse(data) {
    return data.data.records
  }

  transformGetOneResponse(data) {
    return data.data
  }

  transformGetRawResponse(data) {
    return data.data
  }

  transformGetManyReferenceResponse(data) {
    return data.data.records
  }

  transformUpdateResponse(data) {
    return data.data
  }

  transformDeleteResponse(data, params) {
    return { id: params.id, previousData: params }
  }

  buildRequest(type, params) {
    const requestMethod = {
      [GET_LIST]: this.getListRequest,
      [GET_ONE]: this.getOneRequest,
      [GET_MANY]: this.getManyRequest,
      [GET_MANY_REFERENCE]: this.getManyReferenceRequest,
      [UPDATE]: this.getUpdateRequest,
      [CREATE]: this.getCreateRequest,
      [DELETE]: this.getDeleteRequest,
      [REPORT]: this.getReportRequest,
      [GET_RAW]: this.getRawRequest,
    }[type]

    if (!requestMethod) {
      throw new Error(`Unsupported fetch action type ${type}`)
    }

    return requestMethod.call(this, params)
  }

  buildUrl(query) {
    return `${API_URL_BASE}/${this.getResourcePath(query)}?${stringify(
      query,
      QUERY_STRINGIFY_OPTIONS,
    )}`
  }

  getListRequest(params = DEFAULT_QUERY) {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      _sort: `${order}(${field})`,
      _offset: (page - 1) * perPage || undefined,
      _limit: perPage,
      ...params.filter,
    }

    return {
      url: this.buildUrl(query),
    }
  }

  getOneRequest(params) {
    return {
      url: `${API_URL_BASE}/${this.getResourcePath(params)}/${params.id}`,
    }
  }

  getManyRequest(params) {
    const query = {
      id: params.ids,
    }
    return {
      url: `${API_URL_BASE}/${this.getResourcePath(params)}?${stringify(query)}`,
    }
  }

  getRawRequest(params) {
    return {
      url: this.buildUrl(params),
    }
  }

  getManyReferenceRequest(params) {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      _sort: `${order}(${field})`,
      _offset: (page - 1) * perPage,
      _limit: perPage,
      ...params.filter,
      [params.target]: params.id,
    }
    return {
      url: `${API_URL_BASE}/${this.getResourcePath(params)}?${stringify(query)}`,
    }
  }

  getUpdateRequest(params) {
    return {
      url: `${API_URL_BASE}/${this.getResourcePath(params)}/${params.id}`,
      options: {
        method: 'put',
        data: params.data,
      },
    }
  }

  getCreateRequest(params) {
    var payload = { ...params.data }

    return {
      url: `${API_URL_BASE}/${this.getResourcePath(params)}`,
      options: {
        method: 'post',
        data: payload,
      },
    }
  }

  getDeleteRequest(params) {
    return {
      url: `${API_URL_BASE}/${this.getResourcePath(params)}/${params.id}`,
      options: {
        method: 'delete',
        body: {},
      },
    }
  }

  getReportRequest(params) {
    const reportQuery = {
      filter: params.filter,
      dimensions: params.dimensions,
    }

    return {
      url: `${API_URL_BASE}/report/${this.getResourcePath(params)}?${stringify(reportQuery, {
        arrayFormat: 'bracket',
      })}`,
    }
  }

  transformError(type, error) {
    const translationMethod = {
      [GET_LIST]: this.transformGetListError,
      [GET_ONE]: this.transformGetOneError,
      [GET_MANY]: this.transformGetManyError,
      [GET_MANY_REFERENCE]: this.transformGetManyReferenceError,
      [UPDATE]: this.transformUpdateError,
      [CREATE]: this.transformCreateError,
      [DELETE]: this.transformDeleteError,
      [REPORT]: this.transformGetReportError,
      [GET_RAW]: this.transformGetRawError,
    }[type]

    if (!translationMethod) {
      throw new Error(`Unsupported fetch action type ${type}`)
    }

    const requestError = new RequestError(error)

    return translationMethod(requestError)
  }

  transformCreateError(err) {
    return err
  }

  transformGetReportError(err) {
    return err
  }

  transformGetManyError(err) {
    return err
  }

  transformGetListError(err) {
    return err
  }

  transformGetOneError(err) {
    return err
  }

  transformGetRawError(err) {
    return err
  }

  transformGetManyReferenceError(err) {
    return err
  }

  transformUpdateError(err) {
    return err
  }

  transformDeleteError(err) {
    return err
  }
}
