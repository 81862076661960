import React from 'react'
import PropTypes from 'prop-types'

import { notifyInfo, notifyError } from '../SRONotification'
import { getRandomString } from '../../../utils/stringUtils'
import GreenCheckbox from '../GreenCheckbox'

export default class ActivityCheckbox extends React.Component {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      isActive: this.props.defaultIsActive,
      isSaving: false,
    })

    this.onToggleClick = this.onToggleClick.bind(this)
    this.updateIsActive = this.updateIsActive.bind(this)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      isActive: newProps.defaultIsActive,
    })
  }

  onToggleClick(e, newIsActive) {
    e.stopPropagation()

    if (this.props.disabled || this.state.isSaving) {
      return
    }

    this.setState(
      {
        isActive: newIsActive,
        isSaving: true,
      },
      this.updateIsActive,
    )
  }

  async updateIsActive() {
    try {
      await this.props.updateIsActive({
        itemId: this.props.itemId,
        isActive: this.state.isActive,
      })
      this.setState({ isSaving: false })
      notifyInfo(this.props.successMessageTemplate, { displayName: this.props.displayName })
    } catch (err) {
      notifyError(this.props.failureMessageTemplate, { displayName: this.props.displayName })
    }
  }

  render() {
    return (
      <GreenCheckbox
        key={getRandomString()}
        checked={this.state.isActive}
        onToggle={this.onToggleClick}
        disabledMessage={this.props.disabledMessage}
        disabled={this.props.disabled}
      />
    )
  }
}

ActivityCheckbox.propTypes = {
  defaultIsActive: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  successMessageTemplate: PropTypes.string,
  failureMessageTemplate: PropTypes.string,
  displayName: PropTypes.string,
  itemId: PropTypes.number,
  updateIsActive: PropTypes.func,
}

ActivityCheckbox.defaultProps = {
  disabled: false,
}
