import Action from './action'

export class AgentListRequestedAction extends Action {
  static NAME = 'agent--list-requested-action'
}

export class AgentListReceivedAction extends Action {
  static NAME = 'agent--list-received-action'

  static getExtraData(data) {
    return { data }
  }
}
