import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import HorizontalStack from '../widgets/charts/HorizontalStack'
import { messages } from '../../i18n/messages'
import PercentageInput from '../widgets/PercentageInput'
import SROButton from '../widgets/SROFormComponents/SROButton'
import Icon from '../icons'
import { STEP_3_ERROR } from '../../actions/settings'

import './client-rating-summary.scss'

const COLORS = [
  '#C8E790',
  '#5C99F9',
  '#F0CB42',
  'red',
  'purple',
  'cyan',
  'brown',
  'black',
  'yellow',
  'orange',
  'pink',
  'gray',
  'darkblue',
  'lightblue',
  'darkgreen',
  'lightgreen',
]

class GraphAgentStatLine extends React.Component {
  getOrderedColorStack() {
    return _(this.props.colorSet.order)
      .filter((ratingName) => !!this.props.values[ratingName])
      .map((ratingName) => ({
        percent: this.props.values[ratingName].percent,
        color: this.props.colorSet.mapping[ratingName],
        tooltip: `${ratingName}: ${this.props.values[ratingName].percent.toFixed(1)}% (${
          this.props.values[ratingName].absolute
        })`,
      }))
      .value()
  }

  render() {
    return (
      <div className="agent-stats-line">
        <div className="agent-name">{this.props.agentName}</div>
        <div className="agent-chart-value">
          <HorizontalStack
            widthPx={350}
            heightPx={20}
            orderedColorStack={this.getOrderedColorStack()}
          />
        </div>
      </div>
    )
  }
}

GraphAgentStatLine.propTypes = {
  colorSet: PropTypes.object,
  values: PropTypes.object,
  agentName: PropTypes.string,
}

class TableLine extends React.Component {
  constructor(props) {
    super(props)

    this.state = { ...this.state, totalVisits: this.calculateInitialTotalVisits() }

    this.inputRefs = []

    this.onPercentChange = this.onPercentChange.bind(this)
  }

  calculateInitialTotalVisits() {
    return _(this.props.lineData.rateVisits).values().sum()
  }

  serialize() {
    const keys = _.map(this.inputRefs, (inputRef) => inputRef.current.getName())
    const values = _.map(this.inputRefs, (inputRef) => inputRef.current.getAbsoluteValue())
    const data = {
      agentId: this.props.lineData.agentId,
      rateVisits: _.zipObject(keys, values),
    }

    return data
  }

  static getCols(ratingNames, instance) {
    const firstStaticCols = [
      {
        header: messages.AGENT_CODE,
        value: (lineData) => lineData.externalId,
      },
      {
        header: messages.AGENT_NAME,
        value: (lineData) => lineData.agentName,
      },
    ]

    const ratingCols = _.map(ratingNames, (rateName) => ({
      header: `${rateName}-Q${rateName}`,
      value: (lineData) => {
        const ref = React.createRef()
        instance.addInputRef(ref)
        const percentage = lineData.agentStats[rateName]
          ? Math.round(
              (lineData.rateVisits[rateName] / lineData.agentStats[rateName].absoluteVisits) * 100,
            )
          : 0
        const InputDOM = (
          <PercentageInput
            total={lineData.agentStats[rateName] ? lineData.agentStats[rateName].absoluteVisits : 0}
            key={rateName}
            defaultValue={percentage || 0}
            onChange={instance.onPercentChange}
            name={rateName}
            ref={ref}
          />
        )

        return InputDOM
      },
    }))

    const lastStaticCols = [
      {
        header: messages.AGENT_ACTUAL_TOTAL_VISITS,
        value: (lineData, state) => state.totalVisits,
        className: 'total',
      },
      {
        header: messages.AGENT_EXPECTED_VISITS,
        value: (lineData) => lineData.configuredMonthlyVisits,
      },
    ]

    return _.concat(firstStaticCols, ratingCols, lastStaticCols)
  }

  static getHeaders(ratingNames) {
    return _.map(this.getCols(ratingNames), (col) => col.header)
  }

  addInputRef(ref) {
    this.inputRefs.push(ref)
  }

  onPercentChange() {
    const totalVisits = _(this.inputRefs)
      .map((ref) => (ref.current ? ref.current.getAbsoluteValue() : 0))
      .sum()
    this.setState({
      totalVisits,
    })
  }

  render() {
    this.inputRefs = []
    const cols = this.constructor.getCols(this.props.ratingNames, this)
    return (
      <tr
        className={classnames({
          'has-error': this.state.totalVisits > this.props.lineData.configuredMonthlyVisits,
        })}
      >
        {_.map(cols, (col, index) => (
          <td key={index} className={col.className}>
            {col.value(this.props.lineData, this.state)}
          </td>
        ))}
      </tr>
    )
  }
}

TableLine.propTypes = {
  lineData: PropTypes.object,
  ratingNames: PropTypes.array,
}

class Legend extends React.Component {
  constructor(props) {
    super(props)

    this.renderLegendItem = this.renderLegendItem.bind(this)
  }

  renderLegendItem(label) {
    const color = this.props.colorSet.mapping[label]
    return (
      <div className="legend-item" key={label}>
        <div className="color-sample" style={{ backgroundColor: color }}></div>
        <div className="item-label">{label}</div>
      </div>
    )
  }

  render() {
    return <div className="legend">{_.map(this.props.colorSet.order, this.renderLegendItem)}</div>
  }
}

Legend.propTypes = {
  colorSet: PropTypes.object,
}

export default class ClientRatingSummary extends React.Component {
  /**
   * @param {object} props
   * @param {object} opts.agentSummary
   * @param {object} opts.ratings
   * @param {Function} opts.onClose
   * @param {Function} opts.onSave
   * @param {object} opts.step3status
   */
  constructor(props) {
    super(props)

    this.state = { ...this.state, errorMessage: '' }

    this.save = this.save.bind(this)
  }

  makeColorSet() {
    const ratingNames = _(this.props.agentSummary.agentRatingSummary)
      .map((agentData) => _.keys(agentData))
      .flatten()
      .uniq()
      .sortBy()
      .sortedUniq()
      .value()

    const mapping = _.zipObject(ratingNames, COLORS)
    const order = ratingNames

    return {
      mapping,
      order,
    }
  }

  renderGraphBox() {
    const { agentsById, agentRatingSummary } = this.props.agentSummary
    const colorSet = this.makeColorSet()

    return (
      <div className="graph-box">
        <div className="section-title">{messages.CLIENT_RATING}</div>
        <div className="legend">
          <Legend colorSet={colorSet} />
        </div>
        <div className="agent-list">
          {_.map(agentRatingSummary, (agentStats, agentId) => (
            <GraphAgentStatLine
              values={agentStats}
              agentName={agentsById[agentId].name}
              key={agentId}
              colorSet={colorSet}
            />
          ))}
        </div>
      </div>
    )
  }

  getRateVisits(agentId) {
    const rateVisits = this.props.agentSummary.rateVisits[agentId]
    return _.mapKeys(rateVisits, (_, rateId) => this.props.agentSummary.rateIdToName[rateId])
  }

  renderTable() {
    const ratingNames = _.map(this.props.ratings, (rating) => rating.name)
    this.lineRefs = []
    const lines = _.map(this.props.agentSummary.agentsById, (agent, agentId) => {
      const agentStats = this.props.agentSummary.agentRatingSummary[agentId] || {}
      const { numDailyVisits, workingHours } =
        this.props.agentSummary.agentAttributesByAgentId[agentId]
      const numWorkingDays = Object.values(workingHours).filter(
        (dailyWorkingHours) => dailyWorkingHours.length > 0,
      ).length
      const numMonthlyVisits = Math.round(numDailyVisits * numWorkingDays * (30 / 7))

      return {
        agentId,
        agentName: agent.name,
        externalId: agent.externalId,
        configuredMonthlyVisits: numMonthlyVisits,
        rateVisits: this.getRateVisits(agentId),
        agentStats,
        ref: this.addLineRef(),
      }
    })
    return (
      <div>
        <div className="section-title table-header">{messages.AGENT_RATE_PREFERENCES}</div>
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                {_.map(TableLine.getHeaders(ratingNames), (header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {_.map(lines, (lineData, index) => (
                <TableLine
                  agentSummary={this.props.agentSummary}
                  ratingNames={ratingNames}
                  lineData={lineData}
                  key={index}
                  ref={lineData.ref}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  addLineRef() {
    const ref = React.createRef()
    this.lineRefs.push(ref)
    return ref
  }

  serialize() {
    return _.map(this.lineRefs, (lineRef) => lineRef.current.serialize())
  }

  save() {
    try {
      this.props.onSave(this.serialize(), this.props.agentSummary.newUUIDs)
    } catch (err) {
      this.setState({
        errorMessage: err.message,
      })
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.step3status.status === STEP_3_ERROR.status) {
      this.setState({
        errorMessage: newProps.step3status.error,
      })
    }
  }

  renderFooter() {
    return [
      <div className="buttons-section" key="buttons-section">
        <SROButton className="save" onClick={this.save}>
          {messages.FINISH_SETUP}
        </SROButton>
        <SROButton className="sro-button-secondary back" onClick={this.props.onClose}>
          {messages.BACK}
        </SROButton>
        <div className="error-message">{this.state.errorMessage}</div>
      </div>,
      <Icon.x onClick={this.props.onClose} key="x" />,
    ]
  }

  render() {
    return (
      <div className="client-rating-summary">
        {this.renderGraphBox()}
        {this.renderTable()}
        {this.renderFooter()}
      </div>
    )
  }
}

ClientRatingSummary.propTypes = {
  agentSummary: PropTypes.object,
  onClose: PropTypes.func,
  ratings: PropTypes.array,
  onSave: PropTypes.func,
}
