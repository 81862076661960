import React from 'react'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'

import VisitTypeIcon from '../widgets/VisitTypeIcon'

const TravelRow = (props) => {
  const { data, rating } = props
  const translate = useTranslate()

  return (
    <tr>
      <td className="travel-col rating-name">
        {rating.name}
        {data.isPhone && <VisitTypeIcon className="visit-type-icon" visitType="phone" />}
      </td>
      <td className="travel-col">{data.clientName}</td>
      <td className="travel-col">{data.address}</td>
      <td className="travel-col">{rating.visitFrequencyDays}</td>
      <td className="travel-col">{data.visitDays}</td>
      <td className="travel-col">{data.visitPurpose || translate('PLANNED_VISIT')}</td>
    </tr>
  )
}

TravelRow.propTypes = {
  data: PropTypes.object,
  rating: PropTypes.object,
}

export default TravelRow
