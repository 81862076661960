import React, { useRef } from 'react'

import _ from 'lodash'
import { connect } from 'react-redux'
import { Button, Box } from '@material-ui/core'
import {
  DateField,
  Show,
  SimpleShowLayout,
  SimpleForm,
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  FunctionField,
  Toolbar,
  SaveButton,
  crudCreate,
  useTranslate,
  BooleanInput,
  SelectInput,
  TextInput,
} from 'react-admin'
import PropTypes from 'prop-types'
import SROFormTextInput from '../widgets/SROFormComponents/SROFormTextInput'

import { accountIdEntry } from '../../providers/localStorageProvider'
import { accountModeMap } from '../../providers/transformers/accountTransformer'
import Requester from '../../providers/request'
import SRODateField from '../widgets/SROFormComponents/SRODateField'
import ActivityCheckbox from '../widgets/ActivityCheckbox'
import EditPencilIcon from '../widgets/EditPencilIcon'
import { AccountFullResource } from '../../providers/resources/comboResources'
import SROSecretInput from '../widgets/SROFormComponents/SROSecretInput'
import { SROMoment } from '../../utils/timeUtils'
import { goToResource } from '../../utils/reactAdminUtils'

import ClientRatingFormView from './ClientRatingFormView'
import SROFormComponent from '../widgets/SROFormComponents/SROFormComponents'
import { notifyError } from '../widgets/SRONotification'
import SROTabSet from '../widgets/SROTabSet'
import SROListActions from '../widgets/SROFormComponents/SROListActions'

import './account.scss'
import '../widgets/SROFormComponents/sro-form-style.scss'
import '../sro-table.scss'

function renderModeField(record, source) {
  return accountModeMap[record[source]]
}

export const AccountList = (props) => {
  const translate = useTranslate()

  async function updateIsActive({ itemId, isActive }) {
    const requester = new Requester()
    await requester.update(AccountFullResource, { id: itemId, data: { isActive } })
  }

  function renderIsActiveCheckbox(record) {
    return (
      <div>
        <ActivityCheckbox
          itemId={record.id}
          displayName={record.name}
          updateIsActive={updateIsActive}
          defaultIsActive={record.isActive}
          successMessageTemplate={translate('ACCOUNT_UPDATE_SUCCESS')}
          failureMessageTemplate={translate('ACCOUNT_UPDATE_FAIL')}
        />
      </div>
    )
  }

  return (
    <div className="account-page main-page list-view sro-table">
      <h1 className="page-title float-left">{translate('ACCOUNTS')}</h1>
      <List
        {...props}
        bulkActionButtons={false}
        actions={<SROListActions createLabel="ADD_ACCOUNT" />}
      >
        <Datagrid>
          <TextField source="name" label="ACCOUNT_NAME" />
          <TextField source="id" label="ACCOUNT_ID" />
          <FunctionField source="isActive" label="ACTIVE" render={renderIsActiveCheckbox} />
          <TextField source="numAgents" label="AGENTS" />
          <FunctionField source="mode" label="sro.mode" render={renderModeField} />
          <SRODateField source="createdAt" label="CREATED" />
          <SRODateField source="updatedAt" label="UPDATED" />
          <FunctionField
            source="id"
            label=""
            sortable={false}
            render={(inputProps) => (
              <EditPencilIcon resource={AccountFullResource} id={inputProps.id} />
            )}
          />
        </Datagrid>
      </List>
    </div>
  )
}

const EditSideTable = (props) => {
  const { record } = props
  const translate = useTranslate()

  function formatDate(serverDate) {
    const sroDate = SROMoment.fromServerString(serverDate)

    if (!sroDate) {
      return null
    }

    return sroDate.formatDateShort()
  }

  return (
    <table className="sro-side-table">
      <tbody>
        <tr>
          <th>{translate('ACCOUNT_ID')}</th>
          <td>{record.id}</td>
        </tr>
        <tr>
          <th>{translate('API_KEY')}</th>
          <td className="api-key">
            <SROSecretInput source="apiKey" record={record} />
          </td>
        </tr>
        <tr>
          <th>{translate('CREATED')}</th>
          <td>{formatDate(record.createdAt)}</td>
        </tr>
        <tr>
          <th>{translate('UPDATED')}</th>
          <td>{formatDate(record.updatedAt)}</td>
        </tr>
        <tr>
          <th>{translate('ACTIVE')}</th>
          <td>
            <BooleanInput label="" source="isActive" />
          </td>
        </tr>
        <tr>
          <th>{translate('sro.mode')}</th>
          <td>
            <SelectInput
              label=""
              source="mode"
              choices={Object.keys(accountModeMap).map((key) => ({
                id: key,
                name: accountModeMap[key],
              }))}
            />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

EditSideTable.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    mode: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
}

const AccountPage = (props) => {
  const translate = useTranslate()
  return (
    <div className="account-form main-page">
      <div className="back-from-edit-wrapper">
        <h1 className="page-title float-left">{translate('CREATE_ACCOUNT')}</h1>
        <Button className="link-style-button" onClick={() => goToResource(AccountFullResource)}>
          {translate('BACK_TO_ACCOUNTS')}
        </Button>
      </div>
      {props.children}
    </div>
  )
}

AccountPage.propTypes = {
  children: PropTypes.node,
}

const MainEditForm = (props) => {
  const { record } = props

  return (
    <table>
      <tbody>
        <tr>
          <td className="main-edit-form">
            <SROFormTextInput source="name" label="ACCOUNT_NAME" record={record} />
            <SROFormTextInput source="email" label="EMAIL" record={record} />
          </td>
          <td>
            <EditSideTable record={record} />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

MainEditForm.propTypes = {
  record: PropTypes.object,
}

class ClientRatingFormViewWrapper extends SROFormComponent {
  constructor(props) {
    super(props)

    this.references = { ratingForm: React.createRef() }

    this.getFormElem = this.getFormElem.bind(this)
    this.serialize = this.serialize.bind(this)
  }

  getFormElem() {
    return this.references.ratingForm.current
  }

  serialize() {
    return this.getFormElem().serialize()
  }

  render() {
    return (
      <ClientRatingFormView
        ref={this.references.ratingForm}
        accountData={this.props.record}
        notifyError={notifyError}
        save={this.save}
        accountId={this.props.record.id}
      />
    )
  }
}

const EditToolbarView = (props) => {
  const { basePath, handleSubmit, redirect, saveAll, getRating } = props

  function handleClick() {
    return handleSubmit((values) => {
      const updatedValues = { ...values, clientRating: getRating() }
      saveAll(updatedValues, basePath, redirect)
    })
  }

  return (
    <Toolbar {..._.omit(props, ['saveAll', 'getRating'])}>
      <SaveButton className="sro-button" handleSubmitWithRedirect={handleClick} />
    </Toolbar>
  )
}

EditToolbarView.propTypes = {
  basePath: PropTypes.string,
  handleSubmit: PropTypes.func,
  redirect: PropTypes.string,
  saveAll: PropTypes.func,
  getRating: PropTypes.func,
}

const EditToolbar = connect(undefined, {
  saveAll: (values, basePath, redirectTo) =>
    crudCreate(AccountFullResource.RESOURCE_NAME, { ...values }, basePath, redirectTo),
})(EditToolbarView)

export const AccountEdit = (props) => {
  const rating = useRef(null)
  const translate = useTranslate()

  const ClientRatingFormViewWrapperWithRef = (props) => (
    <ClientRatingFormViewWrapper {...props} ref={rating} />
  )
  return (
    <AccountPage>
      <Edit undoable={false} {...props} className="sro-form">
        <SimpleForm
          variant="standard"
          toolbar={<EditToolbar getRating={() => rating.current.serialize()} />}
        >
          <SROTabSet
            tabs={[
              {
                label: translate('EDIT_ACCOUNT'),
                Content: MainEditForm,
              },
              {
                label: translate('CLIENT_RATING'),
                Content: ClientRatingFormViewWrapperWithRef,
              },
            ]}
          />
        </SimpleForm>
      </Edit>
    </AccountPage>
  )
}

export const AccountCreate = (props) => (
  <AccountPage>
    <Create {...props} className="sro-form create-account">
      <SimpleForm variant="standard">
        <Box maxWidth="256px">
          <TextInput source="name" label="ACCOUNT_NAME" fullWidth />
          <TextInput source="email" type="email" label="EMAIL" fullWidth />
          <BooleanInput source="isActive" label="ACTIVE" />
        </Box>
      </SimpleForm>
    </Create>
  </AccountPage>
)

export const AccountShow = (props) => {
  const { record } = props
  const translate = useTranslate()

  return (
    <div className="main-page">
      <h1 className="page-title">{translate('ACCOUNT_DETAILS')}</h1>
      <Show {...props} id={accountIdEntry.get()}>
        <SimpleShowLayout>
          <TextField source="name" label="ACCOUNT_NAME" />
          <TextField source="email" label="EMAIL" />
          <TextField source="id" label="ACCOUNT_ID" />
          <SROSecretInput source="apiKey" label="API_KEY" record={record} />
          <FunctionField source="mode" label="sro.mode" render={renderModeField} />
          <DateField source="createdAt" label="CREATED" />
          <DateField source="updatedAt" label="UPDATED" />
        </SimpleShowLayout>
      </Show>
    </div>
  )
}

AccountShow.propTypes = {
  record: PropTypes.object,
}
