import { combineReducers } from 'redux'

import {
  AgentTeamListRequestedAction,
  AgentTeamListReceivedAction,
} from '../actions/agentTeamListActions'

function list(state = null, action) {
  switch (action.type) {
    case AgentTeamListRequestedAction.NAME: {
      return null
    }
    case AgentTeamListReceivedAction.NAME: {
      return action.data
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  list,
})
