import _ from 'lodash'

import { messages, fill } from '../i18n/messages'

export default class FormValidator {
  constructor(schema) {
    this.schema = schema
  }

  isValidEmail(email) {
    // Source: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    var re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  validate(data) {
    const errorAggregator = new ErrorAggregator()

    _.forIn(this.schema, (fieldConf, fieldPath) => {
      const value = _.get(data, fieldPath)
      const title = fieldConf.title
      const addError = (message) => errorAggregator.addError(fieldConf.refName, message)

      if (
        (fieldConf.required && !value && value !== 0) ||
        (fieldConf.customRequired && !fieldConf.customRequired(value))
      ) {
        addError(fill(messages.KEY_IS_MANDATORY, { title }))
      }

      if (fieldConf.maxLength && value.length > fieldConf.maxLength) {
        addError(fill(messages.ERROR_OVER_MAX_LENGTH, { title, max: fieldConf.maxLength }))
      }

      if (typeof fieldConf.gt !== 'undefined' && value <= fieldConf.gt) {
        addError(fill(messages.ERROR_VALUE_MUST_BE_GT, { title, gt: fieldConf.gt }))
      }

      if (typeof fieldConf.gte !== 'undefined' && value < fieldConf.gte) {
        addError(fill(messages.ERROR_VALUE_MUST_BE_GTE, { title, gte: fieldConf.gte }))
      }

      if (typeof fieldConf.lte !== 'undefined' && value > fieldConf.lte) {
        addError(fill(messages.ERROR_VALUE_MUST_BE_LTE, { title, lte: fieldConf.lte }))
      }

      if (fieldConf.customValidation) {
        fieldConf.customValidation(data)
      }

      if (fieldConf.validEmail && value && !this.isValidEmail(value)) {
        addError(fill(messages.ERROR_INVALID_EMAIL, { title }))
      }
    })

    errorAggregator.validate()
  }
}

class ErrorAggregator {
  constructor() {
    this.errorMessage = null
    this.badFields = []
  }

  addError(field, message) {
    this.errorMessage = this.errorMessage || message
    this.badFields.push(field)
  }

  validate() {
    if (this.badFields.length > 0) {
      throw new FormError(this.errorMessage, this.badFields)
    }
  }
}

export class FormError extends Error {
  constructor(message, badFields) {
    super(message)
    this.badFields = badFields
  }
}
