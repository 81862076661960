import React from 'react'

import _ from 'lodash'

import { messages } from '../../../i18n/messages'
import { SimpleCollection } from '../../widgets/SimpleCollection'
import Icons from '../../icons'
import SROFormComponent from '../../widgets/SROFormComponents/SROFormComponents'
import RatingLine from './RatingLine'

import './client-rating-form.scss'
import '../../widgets/SROFormComponents/sro-form-style.scss'

export default class ClientRatingFormView extends SROFormComponent {
  constructor(props) {
    super(props)

    this.references = {
      ratings: React.createRef(),
    }

    this.serialize = this.serialize.bind(this)
    this.save = this.save.bind(this)
    this.addRating = this.addRating.bind(this)
  }

  translateProps(props) {
    const features = _.get(props, 'accountData.accountOptions.data.features')
    const ops = _.get(props, 'accountData.accountOptions.data.ops')

    return {
      ratings: props.accountData.clientRating,
      features: _.map(features, (value) => ({
        id: value,
        name: value,
      })),
      ops: _.map(ops, (op) => ({
        id: op.name,
        name: op.symbol,
      })),
      opsValidation: _(ops)
        .keyBy('name')
        .map((op) => op.value)
        .value(),
    }
  }

  renderRatings() {
    const translatedProps = this.translateProps(this.props)

    if (translatedProps.ratings === null) {
      return undefined
    }
    const forwardProps = _.pick(translatedProps, ['features', 'ops', 'opsValidation'])

    return (
      <SimpleCollection
        defaultItemList={translatedProps.ratings}
        renderItem={(rating) => <RatingLine {...rating} {...forwardProps} />}
        ref={this.references.ratings}
      />
    )
  }

  serialize(newUUIDs) {
    const ratings = _.map(this.references.ratings.current.serialize(), (rating) => ({
      ...rating,
      id: _.includes(newUUIDs, rating.id) ? undefined : rating.id,
    }))

    if (!this.isValid(ratings)) {
      throw new Error(messages.AT_LEAST_ONE_RATING_REQUIRED)
    }

    return ratings
  }

  isValid(data) {
    return data.length > 0
  }

  save(modalData, newUUIDs) {
    let clientRating
    try {
      clientRating = this.serialize(newUUIDs)
    } catch (err) {
      this.props.notifyError(err.message)
      return
    }

    this.props.save({
      newUUIDs,
      accountPatchData: { clientRating, id: this.props.accountId },
      modalData,
    })
  }

  addRating() {
    this.references.ratings.current.addItem({})
  }

  render() {
    return (
      <div className="client-rating-form sro-form">
        <div>{messages.CLIENT_RATING}</div>
        <div className="rating-box">
          <div className="rating-list">{this.renderRatings()}</div>
          <div className="global-buttons">
            <div className="add-rating" onClick={this.addRating}>
              <Icons.plusInCircle />
              <span>{messages.ADD_RATING}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
