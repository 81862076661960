export const CREATE_ACTIVITY_FORM_CLOSE = 'CREATE_ACTIVITY_FORM_CLOSE'
export const createActivityFormClose = () => {
  return {
    type: CREATE_ACTIVITY_FORM_CLOSE,
  }
}

export const CREATE_ACTIVITY_FORM_OPEN = 'CREATE_ACTIVITY_FORM_OPEN'
export const createActivityFormOpen = (agentName, externalAgentId, agentId, callId) => ({
  type: CREATE_ACTIVITY_FORM_OPEN,
  agentName,
  externalAgentId,
  agentId,
  callId,
})

export const CREATE_ACTIVITY_FORM_REQUESTED = 'CREATE_ACTIVITY_FORM_REQUESTED'
export const createActivityFormRequested = (data) => ({
  type: CREATE_ACTIVITY_FORM_REQUESTED,
  data,
})

export const CREATE_ACTIVITY_FORM_FAILURE = 'CREATE_ACTIVITY_FORM_FAILURE'
export const createActivityFormFailure = (error) => ({
  type: CREATE_ACTIVITY_FORM_FAILURE,
  error,
})

export const CREATE_ACTIVITY_FORM_LOADING = 'CREATE_ACTIVITY_FORM_LOADING'
export const createActivityFormLoading = (loading = false) => ({
  type: CREATE_ACTIVITY_FORM_LOADING,
  loading,
})

export const CREATE_ACTIVITY_FORM_ACTION_TYPES = [
  CREATE_ACTIVITY_FORM_CLOSE,
  CREATE_ACTIVITY_FORM_OPEN,
  CREATE_ACTIVITY_FORM_REQUESTED,
  CREATE_ACTIVITY_FORM_FAILURE,
  CREATE_ACTIVITY_FORM_LOADING,
]

export const REQUEST_AGENT_CLIENT_LIST = 'REQUEST_AGENT_CLIENT_LIST'
export const requestAgentClientList = (params) => ({
  type: REQUEST_AGENT_CLIENT_LIST,
  params,
})

export const RECEIVE_AGENT_CLIENT_LIST = 'RECEIVE_AGENT_CLIENT_LIST'
export const receiveAgentClientList = (data) => ({
  type: RECEIVE_AGENT_CLIENT_LIST,
  data,
})
