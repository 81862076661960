import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import { useTranslate } from 'react-admin'
import { Button, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import PropTypes from 'prop-types'

import SROInput from '../../widgets/SROFormComponents/SROInput'
import { getUUID } from '../../../utils/stringUtils'
import icons from '../../icons'
import DateRangeInput from '../../widgets/DateRangeInput'
import { SROMoment } from '../../../utils/timeUtils'
import { messages } from '../../../i18n/messages'
import { accountIdEntry } from '../../../providers/localStorageProvider'

import './create-activity-modal-view.scss'
import '../../widgets/SROFormComponents/sro-form-style.scss'

const DEFAULT_DAYS_FROM_NOW = 8

class FormContent extends React.Component {
  constructor(props) {
    super(props)

    this.references = {
      visitPurpose: React.createRef(),
      visitRemark: React.createRef(),
    }

    this.serialize = this.serialize.bind(this)
  }

  serialize() {
    return {
      visitPurpose: this.references.visitPurpose.current.getValue(),
      visitRemark: this.references.visitRemark.current.getValue(),
      scheduleTime: this.props.scheduleTime,
    }
  }

  render() {
    const props = _.omit(this.props, [
      'onScheduleTimeChange',
      'onClientChange',
      'scheduleTime',
      'allClients',
      'clientList',
    ])

    return (
      <table className="create-activity-modal-table">
        <tbody>
          <tr>
            <th>{messages.CLIENT}</th>
          </tr>
          <tr>
            <td colSpan={2}>
              <Autocomplete
                options={this.props.clientList}
                getOptionLabel={(option) => option.name}
                disableClearable={true}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
                onChange={(_, value) => value && this.props.onClientChange(value)}
              />
            </td>
          </tr>
          <tr>
            <th>{messages.VISIT_PURPOSE}</th>
            <th>{messages.DATE}</th>
          </tr>
          <tr>
            <td>
              <SROInput ref={this.references.visitPurpose} source="visitPurpose" {...props} />
            </td>
            <td>
              <DateRangeInput
                selectSingle={true}
                onChange={this.props.onScheduleTimeChange}
                from={this.props.scheduleTime}
                className="left-item"
                {...props}
              />
            </td>
          </tr>
          <tr>
            <th>{messages.VISIT_REMARK}</th>
          </tr>
          <tr>
            <td colSpan={2}>
              <SROInput
                ref={this.references.visitRemark}
                source="visitRemark"
                className="visit-remark sro-form-input"
                {...props}
                multiline
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}

FormContent.propTypes = {
  onScheduleTimeChange: PropTypes.func,
  scheduleTime: PropTypes.object,
  clientList: PropTypes.array,
  onClientChange: PropTypes.func,
}

const CreateActivityModalView = (props) => {
  const { formState, save, cancel } = props

  const translate = useTranslate()

  const [scheduleTime, setScheduleTime] = useState(
    SROMoment.now().add(DEFAULT_DAYS_FROM_NOW, 'days'),
  )
  const [externalClientId, setExternalClientId] = useState(null)

  const references = { form: useRef() }

  const form = _.get(references, 'form.current')

  function createActivity() {
    const formData = form.serialize()
    const requestData = {
      ...formData,
      isNew: true,
      externalAgentId: formState.agent.externalId,
      externalClientId,
      activityId: `sro-${getUUID()}`,
      scheduleTime: scheduleTime,
      isClosed: false,
      isCanceled: false,
      startTime: formData.scheduleTime,
      endTime: formData.scheduleTime,
      cancelReason: '',
      rescheduleReason: '',
      accountId: accountIdEntry.get(),
    }
    save(requestData)
  }

  useEffect(() => {
    form && form.reset && form.reset()
  }, [formState, form])

  if (!formState.shown || !formState.agentClientList) {
    return <div />
  }

  // Fixme: Wait for updates re this bug which throws error to the console when the window closes:
  // https://github.com/marmelab/react-admin/issues/3356
  return (
    <div className="activity-wrapper">
      <div className="activity-hover-modal">
        <div className="activity-agent-name">
          {translate('CREATE_NEW_ACTIVITY_FOR_AGENT_X', {
            agentName: formState.agent.name,
          })}
        </div>
        <div>
          <icons.x className="x-close-button" onClick={cancel} />
          <FormContent
            ref={references.form}
            onScheduleTimeChange={(newValue) => setScheduleTime(newValue)}
            onClientChange={(newClient) => setExternalClientId(newClient.externalId)}
            scheduleTime={scheduleTime}
            clientList={formState.agentClientList}
          />
        </div>
        <div className="bottom-elements">
          <Button
            onClick={createActivity}
            className="activity-button-save"
            disabled={formState.loading}
          >
            {formState.loading ? `${translate('LOADING')}...` : translate('CREATE_ACTIVITY')}
          </Button>
          <Button onClick={cancel} className="activity-button-cancel">
            {translate('CANCEL')}
          </Button>
        </div>
      </div>
    </div>
  )
}

CreateActivityModalView.propTypes = {
  formState: PropTypes.object,
  save: PropTypes.func,
  cancel: PropTypes.func,
}

export default CreateActivityModalView
