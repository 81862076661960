import React from 'react'
import _ from 'lodash'

import './daily-activity-view.scss'

class LoadingPlaceholder extends React.Component {
  generateSummaryBox(boxId) {
    return (
      <div className="shadow-summary-box" key={boxId}>
        <div className="line-box">
          {_.map(_.range(4), (id) => (
            <div className="shadow-summary-line" key={id}>
              &nbsp;
            </div>
          ))}
        </div>
        <div className="shadow-summary-circle">&nbsp;</div>
      </div>
    )
  }

  generateAgentBox(boxId) {
    return (
      <div className="shadow-agent-box" key={boxId}>
        <div className="shadow-agent-picture">&nbsp;</div>
        <div className="shadow-agent-title">&nbsp;</div>
        <div className="shadow-agent-line line-1">&nbsp;</div>
        <div className="shadow-agent-line line-2">&nbsp;</div>
        <div className="shadow-agent-line line-3">&nbsp;</div>
      </div>
    )
  }

  render() {
    return (
      <div className="daily-activity-page daily-activity-placeholder page">
        <div className="summary-boxes">{_.map(_.range(3), this.generateSummaryBox)}</div>
        <div className="agent-boxes">{_.map(_.range(3), this.generateAgentBox)}</div>
      </div>
    )
  }
}

export default LoadingPlaceholder
