import _ from 'lodash'
import { useQuery } from 'react-admin'

import { ActivityCalendarResource, AccountResource } from '../providers/resources/requestResources'

export function useActivityCalendarData(dateRange) {
  const { data: activities, loading: loadingActivities } = useQuery({
    type: 'GET_RAW',
    resource: ActivityCalendarResource.RESOURCE_NAME,
    payload: {
      from: dateRange.from.formatDate(),
      to: dateRange.to.formatDate(),
    },
  })

  const { data: accountData, loading: loadingAccount } = useQuery({
    type: 'GET_LIST',
    resource: AccountResource.RESOURCE_NAME,
  })

  if (loadingActivities || loadingAccount) {
    return { loading: true }
  }

  const rateByIdByAccountId = _(accountData)
    .keyBy('id')
    .mapValues((account) => _.keyBy(account.clientRating, 'id'))
    .value()

  return {
    activities,
    rateByIdByAccountId,
    loading: false,
  }
}
