import React from 'react'
import PropTypes from 'prop-types'

import SROTooltip from '../../SROTooltip'

const makePx = (value) => `${value}px`

export default class HorizontalStack extends React.Component {
  renderBricks() {
    const percentPixels = this.props.widthPx / 100

    const bricks = []
    let offsetPx = 0

    for (const brickData of this.props.orderedColorStack) {
      const width = brickData.percent * percentPixels
      const style = {
        width: makePx(width),
        height: makePx(this.props.heightPx),
        position: 'absolute',
        top: 0,
        left: makePx(offsetPx),
        backgroundColor: brickData.color,
      }

      bricks.push(
        <SROTooltip
          text={brickData.tooltip}
          key={offsetPx}
          style={style}
          position="right"
        ></SROTooltip>,
      )
      offsetPx += width
    }
    return bricks
  }

  render() {
    return (
      <div
        style={{
          width: makePx(this.props.widthPx),
          height: makePx(this.props.heightPx),
          position: 'relative',
        }}
      >
        {this.renderBricks()}
      </div>
    )
  }
}

HorizontalStack.propTypes = {
  widthPx: PropTypes.number,
  heightPx: PropTypes.number,
  orderedColorStack: PropTypes.array,
}
