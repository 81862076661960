import _ from 'lodash'

export class Pie {
  constructor(radius, center, thickness) {
    this.radius = radius
    this.center = center
    this.thickness = thickness

    this.angleToCoords = this.angleToCoords.bind(this)
    this.getSliceAngleToCoords = this.getSliceAngleToCoords.bind(this)
  }

  angleToCoords(deg, radius) {
    const rad = (90 - deg) * (Math.PI / 180)
    return {
      x: this.center - Math.cos(rad) * radius,
      y: this.center - Math.sin(rad) * radius,
    }
  }

  getSliceAngleToCoords(deg) {
    const extraRadius = this.radius + 1
    const coords = this.angleToCoords(deg, extraRadius)
    return `${coords.x},${coords.y}`
  }

  percToAngle(perc) {
    return ((100 - perc) * 360) / 100
  }

  makeSlicePoints(perc) {
    const lastAngle = this.percToAngle(perc)
    const angles = _.concat(_.range(0, lastAngle, 1), [lastAngle])
    const circumferenceCoords = _.map(angles, this.getSliceAngleToCoords)
    const allCoords = _.concat([`${this.center},${this.center}`], circumferenceCoords)
    return _.join(allCoords, ' ')
  }

  getCoordsByPerc(perc) {
    return this.angleToCoords(this.percToAngle(perc), this.radius - this.thickness / 2)
  }
}
