import React from 'react'
import { useTranslate } from 'react-admin'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Icon from '../../icons'
import SROTooltip from '../SROTooltip'
import { useGoToResource } from '../../../utils/reactAdminUtils'

import './edit-pencil-icon.scss'

const EditPencilIcon = ({ resource, id, disabled }) => {
  const translate = useTranslate()
  const goToResource = useGoToResource()

  return (
    <SROTooltip text={disabled ? 'disabled' : 'EDIT'}>
      <div
        className={classnames('edit-pencil-icon')}
        onClick={() => {
          if (!disabled) {
            goToResource(resource, id)
          }
        }}
      >
        <Icon.editPencil tooltip={translate('EDIT')} />
      </div>
    </SROTooltip>
  )
}

EditPencilIcon.propTypes = {
  id: PropTypes.number,
  resource: PropTypes.func,
  disabled: PropTypes.bool,
}

EditPencilIcon.defaultProps = {
  disabled: false,
}

export default EditPencilIcon
