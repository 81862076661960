import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'

import { TextInput, useTranslate } from 'react-admin'

import './sro-form-style.scss'

const SROFormTextInput = (props) => {
  const { className, label } = props
  const translate = useTranslate()

  return (
    <div className={classnames('sro-form-input', className)}>
      <div className="upper-title">{translate(label)}</div>
      <TextInput {...props} label="" />
    </div>
  )
}

SROFormTextInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
}

export default SROFormTextInput
