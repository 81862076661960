export function toPrecision(number, precision) {
  return parseFloat(number.toFixed(precision))
}

export function percentage(nominator, denominator, max = undefined) {
  const percentage = Math.round((100 * nominator) / denominator) || 0

  if (!max) {
    return percentage
  }

  return Math.min(percentage, max)
}
