import React, { useRef } from 'react'

import _ from 'lodash'
import jsonExport from 'jsonexport/dist'
import {
  List,
  Datagrid,
  NumberField,
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  Filter,
  FunctionField,
  Toolbar,
  SaveButton,
  number,
  minValue,
  useTranslate,
  downloadCSV,
  NumberInput,
  ReferenceField,
  TextField,
  SelectInput,
  ReferenceInput,
  useEditController,
} from 'react-admin'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { messages } from '../../i18n/messages'
import Requester from '../../providers/request'
import {
  AgentResource,
  AgentStatResource,
  AgentTeamResource,
} from '../../providers/resources/requestResources'
import { AgentFullResource } from '../../providers/resources/comboResources'
import { accountIdEntry } from '../../providers/localStorageProvider'
import { hasAtLeastRole, ROLE_ACCOUNT_MANAGER } from '../../providers/roles'
import { goToResource, getUrlEntity } from '../../utils/reactAdminUtils'

import EditPencilIcon from '../widgets/EditPencilIcon'
import { notifyInfo } from '../widgets/SRONotification'
import WorkHoursFormPicker from '../widgets/WorkHoursPicker/WorkHoursFormPicker'
import GeoFormPicker from '../widgets/SROFormComponents/GeoFormPicker'
import SROButton from '../widgets/SROFormComponents/SROButton'
import ActivityCheckbox from '../widgets/ActivityCheckbox'
import ClientRatingSummary from '../ClientRatingSummary'
import { MONTH_CODE_LIST } from '../../utils/timeUtils'

import './agent.scss'
import '../sro-table.scss'
import '../widgets/SROFormComponents/sro-form-style.scss'

function AgentFilter(props) {
  return (
    <Filter {...props} className="sro-form">
      <TextInput className="sro-filter" label="AGENT_NAME" source="_q_name" alwaysOn />
      <TextInput className="sro-filter" label="AGENT_CODE" source="_q_externalId" alwaysOn />
    </Filter>
  )
}

function IsActiveCheckbox({ record }) {
  const translate = useTranslate()

  return (
    <ActivityCheckbox
      itemId={record.id}
      displayName={record.name}
      updateIsActive={async ({ itemId, isActive }) => {
        const requester = new Requester()
        await requester.update(AgentResource, { id: itemId, data: { isActive } })
      }}
      defaultIsActive={record.isActive}
      successMessageTemplate={translate('AGENT_UPDATE_SUCCESS')}
      failureMessageTemplate={translate('AGENT_UPDATE_FAIL')}
    />
  )
}

IsActiveCheckbox.propTypes = {
  record: PropTypes.object,
}

export const AgentList = (props) => {
  const translate = useTranslate()

  async function exporter(agents, fetchRelatedRecords) {
    const stats = await fetchRelatedRecords(agents, 'id', AgentStatResource.RESOURCE_NAME)
    const boolTranslateMap = {
      true: translate('yes'),
      false: translate('no'),
    }
    const agentsForExport = agents.map((agent) => {
      const agentForExport = _.pick(agent, ['externalId', 'name', 'isActive'])
      agentForExport.numClients = _.get(stats[agent.id], 'numClients', 0)
      agentForExport.isActive = boolTranslateMap[agentForExport.isActive]
      return agentForExport
    })
    jsonExport(
      agentsForExport,
      {
        headers: ['externalId', 'name', 'numClients', 'isActive'],
        rename: ['AGENT_CODE', 'AGENT_NAME', 'NUMBER_OF_CLIENTS', 'ACTIVE'].map((key) =>
          translate(key),
        ),
      },
      (err, csv) => {
        downloadCSV(`\ufeff${csv}`, 'agents')
      },
    )
  }

  const EditIcon = (props) => {
    return <EditPencilIcon resource={AgentFullResource} id={props.id} disabled={!props.isActive} />
  }

  EditIcon.propTypes = {
    id: PropTypes.number,
    isActive: PropTypes.bool,
  }

  const hasEditField = hasAtLeastRole(ROLE_ACCOUNT_MANAGER)

  return (
    <div className="agents-page main-page list-view">
      <h1 className="page-title float-left">{translate('AGENTS')}</h1>
      <List
        bulkActionButtons={false}
        {...props}
        filters={<AgentFilter />}
        className="agent-list sro-table"
        exporter={exporter}
      >
        <Datagrid>
          <NumberField source="externalId" label="AGENT_CODE" />
          <NumberField source="name" label="AGENT_NAME" />
          <FunctionField
            source="isActive"
            label="ACTIVE"
            render={(record) => <IsActiveCheckbox record={record} />}
          />
          <NumberField source="numClients" label="NUMBER_OF_CLIENTS" />
          <ReferenceField
            label="AGENT_TEAM"
            source="agentTeamId"
            reference={AgentTeamResource.RESOURCE_NAME}
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          {hasEditField && (
            <FunctionField source="id" label="" sortable={false} render={EditIcon} />
          )}
        </Datagrid>
      </List>
    </div>
  )
}

function cleanSimulation(record) {
  if (!record.simulation) {
    return record
  }

  const newRecord = _.cloneDeep(record)

  _.set(newRecord, 'simulation.agentsById', {
    [record.id]: _.get(newRecord, 'simulation.agentsById')[record.id],
  })

  _.set(newRecord, 'simulation.agentRatingSummary', {
    [record.id]: _.get(newRecord, 'simulation.agentRatingSummary')[record.id],
  })

  return newRecord
}

const RatingTab = React.forwardRef((props, ref) => {
  const { save } = props

  const record = cleanSimulation(props.record)

  if (!_.get(record, 'simulation')) {
    return null
  }

  return (
    <div>
      <ClientRatingSummary
        ref={ref}
        agentSummary={record.simulation}
        ratings={record.simulation.clientRating}
        onClose={() => {}}
        onSave={save}
        step3status={{}}
      />
    </div>
  )
})

RatingTab.displayName = 'RatingTab'
RatingTab.propTypes = {
  record: PropTypes.object,
  save: PropTypes.func,
}

const MainContent = (props) => {
  const translate = useTranslate()

  return (
    <table className="sro-form-wrapper">
      <tbody>
        <tr>
          <td>
            <ReferenceInput
              label="AGENT_TEAM"
              source="agentTeamId"
              reference={AgentTeamResource.RESOURCE_NAME}
              allowEmpty
              emptyText="no_team"
              fullWidth
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <WorkHoursFormPicker
              source="attributes.workingHoursStringified"
              label="WORK_HOURS"
              {...props}
            />
            <GeoFormPicker
              source="attributes.startLocationStringified"
              className="start-picker"
              label="S_ADDRESS"
              {...props}
            />
            <GeoFormPicker
              source="attributes.endLocationStringified"
              className="end-picker"
              label="E_ADDRESS"
              {...props}
            />
            <NumberInput
              source="attributes.visitTimeMinutes"
              label="VISIT_TIME"
              validate={[number(), minValue(1)]}
              {...props}
            />
            <NumberInput
              source="attributes.numDailyVisits"
              label="sro.daily_visits"
              validate={[number(), minValue(1)]}
              {...props}
            />
            <NumberInput
              source="attributes.maxDailyPhoneCalls"
              label="sro.max_daily_phone_calls"
              validate={[number(), minValue(1)]}
              {...props}
            />
            <TextInput source="email" label="EMAIL" type="email" {...props} />
          </td>
          <td>
            <table className="sro-side-table">
              <tbody>
                <tr>
                  <th>{translate('AGENT_CODE')}</th>
                  <td>{props.record.externalId}</td>
                </tr>
                <tr>
                  <th>{translate('AGENT_NAME')}</th>
                  <td>{props.record.name}</td>
                </tr>
              </tbody>
            </table>
            {renderAgentObjectiveTable(props)}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

MainContent.propTypes = {
  record: PropTypes.object,
}

const renderAgentObjectiveTable = (props) => {
  return (
    <table className={'agent-objective-table'}>
      <thead>
        <tr>
          <th>{messages['MONTH']}</th>
          <th>{messages['OBJECTIVE_THIS_YEAR']}</th>
          <th>{messages['OBJECTIVE_LAST_YEAR']}</th>
        </tr>
      </thead>
      <tbody>
        {_.map(MONTH_CODE_LIST, (monthCode, monthId) => renderMonthLine(monthCode, monthId, props))}
      </tbody>
    </table>
  )
}

const renderMonthLine = (monthCode, monthId, props) => {
  const monthNum = monthId + 1
  return (
    <tr key={monthCode}>
      <td>{messages[monthCode]}</td>
      <td>
        <NumberInput
          source={`attributes.currentMonthlySalesTarget.m${monthNum}`}
          label={'empty'}
          validate={[number(), minValue(1)]}
          {...props}
        />
      </td>
      <td>{_.get(props, `record.attributes.formerMonthlySalesTarget.m${monthNum}`)}</td>
    </tr>
  )
}

const ClientRatingSaveButton = (props) => {
  const translate = useTranslate()

  return (
    <SROButton className="sro-button save-rating-table" onClick={props.onClick}>
      {translate('SAVE')}
    </SROButton>
  )
}

ClientRatingSaveButton.propTypes = {
  onClick: PropTypes.func,
}

export function AgentEditView(props) {
  const { loading } = useEditController(props)

  const { save } = props
  const CLIENT_RATING_TAB_ID = '1'

  const translate = useTranslate()
  const ratingTabRef = useRef()

  function onSaveCall(data) {
    notifyInfo(translate('SAVING_PLEASE_WAIT'), {})
    save({
      accountPatchData: {
        id: accountIdEntry.get(),
        data: {},
      },
      modalData: data,
      onDone: async () => {
        notifyInfo(translate('AGENT_UPDATE_SUCCESS'), { displayName: _.get(data, '[0].agentId') })
        goToResource(AgentFullResource)
      },
    })
  }

  const EmptyContent = () => <div></div>

  const EditToolbar = (props) => {
    const isOnClientRatingTab = getUrlEntity().tabId === CLIENT_RATING_TAB_ID

    return (
      <Toolbar {...props}>
        {isOnClientRatingTab ? <EmptyContent /> : <SaveButton className="sro-button" />}
      </Toolbar>
    )
  }

  return (
    <div className="agent-edit-wrapper sro-form">
      <h1 className="page-title float-left">{translate('EDIT_AGENT')}</h1>
      <div className="back-from-edit-wrapper">
        <Button className="link-style-button" onClick={() => goToResource(AgentFullResource)}>
          {translate('BACK_TO_AGENTS')}
        </Button>
      </div>
      {!loading && (
        <Edit undoable={false} {...props}>
          <TabbedForm toolbar={<EditToolbar />} className="sro-tab">
            <FormTab label="EDIT_AGENT" className="edit-section">
              <MainContent />
            </FormTab>
            <FormTab label="CLIENT_RATING">
              <ClientRatingSaveButton onClick={() => ratingTabRef.current.save()} />
              <RatingTab ref={ratingTabRef} save={onSaveCall} />
            </FormTab>
          </TabbedForm>
        </Edit>
      )}
    </div>
  )
}

AgentEditView.propTypes = {
  save: PropTypes.func,
}
