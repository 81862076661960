import { combineReducers } from 'redux'

import { AgentListRequestedAction, AgentListReceivedAction } from '../actions/agentListActions'

function list(state = null, action) {
  switch (action.type) {
    case AgentListRequestedAction.NAME: {
      return null
    }
    case AgentListReceivedAction.NAME: {
      return action.data
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  list,
})
