import React, { useState } from 'react'
import { useLogout } from 'react-admin'
import classnames from 'classnames'
import ClickAwareBox from 'click-aware-box'

import { userNameEntry } from '../../../providers/localStorageProvider'
import { messages } from '../../../i18n/messages'
import UserMenu from '../SidebarIcons/user-menu.svg'

import './profile-menu.scss'

const ProfileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const logout = useLogout()
  const toggle = () => setIsMenuOpen(!isMenuOpen)
  const close = () => setIsMenuOpen(false)

  return (
    <ClickAwareBox className="profile-menu" onClickOut={close} onClickIn={toggle}>
      <div className="profile-line">
        <div className="profile-icon">
          <img src={UserMenu} alt="user-menu" />
        </div>
        <div className="username">{userNameEntry.get()}</div>
      </div>
      <div className={classnames('menu-items', { open: isMenuOpen })}>
        <div className="menu-item logout" onClick={() => logout()}>
          {messages.LOGOUT}
        </div>
      </div>
    </ClickAwareBox>
  )
}

export default ProfileMenu
