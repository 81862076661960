import { connect } from 'react-redux'
import { AnalyticsView } from '../components/AnalyticsView'
import { AnalyticsDataRequestedAction } from '../actions/analyticsAction'

function mapStateToProps(state) {
  return { ...state.customReducers.analytics, data: state.customReducers.analytics.data }
}

function mapDispatchToProps(dispatch) {
  return {
    requestData: (params) => {
      dispatch(AnalyticsDataRequestedAction.make(params))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsView)
