import { combineReducers } from 'redux'
import {
  ClientSummarySimulationRequestAction,
  ClientSummarySimulationResponseAction,
  ClientSummarySimulationError,
} from '../actions/clientSummarySimulator'

function data(state = null, action) {
  switch (action.type) {
    case ClientSummarySimulationRequestAction.NAME:
    case ClientSummarySimulationError.NAME: {
      return null
    }
    case ClientSummarySimulationResponseAction.NAME: {
      return action.data
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  data,
})
