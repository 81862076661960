import { connect } from 'react-redux'

import SettingsView from '../../components/SettingsView'
import { SettingsOpenAction } from '../../actions/settings'
import { GoToStep } from '../../actions/settings'

function mapStateToProps(state) {
  return {
    stepNum: state.customReducers.settings.stepNum,
    error: state.customReducers.settings.error,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    goToStep: (destStep) => dispatch(GoToStep.make(destStep)),
    initStepNum: () => dispatch(SettingsOpenAction.make()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  SettingsView,
)
