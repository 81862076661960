import React from 'react'
import _ from 'lodash'
import jsonExport from 'jsonexport/dist'
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  ReferenceField,
  Filter,
  TextInput,
  SelectInput,
  downloadCSV,
  useTranslate,
} from 'react-admin'

import PropTypes from 'prop-types'

import icons from '../icons'
import TableInlineDropdown from '../widgets/SROFormComponents/TableInlineDropdown'
import SRODateField from '../widgets/SROFormComponents/SRODateField'
import { AgentFullResource } from '../../providers/resources/comboResources'
import SROTooltip from '../widgets/SROTooltip'
import VisitTypeIcon from '../widgets/VisitTypeIcon'

import '../sro-table.scss'
import './client-view.scss'
import { ClientRatingResource, ClientResource } from '../../providers/resources/requestResources'

const CustomRating = (props) => {
  const options = [{ id: null, name: '' }, ..._.values(props.options[props.record.accountId])]

  return (
    <TableInlineDropdown {...props} resource={ClientResource.RESOURCE_NAME} options={options} />
  )
}

CustomRating.propTypes = {
  record: PropTypes.object,
  options: PropTypes.object,
}

function makeErrorIcon(record) {
  const tooltipContent = (record.validationErrors || []).join(',\n')

  return (
    <SROTooltip text={tooltipContent} className="error-icon">
      <icons.errorTriangle />
    </SROTooltip>
  )
}

function makeAdditionalInfoIcon(record) {
  const { attributes = {}, customData = {} } = record
  const { nearbyLabels } = attributes
  if (!nearbyLabels && Object.keys(customData).length === 0) {
    return undefined
  }

  const nearbyLabelsText = (nearbyLabels || []).map(
    (nearbyLabel) => `${nearbyLabel.label}: ${nearbyLabel.value}`,
  )

  const customDataText = Object.keys(customData).map((key) => `${key}: ${customData[key]}`)

  const tooltipContent = [...nearbyLabelsText, ...customDataText].join(', ')

  return (
    <SROTooltip text={tooltipContent} className="info-icon">
      <icons.infoI />
    </SROTooltip>
  )
}

function renderRichId(record) {
  const errorIcon = record.isValid ? undefined : makeErrorIcon(record)
  const additionalInfoIcon = makeAdditionalInfoIcon(record)

  return (
    <div className="id-col">
      {errorIcon}
      {additionalInfoIcon}
      <span>{record.externalId}</span>
    </div>
  )
}

function RichLastVisitDate(props) {
  const { lastVisitType } = props.record

  return (
    <div className="last-visit-date-col">
      <SRODateField {...props} />
      {lastVisitType ? <VisitTypeIcon visitType={lastVisitType} /> : undefined}
    </div>
  )
}

RichLastVisitDate.propTypes = {
  record: PropTypes.object,
}

const ClientView = (props) => {
  const { extraData, ...listProps } = props
  const translate = useTranslate(useTranslate)

  function ClientListFilter(props) {
    if (!extraData) {
      return null
    }

    // eslint-disable-next-line
    const flatRatingChoices = _(extraData.ratingChoices).values().map(_.values).flatten().value()

    return (
      <Filter {...props} className="sro-form">
        <TextInput className="sro-filter" label="NAME" source="_q_name" alwaysOn />
        <TextInput className="sro-filter" label="CLIENT_CODE" source="_q_externalId" alwaysOn />
        <TextInput className="sro-filter" label="ADDRESS" source="_q_address" alwaysOn />
        <SelectInput
          className="sro-filter"
          label="RATING"
          source="rateId"
          choices={flatRatingChoices}
          alwaysOn
          emptyText="all_ratings"
        />
        <SelectInput
          className="sro-filter"
          label="AGENT"
          source="agentId"
          // eslint-disable-next-line
          choices={extraData.agentChoices}
          alwaysOn
          emptyText="ALL_AGENTS"
        />
      </Filter>
    )
  }

  async function exporter(clients, fetchRelatedRecords) {
    const agents = await fetchRelatedRecords(clients, 'agentId', AgentFullResource.RESOURCE_NAME)
    const customRates = await fetchRelatedRecords(
      clients,
      'customRateId',
      ClientRatingResource.RESOURCE_NAME,
    )
    const visitTypeTranslateMap = {
      client: translate('CLIENT'),
      phone: translate('PHONE'),
    }
    const clientsForExport = clients.map((client) => {
      const clientForExport = _.pick(client, [
        'externalId',
        'name',
        'rate.name',
        'address',
        'lastVisitDate',
        'lastOrderDate',
        'nextVisitDate',
        'usedSlashCredit',
        'totalMonthPurchase',
      ])
      clientForExport.agentName = agents[client.agentId].name
      clientForExport.customRateName = _.get(customRates[client.customRateId], 'name')
      clientForExport.lastVisitType = visitTypeTranslateMap[client.lastVisitType]
      return clientForExport
    })
    jsonExport(
      clientsForExport,
      {
        headers: [
          'externalId',
          'name',
          'rate.name',
          'customRateName',
          'agentName',
          'address',
          'lastVisitDate',
          'lastVisitType',
          'lastOrderDate',
          'nextVisitDate',
          'usedSlashCredit',
          'totalMonthPurchase',
        ],
        rename: [
          'CLIENT_CODE',
          'NAME',
          'RATING',
          'CUSTOM_RATING',
          'AGENT',
          'ADDRESS',
          'LAST_VISIT',
          'last_visit_type',
          'LAST_ORDER',
          'NEXT_VISIT',
          'USED_SLASH_CREDIT',
          'MONTH_PURCHASE',
        ].map((key) => translate(key)),
      },
      (err, csv) => {
        downloadCSV(`\ufeff${csv}`, 'clients')
      },
    )
  }

  const ratingChoices = _.get(extraData, 'ratingChoices')

  return (
    <div className="clients-page main-page list-view">
      <div className="page-title float-left">{translate('CLIENTS')}</div>
      <List
        {...listProps}
        filters={<ClientListFilter />}
        filter={{ isActive: true, _include: ['rate', 'nextVisitDate'] }}
        className="list-view sro-table"
        bulkActionButtons={false}
        exporter={exporter}
      >
        <Datagrid>
          <FunctionField source="id" render={renderRichId} label="CLIENT_CODE" />
          <TextField source="name" label="NAME" />
          <TextField source="rate.name" label="RATING" />
          <CustomRating source="customRateId" label="CUSTOM_RATING" options={ratingChoices} />
          <ReferenceField
            source="agentId"
            label="AGENT"
            reference={AgentFullResource.RESOURCE_NAME}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="address" label="ADDRESS" />
          <RichLastVisitDate source="lastVisitDate" label="LAST_VISIT" />
          <SRODateField source="lastOrderDate" label="LAST_ORDER" />
          <SRODateField source="nextVisitDate" label="NEXT_VISIT" />
          <TextField source="usedSlashCredit" label="USED_SLASH_CREDIT" sortBy="usedCredit" />
          <TextField source="totalMonthPurchase" label="MONTH_PURCHASE" />
        </Datagrid>
      </List>
    </div>
  )
}

ClientView.propTypes = {
  extraData: PropTypes.shape({
    ratingChoices: PropTypes.object,
    agentChoices: PropTypes.arrayOf(PropTypes.object),
  }),
}

export default ClientView
