import { CREATE } from 'react-admin'
import { put, takeEvery } from 'redux-saga/effects'

import {
  CREATE_ACTIVITY_FORM_REQUESTED,
  createActivityFormClose,
  createActivityFormFailure,
  createActivityFormLoading,
} from '../actions/createActivityForm'
import dataProvider from '../providers/dataProvider'
import { ActivityResource } from '../providers/resources/requestResources'
import { notifyInfo, notifyError } from '../components/widgets/SRONotification'
import { messages } from '../i18n/messages'

function* createActivity(action) {
  const data = {
    ...action.data,
    scheduleTime: action.data.scheduleTime ? action.data.scheduleTime.formatISO() : null,
  }

  yield put(createActivityFormLoading(true))

  try {
    const { data: result = {} } = yield dataProvider(CREATE, ActivityResource.RESOURCE_NAME, {
      data,
    })
    let additionalMsg = ''
    if (!result.isDuringFreezeTime) {
      additionalMsg = ` (${messages.ACTIVITY_WILL_APPEAR_LATER})`
    }
    yield notifyInfo(`${messages.ACTIVITY_CREATED}${additionalMsg}`)
    yield put(createActivityFormClose())
    yield put(createActivityFormLoading(false))
  } catch (err) {
    yield notifyError(messages.ACTIVITY_CREATION_FAILED, {
      error: err.message || messages.UNKNOWN_ERROR,
    })
    yield put(createActivityFormFailure(err.message))
    yield put(createActivityFormLoading(false))
  }
}

export default function* createActivitySaga() {
  yield takeEvery(CREATE_ACTIVITY_FORM_REQUESTED, createActivity)
}
