import React, { useState, useCallback } from 'react'
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  SingleFieldList,
  ChipField,
  DateField,
  ReferenceArrayField,
  SelectArrayInput,
  TextInput,
  ReferenceArrayInput,
  FunctionField,
  DateInput,
  FormWithRedirect,
  SaveButton,
  DeleteButton,
  useTranslate,
  required,
} from 'react-admin'
import { Box, Toolbar, Select, MenuItem, InputLabel, FormControl, Button } from '@material-ui/core'
import { useForm, useField } from 'react-final-form'
import PropTypes from 'prop-types'

import EditPencilIcon from '../../components/widgets/EditPencilIcon'
import SROListActions from '../widgets/SROFormComponents/SROListActions'
import SROTimeInput from '../widgets/SROFormComponents/SROTimeInput'
import DateRangeInput from '../widgets/DateRangeInput'
import EmptyList from '../EmptyList'

import { AgentBreakResource } from '../../providers/resources/requestResources'
import { AgentFullResource } from '../../providers/resources/comboResources'
import { accountIdEntry } from '../../providers/localStorageProvider'
import { SROMoment } from '../../utils/timeUtils'
import { goToResource } from '../../utils/reactAdminUtils'

import '../sro-table.scss'
import './agent-break.scss'

export const AgentBreakList = (props) => {
  const translate = useTranslate()

  return (
    <div className="agent-break-page main-page">
      <h1 className="page-title float-left">{translate('time_breaks')}</h1>
      <List
        {...props}
        className="sro-table"
        bulkActionButtons={false}
        actions={<SROListActions createLabel="add_time_break" />}
        empty={<EmptyList />}
      >
        <Datagrid>
          <TextField source="id" label="ID" />
          <ReferenceArrayField
            source="agentIds"
            reference={AgentFullResource.RESOURCE_NAME}
            label="AGENTS"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <TextField source="description" label="description" />
          <DateField source="startDate" label="start_date" />
          <DateField source="endDate" label="end_date" />
          <TextField source="startTime" label="START_TIME" emptyText="-" />
          <TextField source="endTime" label="END_TIME" emptyText="-" />
          <DateField source="createdAt" label="CREATED" />
          <DateField source="updatedAt" label="UPDATED" />
          <FunctionField
            source="id"
            label=""
            sortable={false}
            render={(inputProps) => (
              <EditPencilIcon resource={AgentBreakResource} id={inputProps.id} />
            )}
          />
        </Datagrid>
      </List>
    </div>
  )
}

const TimePicker = (props) => {
  const translate = useTranslate()
  const form = useForm()
  const {
    input: { value },
  } = useField()
  const [selectValue, setSelectValue] = useState(value.startTime ? 'startTime' : 'endTime')

  const oppositeMap = {
    startTime: 'endTime',
    endTime: 'startTime',
  }
  form.change(oppositeMap[selectValue], null)

  return (
    <Box display="flex" minWidth="15em">
      <Box flex={1} mr="1em">
        <FormControl {...props}>
          <InputLabel id="select-hour-type">{translate('break_hour_type')}</InputLabel>
          <Select
            labelId="select-hour-type"
            value={selectValue}
            onChange={(event) => {
              const {
                target: { value },
              } = event
              setSelectValue(value)
            }}
          >
            <MenuItem value="startTime">{translate('break_start_time')}</MenuItem>
            <MenuItem value="endTime">{translate('break_end_time')}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box flex={1}>
        {selectValue === 'startTime' && (
          <SROTimeInput source="startTime" label="START_TIME" fullWidth />
        )}
        {selectValue === 'endTime' && (
          <SROTimeInput source="endTime" label="START_TIME" fullWidth />
        )}
      </Box>
    </Box>
  )
}

const RADateRangeInput = (props) => {
  const { label, fullWidth, fromSource, toSource, ...formProps } = props
  const translate = useTranslate()
  const form = useForm()
  const {
    input: { value },
  } = useField()

  const { [fromSource]: from = SROMoment.now(), [toSource]: to = SROMoment.now() } = value
  const [dateRange, setDateRange] = useState({
    from: SROMoment.fromString(from),
    to: SROMoment.fromString(to),
  })

  form.change(fromSource, dateRange.from.formatDate())
  form.change(toSource, dateRange.to.formatDate())

  return (
    <Box>
      <FormControl {...formProps} fullWidth>
        <InputLabel>{translate(label)}</InputLabel>
        <DateRangeInput
          isSingle={false}
          from={dateRange.from}
          to={dateRange.to}
          onChange={(newFrom, newTo) => {
            setDateRange({ from: newFrom, to: newTo })
          }}
          fullWidth={fullWidth}
        />
      </FormControl>
    </Box>
  )
}

RADateRangeInput.propTypes = {
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  fromSource: PropTypes.string,
  toSource: PropTypes.string,
}

const SROSaveButton = ({ handleSubmitWithRedirect, ...props }) => {
  const form = useForm()

  const toServerTime = (value) => {
    return typeof value === 'string' ? value : `${new SROMoment(value).formatTime()}:00`
  }

  const handleClick = useCallback(() => {
    form.change('accountId', accountIdEntry.get())
    const {
      values: { startTime, endTime },
    } = form.getState()

    if (startTime) {
      form.change('startTime', toServerTime(startTime))
    }
    if (endTime) {
      form.change('endTime', toServerTime(endTime))
    }

    handleSubmitWithRedirect('list')
  }, [form, handleSubmitWithRedirect])

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />
}

SROSaveButton.propTypes = {
  handleSubmitWithRedirect: PropTypes.func,
}

const EditForm = (props) => (
  <FormWithRedirect
    {...props}
    render={(formProps) => (
      <form>
        <Box p="1em" display="flex" maxWidth="355px">
          <Box mr="1em">
            <Box>
              <ReferenceArrayInput
                source="agentIds"
                reference={AgentFullResource.RESOURCE_NAME}
                resource={AgentFullResource.RESOURCE_NAME}
                filter={{ isActive: true }}
                label="AGENTS"
              >
                <SelectArrayInput validate={[required()]} optionText="name" fullWidth />
              </ReferenceArrayInput>
            </Box>
            <Box>
              <TextInput
                source="description"
                label="description"
                validate={[required()]}
                fullWidth
              />
            </Box>
            <Box mb="1.5em">
              <RADateRangeInput
                fromSource="startDate"
                toSource="endDate"
                label="date_range"
                validate={[required()]}
                fullWidth
              />
            </Box>
            <TimePicker fullWidth />
          </Box>
          <Box ml="1em">
            <Box>
              <TextInput disabled source="id" fullWidth />
            </Box>
            <Box>
              <DateInput disabled source="createdAt" label="CREATED" fullWidth />
            </Box>
            <Box>
              <DateInput disabled source="updatedAt" label="UPDATED" fullWidth />
            </Box>
          </Box>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" width="100%">
            <SROSaveButton
              className="sro-button"
              saving={formProps.saving}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            />
            <DeleteButton
              undoable={false}
              resource={AgentBreakResource.RESOURCE_NAME}
              record={formProps.record}
              redirect={() => goToResource(AgentBreakResource.RESOURCE_NAME)}
            />
          </Box>
        </Toolbar>
      </form>
    )}
  />
)

export const AgentBreakEdit = (props) => {
  const translate = useTranslate()

  return (
    <div className="main-page agent-break-form-wrapper">
      <div>
        <h1 className="page-title float-left">{translate('edit_time_break')}</h1>
        <div className="back-from-edit-wrapper">
          <Button className="link-style-button" onClick={() => goToResource(AgentBreakResource)}>
            {translate('sro.back_to_time_breaks')}
          </Button>
        </div>
      </div>
      <Edit {...props} undoable={false} className="sro-form agent-break-form">
        <EditForm />
      </Edit>
    </div>
  )
}

const CreateForm = (props) => (
  <FormWithRedirect
    {...props}
    render={(formProps) => (
      <form>
        <Box p="1em" display="flex" maxWidth="355px">
          <Box mr="1em">
            <Box>
              <ReferenceArrayInput
                source="agentIds"
                reference={AgentFullResource.RESOURCE_NAME}
                resource={AgentFullResource.RESOURCE_NAME}
                filter={{ isActive: true }}
                label="AGENTS"
              >
                <SelectArrayInput optionText="name" validate={[required()]} fullWidth />
              </ReferenceArrayInput>
            </Box>
            <Box>
              <TextInput
                source="description"
                label="description"
                validate={[required()]}
                fullWidth
              />
            </Box>
            <Box mb="1em">
              <RADateRangeInput
                fromSource="startDate"
                toSource="endDate"
                label="date_range"
                validate={[required()]}
                fullWidth
              />
            </Box>
            <TimePicker fullWidth />
          </Box>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" width="100%">
            <SROSaveButton
              className="sro-button"
              saving={formProps.saving}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            />
          </Box>
        </Toolbar>
      </form>
    )}
  />
)

export const AgentBreakCreate = (props) => {
  const translate = useTranslate()

  return (
    <div className="main-page agent-break-form-wrapper">
      <div>
        <h1 className="page-title float-left">{translate('add_time_break')}</h1>
        <div className="back-from-edit-wrapper">
          <Button className="link-style-button" onClick={() => goToResource(AgentBreakResource)}>
            {translate('sro.back_to_time_breaks')}
          </Button>
        </div>
      </div>
      <Create {...props} className="sro-form agent-break-form">
        <CreateForm />
      </Create>
    </div>
  )
}
