import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import { captureException } from '../../../utils/errorMonitoring'
import './sro-form-style.scss'
import icons from '../../icons'
import SROFormComponent from './SROFormComponents'
import SROTooltip from '../SROTooltip'

export const isEmpty = (value) => _.isEmpty(value.coordinates) || _.isEmpty(value.address)

export default class GeoPicker extends SROFormComponent {
  constructor(props) {
    super(props)
    this.state = Object.assign({}, this.state, {
      address: this.props.defaultValue.address || '',
      lastChosenAddress: this.props.defaultValue.address || '',
      coordinates: this.props.defaultValue.coordinates || {},
      lastChosenCoords: this.props.defaultValue.coordinates || {},
    })

    this.handleSelect = this.handleSelect.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleError = this.handleError.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  handleChange = (address) => {
    const values = { address, coordinates: {} }
    this.setState(values)
    this.props.onChange(values)
  }

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((data) => {
        const coordinates = {
          longitude: data.lng,
          latitude: data.lat,
        }

        this.setState({
          lastChosenCoords: {
            longitude: data.lng,
            latitude: data.lat,
          },
          lastChosenAddress: address,
          coordinates,
          address,
        })
        this.props.onChange({ coordinates, address })
      })
      .catch((err) => {
        captureException(err)
      })
  }

  handleError = (err) => {
    if (err !== 'ZERO_RESULTS') {
      captureException(err)
    }
  }

  getValue() {
    return {
      coordinates: this.state.coordinates,
      address: this.state.address,
    }
  }

  getExtraClasses() {
    return ['sro-geopicker-wrapper', { 'has-title': !!this.props.title }]
  }

  restoreLastGoodAddress() {
    const values = {
      address: this.state.lastChosenAddress,
      coordinates: this.state.lastChosenCoords,
    }
    this.setState(values)
    this.props.onChange(values)
  }

  onBlur() {
    if (_.isEmpty(this.state.address)) {
      this.setState({
        lastChosenAddress: '',
        lastChosenCoords: {},
      })
    } else {
      this.restoreLastGoodAddress()
    }
  }

  modifyInputProps(inputProps) {
    const that = this
    return Object.assign({}, inputProps, {
      onBlur: function () {
        that.onBlur()
        inputProps.onBlur.apply(this, arguments)
      },
    })
  }

  render() {
    return (
      <SROTooltip
        className={classnames(this.getClasses())}
        position="bottom"
        text={this.state.coordinates.longitude ? this.state.address : ''}
      >
        {this.props.title && <div className="upper-title">{this.props.title}</div>}
        <PlacesAutocomplete
          value={this.state.address}
          onError={this.handleError}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className="sro-input sro-geoinput">
              <div className="input-box">
                <input
                  {...this.modifyInputProps(
                    getInputProps({
                      placeholder: this.props.placeholder,
                      className: 'location-search-input',
                    }),
                  )}
                />
                <div className="geo-input-button">
                  <icons.geoInput />
                </div>
              </div>
              <div className="autocomplete-dropdown-container">
                {loading && (
                  <div className="suggestion-item">
                    <span>Loading...</span>
                  </div>
                )}
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item'
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' }
                  return (
                    <div
                      key={index}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </SROTooltip>
    )
  }
}

GeoPicker.defaultProps = {
  defaultValue: {
    address: '',
    coordinates: {},
  },
  onChange: () => {},
}
