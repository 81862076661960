import React from 'react'
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  SingleFieldList,
  ChipField,
  DateField,
  TextInput,
  FunctionField,
  DateInput,
  useTranslate,
  required,
  ReferenceManyField,
  Toolbar,
  DeleteButton,
  SaveButton,
  FormWithRedirect,
} from 'react-admin'
import { Box, Button } from '@material-ui/core'

import EditPencilIcon from '../../components/widgets/EditPencilIcon'
import SROListActions from '../widgets/SROFormComponents/SROListActions'
import { AgentTeamResource } from '../../providers/resources/requestResources'
import { AgentFullResource } from '../../providers/resources/comboResources'
import { goToResource } from '../../utils/reactAdminUtils'

import EmptyList from '../EmptyList'
import '../sro-table.scss'
import './agent-team.scss'

export const AgentTeamList = (props) => {
  const translate = useTranslate()

  return (
    <div className="main-page">
      <h1 className="page-title float-left">{translate('agent_teams')}</h1>
      <List
        {...props}
        className="sro-table"
        bulkActionButtons={false}
        actions={<SROListActions createLabel="add_agent_team" />}
        empty={<EmptyList />}
      >
        <Datagrid>
          <TextField source="id" label="ID" />
          <TextField source="name" label="name" />
          <ReferenceManyField
            target="agentTeamId"
            reference={AgentFullResource.RESOURCE_NAME}
            label="AGENTS"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceManyField>
          <DateField source="createdAt" label="CREATED" />
          <DateField source="updatedAt" label="UPDATED" />
          <FunctionField
            source="id"
            label=""
            sortable={false}
            render={(inputProps) => (
              <EditPencilIcon resource={AgentTeamResource} id={inputProps.id} />
            )}
          />
        </Datagrid>
      </List>
    </div>
  )
}

const AgentTeamToolbar = (formProps) => (
  <Toolbar>
    <Box display="flex" justifyContent="space-between" width="100%">
      <SaveButton
        saving={formProps.saving}
        handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
        className="sro-button"
      />
      <DeleteButton
        resource={AgentTeamResource.RESOURCE_NAME}
        record={formProps.record}
        undoable={false}
      />
    </Box>
  </Toolbar>
)

export const AgentTeamEdit = (props) => {
  const translate = useTranslate()

  return (
    <div className="main-page">
      <div>
        <h1 className="page-title float-left">{translate('edit_agent_team')}</h1>
        <div className="back-from-edit-wrapper">
          <Button className="link-style-button" onClick={() => goToResource(AgentTeamResource)}>
            {translate('sro.back_to_agent_teams')}
          </Button>
        </div>
      </div>
      <Edit {...props} undoable={false} className="sro-form">
        <FormWithRedirect
          render={(formProps) => (
            <form>
              <Box p="1em" display="flex">
                <Box flex={1}>
                  <Box>
                    <TextInput disabled source="id" />
                  </Box>
                  <Box>
                    <TextInput source="name" label="NAME" validate={[required()]} />
                  </Box>
                  <Box>
                    <DateInput disabled source="createdAt" label="CREATED" />
                  </Box>
                  <Box>
                    <DateInput disabled source="updatedAt" label="UPDATED" />
                  </Box>
                </Box>
              </Box>
              <AgentTeamToolbar {...formProps} />
            </form>
          )}
        />
      </Edit>
    </div>
  )
}

export const AgentTeamCreate = (props) => {
  const translate = useTranslate()

  return (
    <div className="main-page">
      <div>
        <h1 className="page-title float-left">{translate('add_agent_team')}</h1>
        <div className="back-from-edit-wrapper">
          <Button className="link-style-button" onClick={() => goToResource(AgentTeamResource)}>
            {translate('sro.back_to_agent_teams')}
          </Button>
        </div>
      </div>
      <Create {...props} className="sro-form">
        <FormWithRedirect
          render={(formProps) => (
            <form>
              <Box p="1em" display="flex">
                <Box flex={1}>
                  <Box>
                    <TextInput source="name" label="NAME" validate={[required()]} />
                  </Box>
                </Box>
              </Box>
              <AgentTeamToolbar {...formProps} />
            </form>
          )}
        />
      </Create>
    </div>
  )
}
