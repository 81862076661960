import { getResourceByName } from './resources/resources'
import { captureException } from '../utils/errorMonitoring'

export const REPORT = 'REPORT'
export const GET_RAW = 'GET_RAW'

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resourceName Resource name, e.g. 'posts'
 * @param {Object} params Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default async (type, resourceName, params) => {
  const resource = getResourceByName(resourceName)
  try {
    return await resource.fetch(type, params)
  } catch (err) {
    if (![401, 404, 409, 422].includes(err.status)) {
      captureException(err)
    }

    throw err
  }
}
