import React from 'react'
import { Admin, Resource } from 'react-admin'
import { Route } from 'react-router-dom'
import { JssProvider } from 'react-jss'
import { createBrowserHistory } from 'history'

import dataProvider from './providers/dataProvider'
import authProvider from './providers/authProvider'
import { hasAtLeastRole, notAdmin, ROLE_ACCOUNT_MANAGER, ROLE_ADMIN } from './providers/roles'
import reducers from './reducers'
import customSagas from './sagas'
import i18nProvider from './i18n/messages'

import Layout from './components/Layout'
import ActivityCalendar from './containers/ActivityCalendar'
import DailyActivity from './containers/DailyActivity'
import Settings from './containers/Settings/Settings'
import { AccountList, AccountEdit, AccountCreate, AccountShow } from './components/AccountView'
import { UserList, UserShow } from './components/UserView'
import { UserCreate, UserEdit } from './containers/User'
import { AgentList } from './components/AgentView'
import { PlanFailureList, PlanFailureShow } from './components/PlanFailureView'
import Dashboard from './components/DashboardView'
import SalesDashboard from './components/SalesDashboardView'
import Analytics from './containers/Analytics'
import TripMap from './components/TripMapView'
import ClientList from './containers/ClientList'
import { AgentEdit } from './containers/Agent'
import SidebarIcons from './components/Sidebar/SidebarIcons'
import LoginPage from './components/LoginPage'
import PasswordResetForm from './components/LoginPage/PasswordResetForm'
import { AgentBreakList, AgentBreakEdit, AgentBreakCreate } from './components/AgentBreakView'
import { AgentTeamList, AgentTeamEdit, AgentTeamCreate } from './components/AgentTeamView'

import {
  ActivityCalendarResource,
  ClientByAgentResource,
  DailyActivityResource,
  AgentPlusAllResource,
  ReportActivityResource,
  SettingsResource,
  TripMapResource,
  UserResource,
  AccountResource,
  VerifyEmailPasswordResetResource,
  AgentBreakResource,
  ClientRatingResource,
  PlanFailureResource,
  ClientResource,
  AgentTeamResource,
} from './providers/resources/requestResources'
import {
  UserFullResource,
  AnalyticsResource,
  ClientScreenResource,
  AgentFullResource,
  AccountFullResource,
} from './providers/resources/comboResources'

import './app.scss'

const history = createBrowserHistory()

const getUserResource = () => {
  if (!hasAtLeastRole(ROLE_ACCOUNT_MANAGER)) {
    return (
      <Resource
        key={UserResource.RESOURCE_NAME}
        name={UserResource.RESOURCE_NAME}
        list={UserShow}
        options={{ label: 'MY_USER' }}
        icon={() => <SidebarIcons.UsersIcon />}
      />
    )
  }

  return (
    <Resource
      key={UserFullResource.RESOURCE_NAME}
      name={UserFullResource.RESOURCE_NAME}
      list={UserList}
      edit={UserEdit}
      create={UserCreate}
      options={{ label: 'USERS' }}
      icon={() => <SidebarIcons.UsersIcon />}
    />
  )
}

const getAccountResources = () => {
  if (!hasAtLeastRole(ROLE_ACCOUNT_MANAGER)) {
    return null
  }

  if (hasAtLeastRole(ROLE_ADMIN)) {
    return [
      <Resource
        key={AccountFullResource.RESOURCE_NAME}
        name={AccountFullResource.RESOURCE_NAME}
        list={AccountList}
        edit={AccountEdit}
        create={AccountCreate}
        options={{ label: 'ACCOUNTS' }}
        icon={() => <SidebarIcons.AccountsIcon />}
      />,
      <Resource
        key={AccountResource.RESOURCE_NAME}
        name={AccountResource.RESOURCE_NAME}
        list={AccountShow}
        options={{ label: 'ACCOUNT', show: false }}
        icon={() => <SidebarIcons.AccountsIcon />}
      />,
    ]
  }

  return [
    <Resource
      key={AccountResource.RESOURCE_NAME}
      name={AccountResource.RESOURCE_NAME}
      list={AccountShow}
      options={{ label: 'ACCOUNT' }}
      icon={() => <SidebarIcons.AccountsIcon />}
    />,
  ]
}

function getRenderedResources() {
  const resources = []

  if (notAdmin()) {
    resources.push(
      <Resource
        key={DailyActivityResource.RESOURCE_NAME}
        name={DailyActivityResource.RESOURCE_NAME}
        list={DailyActivity}
        options={{ label: 'DAILY_ACTIVITY' }}
        icon={() => <SidebarIcons.DailyActivityIcon />}
      />,
    )
    resources.push(
      <Resource
        key={ActivityCalendarResource.RESOURCE_NAME}
        name={ActivityCalendarResource.RESOURCE_NAME}
        list={ActivityCalendar}
        options={{ label: 'ACTIVITY_CALENDAR' }}
        icon={() => <SidebarIcons.ActivityCalendarIcon />}
      />,
    )
    resources.push(
      <Resource
        key="dashboard"
        name="dashboard"
        list={Dashboard}
        options={{ label: 'DASHBOARD' }}
        icon={() => <SidebarIcons.DashboardIcon />}
      />,
    )
    resources.push(
      <Resource
        key="sales-dashboard"
        name="sales-dashboard"
        list={SalesDashboard}
        options={{ label: 'sro.sales_dashboard' }}
        icon={() => <SidebarIcons.DashboardIcon />}
      />,
    )
    resources.push(
      <Resource
        key={AnalyticsResource.RESOURCE_NAME}
        name={AnalyticsResource.RESOURCE_NAME}
        list={Analytics}
        options={{ label: 'ANALYTICS' }}
        icon={() => <SidebarIcons.AnalyticsIcon />}
      />,
    )
  }

  if (hasAtLeastRole(ROLE_ACCOUNT_MANAGER) && notAdmin()) {
    resources.push(
      <Resource
        key={SettingsResource.RESOURCE_NAME}
        name={SettingsResource.RESOURCE_NAME}
        options={{ label: 'SETTINGS' }}
        list={Settings}
      />,
    )
  }

  resources.push(
    <Resource
      key={AgentFullResource.RESOURCE_NAME}
      name={AgentFullResource.RESOURCE_NAME}
      list={AgentList}
      edit={AgentEdit}
      options={{ label: 'AGENTS' }}
      icon={() => <SidebarIcons.AgentsIcon />}
    />,
  )
  resources.push(
    <Resource
      key={ClientScreenResource.RESOURCE_NAME}
      name={ClientScreenResource.RESOURCE_NAME}
      list={ClientList}
      options={{ label: 'CLIENTS' }}
      icon={() => <SidebarIcons.ClientsIcon />}
    />,
  )

  const accountResources = getAccountResources()
  if (accountResources) {
    resources.push(...accountResources)
  }

  const userResource = getUserResource()
  if (userResource) {
    resources.push(userResource)
  }

  if (hasAtLeastRole(ROLE_ACCOUNT_MANAGER)) {
    resources.push(
      <Resource
        key={AgentBreakResource.RESOURCE_NAME}
        name={AgentBreakResource.RESOURCE_NAME}
        list={AgentBreakList}
        edit={AgentBreakEdit}
        create={AgentBreakCreate}
        options={{ label: 'time_breaks' }}
        icon={() => <SidebarIcons.AgentBreakIcon />}
      />,
    )
  }

  if (hasAtLeastRole(ROLE_ACCOUNT_MANAGER)) {
    resources.push(
      <Resource
        key={AgentTeamResource.RESOURCE_NAME}
        name={AgentTeamResource.RESOURCE_NAME}
        list={AgentTeamList}
        edit={AgentTeamEdit}
        create={AgentTeamCreate}
        options={{ label: 'agent_teams' }}
        icon={() => <SidebarIcons.AgentsIcon />}
      />,
    )
  } else {
    resources.push(
      <Resource key={AgentTeamResource.RESOURCE_NAME} name={AgentTeamResource.RESOURCE_NAME} />,
    )
  }

  if (hasAtLeastRole(ROLE_ADMIN)) {
    resources.push(
      <Resource
        key={PlanFailureResource.RESOURCE_NAME}
        name={PlanFailureResource.RESOURCE_NAME}
        list={PlanFailureList}
        show={PlanFailureShow}
        options={{ label: 'plan_failures' }}
      />,
    )
  }

  resources.push(
    <Resource
      key={ClientByAgentResource.RESOURCE_NAME}
      name={ClientByAgentResource.RESOURCE_NAME}
    />,
    <Resource key={AgentPlusAllResource.RESOURCE_NAME} name={AgentPlusAllResource.RESOURCE_NAME} />,
    <Resource
      key={ReportActivityResource.RESOURCE_NAME}
      name={ReportActivityResource.RESOURCE_NAME}
    />,
    <Resource key={ClientRatingResource.RESOURCE_NAME} name={ClientRatingResource.RESOURCE_NAME} />,
    <Resource key={ClientResource.RESOURCE_NAME} name={ClientResource.RESOURCE_NAME} />,
    <Resource
      key={TripMapResource.RESOURCE_NAME}
      name={TripMapResource.RESOURCE_NAME}
      options={{ show: false }}
      list={TripMap}
    />,
  )

  return resources
}

const App = () => (
  <JssProvider>
    <Admin
      history={history}
      loginPage={LoginPage}
      i18nProvider={i18nProvider}
      layout={Layout}
      dataProvider={dataProvider}
      authProvider={authProvider}
      customReducers={{
        customReducers: reducers,
      }}
      customSagas={customSagas}
      customRoutes={[
        <Route
          key={VerifyEmailPasswordResetResource.RESOURCE_NAME}
          path={`/${VerifyEmailPasswordResetResource.RESOURCE_NAME}`}
          component={PasswordResetForm}
          noLayout
        />,
      ]}
      title="Amodat Sales"
    >
      {getRenderedResources()}
    </Admin>
  </JssProvider>
)

export default App
