import _ from 'lodash'

import { Route } from '../../utils/geoUtils'
import { SROMoment } from '../../utils/timeUtils'

export default class DailyActivityTransformer {
  constructor() {
    this.byAgent = {}
  }

  loadData(data) {
    data.forEach((travel) => {
      this._enrichTravel(travel)
      this.byAgent[travel.agentId] = this.byAgent[travel.agentId] || new Agent(travel.agentId)
      this.byAgent[travel.agentId].addTravel(travel)
    })
  }

  export() {
    return _(this.byAgent)
      .values()
      .map((agent) => agent.export())
      .value()
  }

  _enrichTravel(travel) {
    travel.startTimePlanned = SROMoment.fromServerString(travel.arrivalTime || travel.arrivalTime)
    travel.endTimePlanned = travel.startTimePlanned
      ? travel.startTimePlanned.add(travel.visitTimeMinutes * 60 * 1000)
      : undefined

    if (travel.isClosed) {
      travel.startTime = SROMoment.fromServerString(travel.startTime)
      travel.endTime = SROMoment.fromServerString(travel.endTime)
    } else {
      travel.startTime = travel.startTimePlanned
      travel.endTime = travel.endTimePlanned
    }

    travel.metersToNextStop = travel.metersToNextStop || 0
  }
}

class Agent {
  constructor() {
    this.travels = []

    this.accountId = null
    this.agentId = null
    this.agentName = null
    this.agentTitle = ''
    this.clientId = null
    this.clientName = null
    this.totalOrders = 0
    this.activities = 0
    this.activitiesDone = 0
    this.canceledActivities = 0
    this.route = new Route()
  }

  addRouteStop(travel) {
    this.route.addStop({
      latitude: travel.latitude,
      longitude: travel.longitude,
      startTime: travel.startTime,
      endTime: travel.endTime,
      metersToNextStop: travel.metersToNextStop,
      isEdge: !travel.activityId,
    })
  }

  addTravel(travel) {
    this.accountId = _.get(travel, 'agent.accountId')
    this.agentId = _.get(travel, 'agent.id')
    this.clientId = _.get(travel, 'clientId')
    this.clientName = _.get(travel, 'client.name')
    this.externalClientId = _.get(travel, 'externalClientId')

    this.externalAgentId = _.get(travel, 'agent.externalId')
    this.agentName = _.get(travel, 'agent.name')

    this.travels.push(new Travel(travel))
    this.addRouteStop(travel)

    this.totalOrders += _.get(travel, 'documentTotal', 0)

    if (travel.activityId && !travel.isPhone) {
      this.activities++
    }

    if (travel.isCanceled && !travel.isPhone) {
      this.canceledActivities++
    }

    if (travel.isClosed && !travel.isPhone) {
      this.activitiesDone++
    }
  }

  export() {
    const timeRange = this.route.getTimeRange()
    const totalKM = this.route.getTotalMeters() / 1000

    return {
      accountId: this.accountId,
      agentId: this.agentId,
      agent: this.agent,
      clientId: this.clientId,
      externalClientId: this.externalClientId,
      clientName: this.clientName,
      externalAgentId: this.externalAgentId,
      agentName: this.agentName,
      agentTitle: this.agentTitle,
      totalOrders: this.totalOrders,
      activities: this.activities,
      activitiesDone: this.activitiesDone,
      canceledActivities: this.canceledActivities,
      totalKM: Math.round(totalKM),
      avgKM: Math.round(totalKM / this.activities) || 0,
      agentVisits: _(this.travels)
        .sortBy('data.reportedAt')
        .map((travel) => travel.export())
        .filter((travel) => travel.activityId)
        .value(),
      startTime: timeRange.startTime,
      endTime: timeRange.endTime,
    }
  }
}

class Travel {
  constructor(data) {
    this.data = data
  }

  getClassification() {
    if (!this.data.activityId) {
      return 'parking'
    }

    if (this.data.isCanceled) {
      return 'canceled'
    }

    if (this.data.isClosed) {
      return 'closed'
    }

    return 'open'
  }

  export() {
    const data = this.data
    let { attributes = {}, client = {} } = this.data
    const isClosed = data.isClosed && !data.isCanceled

    return {
      agentId: data.agentId,
      externalAgentId: _.get(data, 'agent.externalId'),
      agentName: _.get(data, 'agent.name'),
      rating: client.rateId,
      cityName: client.cityName,
      order: data.order,
      startTime: data.startTime,
      startTimePlanned: data.startTimePlanned,
      endTime: data.endTime,
      endTimePlanned: data.endTimePlanned,
      lastVisit: SROMoment.fromServerString(isClosed ? data.lastVisitDate : client.lastVisitDate),
      lastOrder: data.lastOrderDate,
      orderDate: data.reportedAt,
      lastOrderTotal: isClosed ? data.lastOrderTotal : client.lastOrderTotal,
      agentRemark: data.visitRemark,
      visitPurpose: data.visitPurpose,
      isCanceled: data.isCanceled,
      totalSale: data.documentTotal || 0,
      type: data.visitType,
      clientName: client.name,
      clientId: client.id,
      externalClientId: client.externalId,
      classification: this.getClassification(),
      isClosed: data.isClosed,
      attributes,
      activityId: data.activityId,
      notSellingReason: data.notSellingReason,
      isPlanned: data.isPlanned,
      isPhone: data.isPhone,
      cancelReason: data.cancelReason,
      rescheduleReason: data.rescheduleReason,
      rescheduleTime: data.rescheduleTime,
      documentDetails: data.documentDetails,
      doPrintBack: data.doPrintBack,
    }
  }
}
