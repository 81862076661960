import { put, takeEvery } from 'redux-saga/effects'
import Requester from '../providers/request'
import { DataReceivedAction, RequestAction } from '../actions/requestAction'

function* makeRequest(action) {
  const data = yield new Requester().makeRequest(action.requestType, action.resource, action.params)
  yield put(DataReceivedAction.make(action.requestId, data))
}

export default function* requestSaga() {
  yield takeEvery(RequestAction.NAME, makeRequest)
}
