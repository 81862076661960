import React from 'react'

import { DateField } from 'react-admin'

import { GLOBAL_LOCALE_CODE } from '../../../utils/timeUtils'

const SRODateField = (props) => {
  return (
    <DateField
      locales={GLOBAL_LOCALE_CODE}
      options={{ year: 'numeric', month: 'numeric', day: 'numeric' }}
      {...props}
    />
  )
}

export default SRODateField
