import React from 'react'

import { useTranslate } from 'react-admin'

import classnames from 'classnames'
import PropTypes from 'prop-types'

import icons from '../icons'
import SROTooltip from '../widgets/SROTooltip'

export default function VisitTypeIcon(props) {
  const { visitType } = props
  const translate = useTranslate()

  const IconType = {
    client: icons.house,
    phone: icons.phone,
  }[visitType]

  if (!IconType) {
    return undefined
  }

  return (
    <SROTooltip text={translate(`sro.${visitType}_visit`)}>
      <div className={classnames('visit-type-icon')}>
        <IconType />
      </div>
    </SROTooltip>
  )
}

VisitTypeIcon.propTypes = {
  visitType: PropTypes.string,
}
