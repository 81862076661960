import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { numberFormat } from '../../../utils/stringUtils'
import SROTooltip from '../SROTooltip'

class NumberView extends React.Component {
  render() {
    const formatted = numberFormat(this.props.value)
    const stringified = '' + this.props.value

    const tooltipText =
      !!this.props.value && formatted !== stringified && this.props.tooltipEnabled
        ? '' + this.props.value
        : ''

    return (
      <SROTooltip text={tooltipText}>
        <div className={classnames('number-view')}>
          <span>{formatted}</span>
        </div>
      </SROTooltip>
    )
  }
}

NumberView.propTypes = {
  value: PropTypes.number,
  tooltipEnabled: PropTypes.bool,
}

NumberView.defaultProps = {
  tooltipEnabled: true,
}

export default NumberView
