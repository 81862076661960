import { combineReducers } from 'redux'
import {
  AnalyticsDataReceivedAction,
  AnalyticsDataRequestedAction,
} from '../actions/analyticsAction'
import { SROMoment } from '../utils/timeUtils'

const DEFAULT_PARAMS = {
  scoreByCategoryMonth: SROMoment.now().formatShortMonthYear(),
}

function params(state = DEFAULT_PARAMS, action) {
  if (action.type !== AnalyticsDataRequestedAction.NAME) {
    return state
  }

  return action.params
}

function data(state = null, action) {
  switch (action.type) {
    case AnalyticsDataReceivedAction.NAME: {
      return action.data
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  params,
  data,
})
