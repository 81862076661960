import React from 'react'
import _ from 'lodash'
import { v1 as uuidv1 } from 'uuid'
import PropTypes from 'prop-types'

export class SimpleCollection extends React.Component {
  constructor(props) {
    super(props)
    this.state = Object.assign({}, this.state, {
      items: _.map(this.props.defaultItemList, this.addKey),
    })
  }

  addKey(item) {
    return Object.assign({}, item, {
      key: uuidv1(),
      ref: React.createRef(),
    })
  }

  onRemove(index) {
    const newItems = _.concat(this.state.items.slice(0, index), this.state.items.slice(index + 1))
    this.setState({ items: newItems })
  }

  addItem(item) {
    this.setState({
      items: _.concat(this.state.items, [this.addKey(item)]),
    })
  }

  serialize() {
    return _.map(this.state.items, (item) => item.ref.current.serialize())
  }

  swapRatings(indexA, indexB) {
    const newItems = _.clone(this.state.items)
    newItems[indexA] = _.clone(this.state.items[indexB])
    newItems[indexB] = _.clone(this.state.items[indexA])
    this.setState({
      items: newItems,
    })
  }

  render() {
    return (
      <div className="simple-collection">
        {_.map(this.state.items, (itemData, index) => {
          const fullItemData = Object.assign({}, itemData, {
            onRemove: () => this.onRemove(index),
            onMoveUp: index > 0 ? () => this.swapRatings(index, index - 1) : undefined,
            onMoveDown:
              index < this.state.items.length - 1
                ? () => this.swapRatings(index, index + 1)
                : undefined,
          })
          return this.props.renderItem(fullItemData)
        })}
      </div>
    )
  }
}

SimpleCollection.propTypes = {
  renderItem: PropTypes.func,
  defaultItemList: PropTypes.array,
}
