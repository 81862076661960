import { combineReducers } from 'redux'

import {
  SettingsOpenAction,
  Step1SaveSuccess,
  Step2SaveSuccess,
  Step3SaveSuccess,
  AccountDataReceivedAction,
  STEP_1_NAME,
  STEP_2_NAME,
  STEP_3_NAME,
  SaveStep3Action,
  Step1SaveFailure,
  Step2SaveFailure,
  Step3SaveFailure,
  STEP_3_READY,
  STEP_3_DONE,
  STEP_3_SAVING,
  STEP_3_ERROR,
  GoToStep,
  CloseSubModalAction,
} from '../actions/settings'
import {
  ClientSummarySimulationError,
  ClientSummarySimulationResponseAction,
} from '../actions/clientSummarySimulator'

function stepNum(state = STEP_1_NAME, action) {
  switch (action.type) {
    case SettingsOpenAction.NAME: {
      return STEP_1_NAME
    }
    case Step1SaveSuccess.NAME: {
      return STEP_2_NAME
    }
    case Step2SaveSuccess.NAME: {
      return STEP_3_NAME
    }
    case GoToStep.NAME: {
      return action.destStep
    }
    default: {
      return state
    }
  }
}

function accountData(state = null, action) {
  if (action.type !== AccountDataReceivedAction.NAME) {
    return state
  }

  return action.data
}

function step3status(state = STEP_3_READY, action) {
  switch (action.type) {
    case SaveStep3Action.NAME: {
      return Object.assign(STEP_3_SAVING, { showModal: true })
    }
    case Step2SaveSuccess.NAME: {
      return Object.assign(STEP_3_READY, { showModal: true })
    }
    case Step3SaveSuccess.NAME: {
      return Object.assign({}, STEP_3_DONE, { showModal: true })
    }
    case Step3SaveFailure.NAME: {
      return Object.assign({}, STEP_3_ERROR, { error: action.error, showModal: true })
    }
    case CloseSubModalAction.NAME: {
      return Object.assign({}, state, { showModal: false })
    }
    default: {
      return state
    }
  }
}

function error(state = '', action) {
  switch (action.type) {
    case SettingsOpenAction.NAME:
    case Step1SaveSuccess.NAME:
    case Step2SaveSuccess.NAME:
    case Step3SaveSuccess.NAME:
    case ClientSummarySimulationResponseAction.NAME: {
      return ''
    }
    case Step1SaveFailure.NAME:
    case Step2SaveFailure.NAME:
    case Step3SaveFailure.NAME:
    case ClientSummarySimulationError.NAME: {
      return action.error
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  stepNum,
  accountData,
  step3status,
  error,
})
