import React from 'react'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ToggleButton } from '../../components/widgets/Buttons'
import icons from '../../components/icons'
import SmallRouteLink from '../widgets/RouteLink/SmallRouteLink'
import NumberView from '../widgets/NumberView'
import SROTooltip from '../widgets/SROTooltip'

const AgentDateRow = (props) => {
  const { data, onToggle, openLines, agentId, date, counts } = props
  const translate = useTranslate()

  return (
    <tr>
      <td className="agent-date-col">
        <ToggleButton
          title={translate('SHOW_DETAILS')}
          className="agent-date-toggle"
          onClick={() => onToggle(data.id)}
          value={openLines[data.id]}
          displayBlock
        />
        <div className="date-text">{data.date.formatDateShort()}</div>
      </td>
      <td className={classnames('agent-date-col')}>
        <SROTooltip text={translate('PLANNED_VISITS')}>
          <icons.planned className="planned" />
          <NumberView value={counts.plannedCount} />
        </SROTooltip>
      </td>
      <td className={classnames('agent-date-col')}>
        <SROTooltip text={translate('UNPLANNED_VISITS')}>
          <icons.user className="unplanned" />
          <NumberView value={counts.unplannedCount} />
        </SROTooltip>
      </td>
      <td className={classnames('agent-date-col')}>
        <SROTooltip text={translate('DAY_TRAVEL_DISTANCE')}>
          <icons.location />
          <NumberView value={data.totalDayKM} />
          <span>&nbsp;KM</span>
        </SROTooltip>
      </td>
      <td className={classnames('agent-date-col', 'travel-times')}>
        <SmallRouteLink
          mapLink={data.mapLink}
          dateStartTime={data.dateStartTime}
          dateEndTime={data.dateEndTime}
          agentId={agentId}
          date={date}
        />
      </td>
      <td className="agent-date-col">&nbsp;</td>
    </tr>
  )
}

AgentDateRow.propTypes = {
  data: PropTypes.object,
  onToggle: PropTypes.func,
  openLines: PropTypes.object,
  agentId: PropTypes.number,
  date: PropTypes.string,
  counts: PropTypes.shape({
    plannedCount: PropTypes.number,
    unplannedCount: PropTypes.number,
  }),
}

export default AgentDateRow
