import { useQuery } from 'react-admin'

import { MapDetailsResource } from '../providers/resources/requestResources'
import AgentLocationTransformer from '../providers/transformers/agentLocationTransformer'

export function useTripMapData(params) {
  const { nearbyOnly } = params

  const { data, loading, error } = useQuery({
    type: 'GET_RAW',
    resource: MapDetailsResource.RESOURCE_NAME,
    payload: {
      ...params,
      date: params.date.formatDate(),
    },
  })

  if (data) {
    const { agent, agentLastLocation, nearbyClients, activities, routes, agentFirstLocation } = data

    const points = new AgentLocationTransformer().getPoints({
      agent,
      agentLastLocation,
      nearbyClients,
      activities,
      nearbyOnly,
      agentFirstLocation,
    })

    return {
      data: {
        agentLastLocation,
        activities,
        routes,
        points,
      },
      loading,
      error,
    }
  }

  return { data: undefined, loading, error }
}
