import { connect } from 'react-redux'
import { UserCreateView, UserEditView } from '../components/UserView'
import { AccountListRequestedAction } from '../actions/accountListActions'
import { AgentTeamListRequestedAction } from '../actions/agentTeamListActions'
import { AgentListRequestedAction } from '../actions/agentListActions'

class UserMapping {
  static mapStateToProps(state) {
    return {
      allAccounts: state.customReducers.accountList.list,
      allAgentTeams: state.customReducers.agentTeamList.list,
      allAgents: state.customReducers.agentList.list,
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      fetchAllFormOptions: () => {
        dispatch(AccountListRequestedAction.make())
        dispatch(AgentTeamListRequestedAction.make())
        dispatch(AgentListRequestedAction.make())
      },
    }
  }
}

export const UserCreate = connect(
  UserMapping.mapStateToProps,
  UserMapping.mapDispatchToProps,
)(UserCreateView)
export const UserEdit = connect(
  UserMapping.mapStateToProps,
  UserMapping.mapDispatchToProps,
)(UserEditView)
