export async function runArrayInParallel(promiseArray) {
  const results = []

  for (const promiseIndex in promiseArray) {
    results.push(await promiseArray[promiseIndex])
  }

  return results
}

export async function runObjectInParallel(promiseObject) {
  const results = {}

  for (const promiseKey in promiseObject) {
    results[promiseKey] = await promiseObject[promiseKey]
  }

  return results
}

export async function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}
