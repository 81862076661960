import Action from './action'

export class AnalyticsDataReceivedAction extends Action {
  static NAME = 'analytics-data-received-action'

  static getExtraData(data) {
    return { data }
  }
}

export class AnalyticsDataRequestedAction extends Action {
  static NAME = 'analytics-data-requested-action'

  static getExtraData(params) {
    return { params }
  }
}
