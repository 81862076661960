import _ from 'lodash'

function calculateNumClientsPerAgentRate(simulatePerClientRating, clientToAgentId) {
  return _.reduce(
    simulatePerClientRating,
    (aggResult, clientRating) => {
      const newAggResult = _.cloneDeep(aggResult)
      const agentId = clientToAgentId[clientRating.clientId]
      const path = [agentId, clientRating.rateName]
      _.set(newAggResult, path, _.get(newAggResult, path, 0) + 1)
      return newAggResult
    },
    {},
  )
}

function calculateAgentStats(numClientsPerAgentRate, visitFrequencyDaysPerRate) {
  const result = {}
  for (const agentId of Object.keys(numClientsPerAgentRate)) {
    const numClientsPerRate = numClientsPerAgentRate[agentId]
    const totalNumClients = _(numClientsPerRate).values().sum()

    result[agentId] = _(numClientsPerRate)
      .map((numClients, rateName) => {
        const visitFrequencyDays = visitFrequencyDaysPerRate[rateName]
        const absoluteVisits = Math.round(numClientsPerRate[rateName] * (30 / visitFrequencyDays))
        return {
          rateName,
          absolute: numClients,
          percent: (100 * numClients) / totalNumClients,
          absoluteVisits,
        }
      })
      .keyBy('rateName')
      .value()
  }
  return result
}

export function processClientSimulator({
  clients,
  simulatePerClientRating,
  visitFrequencyDaysPerRate,
}) {
  const clientIdToAgentId = _(clients)
    .keyBy('id')
    .mapValues((c) => c.agentId)
    .value()

  const numClientsPerAgentRate = calculateNumClientsPerAgentRate(
    simulatePerClientRating,
    clientIdToAgentId,
  )

  return calculateAgentStats(numClientsPerAgentRate, visitFrequencyDaysPerRate)
}
