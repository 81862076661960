import { useQuery } from 'react-admin'
import _ from 'lodash'

import { SROMoment } from '../utils/timeUtils'

import { DEFAULT_QUERY } from '../providers/queryTools'
import { numberFormat } from '../utils/stringUtils'
import {
  RawReportActivitiesResource,
  AnnualActivityResource,
  AgentResource,
} from '../providers/resources/requestResources'
import { AnalyticsResource } from '../providers/resources/comboResources'

function calculateSales(rawReportActivities, salesActualKey = 'salesActual') {
  const { salesActual, salesTarget } = rawReportActivities.records.reduce(
    (state, current) => {
      state.salesActual += current[salesActualKey]
      state.salesTarget += current.salesTarget
      return state
    },
    { salesActual: 0, salesTarget: 0 },
  )
  return {
    percent: Math.round((100 * salesActual) / salesTarget || 0),
    absActual: salesActual,
    absPlanned: salesTarget,
  }
}

function calculateSalesByAgent(rawReportActivities, salesActualKey = 'salesActual') {
  return rawReportActivities.records
    .map((record) => ({
      name: `${record.agentName}: ${numberFormat(record[salesActualKey])}`,
      value: record[salesActualKey],
    }))
    .sort((a, b) => b.value - a.value)
}

export function useSalesDashboardData({ agentId, month }) {
  const now = SROMoment.now()

  const { data: reportActivityYear, loading: reportActivityYearLoading } = useQuery({
    type: 'GET_RAW',
    resource: RawReportActivitiesResource.RESOURCE_NAME,
    payload: {
      agentId,
      from: now.startOf('year').formatDate(),
      to: now.endOf('year').formatDate(),
      dimensions: ['agentId'],
    },
  })
  const { data: reportActivityMonth, loading: reportActivityMonthLoading } = useQuery({
    type: 'GET_RAW',
    resource: RawReportActivitiesResource.RESOURCE_NAME,
    payload: {
      agentId,
      from: month.monthStart.formatDate(),
      to: month.monthEnd.formatDate(),
      dimensions: ['agentId', 'month'],
    },
  })
  const { data: reportActivityDay, loading: reportActivityDayLoading } = useQuery({
    type: 'GET_RAW',
    resource: RawReportActivitiesResource.RESOURCE_NAME,
    payload: {
      agentId,
      from: now.formatDate(),
      to: now.formatDate(),
      dimensions: ['agentId', 'date'],
    },
  })
  const { data: reportMonthlyCurrentYear, loading: reportMonthlyCurrentYearLoading } = useQuery({
    type: 'GET_RAW',
    resource: AnnualActivityResource.RESOURCE_NAME,
    payload: {
      agentId: AgentResource.getQueryAgentId(agentId),
      from: now.startOf('year').formatDate(),
      to: now.endOf('year').formatDate(),
    },
  })
  const { data: reportMonthlyLastYear, loading: reportMonthlyLastYearLoading } = useQuery({
    type: 'GET_RAW',
    resource: AnnualActivityResource.RESOURCE_NAME,
    payload: {
      agentId: AgentResource.getQueryAgentId(agentId),
      from: now.add(-1, 'year').startOf('year').formatDate(),
      to: now.add(-1, 'year').endOf('year').formatDate(),
    },
  })
  const result = useQuery({
    type: 'GET_RAW',
    resource: AnalyticsResource.RESOURCE_NAME,
    payload: {
      scoreByCategoryMonth: month.monthStart.formatShortMonthYear(),
    },
  })
  const { data: analyticsReport, loading: analyticsReportLoading } = result
  const agentPayload = _.cloneDeep(DEFAULT_QUERY)
  agentPayload.filter.isActive = true
  const { data: agents, loading: agentsLoading } = useQuery({
    type: 'GET_LIST',
    resource: AgentResource.RESOURCE_NAME,
    payload: agentPayload,
  })

  const loading =
    reportActivityYearLoading ||
    reportActivityMonthLoading ||
    reportActivityDayLoading ||
    reportMonthlyCurrentYearLoading ||
    reportMonthlyLastYearLoading ||
    analyticsReportLoading ||
    agentsLoading

  if (loading) {
    return { data: undefined, loading }
  }

  return {
    data: {
      salesYear: calculateSales(reportActivityYear),
      salesMonth: calculateSales(reportActivityMonth),
      salesDay: calculateSales(reportActivityDay, 'orderActual'),
      salesByAgentYear: calculateSalesByAgent(reportActivityYear),
      salesByAgentMonth: calculateSalesByAgent(reportActivityMonth),
      salesByAgentDay: calculateSalesByAgent(reportActivityDay, 'orderActual'),
      reportMonthlyCurrentYear,
      reportMonthlyLastYear,
      analyticsReport,
      agents,
    },
    loading,
  }
}
