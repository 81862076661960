import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

import classnames from 'classnames'
import _ from 'lodash'

import { messages } from '../../i18n/messages'
import { SROMoment } from '../../utils/timeUtils'
import { useReportActivityDoPrintBack } from '../../hooks/dailyActivityHooks'

import SROTooltip from '../widgets/SROTooltip'

const FieldCol = ({ titleKey, value }) => {
  return (
    <td className="field-col">
      <div className="field-col-title">{messages[titleKey]}</div>
      <div className="field-col-value">{value}</div>
    </td>
  )
}

FieldCol.propTypes = {
  titleKey: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const DoPrintBackButton = ({ externalAgentId, activityId, initialDoPrintBack }) => {
  const [doPrintBack, setDoPrintBack] = useState(initialDoPrintBack)

  const { dispatch } = useReportActivityDoPrintBack()

  function onClick() {
    if (!doPrintBack) {
      dispatch({ externalAgentId, activityId, doPrintBack: !doPrintBack })
      setDoPrintBack(!doPrintBack)
    }
  }

  const buttonMsg = doPrintBack ? messages['PRINT_BACK_SENT'] : messages['PRINT_BACK']

  return (
    <td className="field-col">
      <div className="field-col-title"></div>
      <div className="field-col-value">
        <Button className="do-print-back-checkbox" disabled={doPrintBack} onClick={onClick}>
          {buttonMsg}
        </Button>
      </div>
    </td>
  )
}

DoPrintBackButton.propTypes = {
  externalAgentId: PropTypes.string,
  activityId: PropTypes.string,
  initialDoPrintBack: PropTypes.bool,
}

const OrderDrilldownView = ({ onHide, shown, lineData }) => {
  function renderNote(lineData) {
    if (!lineData.note) {
      return ''
    }

    return (
      <SROTooltip position="right" text={lineData.note}>
        💡
      </SROTooltip>
    )
  }

  function renderLine(lineData) {
    const className = lineData.isColored ? 'colored' : ''

    return (
      <tr key={lineData.lineNumber} className={className}>
        <td>{lineData.lineNumber}</td>
        <td>{lineData.itemCode}</td>
        <td>{lineData.itemName}</td>
        <td>{lineData.quantity}</td>
        <td>{lineData.packageQuantity}</td>
        <td>{lineData.numberOfPackages}</td>
        <td>{lineData.price}</td>
        <td>{`${lineData.discount}%`}</td>
        <td>{lineData.bonus}</td>
        <td>{lineData.total}</td>
        <td>{renderNote(lineData)}</td>
      </tr>
    )
  }

  function formatDate(raw) {
    try {
      return SROMoment.fromServerString(raw).formatDateShort()
    } catch {
      return ''
    }
  }

  function getModalData() {
    const modalData = Object.assign({}, lineData, {
      documentType: _.get(lineData, 'documentDetails.documentType', ''),
      documentNumber: _.get(lineData, 'documentDetails.documentNumber', ''),
      clientName: _.get(lineData, 'clientName', ''),
      orderDate: formatDate(_.get(lineData, 'orderDate', '')),
      agentName: _.get(lineData, 'agentName', ''),
      reference: _.get(lineData, 'documentDetails.reference', ''),
      supplyDate: formatDate(_.get(lineData, 'documentDetails.supplyTime', '')),
      total: _.get(lineData, 'documentDetails.total', 0),
      discount: _.get(lineData, 'documentDetails.discount', 0),
      vat: _.get(lineData, 'documentDetails.vat', 0),
      note: _.get(lineData, 'documentDetails.note', 0),
      externalClientId: _.get(lineData, 'externalClientId'),
      doPrintBack: _.get(lineData, 'doPrintBack', false),
    })

    modalData.totalIncludingVat = modalData.total * (1 + modalData.vat / 100)
    modalData.title = `${modalData.documentType} ${modalData.documentNumber}`

    return modalData
  }

  function renderNoInfo() {
    return <div className="no-info">{messages['NO_INFO_TO_DISPLAY']}</div>
  }

  function renderModalContent(modalData) {
    if (!modalData.total) {
      return renderNoInfo()
    }

    return (
      <>
        <table dir="rtl">
          <tbody>
            <tr>
              <FieldCol titleKey="CLIENT_NAME" value={modalData.clientName} />
              <FieldCol titleKey="CLIENT_CODE" value={modalData.externalClientId} />
            </tr>
            <tr>
              <FieldCol titleKey="ORDER_DATE" value={modalData.orderDate} />
              <FieldCol titleKey="AGENT_NAME" value={modalData.agentName} />
            </tr>
            <tr>
              <FieldCol titleKey="REFERENCE_NUM" value={modalData.reference} />
              <FieldCol titleKey="SUPPLY_DATE" value={modalData.supplyDate} />
            </tr>
          </tbody>
        </table>
        <div className="order-drilldown-list">
          <table dir="rtl">
            <thead>
              <tr>
                <th>{messages['LINE_NUM']}</th>
                <th>{messages['ITEM_CODE']}</th>
                <th>{messages['ITEM_NAME']}</th>
                <th>{messages['QUANTITY']}</th>
                <th>{messages['ITEMS_PER_PACKAGE']}</th>
                <th>{messages['AMOUNT_OF_PACKAGES']}</th>
                <th>{messages['PRICE']}</th>
                <th>{messages['DISCOUNT']}</th>
                <th>{messages['BONUS']}</th>
                <th>{messages['TOTAL']}</th>
                <th>{messages['COMMENT']}</th>
              </tr>
            </thead>
            <tbody>{_.map(_.get(lineData, 'documentDetails.lines', []), renderLine)}</tbody>
          </table>
        </div>
        <table dir="rtl">
          <tbody>
            <tr>
              <FieldCol titleKey="TOTAL" value={modalData.total} />
              <FieldCol titleKey="DISCOUNT" value={`${modalData.discount}%`} />
            </tr>
            <tr>
              <FieldCol titleKey="VAT" value={`${modalData.vat}%`} />
              <FieldCol
                titleKey="TOTAL_INCLUDING_VAT"
                value={(modalData.totalIncludingVat || 0).toFixed(2)}
              />
            </tr>
            <tr>
              <FieldCol titleKey="COMMENT" value={modalData.note} />
              <DoPrintBackButton
                key={modalData.activityId}
                externalAgentId={modalData.externalAgentId}
                activityId={modalData.activityId}
                initialDoPrintBack={modalData.doPrintBack}
              />
            </tr>
          </tbody>
        </table>
      </>
    )
  }

  const modalData = getModalData()

  return (
    <div className="order-drilldown-modal-point">
      <div
        className={classnames({ 'order-drilldown-modal': true, shown: !!shown[lineData.clientId] })}
      >
        <div className="order-drilldown-modal-x" onClick={() => onHide(lineData.clientId)}>
          𐌢
        </div>
        <div className="order-drilldown-modal-title">{modalData.title}</div>
        <div className="order-drilldown-modal-content">{renderModalContent(modalData)}</div>
      </div>
    </div>
  )
}

OrderDrilldownView.propTypes = {
  shown: PropTypes.object,
  onHide: PropTypes.func,
  lineData: PropTypes.object,
}

export default OrderDrilldownView
