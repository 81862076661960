import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import { messages } from '../../i18n/messages'
import Icon from '../icons'
import { STEP_3_ERROR, STEP_3_SAVING, STEP_3_DONE } from '../../actions/settings'

class SubModal extends React.Component {
  static STATUS = undefined

  constructor(props) {
    super(props)

    this.error = props.error || ''
  }

  render() {
    return (
      <div className="full-screen-cover">
        <div className="sub-modal">{this.renderContent(this.error)}</div>
      </div>
    )
  }

  renderContent() {
    throw new Error('Not implemented')
  }
}

SubModal.propTypes = {
  error: PropTypes.string,
}

class SubModalInProgress extends SubModal {
  static STATUS = STEP_3_SAVING.status

  renderContent() {
    return (
      <div className="submodal-content submodal-in-progress">
        <div className="in-progress-text">{messages.WAIT_PLANNING_SCHEDULE}</div>
        <Icon.spinner />
      </div>
    )
  }
}

class SubModalErrors extends SubModal {
  static STATUS = STEP_3_ERROR.status

  renderContent(error) {
    return (
      <div className="submodal-content submodal-errors">
        <Icon.x onClick={this.props.onClose} />
        {messages.CORRECT_ERRORS_BEFORE_SAVING}
        <p>{error}</p>
      </div>
    )
  }
}

class SubModalSuccess extends SubModal {
  static STATUS = STEP_3_DONE.status

  renderContent() {
    return (
      <div className="submodal-content submodal-success">
        <div className="success-text">{messages.FINISHED_LOADING_REDIRECTING_MAIN}</div>
        <Icon.successV />
      </div>
    )
  }
}

export const SUB_MODALS = _.keyBy(
  [SubModalInProgress, SubModalErrors, SubModalSuccess],
  (submodal) => submodal.STATUS,
)
