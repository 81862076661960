import React from 'react'
import PropTypes from 'prop-types'

import { getRandomString } from '../../../utils/stringUtils'
import { Pie } from './pieUtil'

import './pie-gauge.scss'

const RADIUS = 65
const THICKNESS = 8
const MARGIN = 3
const CENTER = RADIUS + MARGIN
const SIDE_SIZE = (RADIUS + MARGIN) * 2

class PieGauge extends React.Component {
  render() {
    const perc = window.isFinite(this.props.perc) ? Math.round(this.props.perc) || 0 : 0
    const pie = new Pie(RADIUS, CENTER, THICKNESS)
    const gradientId = `fill_${getRandomString()}`
    return (
      <div className="pie-wrap">
        <svg height={SIDE_SIZE} width={SIDE_SIZE}>
          <defs>
            <linearGradient id={gradientId} x1="50%" y1="0%" x2="50%" y2="100%">
              <stop offset="0%" style={{ stopColor: this.props.color1 }} />
              <stop offset="100%" style={{ stopColor: this.props.color2 }} />
            </linearGradient>
          </defs>
          <ellipse cx={CENTER} cy={CENTER} rx={RADIUS} ry={RADIUS} fill={`url(#${gradientId})`} />
          <polygon points={pie.makeSlicePoints(perc)} fill={this.props.emptyColor} />
          <ellipse
            cx={CENTER}
            cy={CENTER}
            rx={RADIUS - THICKNESS}
            ry={RADIUS - THICKNESS}
            fill={this.props.backgroundColor}
          />
        </svg>
        <div className="percentage-wrapper">
          <div className="percentage">{`${perc}%`}</div>
        </div>
      </div>
    )
  }
}

PieGauge.propTypes = {
  perc: PropTypes.number,
  color1: PropTypes.string,
  color2: PropTypes.string,
  emptyColor: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default PieGauge
