import { connect } from 'react-redux'

import view from './view'
import { RequestAction } from '../../../../actions/requestAction'
import { UPDATE } from 'ra-core'

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  save: ({ resource, data }) =>
    dispatch(
      RequestAction.make({
        resource: resource,
        requestType: UPDATE,
        params: data,
      }),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(view)
