import _ from 'lodash'

import { REQUEST_RESOURCE_CLASSES } from './requestResources'
import { COMBO_RESOURCE_CLASSES } from './comboResources'

const allResourceClasses = _.concat(REQUEST_RESOURCE_CLASSES, COMBO_RESOURCE_CLASSES)

const resources = _.mapKeys(allResourceClasses, (resource) => resource.RESOURCE_NAME)

export const getResourceClassByName = (name) => resources[name]

export const getResourceByName = (name) => {
  const ResourceClass = getResourceClassByName(name)

  if (!ResourceClass) {
    return null
  }

  return new ResourceClass()
}

export const getResourceByPathName = (pathName) => getResourceByName(pathName.replace('/', ''))
