import React, { Component, createElement } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { withRouter } from 'react-router'
import { MuiThemeProvider, createTheme, withStyles, createStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose'
import { Sidebar, Notification, Error, defaultTheme } from 'react-admin'
import _ from 'lodash'

import Menu from './Menu'
import { getResourceByPathName } from '../providers/resources/resources'
import SRONotification from './widgets/SRONotification'

import './sidebar.scss'

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      minWidth: 'fit-content',
      width: '100%',
    },
    appFrame: {
      display: 'flex',
      flexDirection: 'column',
    },
    contentWithSidebar: {
      display: 'flex',
      flexGrow: 1,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexBasis: 0,
      padding: theme.spacing(3),
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 5,
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
  })

const sanitizeRestProps = (props) =>
  _.omit(props, ['staticContext', 'history', 'location', 'match'])

function getContainerClassName(props) {
  try {
    return getResourceByPathName(props.location.pathname).getContainerClassName()
  } catch {
    return ''
  }
}

class Layout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      hasError: false,
      errorMessage: null,
      errorInfo: null,
      isMenuOpen: true,
    }

    /**
     * Reset the error state upon navigation
     *
     * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
     */
    props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false })
      }
    })

    this.toggleMenu = this.toggleMenu.bind(this)
  }

  componentDidCatch(errorMessage, errorInfo) {
    this.setState({ hasError: true, errorMessage, errorInfo })
  }

  toggleMenu() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen })
  }

  render() {
    const {
      children,
      classes,
      className,
      error,
      dashboard,
      logout,
      menu,
      notification,
      sidebar,
      title,
      ...props
    } = this.props
    const { hasError, errorMessage, errorInfo } = this.state
    const containerClassName = getContainerClassName(props)
    const notificationElement = createElement(notification)
    const resource = getResourceByPathName(props.location.pathname)
    const showSidebar = !!_.get(resource, 'constructor.SHOW_SIDEBAR', true)

    return (
      <div className={classnames('layout', classes.root, className)} {...sanitizeRestProps(props)}>
        <div className={classes.appFrame}>
          <main className={classes.contentWithSidebar}>
            {createElement(
              sidebar,
              {
                id: 'sidebar',
                className: classnames('sro-sidebar', {
                  open: this.state.isMenuOpen,
                  hide: !showSidebar,
                }),
              },
              createElement(menu, {
                logout,
                toggleMenu: this.toggleMenu,
                hasDashboard: !!dashboard,
              }),
            )}
            <div className={classnames(classes.content, containerClassName)}>
              {hasError
                ? createElement(error, {
                    error: errorMessage,
                    errorInfo,
                    title,
                  })
                : children}
            </div>
            {SRONotification.getElement()}
          </main>
          {notificationElement}
        </div>
      </div>
    )
  }
}

const componentPropType = PropTypes.oneOfType([PropTypes.func, PropTypes.string])

Layout.propTypes = {
  appBar: componentPropType,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  classes: PropTypes.object,
  className: PropTypes.string,
  customRoutes: PropTypes.array,
  dashboard: componentPropType,
  error: componentPropType,
  history: PropTypes.object.isRequired,
  logout: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
  menu: componentPropType,
  notification: componentPropType,
  open: PropTypes.bool,
  sidebar: componentPropType,
  title: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  error: Error,
  menu: Menu,
  notification: Notification,
  sidebar: Sidebar,
}

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
})

const EnhancedLayout = compose(
  connect(
    mapStateToProps,
    {}, // Avoid connect passing dispatch in props
  ),
  withRouter,
  withStyles(styles),
)(Layout)

class LayoutWithTheme extends Component {
  constructor(props) {
    super(props)
    this.theme = createTheme({
      ...props.theme,
      typography: {
        fontFamily: "'Ubuntu', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      },
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.theme !== this.props.theme) {
      this.theme = createTheme(nextProps.theme)
    }
  }

  render() {
    return (
      <MuiThemeProvider theme={this.theme}>
        <EnhancedLayout {..._.omit(this.props, 'theme')} />
      </MuiThemeProvider>
    )
  }
}

LayoutWithTheme.propTypes = {
  theme: PropTypes.object,
}

LayoutWithTheme.defaultProps = {
  theme: defaultTheme,
}

export default LayoutWithTheme
