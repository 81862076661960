import MapModel from './mapModel'

export default class MapController {
  constructor(options) {
    this.model = new MapModel(options)

    this.centralize = this.centralize.bind(this)
    this.changeZoom = this.changeZoom.bind(this)
    this.getModel = this.getModel.bind(this)
    this.updateBounds = this.updateBounds.bind(this)
  }

  getModel() {
    return this.model
  }

  changeZoom(delta) {
    this.model.changeZoom(delta)
  }

  setZoom(zoom) {
    this.model.setZoom(zoom)
  }

  centralize(isInitial = true) {
    this.model.centralize(isInitial)
  }

  updateBounds(bounds) {
    this.model.updateBounds(bounds)
  }
}
