import Action from './action'

export const STEP_1_NAME = 1
export const STEP_2_NAME = 2
export const STEP_3_NAME = 3
export const SETTINGS_DONE = null

export const STEP_3_READY = {
  status: 'ready',
}

export const STEP_3_SAVING = {
  status: 'saving',
}

export const STEP_3_ERROR = {
  status: 'error',
}

export const STEP_3_DONE = {
  status: 'done',
}

export class FetchAccountAction extends Action {
  static NAME = 'settings-account-fetch'

  static getExtraData(accountId, options) {
    return { accountId, options }
  }
}

export class AccountDataReceivedAction extends Action {
  static NAME = 'settings-account-data-received'

  static getExtraData(data) {
    return { data }
  }
}

export class SettingsOpenAction extends Action {
  static NAME = 'settings-start'
}

export class GoToStep extends Action {
  static NAME = 'settings-go-to-step'

  static getExtraData(destStep) {
    return { destStep }
  }
}

class StepSaveFailure extends Action {
  static getExtraData(error) {
    return { error }
  }
}

// Step 1 actions

export class SaveStep1Action extends Action {
  static NAME = 'settings-save-step-1'

  static getExtraData(data) {
    return { data }
  }
}

export class Step1SaveSuccess extends Action {
  static NAME = 'settings-step-1-save-success'
}

export class Step1SaveFailure extends StepSaveFailure {
  static NAME = 'settings-step-1-save-failure'
}

// Step 2 actions

export class SaveStep2Action extends Action {
  static NAME = 'settings-save-step-2'

  static getExtraData(data) {
    return { data }
  }
}

export class Step2SaveSuccess extends Action {
  static NAME = 'settings-step-2-save-success'
}

export class Step2SaveFailure extends StepSaveFailure {
  static NAME = 'settings-step-2-save-failure'
}

// Step 3 actions

export class SaveStep3Action extends Action {
  static NAME = 'settings-save-step-3'

  static getExtraData(data) {
    return { data }
  }
}

export class Step3SaveSuccess extends Action {
  static NAME = 'settings-step-3-save-success'
}

export class Step3SaveFailure extends StepSaveFailure {
  static NAME = 'settings-step-3-save-failure'
}

export class CloseSubModalAction extends Action {
  static NAME = 'settings-step-3-close-sub-modal'
}
