import React from 'react'
import PropTypes from 'prop-types'

import ClientRatingSummary from '../ClientRatingSummary'
import Icon from '../icons'

export default class ClientRatingSummaryModal extends React.Component {
  renderContent() {
    if (!this.props.agentSummary) {
      return (
        <div>
          <Icon.loading className="initial-loading" />
        </div>
      )
    }

    return <ClientRatingSummary {...this.props} />
  }

  render() {
    return (
      <div className="client-rating-summary-modal-wrapper">
        <div className="client-rating-summary-modal">{this.renderContent()}</div>
      </div>
    )
  }
}

ClientRatingSummaryModal.propTypes = {
  agentSummary: PropTypes.object,
}
