import _ from 'lodash'
import polyglotI18nProvider from 'ra-i18n-polyglot'

import englishMessages from './englishMessages'
import hebrewMessages from './hebrewMessages'
import { localeEntry } from '../providers/localStorageProvider'

const messageTranslations = {
  en: englishMessages,
  he: hebrewMessages,
}

const defaultLocale = localeEntry.get() || 'he'

export default polyglotI18nProvider((locale) => messageTranslations[locale], defaultLocale)

export const fill = (message, dict) =>
  _(dict)
    .map((value, key) => ({ key, value }))
    .reduce((state, { key, value }) => state.replace('%{' + key + '}', value), message)

export const messages = messageTranslations[defaultLocale]

export function isRTL() {
  return defaultLocale === 'he'
}
