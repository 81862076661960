import Action from './action'

export class ClientSummarySimulationRequestAction extends Action {
  static NAME = 'settings-step-3-fetch-client-summary-request-simulator'

  static getExtraData(accountId, data) {
    return { accountId, data }
  }
}

export class ClientSummarySimulationResponseAction extends Action {
  static NAME = 'settings-step-3-fetch-client-summary-response-simulator'

  static getExtraData(data) {
    return { data }
  }
}

export class ClientSummarySimulationError extends Action {
  static NAME = 'settings-step-3-fetch-client-summary-error'

  static getExtraData(error) {
    return { error }
  }
}
