import { put, takeEvery } from 'redux-saga/effects'
import {
  AgentTeamListRequestedAction,
  AgentTeamListReceivedAction,
} from '../actions/agentTeamListActions'
import Requester from '../providers/request'
import { AgentTeamResource } from '../providers/resources/requestResources'

function* fetch() {
  const data = yield new Requester().getEverything(AgentTeamResource)
  yield put(AgentTeamListReceivedAction.make(data.data))
}

export default function* tripMapSaga() {
  yield takeEvery(AgentTeamListRequestedAction.NAME, fetch)
}
