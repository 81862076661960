import React from 'react'

import _ from 'lodash'

import { messages } from '../../../i18n/messages'

import './trip-map-shadow.scss'

export default class TripMapShadow extends React.Component {
  render() {
    return (
      <div className="trip-shadow">
        <div className="table-pane">
          <div className="table-items">
            {_.map(_.range(0, 8), (index) => (
              <div className="table-line" key={index} />
            ))}
          </div>
        </div>
        <div className="map-pane">
          <div className="loading-map-text">{messages.LOADING_MAP}</div>
        </div>
      </div>
    )
  }
}
