import dataProvider, { GET_RAW } from './dataProvider'

import { DEFAULT_QUERY, ALL_ITEMS_QUERY } from './queryTools'
import { GET_LIST, GET_ONE, UPDATE, CREATE, GET_MANY } from 'ra-core'

export default class Requester {
  async makeRequest(requestType, resource, params = DEFAULT_QUERY) {
    return await dataProvider(requestType, resource.RESOURCE_NAME, params)
  }

  async getEverything(resource) {
    return await this.getList(resource, ALL_ITEMS_QUERY)
  }

  async getList(resource, params) {
    return await this.makeRequest(GET_LIST, resource, params)
  }

  async getMany(resource, params) {
    return await this.makeRequest(GET_MANY, resource, params)
  }

  async getRaw(resource, params) {
    return await this.makeRequest(GET_RAW, resource, params)
  }

  async getOne(resource, id) {
    return await this.makeRequest(GET_ONE, resource, { id })
  }

  async update(resource, data) {
    return await this.makeRequest(UPDATE, resource, data)
  }

  async post(resource, data) {
    return await this.makeRequest(CREATE, resource, data)
  }

  async patch(resource, data) {
    // fixme: According to the standards we need to send a PATCH request when updating only part of the object,
    // but currently on the server PUT behaves like PATCH, and PATCH is not implemented
    return await this.update(resource, { id: data.id, data })
  }
}
