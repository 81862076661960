import { GET_LIST, GET_MANY } from 'react-admin'

import { put, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'

import { CHANGE_FILTER } from '../actions/dailyActivity'
import dataProvider from '../providers/dataProvider'
import {
  DailyActivityResource,
  AccountResource,
  ReportActivityResource,
  AgentResource,
} from '../providers/resources/requestResources'
import { DEFAULT_QUERY } from '../providers/queryTools'
import { makeDataReceived } from '../actions/dailyActivity'
import { messages } from '../i18n/messages'
import { handleCatch } from '../components/widgets/SRONotification'

async function getDailyActivityData(action) {
  const date = action.filter.date.formatDate()
  const params = Object.assign({}, DEFAULT_QUERY, {
    filter: {
      agentId: action.filter.agent,
      date: date,
    },
  })
  const response = await dataProvider(GET_LIST, DailyActivityResource.RESOURCE_NAME, params)
  return response.data
}

async function getRatingDictionary(activitiesByAgent) {
  const accounts = await dataProvider(GET_MANY, AccountResource.RESOURCE_NAME, {
    ids: _(activitiesByAgent)
      .map((activity) => activity.accountId)
      .uniq()
      .value(),
  })
  const clientRating = _.concat.apply(
    null,
    _.map(accounts.data, (account) => account.clientRating),
  )
  return _.zipObject(
    _.map(clientRating, (rating) => rating.id),
    _.map(clientRating, (rating) => rating.name),
  )
}

async function requestSalesGoal({ date, dimensions }) {
  return await dataProvider(GET_LIST, ReportActivityResource.RESOURCE_NAME, {
    from: date.formatDate(),
    to: date.formatDate(),
    dimensions,
  })
}

async function getSalesGoalGlobal(date, salesGoalsByAgent, action) {
  if (action.filter.agent !== AgentResource.ALL_AGENTS_ID) {
    return salesGoalsByAgent[action.filter.agent]
  }

  const response = await requestSalesGoal({ date, dimensions: ['date'] })
  return response.data[0]
}

async function getSalesGoalByAgent(date) {
  const response = await requestSalesGoal({ date, dimensions: ['date', 'agentId'] })
  const agentData = response.data
  const agentIds = _.map(agentData, (item) => item.agentId)
  return _.zipObject(agentIds, agentData)
}

async function getAgentData() {
  const query = _.cloneDeep(DEFAULT_QUERY)
  query.filter.isActive = true
  const response = await dataProvider(GET_LIST, AgentResource.RESOURCE_NAME, query)
  return _.concat([{ id: AgentResource.ALL_AGENTS_ID, name: messages.ALL_AGENTS }], response.data)
}

async function getData(action) {
  return Promise.all([getSalesGoalByAgent(action.filter.date), getDailyActivityData(action)])
    .then(([salesGoalByAgent, activitiesByAgent]) =>
      Promise.all([
        activitiesByAgent,
        getRatingDictionary(activitiesByAgent),
        getSalesGoalGlobal(action.filter.date, salesGoalByAgent, action),
        getAgentData(),
        salesGoalByAgent,
      ]),
    )
    .then(([activitiesByAgent, ratingDictionary, salesGoalGlobal, agents, salesGoalByAgent]) => {
      return {
        data: {
          activitiesByAgent: activitiesByAgent,
          ratingDictionary,
          salesGoalGlobal,
          salesGoalByAgent,
          agents,
        },
      }
    })
}

function* callRequestData(action) {
  try {
    const data = yield getData(action)
    yield put(makeDataReceived(data))
  } catch (err) {
    handleCatch(err)
  }
}

export default function* dailyActivitySaga() {
  yield takeEvery(CHANGE_FILTER, callRequestData)
}
