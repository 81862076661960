import { combineReducers } from 'redux'

import {
  CHANGE_FILTER,
  DATA_RECEIVED,
  STATUS_DATA_REQUESTED,
  STATUS_DATA_RECEIVED,
} from '../actions/dailyActivity'
import { SROMoment } from '../utils/timeUtils'
import { AgentResource } from '../providers/resources/requestResources'
import { messages } from '../i18n/messages'

const DEFAULT_DATA = {
  agents: [
    {
      id: AgentResource.ALL_AGENTS_ID,
      name: messages.ALL_AGENTS,
    },
  ],
}

function filter(state = {}, action) {
  switch (action.type) {
    case CHANGE_FILTER:
      return action.filter
    default:
      return Object.assign(
        {
          date: SROMoment.now(),
          agent: AgentResource.ALL_AGENTS_ID,
        },
        state,
      )
  }
}

function status(state = STATUS_DATA_REQUESTED, action) {
  switch (action.type) {
    case CHANGE_FILTER:
      return STATUS_DATA_REQUESTED
    case DATA_RECEIVED:
      return STATUS_DATA_RECEIVED
    default:
      return state
  }
}

function data(state = DEFAULT_DATA, action) {
  if (action.type !== DATA_RECEIVED) {
    return state
  }

  return action.data.data
}

export default combineReducers({
  filter,
  status,
  data,
})
