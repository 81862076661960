import * as Sentry from '@sentry/browser'

class LocalStorageKey {
  constructor(key, opts = {}) {
    if (opts.sentryContext) {
      this.sentryContext = opts.sentryContext
    }
    this.converter = opts.converter || ((id) => id)

    this.key = key
  }

  isReady() {
    return !!localStorage
  }

  get() {
    if (!this.isReady()) {
      return null
    }

    const value = this.converter(localStorage.getItem(this.key))

    if (this.sentryContext) {
      Sentry.setExtra(this.key, value)
    }

    return value
  }

  set(value) {
    if (!this.isReady()) {
      return
    }

    localStorage.setItem(this.key, value)

    if (this.sentryContext) {
      Sentry.setExtra(this.key, value)
    }
  }

  remove() {
    if (!this.isReady()) {
      return
    }

    localStorage.removeItem(this.key)

    if (this.sentryContext) {
      Sentry.setExtra(this.key, null)
    }
  }
}

function toBool(value) {
  switch (value) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return value
  }
}

export const tokenEntry = new LocalStorageKey('token')
export const accountIdEntry = new LocalStorageKey('accountId', { sentryContext: true })
export const roleIdEntry = new LocalStorageKey('roleId', {
  sentryContext: true,
  converter: parseInt,
})
export const userIdEntry = new LocalStorageKey('userId', { sentryContext: true })
export const userNameEntry = new LocalStorageKey('username', { sentryContext: true })
export const localeEntry = new LocalStorageKey('locale')
export const isAccountValidEntry = new LocalStorageKey('isAccountValid', { converter: toBool })
export const accountModeEntry = new LocalStorageKey('accountMode')
export const locationTrackingUrlEntry = new LocalStorageKey('locationTrackingUrl')
