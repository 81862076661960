import axios from 'axios'
import _ from 'lodash'
import { API_URL_BASE } from '../Config'
import {
  tokenEntry,
  accountIdEntry,
  roleIdEntry,
  userIdEntry,
  userNameEntry,
  localeEntry,
  isAccountValidEntry,
  accountModeEntry,
  locationTrackingUrlEntry,
} from './localStorageProvider'

export const LOGIN_SCREEN_URL = '/login'
export const ACCOUNT_API_KEY_PARAM = '_apikey'
export const AGENT_API_KEY_PARAM = '_agentApikey'
const ACCOUNT_API_KEY_HEADER_PREFIX = 'Apikey'
const AGENT_API_KEY_HEADER_PREFIX = 'AgentApikey'
const BROWSER_LOGIN_HEADER_PREFIX = 'Bearer'

export default {
  login: async ({ username, password }) => {
    const response = await axios({
      method: 'post',
      url: `${API_URL_BASE}/user/authenticate`,
      data: { username, password },
      headers: { 'content-type': 'application/json' },
    })

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText)
    }

    const { data } = response.data
    const minRoleId = _(data.roles)
      .map((role) => role.id)
      .min()

    userIdEntry.set(data.id)
    roleIdEntry.set(minRoleId)
    accountIdEntry.set(data.accountId)
    tokenEntry.set(data.token)
    localeEntry.set(data.attributes.language || 'he')
    userNameEntry.set(data.username)
    isAccountValidEntry.set(data.account && data.account.isValid)
    accountModeEntry.set(data.account && data.account.mode)
    locationTrackingUrlEntry.set(data.account && data.account.attributes.locationTrackingScreenUrl)

    if (window.heap) {
      window.heap.identify(data.id)
      window.heap.addUserProperties({
        username: data.username,
        email: data.email,
        accountId: data.accountId,
        role: minRoleId,
        accountMode: data.account && data.account.mode,
      })
    }
  },
  logout: () => {
    tokenEntry.remove()
    if (window.heap) {
      window.heap.resetIdentity()
    }

    return Promise.resolve()
  },
  checkError: (error) => {
    if (error.status === 401 || error.status === 403) {
      tokenEntry.remove()
      return Promise.reject()
    }

    return Promise.resolve()
  },
  checkAuth: () => {
    return getAuthHeader() ? Promise.resolve() : Promise.reject()
  },
  getPermissions: () => Promise.resolve(),
}

export const getAuthHeader = () => {
  const urlParams = new URLSearchParams(window.location.search)

  let apiKey = urlParams.get(ACCOUNT_API_KEY_PARAM)
  if (apiKey) {
    return `${ACCOUNT_API_KEY_HEADER_PREFIX} ${apiKey}`
  }

  apiKey = urlParams.get(AGENT_API_KEY_PARAM)
  if (apiKey) {
    return `${AGENT_API_KEY_HEADER_PREFIX} ${apiKey}`
  }

  const token = tokenEntry.get()
  if (token) {
    return `${BROWSER_LOGIN_HEADER_PREFIX} ${token}`
  }

  return null
}
