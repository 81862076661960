import React from 'react'

import _ from 'lodash'
import classnames from 'classnames'
import SROFormComponent from './SROFormComponents'

import './sro-form-style.scss'

export default class SROInput extends SROFormComponent {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      value:
        this.props.defaultValue === undefined || this.props.defaultValue === null
          ? ''
          : this.props.defaultValue,
    })

    this.onChange = this.onChange.bind(this)
  }

  dropNonDigits(value) {
    return value.replace(new RegExp(/[^\d]*/g), '')
  }

  onChange(event) {
    const typedValue = event.target.value
    const value = this.props.numeric ? this.dropNonDigits(typedValue) : typedValue

    this.setState({ value })
    this.props.onChange && this.props.onChange(value)
  }

  getValue() {
    return this.state.value
  }

  getExtraClasses() {
    return ['sro-input']
  }

  render() {
    const props = _.omit(this.props, ['className', 'numeric', 'defaultValue', 'multiline'])
    return (
      <div className={classnames(this.getClasses())}>
        <div className="upper-title">{this.props.title}</div>
        {this.renderInput(props)}
      </div>
    )
  }

  renderInput(props) {
    if (this.props.multiline) {
      return <textarea value={this.state.value} {...props} onChange={this.onChange}></textarea>
    }

    return <input value={this.state.value} {...props} onChange={this.onChange} />
  }
}

SROInput.defaultProps = {
  numeric: false,
}
