import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import { messages } from '../../i18n/messages'
import { SROMoment } from '../../utils/timeUtils'
import { numberFormat } from '../../utils/stringUtils'

import './analytics.scss'
import SRODropdown from '../widgets/SROFormComponents/SRODropdown'
import SROBar from '../widgets/charts/SROBar'
import AnalyticsShadow from './AnalyticsShadow'

export function AnalyticsView(props) {
  const { requestData, params, data } = props
  const { scoreByCategoryMonth } = params

  useEffect(() => {
    requestData({ scoreByCategoryMonth: scoreByCategoryMonth })
  }, [requestData, scoreByCategoryMonth])

  function getMonthsList() {
    const monthStrings = []
    let curDate = SROMoment.now()
    for (let i = 0; i < 12; i++) {
      const monthString = curDate.formatShortMonthYear()
      monthStrings.push({ id: monthString, name: monthString })
      curDate = curDate.add(-1, 'month')
    }

    return monthStrings
  }

  function renderAgentScoreByCategory(data) {
    return (
      <div>
        <div className="chart-title">{messages.SCORE_BY_CATEGORY}</div>
        <SRODropdown
          options={getMonthsList()}
          onChange={(newMonth) => {
            requestData({ scoreByCategoryMonth: newMonth })
          }}
          value={scoreByCategoryMonth}
        />
        <SROBar
          data={data.monthlyScoreByCategory}
          metrics={[
            { name: 'percent.sales', label: messages.SALES, color: '#ffda84' },
            { name: 'percent.collection', label: messages.COLLECTION, color: '#ff9d87' },
            { name: 'percent.visits', label: messages.PLANNED_VISITS, color: '#bcd6ff' },
            { name: 'percent.successful', label: messages.SUCCESSFUL_VISITS, color: '#7cd1d4' },
          ]}
          tooltip={ScoreByCategoryTooltip}
          maxYAxis={100}
          xAxisDataKey="agentName"
          width={1200}
        />
      </div>
    )
  }

  function renderAgentScoreCurrentMonth(data) {
    return (
      <div>
        <div className="chart-title">{messages.AGENT_SCORE_CURRENT_MONTH}</div>
        <SROBar
          data={data.agentScoreCurrentMonth.agentsScores}
          metrics={[{ name: 'score', label: messages.AGENT_SCORE, color: '#7cd1d4' }]}
          horizontalLines={[
            { y: data.agentScoreCurrentMonth.companyTarget, stroke: '#bcd6ff', strokeWidth: 2 },
            { y: data.agentScoreCurrentMonth.companyScore, stroke: '#ffd166', strokeWidth: 2 },
          ]}
          tooltip={AgentScoreCurrentTooltip}
          maxYAxis={100}
          xAxisDataKey="agentName"
          width={575}
        />
      </div>
    )
  }

  function renderAgentScoreCurrentYear(data) {
    return (
      <div>
        <div className="chart-title">{messages.AGENT_SCORE_CURRENT_YEAR}</div>
        <SROBar
          data={data.agentScoreCurrentYear.agentsScores}
          metrics={[{ name: 'score', label: messages.AGENT_SCORE, color: '#7cd1d4' }]}
          horizontalLines={[
            { y: data.agentScoreCurrentYear.companyTarget, stroke: '#bcd6ff', strokeWidth: 2 },
            { y: data.agentScoreCurrentYear.companyScore, stroke: '#ffd166', strokeWidth: 2 },
          ]}
          tooltip={AgentScoreCurrentTooltip}
          maxYAxis={100}
          xAxisDataKey="agentName"
          width={575}
        />
      </div>
    )
  }

  if (!data) {
    return <AnalyticsShadow />
  }

  return (
    <div className="analytics">
      <h1 className="page-title float-left">{messages.ANALYTICS}</h1>
      <div className="analytics-container">
        <div className="analytics-row">
          <div className="analytics-cell">{renderAgentScoreByCategory(data)}</div>
        </div>
        <div className="analytics-row">
          <div className="analytics-cell">{renderAgentScoreCurrentMonth(data)}</div>
          <div className="analytics-cell">{renderAgentScoreCurrentYear(data)}</div>
        </div>
      </div>
    </div>
  )
}

AnalyticsView.propTypes = {
  requestData: PropTypes.func,
  params: PropTypes.shape({
    scoreByCategoryMonth: PropTypes.string,
  }),
  data: PropTypes.object,
}

const ScoreByCategoryTooltip = ({ active, label, payload, metrics }) => {
  if (active) {
    const metricsByName = _.keyBy(metrics, 'name')
    return (
      <div className="actual-visit-tooltip">
        <div className="background"></div>
        <div className="list-title">{label}</div>
        <div className="item-list">
          {_.map(payload, (item) => {
            const dataKey = item.dataKey.split('.')[1]
            const { payload } = item
            const percent = payload.percent[dataKey]
            const value = numberFormat(payload.value[dataKey])
            const total = numberFormat(payload.total[dataKey])
            return (
              <div key={item.dataKey} className="item-line">
                <div className="item-circle" style={{ backgroundColor: item.color }}></div>
                <div className="item-title">
                  {`${metricsByName[item.dataKey].label} - ${percent}% (${value}/${total})`}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return null
}

ScoreByCategoryTooltip.propTypes = {
  active: PropTypes.bool,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
  payload: PropTypes.array,
}

const AgentScoreCurrentTooltip = ({ active, label, payload, metrics }) => {
  if (active) {
    const metricsByName = _.keyBy(metrics, 'name')
    let companyScore = null
    let companyTarget = null

    return (
      <div className="actual-visit-tooltip">
        <div className="background"></div>
        <div className="list-title">{label}</div>
        <div className="item-list">
          {_.map(payload, (item) => {
            const { payload, dataKey, color } = item
            companyScore = payload.companyScore
            companyTarget = payload.companyTarget
            const percent = Math.round(payload[dataKey])
            return (
              <div key={dataKey} className="item-line">
                <div className="item-circle" style={{ backgroundColor: color }}></div>
                <div className="item-title">{`${metricsByName[dataKey].label} - ${percent}%`}</div>
              </div>
            )
          })}
          {companyScore !== null && (
            <div className="item-line">
              <div className="item-circle" style={{ backgroundColor: '#ffd166' }}></div>
              <div className="item-title">{`${messages.COMPANY_SCORE} - ${companyScore}%`}</div>
            </div>
          )}
          {companyTarget !== null && (
            <div className="item-line">
              <div className="item-circle" style={{ backgroundColor: '#bcd6ff' }}></div>
              <div className="item-title">{`${messages.COMPANY_TARGET} - ${companyTarget}%`}</div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return null
}

AgentScoreCurrentTooltip.propTypes = {
  active: PropTypes.bool,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  payload: PropTypes.array,
}
