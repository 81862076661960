import { put, takeEvery } from 'redux-saga/effects'
import { AgentListRequestedAction, AgentListReceivedAction } from '../actions/agentListActions'
import Requester from '../providers/request'
import { AgentResource } from '../providers/resources/requestResources'

function* fetch() {
  const data = yield new Requester().getEverything(AgentResource)
  yield put(AgentListReceivedAction.make(data.data))
}

export default function* tripMapSaga() {
  yield takeEvery(AgentListRequestedAction.NAME, fetch)
}
