import React, { useState } from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './sro-tabset.scss'

const SROTabSet = (props) => {
  const { tabs, ...restProps } = props
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  return (
    <div className="sro-tabset">
      <div className="tab-headers">
        {_.map(tabs, ({ label }, index) => {
          return (
            <div
              className={classnames('tab-header', {
                active: index === activeTabIndex,
              })}
              onClick={() => setActiveTabIndex(index)}
              key={index}
            >
              {label}
            </div>
          )
        })}
      </div>
      <div className="tab-contents">
        {_.map(tabs, ({ Content }, index) => {
          return (
            <div
              className={classnames('sro-tabset-tab', {
                show: activeTabIndex === index,
              })}
              key={index}
            >
              {<Content {...restProps} />}
            </div>
          )
        })}
      </div>
    </div>
  )
}

SROTabSet.propTypes = {
  tabs: PropTypes.array,
}

export default SROTabSet
