import _ from 'lodash'
import { GET_LIST, GET_ONE, UPDATE } from 'ra-core'

import { v1 as uuidv1 } from 'uuid'
import { messages } from '../../i18n/messages'

import { CREATE } from 'react-admin'
import {
  ClientRatingSimulatorResource,
  ClientResource,
  AgentResource,
  AccountResource,
  AccountRatingsResource,
  AgentStatResource,
  AccountStatResource,
  AccountOptionsResource,
  UserResource,
  ReportActivityResource,
  ReportAgentScoreResource,
} from '../resources/requestResources'
import Requester from '../request'
import { processClientSimulator } from '../transformers/clientSimulatorTransformer'
import { makeFilter, DEFAULT_QUERY } from '../queryTools'
import BaseResource from './baseResource'
import { runObjectInParallel } from '../../utils/asyncUtils'
import { percentage } from '../../utils/numberUtils'
import { notifyError } from '../../components/widgets/SRONotification'
import { SROMoment } from '../../utils/timeUtils'
import { localeEntry, accountIdEntry } from '../localStorageProvider'

class ComboResource extends BaseResource {}

export class ClientScreenResource extends ComboResource {
  static RESOURCE_NAME = 'clients'

  async fetch(type, params) {
    if (type !== GET_LIST) {
      return await super.fetch(type, params)
    }

    const requester = new Requester()

    const [filteredClients, accountRatings, allAgents] = await Promise.all([
      requester.getList(ClientResource, params),
      requester.getEverything(AccountRatingsResource),
      requester.getRaw(AgentResource, { isActive: true }),
    ])

    const clients = filteredClients.data
    const ratingChoices = accountRatings.data
    const agentChoices = allAgents.data.records

    const result = _.map(clients, (client) => ({
      ...client,
      customRateDisplay: _.get(ratingChoices[client.accountId][client.customRateId], 'name'),
      validationErrors: _.get(client, 'attributes.validationErrors'),
      lastVisitType: _.get(client, 'attributes.lastVisitType'),
    }))

    return {
      ...filteredClients,
      data: result,
      agentChoices,
      ratingChoices,
    }
  }
}

export class LoginResource extends ComboResource {
  static RESOURCE_NAME = 'login'
}

export class AgentFullResource extends ComboResource {
  static RESOURCE_NAME = 'agents'

  async fetch(type, params) {
    switch (type) {
      case GET_LIST: {
        return await this.getEnrichedList(params)
      }
      case GET_ONE: {
        return await this.getEnrichedOne(params)
      }
      default: {
        return await new Requester().makeRequest(type, AgentResource, params)
      }
    }
  }

  async getEnrichedOne(params) {
    const requester = new Requester()
    const agentData = await requester.getOne(AgentResource, params.id)
    const accountId = agentData.data.accountId

    const accountData = await requester.getOne(AccountResource, accountId)

    const clientRating = accountData.data.clientRating
    agentData.data.simulation = await requester.post(SimulationFullResource, {
      accountId,
      data: clientRating,
    })

    return agentData
  }

  async getEnrichedList(params) {
    _.forEach(
      {
        'pagination.perPage': 10,
        'pagination.page': 1,
        'sort.field': 'id',
        'sort.order': 'DESC',
      },
      (value, key) => {
        if (!_.get(params, key)) {
          _.set(params, key, value)
        }
      },
    )

    _.unset(params, 'filter.requestId')

    const requester = new Requester()
    const agentData = await requester.getList(AgentResource, params)

    const agentStats = await this.getAgentStats()

    agentData.data = _.map(agentData.data, (item) =>
      Object.assign({}, item, { numClients: _.get(agentStats[item.id], 'numClients', 0) }),
    )

    return agentData
  }

  async getAgentStats() {
    const requester = new Requester()
    const raw = await requester.getRaw(AgentStatResource, {})

    const items = _.get(raw, 'data.records', [])

    return _.keyBy(items, 'id')
  }
}

export class AccountFullResource extends ComboResource {
  static RESOURCE_NAME = 'accounts'

  async fetch(type, params) {
    const requester = new Requester()

    switch (type) {
      case GET_ONE:
        return this.getOne(params)

      case GET_LIST:
        return this.getList(params)

      case CREATE:
        if (params.data.id) {
          return await requester.update(AccountResource, { id: params.data.id, data: params.data })
        }

        return await requester.post(AccountResource, params)

      default:
        return await requester.makeRequest(type, AccountResource, params)
    }
  }

  async getOne(params) {
    const requester = new Requester()
    const raw = await runObjectInParallel({
      account: requester.getOne(AccountResource, params.id),
      accountOptions: requester.getRaw(AccountOptionsResource, params),
    })

    const data = Object.assign({}, raw.account.data, {
      accountOptions: raw.accountOptions,
    })

    return { data }
  }

  async getList(params) {
    const requester = new Requester()

    const rawResponses = await runObjectInParallel({
      accountData: requester.getList(AccountResource, params),
      accountStats: this.getAccountStats(),
    })

    const accountData = rawResponses.accountData

    accountData.data = _.map(accountData.data, (item) =>
      Object.assign({}, item, { numAgents: rawResponses.accountStats[item.id].numAgents }),
    )

    return accountData
  }

  async getAccountStats() {
    const requester = new Requester()
    const raw = await requester.getRaw(AccountStatResource, {})

    const items = _.get(raw, 'data.records', [])

    return _.keyBy(items, 'id')
  }
}

export class SimulationFullResource extends ComboResource {
  static RESOURCE_NAME = 'simulation-full-resource'

  async fetchData(accountId, data) {
    const requester = new Requester()
    const clientToRatingRequest = requester.post(ClientRatingSimulatorResource, {
      accountId,
      data: {
        clientRating: data,
      },
    })

    const filterQuery = makeFilter({ filter: { accountId, isActive: true } })

    const allClientsRequest = requester.getList(ClientResource, filterQuery)
    const allAgentsRequest = requester.getList(AgentResource, filterQuery)
    const accountDataRequest = requester.getOne(AccountResource, accountId)

    return {
      accountData: await accountDataRequest,
      clientToRating: await clientToRatingRequest,
      allClients: await allClientsRequest,
      allAgents: await allAgentsRequest,
    }
  }

  async fetch(type, params) {
    const newUUIDs = []
    const generateUUID = () => {
      const newUUID = uuidv1()
      newUUIDs.push(newUUID)
      return newUUID
    }

    const { accountData, allAgents, allClients, clientToRating } = await this.fetchData(
      params.accountId,
      params.data,
    )
    const account = accountData.data
    const agents = allAgents.data
    const clients = allClients.data
    const simulatePerClientRating = clientToRating.data

    const existingRateNameToId = _(account.clientRating)
      .keyBy('name')
      .mapValues((rating) => rating.id)
      .value()
    const newClientRating = _(params.data)
      .mapKeys((rating) => existingRateNameToId[rating.name] || generateUUID())
      .value()
    const rateIdToName = _(newClientRating)
      .mapValues((rating) => rating.name)
      .value()
    const visitFrequencyDaysPerRate = _(params.data)
      .mapKeys((rating) => rating.name)
      .mapValues((rating) => rating.visitFrequencyDays)
      .value()
    const agentRatingSummary = processClientSimulator({
      clients,
      simulatePerClientRating,
      visitFrequencyDaysPerRate,
    })
    const agentsById = _.keyBy(agents, 'id')
    const rateVisits = _(agentsById)
      .mapValues((agent) => agent.routePreferences.rateVisits)
      .value()
    const agentAttributesByAgentId = _.mapValues(agentsById, (agent) => ({
      ...account.attributes.agent,
      ...agent.attributes,
    }))

    return {
      agentRatingSummary,
      agentsById,
      rateVisits,
      rateIdToName,
      newUUIDs,
      agentAttributesByAgentId,
      simulatePerClientRating,
      clientRating: account.clientRating,
    }
  }
}

export class UserFullResource extends ComboResource {
  static RESOURCE_NAME = 'users'

  async fetch(type, params) {
    switch (type) {
      case GET_ONE: {
        return await this.getEnrichedOne(params)
      }
      case UPDATE: {
        return await this.updateUser(params)
      }
      default: {
        return await new Requester().makeRequest(type, UserResource, params)
      }
    }
  }

  async updateUser(formParams) {
    const params = this.translateClientToServer(formParams)

    return await new Requester().update(UserResource, params)
  }

  translateClientToServer(formParams) {
    if (!_.get(formParams, 'data.agentTeamId')) {
      _.unset(formParams, 'data.agentTeamId')
    }

    const languageCode = formParams.data.languageCode
    if (languageCode) {
      localeEntry.set(languageCode)
    }

    return {
      ...formParams,
      data: {
        ..._.pick(formParams.data, ['email', 'isActive', 'roleId', 'agentTeamId']),
        attributes: {
          language: languageCode,
        },
      },
    }
  }

  translateServerToClient(serverParams) {
    const { roles, attributes = {}, ...otherFields } = serverParams

    return {
      ...otherFields,
      roleId: _.get(roles, '[0].id'),
      languageCode: attributes.language || 'he',
    }
  }

  async getEnrichedOne(params) {
    const requester = new Requester()
    const rawData = await runObjectInParallel({
      userData: requester.getOne(UserResource, params.id),
      accounts: requester.getEverything(AccountResource),
    })

    const userAccount =
      _.findLast(rawData.accounts.data, { id: rawData.userData.data.accountId }) || {}

    return {
      data: {
        ...this.translateServerToClient(rawData.userData.data),
        allAccounts: _.get(rawData, 'accounts.data', []),
        accountName: userAccount.name,
      },
    }
  }
}

export class AnalyticsResource extends ComboResource {
  static RESOURCE_NAME = 'analytics'

  getMonthlyScoreByCategoryParams(time) {
    const from = SROMoment.parseShortMonthYear(time).startOf('month')
    const to = from.endOf('month')

    return {
      from: from.formatDate(),
      to: to.formatDate(),
      dimensions: ['agentId', 'month'],
    }
  }

  getAgentScoreCurrentMonthParams() {
    const from = SROMoment.now().startOf('month')
    const to = from.endOf('month')

    return {
      accountId: accountIdEntry.get(),
      from: from.formatDate(),
      to: to.formatDate(),
      dimensions: ['agentId', 'month'],
    }
  }

  getAgentScoreCurrentYearParams() {
    const from = SROMoment.now().startOf('year')
    const to = SROMoment.now().add(-1, 'month').endOf('month')

    return {
      accountId: accountIdEntry.get(),
      from: from.formatDate(),
      to: to.formatDate(),
      dimensions: ['agentId', 'month'],
    }
  }

  transformMonthlyScoreByCategory(records) {
    return records.map((record) => ({
      agentName: record.agentName,
      value: {
        sales: Math.round(record.salesActual),
        collection: Math.round(record.collectionActual),
        visits: record.plannedOnSiteActivities,
        successful: record.successfulOnSiteActivities,
      },
      total: {
        sales: Math.round(record.salesTarget),
        collection: Math.round(record.collectionTarget),
        visits: record.plannedActivities,
        successful: record.plannedActivities,
      },
      percent: {
        sales: percentage(record.salesActual, record.salesTarget, 100),
        collection: percentage(record.collectionActual, record.collectionTarget, 100),
        visits: percentage(record.plannedOnSiteActivities, record.plannedActivities, 100),
        successful: percentage(record.successfulOnSiteActivities, record.plannedActivities, 100),
      },
    }))
  }

  transformAgentScoreCurrent(agentScoreCurrent) {
    const { agentsScores, companyTarget } = agentScoreCurrent

    const filteredAgentsScores = _(agentsScores).filter((record) => record.score !== 0)

    const agentsScoresByMonth = _(filteredAgentsScores)
      .filter((record) => record.score !== 0)
      .groupBy('month')
      .map((records) => ({
        score: _.sumBy(records, 'score') / records.length,
      }))
      .value()
    const averagedCompanyScore = Math.round(
      _(agentsScoresByMonth).sumBy('score') / agentsScoresByMonth.length,
    )

    return {
      agentsScores: _(filteredAgentsScores)
        .groupBy('agentName')
        .map((objs, agentName) => ({
          agentName,
          score: _.sumBy(objs, 'score') / objs.length,
          companyTarget: companyTarget,
          companyScore: averagedCompanyScore,
        }))
        .value(),
      companyTarget,
      companyScore: averagedCompanyScore,
    }
  }

  handleReportAgentScoreCatch(err) {
    if (err && err.status === 422) {
      notifyError(messages.AGENT_KPIS_NOT_CONFIGURED)
    } else {
      notifyError(messages.UNKNOWN_ERROR)

      throw err
    }
  }

  async fetch(type, params) {
    const { scoreByCategoryMonth } = params
    const requester = new Requester()
    const agentQuery = _.cloneDeep(DEFAULT_QUERY)
    agentQuery.filter.isActive = true
    const [monthlyScoreByCategory, agentScoreCurrentMonth, agentScoreCurrentYear] =
      await Promise.all([
        requester.getList(
          ReportActivityResource,
          this.getMonthlyScoreByCategoryParams(scoreByCategoryMonth),
        ),
        requester
          .getRaw(ReportAgentScoreResource, this.getAgentScoreCurrentMonthParams())
          .catch(this.handleReportAgentScoreCatch),
        requester
          .getRaw(ReportAgentScoreResource, this.getAgentScoreCurrentYearParams())
          .catch(this.handleReportAgentScoreCatch),
      ])

    return {
      data: {
        monthlyScoreByCategory: this.transformMonthlyScoreByCategory(monthlyScoreByCategory.data),
        agentScoreCurrentMonth: this.transformAgentScoreCurrent(agentScoreCurrentMonth.data),
        agentScoreCurrentYear: this.transformAgentScoreCurrent(agentScoreCurrentYear.data),
      },
    }
  }
}

export const COMBO_RESOURCE_CLASSES = [
  ClientScreenResource,
  LoginResource,
  AgentFullResource,
  AccountFullResource,
  SimulationFullResource,
  UserFullResource,
  AnalyticsResource,
]
