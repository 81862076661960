import Action from './action'

export class RequestAction extends Action {
  static NAME = 'request-requested'

  static getExtraData(options) {
    return options
  }
}

export class DataReceivedAction extends Action {
  static NAME = 'request-data-received'

  static getExtraData(requestId, data) {
    return {
      requestId,
      data,
    }
  }
}
