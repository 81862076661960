import { connect } from 'react-redux'

import { AgentEditView } from '../components/AgentView'
import { SaveStep3Action } from '../actions/settings'

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    save: (data) => dispatch(SaveStep3Action.make(data)),
  }
}

export const AgentEdit = connect(mapStateToProps, mapDispatchToProps)(AgentEditView)
