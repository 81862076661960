import React from 'react'
import _ from 'lodash'
import { PieChart, Pie, Cell } from 'recharts'
import PropTypes from 'prop-types'

import { percentFormat } from '../../../../utils/stringUtils'

import './sro-gauge.scss'

export default class SROGauge extends React.Component {
  renderTooltip() {
    if (!this.props.tooltipContent) {
      return null
    }

    return (
      <div className="sro-gauge-tooltip">
        <div className="tooltip-content">{this.props.tooltipContent}</div>
        <div className="tooltip-triangle"></div>
      </div>
    )
  }

  render() {
    const outerRadius = 100
    const innerRadius = outerRadius - 12
    const percent = _.get(this.props, 'percent')
    const value = _.get(this.props, 'value')
    const data = [
      { name: 'percent', value: percent },
      { name: 'placeholder', value: 100 - percent },
    ]
    const fullCoverage = [{ name: 'all', value: 100 }]
    const emptyColor = '#E1ECF7'

    return (
      <div className="sro-gauge">
        {this.renderTooltip()}
        <div className="sro-gauge-frame">
          <PieChart width={outerRadius * 2} height={outerRadius * 2} className="pie">
            <Pie
              innerRadius={0}
              outerRadius={innerRadius}
              data={fullCoverage}
              isAnimationActive={false}
              dataKey="value"
            >
              <Cell fill={this.props.bgColor} />
            </Pie>
            <Pie
              innerRadius={outerRadius}
              outerRadius={150}
              data={fullCoverage}
              isAnimationActive={false}
              dataKey="value"
            >
              <Cell fill={this.props.bgColor} />
            </Pie>
            <Pie
              isAnimationActive={false}
              onMouseEnter={this.onSliceEnter}
              data={data}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              paddingAngle={0}
              dataKey="value"
            >
              <Cell fill={'none'} />
              <Cell fill={emptyColor} />
            </Pie>
          </PieChart>
          <div className="percent-label">{percentFormat(percent)}</div>
          {value !== undefined && <div className="value-label">{value}</div>}
        </div>
      </div>
    )
  }
}

SROGauge.propTypes = {
  tooltipContent: PropTypes.object,
  bgColor: PropTypes.string,
}
