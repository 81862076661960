import _ from 'lodash'

import { messages } from '../../../i18n/messages'
import { padNumber } from '../../../utils/stringUtils'

export const DAY_OPTIONS = [
  { id: '1', name: messages.SUNDAY },
  { id: '2', name: messages.MONDAY },
  { id: '3', name: messages.TUESDAY },
  { id: '4', name: messages.WEDNESDAY },
  { id: '5', name: messages.THURSDAY },
  { id: '6', name: messages.FRIDAY },
  { id: '7', name: messages.SATURDAY },
]

export function formatTime(time) {
  return _(time)
    .map((num) => padNumber(num, 2))
    .join(':')
}

export function workHoursDataToDisplay(data) {
  const dayNameById = _(DAY_OPTIONS)
    .keyBy('id')
    .mapValues((day) => day.name)
    .value()

  return _(data)
    .mapValues((day) => (day.length > 0 ? day[0] : undefined))
    .mapValues((day, dayId) =>
      day ? `${dayNameById[dayId]} ${formatTime(day.from)} - ${formatTime(day.to)}` : undefined,
    )
    .values()
    .join(', ')
}
