import { connect } from 'react-redux'

import { createActivityFormRequested, createActivityFormClose } from '../actions/createActivityForm'
import CreateActivityModalView from '../components/modals/CreateActivityModalView'

const mapStateToProps = (state) => ({
  formState: state.customReducers.createActivityForm,
})

const mapDispatchToProps = (dispatch) => ({
  save: (data) => dispatch(createActivityFormRequested(data)),
  cancel: () => dispatch(createActivityFormClose()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateActivityModalView)
