import React from 'react'
import SROInput from '../widgets/SROFormComponents/SROInput'
import SROButton from '../widgets/SROFormComponents/SROButton'
import { messages } from '../../i18n/messages'
import amodatLogo from './amodat-logo.png'

import './login.scss'
import '../widgets/SROFormComponents/sro-form-style.scss'
import SRONotification, { notifyError } from '../widgets/SRONotification'
import Requester from '../../providers/request'
import { ResetPasswordResource } from '../../providers/resources/requestResources'

const TOKEN_KEY = 'token'

export default class PasswordResetForm extends React.Component {
  constructor(props) {
    super(props)

    this.references = {
      password1: React.createRef(),
      password2: React.createRef(),
    }

    this.state = {
      ...this.state,
      complete: false,
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit(e) {
    e.preventDefault()

    const password1 = this.references.password1.current.getValue()
    const password2 = this.references.password2.current.getValue()

    if (!password1) {
      notifyError(messages.PLEASE_CHOOSE_A_NEW_PASSWORD)
      return
    }

    if (password1 !== password2) {
      notifyError(messages.PASSWORDS_DO_NOT_MATCH)
      return
    }

    if (password1.length < 7) {
      notifyError(messages.sro.password_rules)
      return
    }

    const data = {
      token: this.getToken(),
      newPassword: password1,
    }

    try {
      await new Requester().update(ResetPasswordResource, { id: 'reset', data })
    } catch {
      notifyError(messages.PASSWORD_RESET_WENT_WRONG)
      return
    }

    this.setState({ complete: true })
  }

  getToken() {
    const urlParser = new URLSearchParams(window.location.search.replace(new RegExp('.*\\?'), ''))
    return urlParser.get(TOKEN_KEY)
  }

  getForm() {
    return (
      <div>
        <div className="upper-title">{messages.NEW_PASSWORD}</div>
        <SROInput ref={this.references.password1} type="password" />
        <div className="upper-title">{messages.RE_ENTER_NEW_PASSWORD}</div>
        <SROInput ref={this.references.password2} type="password" />
        <SROButton type="submit" onClick={this.onSubmit}>
          {messages.RESET}
        </SROButton>
      </div>
    )
  }

  getSuccessMessage() {
    return (
      <div className="success-message-box">
        <div className="success-message">{messages.PASSWORD_RESET_SUCCESSFULLY}</div>
        <a href="/">{messages.CONTINUE_TO_LOGIN}</a>
      </div>
    )
  }

  render() {
    return (
      <div className="password-reset-page sro-form">
        <form className="login-flow-form">
          <img src={amodatLogo} className="amodat-logo" alt="amodat-logo" />
          <div className="form-title">{messages.RESET_PASSWORD}</div>
          {this.state.complete ? this.getSuccessMessage() : this.getForm()}
        </form>
        {SRONotification.getElement()}
      </div>
    )
  }
}
