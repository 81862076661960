import React from 'react'

import classnames from 'classnames'

import { messages } from '../../../i18n/messages'
import SROInput from '../../widgets/SROFormComponents/SROInput'
import { SimpleCollection } from '../../widgets/SimpleCollection'
import FormValidator from '../../../utils/formValidator'
import SROFormComponent from '../../widgets/SROFormComponents/SROFormComponents'
import ConditionLine from './ConditionLine'

export default class RatingLine extends SROFormComponent {
  constructor(props) {
    super(props)

    this.references = {
      ratingName: React.createRef(),
      visitFrequency: React.createRef(),
      conditionCollection: React.createRef(),
    }

    this.addCondition = this.addCondition.bind(this)
  }

  getSchema() {
    return new FormValidator({
      name: {
        title: messages.NAME,
        required: true,
        refName: 'ratingName',
      },
      visitFrequencyDays: {
        title: messages.VISIT_FREQUENCY,
        required: true,
        gte: 7,
        refName: 'visitFrequency',
      },
    })
  }

  serialize() {
    const data = {
      id: this.props.id,
      name: this.references.ratingName.current.getValue(),
      visitFrequencyDays: this.references.visitFrequency.current.getValue(),
      formula: this.references.conditionCollection.current.serialize(),
    }

    this.validateBySchema(data)

    return data
  }

  addCondition() {
    this.references.conditionCollection.current.addItem()
  }

  render() {
    const forwardProps = {
      features: this.props.features,
      ops: this.props.ops,
      opsValidation: this.props.opsValidation,
    }

    return (
      <div className="rating-line">
        <div className="reorder-buttons-wrapper">
          <div className="reorder-buttons">
            <div
              className={classnames('move-button', 'move-up-button', {
                disabled: !this.props.onMoveUp,
              })}
              onClick={this.props.onMoveUp}
            >
              <span>▲</span>
            </div>
            <div
              className={classnames('move-button', 'move-down-button', {
                disabled: !this.props.onMoveDown,
              })}
              onClick={this.props.onMoveDown}
            >
              <span>▼</span>
            </div>
          </div>
        </div>
        <SROInput
          ref={this.references.ratingName}
          title={messages.RATING_NAME}
          defaultValue={this.props.name}
        />
        <SROInput
          ref={this.references.visitFrequency}
          title={messages.VISIT_FREQUENCY}
          defaultValue={this.props.visitFrequencyDays}
          numeric={true}
        />
        <SimpleCollection
          ref={this.references.conditionCollection}
          defaultItemList={this.props.formula}
          renderItem={(itemData) => <ConditionLine {...itemData} {...forwardProps} />}
        />
        <div className="rating-buttons">
          <div className="rating-button add-condition-button" onClick={this.addCondition}>
            {messages.ADD_CONDITION}
          </div>
          <div className="rating-button remove-rating-button" onClick={this.props.onRemove}>
            {messages.REMOVE_RATING}
          </div>
        </div>
        <div className="delimiter-line"></div>
      </div>
    )
  }
}
