import reactAdminSystemEnglishMessages from 'ra-language-english'

const reactAdminMandatoryKeys = {
  Unauthorized: 'Unauthorized',
}

const resources = {
  agentBreak: {
    name: 'Time break |||| Time break',
  },
  agentTeam: {
    name: 'Agent team |||| Agent teams',
  },
}

export default {
  ...reactAdminSystemEnglishMessages,
  ...reactAdminMandatoryKeys,
  resources,
  'Bad Request': 'Bad Request',
  ID: 'id',
  ACTIVITY_CREATED: 'New activity created successfully',
  ACTIVITY_WILL_APPEAR_LATER:
    'The activity will appear in the next working day after planning had occurred',
  ACTIVITY_CREATION_FAILED: 'New activity creation failed: %{error}',
  AGENT: 'Agent',
  CREATE_ACTIVITY: 'Create activity',
  RATING: 'Rating',
  CLIENT_NAME: 'Client name',
  ADDRESS: 'Address',
  CITY_NAME: 'City',
  DAYS_VISIT: 'Days visit',
  VISIT_PURPOSE: 'Visit purpose',
  VISIT_REMARK: 'Visit remark',
  REQUEST_NEW_SCHEDULE: 'Request a new schedule',
  EXPORT_ACTIVITY: 'Export activity',
  PRINT_ACTIVITY: 'Print activity',
  ACTIVITY_CALENDAR: 'Activity calendar',
  NO_ITEMS_TO_SHOW: 'No results found',
  CREATE_NEW_ACTIVITY_FOR_AGENT_X: 'Create new activity for %{agentName}',
  CANCEL: 'Cancel',
  SCHEDULED_AT: 'Scheduled at %{time}',
  ACTIVITY_RESCHEDULE_FAILED: 'Activity reschedule failed',
  ACTIVITY_RESCHEDULED: 'Activity reschedule complete',
  ALL_WEEK: 'All week',
  TOTAL_PLANNED_VISITS: 'Total planned visits',
  TOTAL_UNPLANNED_VISITS: 'Total unplanned visits',
  LOADING: 'Loading',
  PLANNED_VISIT: 'Planned visit',
  PLANNED_VISITS: 'Planned visits',
  UNPLANNED_VISITS: 'Unplanned visits',
  SELECT_DATE_RANGE: 'Select date range',
  DATE_ROUTE: "Day's planned route. Click to show map",
  DAY_TRAVEL_DISTANCE: "Day's travel distance",
  SHOW_DETAILS: 'Show details',
  APPLY: 'Apply',
  DAILY_ACTIVITY: 'Daily activity',
  ORDER_TOTAL: 'Order-T',
  S_TIME: 'S-Time',
  E_TIME: 'E-Time',
  LAST_VISIT: 'Last visit',
  LAST_ORDER_T: 'Last order-T',
  CANCELLATION: 'Cancellation',
  CANCELED: 'Canceled',
  NEW: 'New',
  NEW_ACTIVITIES: 'New activities',
  CLOSED: 'Closed activities',
  ACTIVITIES: 'Activities',
  TOTAL_ORDERS: 'Total orders',
  DAILY: 'Daily',
  MONTHLY: 'Monthly',
  SALES_GOALS: 'Sales goals',
  NOT_SELLING_REASON: 'Not selling reason: %{value}',
  CANCELLATION_REASON: 'Cancellation reason: %{value}',
  RESCHEDULE_REASON: 'Reschedule reason: %{value}',
  RESCHEDULE_TIME: 'Reschedule time: %{value}',
  NO_INFO_TO_SHOW: 'No additional info available',
  TOTAL_KM: 'Total KM',
  SETTINGS: 'Settings',
  SYSTEM_WELCOME: 'Welcome to Amodat - SRO',
  NEXT: 'Next',
  ACCOUNT: 'Account',
  ACCOUNT_DETAILS: 'Account details',
  AGENT_DEFAULT_DETAILS: 'Agent default details',
  ADD_WORK_HOURS: 'Add work hours',
  ACCOUNT_SETTINGS: 'Account settings',
  AGENT_CODE: 'Agent code',
  AGENT_NAME: 'Agent name',
  S_ADDRESS: 'S-Address',
  E_ADDRESS: 'E-Address',
  WORK_HOURS: 'Work hours',
  VISIT_TIME: 'Visit time',
  ACTUAL_VISIT_TIME: 'Actual V.time',
  EMAIL: 'Email',
  STATUS: 'Status',
  ACTIVE: 'Active',
  NAME: 'Name',
  VISIT_FREQUENCY: 'Visit frequency',
  FEATURE: 'Feature',
  OP: 'Operation',
  VALUE: 'Value',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
  SAVE: 'Save',
  ADD_DAY: 'Add day',
  RATING_NAME: 'Rating name',
  ADD_CONDITION: '+ Add condition',
  REMOVE_RATING: 'Remove rating',
  ADD_RATING: 'Add rating',
  AGENT_SETTINGS: 'Agent settings',
  MOVE_ITEM_UP: 'Move item up',
  MOVE_ITEM_DOWN: 'Move item down',
  AT_LEAST_ONE_RATING_REQUIRED: 'Error: At least one rating required',
  CHOOSE_FEATURE: 'Choose feature',
  PREVIEW: 'Preview',
  TOTAL_VISITS: 'Total visits',
  AGENT_EXPECTED_VISITS: 'Estimated visits',
  AGENT_ACTUAL_TOTAL_VISITS: 'Actual Visits',
  ACCOUNT_NAME: 'Account name',
  SAVING_FAILED: 'Saving failed',
  FINISH_SETUP: 'Finish setup',
  BACK: 'Back',
  FROM_MUST_BE_LT_TO: 'Start time must be before end time',
  MUST_FILL_START_AND_END: 'From time and to time are mandatory',
  KEY_IS_MANDATORY: "Field '%{title}' is mandatory",
  ERROR_OVER_MAX_LENGTH: "Field '%{title}' is longer than its max %{max} chars",
  ERROR_VALUE_MUST_BE_GT: "Field '%{title}' must be greater than %{gt}",
  ERROR_VALUE_MUST_BE_GTE: "Field '%{title}' must be greater than or equal to %{gte}",
  ERROR_VALUE_MUST_BE_LTE: "Field '%{title}' must be lower than or equal to %{lte}",
  ERROR_WORKING_HOURS_MUST_CONTAIN_MIN_1: 'Working hours must contain at least one day',
  ERROR_INVALID_EMAIL: "Field '%{title}' contains invalid email",
  TOTAL_MUST_BE_100_PERC: 'Total-% for agent %{agent} does not equal 100%',
  CLIENT: 'Client',
  V_TIME_PLANNED: 'Planned V.Time',
  V_TIME_ACTUAL: 'Actual V.Time',
  V_DURATION: 'V. Duration',
  AGENT_LOCATION: "%{agentName}'s location",
  AGENT_LOCATION_CANNOT_BE_DETECTED: "Agent's location cannot be detected",
  FREEZE_PERIOD: 'Freeze period',
  WAIT_PLANNING_SCHEDULE: 'Planning schedule for all agents, this might take a few minutes...',
  CORRECT_ERRORS_BEFORE_SAVING: 'There are some errors. Please correct them before saving',
  FINISHED_LOADING_REDIRECTING_MAIN: 'Finished planning, taking you to the main page...',
  UNKNOWN_ERROR: 'Unknown error occurred, please try again later or contact support',
  CUSTOM_RATING: 'Custom rating',
  NEXT_VISIT: 'Next visit',
  USED_SLASH_CREDIT: 'Used / Credit',
  MONTH_PURCHASE: 'Month purchase',
  UNPLANNED_ACTIVITY: 'Unplanned activity',
  ALL_AGENTS: 'All agents',
  EMPTY_VALUES_SET_TO_DEFAULT:
    "* Empty fields will get their value from the account's default value",
  ERROR_EMAIL_ALREADY_TAKEN: 'Agent %{externalAgentId}: Email is already taken',
  NEARBY_CLIENTS_RADIUS_METERS: 'Nearby clients radius (meters)',
  VISIT_MAX_DISTANCE_METERS: 'Visit max distance (meters)',
  MONTHLY_VISITS: 'Monthly visits',
  AGENT_RATE_PREFERENCES: 'Agent rate preferences',
  ERROR_AGENT_NOT_FOUND: 'Agent not found',
  NEXT_VISIT_DATE: 'Next visit date',
  DATE: 'Date',
  CLIENT_CODE: 'Client code',
  TOTAL_SUCCESS_VISITS: 'Total Success Visits',
  MONTHLY_VISITS_GRAPH: 'Monthly Visits Graph',
  TOTAL_DAILY_ORDERS: 'Total Daily Orders',
  DAILY_VISITS_GRAPH: 'Daily Visits Graph',
  CLIENTS_RATING: 'Clients Rating',
  CUSTOMERS_BY_RATING: 'Customers by rating',
  AGENTS_VISITS_BY_RATINGS: 'Visits by ratings',
  VISITS_BY_CATEGORIES: 'Visits by categories',
  TOTAL_ROUTE_TIME: 'Total route time',
  ACTUAL_VISIT_TIMES_PER_AGENT: 'Actual visit times per agent',
  ANNUAL_TREND: 'Annual trend - according to planned',
  AGENT_UPDATE_SUCCESS: 'Agent %{displayName} updated successfully',
  AGENT_UPDATE_FAIL: 'Updating agent %{displayName} failed',
  ACTIVITY_UPDATE_SUCCESS: 'Activity %{displayName} updated successfully',
  ACTIVITY_UPDATE_FAIL: 'Updating activity %{displayName} failed',
  USER_UPDATE_SUCCESS: 'User %{displayName} updated successfully',
  USER_UPDATE_FAIL: 'Updating user %{displayName} failed',
  ACCOUNT_UPDATE_SUCCESS: 'Account %{displayName} updated successfully',
  ACCOUNT_UPDATE_FAIL: 'Updating account %{displayName} failed',
  EDIT: 'Edit',
  NUMBER_OF_CLIENTS: 'Number of clients',
  EDIT_AGENT: 'Edit Agent',
  EDIT_ACCOUNT: 'Edit Account',
  CLIENT_RATING: 'Client Rating',
  SAVING_PLEASE_WAIT: 'Saving. Please wait...',
  ON_SITE_PLANNED: 'On-site Planned',
  ON_SITE_UNPLANNED: 'On-site Unplanned',
  BY_PHONE: 'By-phone',
  LEADS: 'Leads',
  ON_SITE_VISIT_TIME: 'On-site visit time',
  BY_PHONE_VISIT_TIME: 'By-phone visit time',
  OTHER: 'Other',
  CUSTOMERS_COUNT: 'Customers count',
  VISITS_COUNT: 'Visits count',
  TOTAL_HOURS: 'Total hours',
  BACK_TO_AGENTS: '◄ Back to agents',
  ON_SITE: 'On-site',
  SUCCESSFUL: 'Successful',
  ROUTE_TIME: 'Route time',
  ACTUAL_VISITS_TIME: 'Actual visits time',
  ACCOUNT_ID: 'Account id',
  AGENTS: 'Agents',
  SHOW: 'Show',
  API_KEY: 'Api key',
  CREATED: 'Created',
  UPDATED: 'Updated',
  BACK_TO_ACCOUNTS: '◄ Back to accounts',
  BACK_TO_USERS: '◄ Back to users',
  ADD_ACCOUNT: 'Add account',
  USER_ID: 'User ID',
  USERNAME: 'Username',
  LAST_LOGIN: 'Last login',
  ROLES: 'Roles',
  ADD_USER: 'Add user',
  PASSWORD: 'Password',
  AGENT_TEAM: 'Agent team',
  USERS: 'Users',
  ACCOUNTS: 'Accounts',
  MY_USER: 'My user',
  CLIENTS: 'Clients',
  REQUEST_ERROR: 'Error: %{errorMessage}',
  ROLE_ADMIN: 'Admin',
  ROLE_ACCOUNT_MANAGER: 'Account manager',
  ROLE_AGENT_TEAM_MANAGER: 'Agent team manager',
  ROLE_AGENT: 'Agent',
  ROLE: 'Role',
  EDIT_USER: 'Edit user',
  CREATE_USER: 'Create user',
  'ra.notification.created': 'Operation completed successfully',
  PROGRESS: 'Progress: %{percent}%',
  START_TIME: 'Start time',
  END_TIME: 'End time',
  AVG_KM_PER_VISIT: 'AVG KM/Visit',
  CREATE_ACCOUNT: 'Create account',
  USER_DETAILS: 'User details',
  LOGIN: 'Login',
  RESET_PASSWORD: 'Reset password',
  LOGIN_FAILED: 'Invalid username or bad password',
  CANNOT_CHANGE_OWN_STATUS: 'Cannot change own active status',
  CANNOT_CHANGE_OWN_ROLE: 'Cannot change own role',
  LOGOUT: 'Sign out',
  DASHBOARD: 'Dashboard',
  PLEASE_FILL_USERNAME: 'Please fill username or email',
  PASSWORD_EMAIL_SENT: "We've sent you an email with a link to reset your password",
  NEW_PASSWORD: 'New password',
  RE_ENTER_NEW_PASSWORD: 'Re-enter password',
  RESET: 'Reset',
  PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
  PLEASE_CHOOSE_A_NEW_PASSWORD: 'Please choose a new password',
  PASSWORD_RESET_WENT_WRONG: 'Something went wrong. The token might have expired, please try again',
  PASSWORD_RESET_SUCCESSFULLY: 'Password reset successfully',
  CONTINUE_TO_LOGIN: 'Continue to login',
  SRO_TITLE: 'Sales Route Optimization',
  ON_SITE_ACTIVITIES: 'Visit Activities',
  PHONE_ACTIVITIES: 'Phone Activities',
  PLANNED: 'Planned',
  ACTUAL: 'Actual',
  LABEL_PERCENT_HOURS: '%{label}: %{percent}% (%{hours} hours)',
  TRIP_END: 'Trip end',
  TRIP_START: 'Trip start',
  VISIT_TYPE: 'Visit Type',
  PHONE: 'Phone',
  NEARBY_CLIENTS: 'Nearby clients',
  VISIT: 'Visit',
  VISIT_TYPE_PHONE: 'Phone',
  NEARBY_ONLY_TODAY: 'Cannot display nearby clients for a date that is not today',
  LOADING_MAP: 'Loading map...',
  PLANNED_START_TIME: 'Planned Start Time',
  LAST_LOCATION: 'Last location',
  WORK_SUMMARY: 'Work summary',
  SALES_SUMMARY: 'Sales goals summary',
  SUCCESS_SUMMARY: 'Successful activities summary',
  ANALYTICS: 'Analytics',
  LAST_ORDER_DATE: 'Last order date',
  HEBREW: 'Hebrew',
  ENGLISH: 'English',
  LANGUAGE: 'Language',
  LOGOUT_LOGIN_TO_APPLY_CHANGES: 'Log-out and log-in to apply changes',
  SCORE_BY_CATEGORY: 'Score by category',
  SALES: 'Sales',
  VISITS: 'Visits',
  COLLECTION: 'Collection',
  SUCCESSFUL_VISITS: 'Successful Visits',
  AGENT_SCORE_DEFINITION: 'Agent score definition',
  COMPANY_TARGET: 'Company target',
  WEIGHT_PER_PARAMETER_IN_PERCENT: 'Weight per parameter in %',
  TOTAL: 'Total',
  TOTAL_AGENT_SCORE_MUST_BE_100_PERC: 'Total agent score must be 100%',
  AGENT_SCORE_CURRENT_MONTH: 'Agent score - current month',
  AGENT_SCORE_CURRENT_YEAR: 'Agent score - current year',
  AGENT_KPIS_NOT_CONFIGURED:
    'Agent KPIs are not configured, please go to Settings to see this page',
  AGENT_SCORE: 'Agent score',
  COMPANY_SCORE: 'Company score',
  ORDER_DATE: 'Order date',
  REFERENCE_NUM: 'Reference',
  SUPPLY_DATE: 'Supply date',
  LINE_NUM: 'Line #',
  ITEM_CODE: 'Item code',
  ITEM_NAME: 'Item name',
  QUANTITY: 'Quantity',
  ITEMS_PER_PACKAGE: 'Items per package',
  AMOUNT_OF_PACKAGES: 'Amount of packages',
  PRICE: 'Price',
  DISCOUNT: 'Discount',
  BONUS: 'Bonus',
  COMMENT: 'Comment',
  VAT: 'VAT',
  TOTAL_INCLUDING_VAT: 'Total including VAT',
  PRINT_BACK: 'Print Back',
  PRINT_BACK_SENT: 'Print back sent',
  OBJECTIVE_THIS_YEAR: 'Objective current year',
  OBJECTIVE_LAST_YEAR: 'Objective last year',
  MONTH: 'Month',
  MONTH_JAN: 'January',
  MONTH_FEB: 'February',
  MONTH_MAR: 'March',
  MONTH_APR: 'April',
  MONTH_MAY: 'May',
  MONTH_JUN: 'June',
  MONTH_JUL: 'July',
  MONTH_AUG: 'August',
  MONTH_SEP: 'September',
  MONTH_OCT: 'October',
  MONTH_NOV: 'November',
  MONTH_DEC: 'December',
  NO_INFO_TO_DISPLAY: 'No info to display',
  empty: '',
  time_breaks: 'Time breaks',
  add_time_break: 'Add time break',
  edit_time_break: 'Edit time break',
  break_hour_type: 'Break hour type',
  break_start_time: 'Break start time',
  break_end_time: 'Break end time',
  date_range: 'Date range',
  agentbreak: 'Time break',
  description: 'Description',
  start_date: 'Start date',
  end_date: 'End date',
  all_ratings: 'All ratings',
  account_location: 'Account location',
  last_visit_type: 'Last visit type',
  yes: 'yes',
  no: 'no',
  plan_failures: 'Plan failures',
  occurrence_date: 'Occurrence date',
  constraints: 'Constraints',
  weekday: 'Weekday',
  opening_hours: 'Opening hours',
  hours: 'Hours',
  dates: 'Date',
  type: 'Type',
  error: 'Error',
  'entity conflict, the entity already exists':
    'Entity conflict: an item with the same identifiers exists',
  disabled: 'Disabled',
  no_team: 'No team',
  agent_teams: 'Agent teams',
  add_agent_team: 'Add agent team',
  edit_agent_team: 'Edit agent team',
  'cannot complete this action due to a relation constraint':
    'Cannot complete this action due to an existing constraint on the record',
  sro: {
    back_to_agent_teams: '◄ Back to agent teams',
    back_to_time_breaks: '◄ Back to time breaks',
    remove_agents_from_team_before_deleting: 'Remove all agents from this team before deleting it',
    password_rules: 'Password must be at least 7 characters long',
    daily_visits: 'Daily visits',
    max_daily_phone_calls: 'Max daily phone calls',
    any: 'Any',
    phone_visit: 'Phone call',
    phone_visits: 'Phone calls',
    client_visit: 'Client visit',
    client_visits: 'Client visits',
    mode: 'Plan',
    sales_dashboard: 'Sales Dashboard',
    daily_goal: 'Daily goal',
    monthly_goal: 'Monthly goal',
    yearly_goal: 'Yearly goal',
    daily_agent_sales: 'Daily agent sales',
    monthly_agent_sales: 'Monthly agent sales',
    yearly_agent_sales: 'Yearly agent sales',
    sales_goal: 'Sales goal',
    sales_actual: 'Sales actual',
    current_year: 'Current year',
    last_year: 'Last year',
    location_tracking: 'Location tracking',
    location_tracking_screen_url: 'Location tracking screen URL',
  },
}
