import React, { useState, useEffect } from 'react'
import { useTranslate } from 'react-admin'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import classnames from 'classnames'
import QueryString from 'query-string'
import PropTypes from 'prop-types'

import AgentRow from './AgentRow'
import AgentDateRow from './AgentDateRow'
import TravelRow from './TravelRow'
import AgentContentHeader from './AgentContentHeader'
import CreateActivityModal from '../../containers/CreateActivityModal'
import DateRangeInput from '../../components/widgets/DateRangeInput'
import ShadowTable from '../ShadowTable'
import SRODropdown from '../widgets/SROFormComponents/SRODropdown'

import './activity-calendar-view.scss'
import { SROMoment } from '../../utils/timeUtils'
import { ActivityCalendarResource } from '../../providers/resources/requestResources'
import { useActivityCalendarData } from '../../hooks/activityCalendarHooks'

const PRINT_MODE = 'print'
const PRINTABLE_ID = 'printable'

const ActivityType = {
  Any: 'any',
  Phone: 'phone',
  Client: 'client',
}

const AgentSpacing = () => (
  <tr>
    <th className="agent-spacing"></th>
  </tr>
)

function generatePrintLink(from, to, agentId) {
  const params = {
    mode: PRINT_MODE,
    from: from.formatDate(),
    to: to.formatDate(),
    agentId,
  }
  return `/${ActivityCalendarResource.RESOURCE_NAME}?${QueryString.stringify(params)}`
}

const ActivityCalendarView = ({ showCreateActivityForm }) => {
  const { search } = useLocation()
  const translate = useTranslate()

  const urlParams = new URLSearchParams(search)

  const isPrintMode = urlParams.get('mode') === PRINT_MODE
  const [isPrinting, setIsPrinting] = useState(false)
  const [dateRange, setDateRange] = useState({
    from: isPrintMode ? SROMoment.fromString(urlParams.get('from')) : SROMoment.now(),
    to: isPrintMode ? SROMoment.fromString(urlParams.get('to')) : SROMoment.now().add(7, 'days'),
  })
  const [activityType, setActivityType] = useState(ActivityType.Any)
  const agentId = parseInt(urlParams.get('agentId'))

  const [openLines, setOpenLines] = useState({})
  const toggleLine = (lineId) => setOpenLines({ ...openLines, [lineId]: !openLines[lineId] })

  const data = useActivityCalendarData(dateRange)

  useEffect(() => {
    if (isPrintMode && !isPrinting && !data.loading) {
      setIsPrinting(true)
      window.print()
      window.close()
    }
  }, [isPrintMode, isPrinting, data, setIsPrinting])

  function getRating(travel) {
    const client = travel.client
    if (!client) {
      return {}
    }

    return _.get(data.rateByIdByAccountId, [client.accountId, client.calculatedRateId], {})
  }

  function getCounts(countData) {
    if (activityType === ActivityType.Phone) {
      return {
        plannedCount: countData.counts[true][true],
        unplannedCount: countData.counts[false][true],
      }
    }
    if (activityType === ActivityType.Client) {
      return {
        plannedCount: countData.counts[true][false],
        unplannedCount: countData.counts[false][false],
      }
    }

    return {
      plannedCount: countData.counts[true][true] + countData.counts[true][false],
      unplannedCount: countData.counts[false][true] + countData.counts[false][false],
    }
  }

  function renderContentRows(formattedActivities) {
    const lines = []

    formattedActivities.forEach((travel) => {
      lines.push(
        <AgentRow
          onToggle={toggleLine}
          openLines={openLines}
          key={travel.id}
          data={travel}
          showCreateActivityForm={showCreateActivityForm}
          isOpen={!!openLines[travel.id]}
          getRating={getRating}
          printLink={generatePrintLink(dateRange.from, dateRange.to, travel.agentId)}
          counts={getCounts(travel)}
        />,
      )

      if (openLines[travel.id] || isPrintMode) {
        lines.push(<AgentContentHeader key={`header_${travel.id}`} />)

        travel.subs.forEach((agentDate) => {
          lines.push(
            <AgentDateRow
              key={agentDate.id}
              data={agentDate}
              onToggle={toggleLine}
              openLines={openLines}
              agentId={travel.agentId}
              externalAgentId={travel.externalAgentId}
              date={agentDate.date.formatDate()}
              counts={getCounts(agentDate)}
            />,
          )

          if (!openLines[agentDate.id] && !isPrintMode) {
            return
          }

          const [phoneActivities, visitActivities] = _(agentDate.subs)
            .filter((activity) => {
              if (activityType === ActivityType.Any) return true
              if (activityType === ActivityType.Phone) return activity.isPhone
              if (activityType === ActivityType.Client) return !activity.isPhone
              return true
            })
            .partition((activity) => activity.isPhone)
            .value()
          const sortedActivities = visitActivities.concat(phoneActivities)

          sortedActivities.forEach((travel) => {
            if (!travel.activityId) {
              return
            }

            lines.push(<TravelRow key={travel.id} data={travel} rating={getRating(travel)} />)
          })
        })
      }

      lines.push(<AgentSpacing key={`${travel.id}_spacing`} />)
    })

    return lines
  }

  function showResults(data) {
    const { activities, loading } = data

    if (loading) {
      return <ShadowTable />
    }

    if (!Array.isArray(activities) || activities.length === 0) {
      return <div className="no-items-to-show">{translate('NO_ITEMS_TO_SHOW')}</div>
    }

    const formattedActivities = isPrintMode
      ? activities.filter((agent) => agent.agentId === agentId)
      : activities

    return (
      <table
        id={PRINTABLE_ID}
        className={classnames('activity-calendar-table', { printMode: isPrintMode })}
      >
        <tbody>{renderContentRows(formattedActivities)}</tbody>
      </table>
    )
  }

  return (
    <div className="activity-calendar-page page">
      <div className="top-elements">
        <h1 className="page-title float-left">{translate('ACTIVITY_CALENDAR')}</h1>
        <DateRangeInput
          isSingle={false}
          onChange={(from, to) => setDateRange({ from, to })}
          from={dateRange.from}
          to={dateRange.to}
          className="left-item"
        />
        <SRODropdown
          options={[
            { id: ActivityType.Any, name: translate('sro.any') },
            { id: ActivityType.Phone, name: translate('sro.phone_visits') },
            { id: ActivityType.Client, name: translate('sro.client_visits') },
          ]}
          value={activityType}
          onChange={(value) => setActivityType(value)}
        />
        <CreateActivityModal />
      </div>
      {showResults(data)}
    </div>
  )
}

ActivityCalendarView.propTypes = {
  showCreateActivityForm: PropTypes.func,
}

export default ActivityCalendarView
