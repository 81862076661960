import { put, takeEvery } from 'redux-saga/effects'
import { REQUEST_AGENT_CLIENT_LIST, receiveAgentClientList } from '../actions/createActivityForm'
import Requester from '../providers/request'
import { ClientResource } from '../providers/resources/requestResources'

function* fetch(action) {
  const data = yield new Requester().getRaw(ClientResource, action.params)
  yield put(receiveAgentClientList(data))
}

export default function* clientListSaga() {
  yield takeEvery(REQUEST_AGENT_CLIENT_LIST, fetch)
}
