import React from 'react'
import _ from 'lodash'
import ClickAwareBox from 'click-aware-box'
import PropTypes from 'prop-types'

import LayerFilter from './LayerFilter'
import { MAP_LAYERS } from '../sroMapLayerDefs'
import GreenCheckbox from '../../widgets/GreenCheckbox'
import Icon from '../../icons'

import './layer-selector.scss'

export default class LayerSelector extends React.Component {
  constructor(props) {
    super(props)

    this.layerFilter = new LayerFilter({
      layerConfig: MAP_LAYERS,
      requestParams: this.props.requestParams,
    })

    this.state = {
      ...this.state,
      popupVisible: false,
      layers: this.layerFilter.getLayers(),
    }

    this.hidePopup = this.hidePopup.bind(this)
    this.togglePopup = this.togglePopup.bind(this)
    this.onToggleCheckbox = this.onToggleCheckbox.bind(this)
    this.notifyState = this.notifyState.bind(this)

    this.notifyState()
  }

  hidePopup() {
    this.setState({ popupVisible: false })
  }

  notifyState() {
    this.props.onLayerFilterChange(this.layerFilter.filter)
  }

  onToggleCheckbox(name) {
    this.layerFilter.toggleLayer(name)

    this.setState({ layers: this.layerFilter.getLayers() }, this.notifyState)
  }

  renderPopup() {
    if (!this.state.popupVisible) {
      return null
    }

    return (
      <ClickAwareBox className="layer-selection-popup" onClickOut={this.hidePopup}>
        <div className="layer-list">
          {_.map(this.state.layers, (layer) => (
            <div className="layer" key={layer.name}>
              <GreenCheckbox
                disabled={!layer.enabled.isEnabled}
                disabledMessage={layer.enabled.denialMessage}
                className="layer-checkbox"
                onToggle={() => this.onToggleCheckbox(layer.name)}
                checked={layer.activated}
              />
              <div className="layer-name">{layer.title}</div>
            </div>
          ))}
        </div>
        <div className="popup-triangle-wrapper">
          <div className="popup-triangle"></div>
        </div>
      </ClickAwareBox>
    )
  }

  togglePopup(e) {
    e.stopPropagation()

    this.setState({ popupVisible: !this.state.popupVisible })
  }

  renderIcon() {
    return (
      <div className="map-button layers" onClick={this.togglePopup}>
        <Icon.layers />
      </div>
    )
  }

  render() {
    return (
      <div className="map-button-wrapper layer-button-wrapper">
        {this.renderIcon()}
        {this.renderPopup()}
      </div>
    )
  }
}

LayerSelector.propTypes = {
  requestParams: PropTypes.object,
  onLayerFilterChange: PropTypes.func,
}

LayerSelector.defaultProps = {
  onLayerFilterChange: () => {},
}
